import get from 'lodash/get'
import { CG_ADVANCED_ROLE_SYS_NAMES, CG_BASE_ROLE_SYS_NAMES } from './constants'
import {
  AssignedContact,
  Predicates,
  ContactsFilters,
  Role,
  TaskFilterKeys,
  ContactToEdit
} from './types'

interface Cell {
  columnKey: string
  content: string
}

interface Row {
  id: string
  cells: Cell[]
}

export const getNormalizedCellContent = (columnKey: string, row: Row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'firstName':
    case 'lastName':
    case 'company':
    case 'isCounselGoUser':
    case 'isCounselGoAdmin':
      return cellContent.toLowerCase()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

export const createPredicates = (contactsFilters: ContactsFilters) => {
  const predicates: Predicates = {}
  const contactsFiltersKeys = Object.keys(contactsFilters)

  contactsFiltersKeys.forEach(key => {
    if (!contactsFilters[key as keyof ContactsFilters]) {
      predicates[key as keyof Predicates] = () => true
    } else {
      if (['labelIds', 'roleIds'].includes(key)) {
        predicates[key as keyof Predicates] = (contact: AssignedContact) => {
          const valuesArr = contactsFilters[key as keyof ContactsFilters]?.values?.map(v => v.value)

          return Boolean(valuesArr?.some(ele => contact[key as TaskFilterKeys].includes(ele)))
        }
      }
    }
  })
  return predicates
}

export const filterContacts = (
  contacts: AssignedContact[],
  predicates: Predicates
): AssignedContact[] => {
  const filterKeys = Object.keys(predicates)
  return contacts.filter(item => {
    return filterKeys.every(key => {
      if (typeof predicates[key as keyof Predicates] !== 'function') return true
      return predicates[key](item)
    })
  })
}

export const extractCgRoles = (arr: Role[]) =>
  arr.filter(item => CG_ADVANCED_ROLE_SYS_NAMES.includes(item.systemName))

export const extractLabels = (arr: Role[]) =>
  arr.filter(
    item => ![...CG_BASE_ROLE_SYS_NAMES, ...CG_ADVANCED_ROLE_SYS_NAMES].includes(item.systemName)
  )

export const determineEditableFields = (contact: ContactToEdit, roles: Role[]) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    title,
    org,
    isCounselGoAdmin,
    isCounselGoUser,
    roleIds,
    labelIds
  } = contact
  return {
    firstName,
    lastName,
    email,
    phone,
    title,
    isCgUser: isCounselGoUser === 'Yes',
    isCgAdmin: isCounselGoAdmin === 'Yes',
    ...(org?.type && ['client', 'vendor'].includes(org.type)
      ? { company: { label: org.name, value: org.id } }
      : { company: { label: 'Other', value: -1 }, companyName: org?.name }),
    cgRoles: isCounselGoUser === 'Yes' ? roles.filter(role => roleIds.includes(role.id)) : [],
    labels: roles.filter(role => labelIds.includes(role.id))
  }
}
