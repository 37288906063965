import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { ContactToEdit } from '../types'

type Props = {
  rowId: number
  contact: ContactToEdit
  showResendInvite: boolean
  editContact: (row: ContactToEdit) => void
  deleteContact: (row: ContactToEdit) => void
  resendInvite: (row: ContactToEdit) => void
}

const ActionsPopover = ({
  rowId,
  contact,
  showResendInvite,
  deleteContact,
  resendInvite,
  editContact
}: Props) => {
  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
        delayHide={1}
        isCapture
        event="click"
        globalEventOff="click"
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={() => {
              ReactTooltip.hide()
              editContact(contact)
            }}
          >
            Edit
          </li>
          <li className={s.actionItem} onClick={() => deleteContact(contact)}>
            Delete
          </li>
          {showResendInvite ? (
            <li className={s.actionItem} onClick={() => resendInvite(contact)}>
              Resend Invite
            </li>
          ) : null}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
