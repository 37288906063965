import get from 'lodash/get'
import keys from 'lodash/keys'
import values from 'lodash/values'
import string from 'lodash/string'
import moment from 'moment'

import { USER_SETTING } from 'utils/constants'

export const fromUserSettings = ({
  legal_entity,
  org_role,
  approval_authority,
  approval_authority_string,
  invoice_escalation,
  default_template,
  last_login,
  forced_logout,
  email_preference,
  google_connect_url,
  connected_to_google,
  client_using_sso,
  preview_dashboard,
  tabuser_active,
  tabuser_id,
  user_id,
  login_preference,
  can_edit_login_preference,
  timezone
}) => ({
  legalEntity: legal_entity,
  userRole: org_role,
  approvalAuthority: approval_authority,
  approvalAuthorityString: approval_authority_string,
  invoiceEscalation: invoice_escalation,
  emailPreference: email_preference,
  defaultTemplate: default_template,
  lastLogin: last_login ? moment(last_login).format('L') : 'N/A',
  forcedLogout: moment(forced_logout).format('L'),
  googleConnectUrl: google_connect_url,
  connectedToGoogle: connected_to_google,
  clientUsingSSO: client_using_sso,
  tabUserActive: tabuser_active,
  tabUserId: tabuser_id,
  userId: user_id,
  loginPreference: {
    value: login_preference,
    label: login_preference === 'SSO' ? login_preference : string.capitalize(login_preference)
  },
  canEditLoginPreference: can_edit_login_preference,
  timezone
})

export const toUserSetting = userSetting => {
  const SETTING_NAMES = values(USER_SETTING)
  const settingName = keys(userSetting).find(key => SETTING_NAMES.includes(key))

  return {
    defaultTemplate: {
      url: '/company/user/update/default_template/',
      params: {
        pk: userSetting.userId,
        value: get(userSetting, 'defaultTemplate', null)
      }
    },
    timezone: {
      url: '/company/user/update/timezone/',
      params: {
        pk: userSetting.userId,
        value: get(userSetting, 'timezone', null)
      }
    },
    tabAccessCreate: {
      url: '/tableau/users/create',
      params: {
        user_id: String(userSetting.userId)
      }
    },
    tabAccessDelete: {
      url: '/tableau/users/delete',
      params: {
        tab_user_id: String(get(userSetting, 'tabAccessDelete'))
      }
    },
    legalEntity: {
      url: '/user/update/name/',
      params: {
        pk: userSetting.userId,
        name: 'legal-entity',
        value: get(userSetting, 'legalEntity', -1)
      }
    },
    loginPreference: {
      url: '/user/update/login_preference/',
      params: {
        pk: userSetting.userId,
        value: String(get(userSetting, 'loginPreference'))
      }
    },
    emailPreference: {
      url: '/user/update/email_batching_preference/',
      params: {
        value: get(userSetting, 'emailPreference')
      }
    }
  }[settingName]
}
