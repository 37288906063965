import { useState, useEffect } from 'react'
import s from 'common/Comments/Comments.scss'
import {
  Spinner,
  Modal,
  useLoading,
  withConfirmation,
  Button,
  ModalContainer
} from 'simple-core-ui'
import { makeGetRequest, makeFormDataPost, makeDeleteRequest } from 'utils/api'
import MessageArea from 'common/Comments/MessageArea/MessageArea'
import Message from 'common/Comments/Message/Message'
import Empty from 'common/Comments/Empty/Empty'
import { useDispatch } from 'react-redux'
import RichTextEditor from 'common/RichTextEditor/RichTextEditor'
import escape from 'lodash/escape'
import unescape from 'lodash/unescape'
import update from 'immutability-helper'
import { openLink } from 'utils/helpers'

const deleteConf = {
  title: 'Delete',
  text: 'Are you sure you want to delete your update?'
}

const updates2Comments = updates => {
  return updates.map(c => {
    return {
      user: {
        full_name: c.update_from_user_name,
        id: window.credentials.user.id
      },
      timestamp: new Date(c.created_date).getTime(),
      id: c.id,
      text: c.status_update,
      isRichText: c.is_rich_text
    }
  })
}

const MatterUpdates = ({ user, matterId, readOnly, isError }) => {
  const [value, setValue] = useState('')
  const [messages, setMessages] = useState([])
  const [editingMessage, setEditingMessage] = useState('')
  const [editingIndex, setEditingIndex] = useState(-1)
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()

  const updateComment = async (message, index) => {
    try {
      await makeFormDataPost(`/manage/matters/update_matter_status/${matterId}/`, {
        matterstatusupdate: message.isRichText ? message.text : unescape(message.text),
        status_update: message.id,
        is_rich_text: true
      })

      setMessages(
        update(messages, {
          [index]: {
            $set: {
              ...message,
              isRichText: true,
              text: message.isRichText ? message.text : unescape(message.text)
            }
          }
        })
      )
      setValue('')
      setEditingIndex(-1)
      setEditingMessage('')
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const submitComment = async () => {
    try {
      const response = await withLoadingLocks(
        makeFormDataPost(`/manage/matters/update_matter_status/${matterId}/`, {
          matterstatusupdate: value
        })
      )

      await fetchUpdates()
      setValue('')
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const editComment = (message, index) => {
    setEditingIndex(index)
    setEditingMessage(message.isRichText ? message : { ...message, text: escape(message.text) })
  }

  const deleteComment = withConfirmation(async (id, index) => {
    try {
      await makeDeleteRequest(`/manage/matters/remove_matter_status_update/${id}/`)
      setMessages(update(messages, { $splice: [[index, 1]] }))
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }, deleteConf)

  const fetchUpdates = async () => {
    try {
      const response = await withLoadingLocks(
        makeGetRequest(`/manage/matters/matter_status_updates/${matterId}/`)
      )

      setMessages(updates2Comments(response.matter_status_updates))
    } catch (error) {
      if (error.response.status === 403) {
        return
      }
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      await fetchUpdates()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadXlsx = async openModal => {
    const url = `/manage/matters/matter_status/excel/download/${matterId}/`
    if (messages.length < 50) {
      openLink(url)
    } else {
      await makeGetRequest(url)
      openModal()
    }
  }

  return (
    <div className={s.container}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your report is ready."
          confirmText="OK"
          size="sm"
          hasCloseIcon={false}
          confirmCb={() => null}
        >
          {openModal => (
            <Button isPrimary isOutline onClick={() => downloadXlsx(openModal)}>
              Download XLSX
            </Button>
          )}
        </ModalContainer>
      </div>
      {isError ? (
        <Empty>An error has occurred.</Empty>
      ) : isLoading ? (
        <Empty>
          <Spinner />
        </Empty>
      ) : messages.length ? (
        <ul className={s.messages}>
          {messages.map((message, idx) => (
            <Message
              user={user}
              message={message}
              onDelete={e => deleteComment(message.id, idx)}
              onEdit={e => editComment(message, idx)}
              key={message.id}
              readOnly={readOnly}
              withRichText={message.isRichText}
              fullWidth
            />
          ))}
        </ul>
      ) : (
        <Empty>Type your first message below...</Empty>
      )}
      <Modal
        title="Edit Update"
        size="md"
        confirmText="Submit"
        isVisible={editingIndex !== -1}
        content={
          <RichTextEditor
            value={editingMessage?.text ?? ''}
            onChange={e => {
              if (!editingMessage) return
              const obj = { ...editingMessage, text: e }
              setEditingMessage(obj)
            }}
            isExpanded
          />
        }
        cancelCb={() => setEditingIndex(-1)}
        confirmCb={() => updateComment(editingMessage, editingIndex)}
      />
      <MessageArea
        isRichTextEditor
        placeholder="Add an update..."
        value={value}
        changeHandler={setValue}
        clickHandler={submitComment}
        buttonContent="Submit"
        readOnly={readOnly}
        isLoading={isLoading}
      />
    </div>
  )
}

export default MatterUpdates
