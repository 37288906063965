import { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DataTableWrapper,
  Button,
  coreThemes,
  ModalContainer,
  ButtonDropdown,
  Ellipsis
} from 'simple-core-ui'
import { getMatterProperty } from './utils'
import s from './MatterList.scss'
import ACT from './actions'
import APP_ACT from 'app/actions'
import { Tabs } from './Tabs'
import moment from 'moment'
import { ActionsPopover } from './ActionsPopover'
import { Filters } from './Filters'
import swal from 'sweetalert'
import axios from 'axios'
import { UploadProgress } from './UploadProgress'
import { makePostRequest } from 'utils/api'
import { EditMatterModal } from './EditMatterModal'
import { serializeParams } from './serializers'
import qs from 'query-string'
import { useMatterGroupLabels, useEntityLabels } from 'hooks/selectors'
import { MATTER_STATUS } from 'matters/constants'
import { useNavigate } from 'react-router-dom'

const MattersListContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const matters = useSelector(state => state.matters.matterList)
  const params = useSelector(state => state.matters.matterListParams)
  const fragment = useSelector(state => state.matters.matterListFragment)
  const isLoading = useSelector(state => state.app.loading.MATTER_LIST_FETCH)
  const pageTitle = useSelector(state => state.matters.pageTitle)
  const attributeName = useSelector(state => state.matters.attributeName)
  const backLink = useSelector(state => state.matters.backLink)

  const [selectedTab, setSelectedTab] = useState(params.category)
  const [selectedRows, setSelectedRows] = useState([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [isUploadProgressModalVisible, setIsUploadProgressModalVisible] = useState(false)
  const [percent, setPercent] = useState(0)
  const [action, setAction] = useState('')
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [newPracticeArea, setNewPracticeArea] = useState(null)
  const [newMatterLead, setNewMatterLead] = useState(null)
  const [editedMatterId, setEditedMatterId] = useState('')
  const [isBulkEdit, setIsBulkEdit] = useState(false)
  const [isEditMatterLead, setIsEditMatterLead] = useState(false)
  const [isEditMatterGroup, setIsEditMatterGroup] = useState(false)

  const selectedRowsLength = selectedRows.length

  const entityLabels = useEntityLabels()
  const matterGroupLabels = useMatterGroupLabels()

  useEffect(() => {
    var baseUrl = window.location.href.split('#')[0]
    if (fragment) {
      window.location.replace(`${baseUrl}#${fragment}`)
      try {
        window.localStorage.setItem('savedMatterList', fragment)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Local Storage is full. Please empty data')
      }
    }
  }, [fragment])

  const allRequests = async (action, cb) => {
    const toRemove = []

    for (let id of selectedRows) {
      try {
        if (action === 'delete') {
          await axios.post(`/manage/matters/${id}/${action}`)
        } else if (action === 'close') {
          await axios.put(`/manage/matters/v2/matter_fields_update/${id}`, {
            closed: true
          })
        } else {
          const matter = matters.results.find(m => m.id === id)
          const paEditable = matter.mattergroup_editable

          await axios.put(`/manage/matters/v2/matter_fields_update/${id}`, {
            ...(paEditable && newPracticeArea ? { practice_area: newPracticeArea.value } : {}),
            ...(newMatterLead ? { lead: newMatterLead.value } : {})
          })
        }
        setPercent(percent => percent + 1)
      } catch (e) {
        toRemove.push(id)
        setPercent(percent => percent + 1)
      }
    }

    const finalArr = selectedRows.filter(r => !toRemove.includes(r))

    cb && cb(finalArr)

    dispatch({
      type: ACT.MATTER_SUMMARY_FETCH_REQUESTED
    })

    const actionLabel = action === 'delete' ? 'delete' : action === 'close' ? 'close' : 'update'

    if (toRemove.length) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: `One or more matters failed to ${actionLabel}. Please try again or contact Support if the issue persists.`,
          level: 'error'
        }
      })
    }
  }

  const results = useMemo(() => {
    const { results, total } = matters
    const columns = [
      {
        columnKey: 'short_name',
        content: 'Matter Name',
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          return (
            <div className={s.matterDetails}>
              <a href={`/v2/matters/${row.id}?fromMatterList=true`}>{cell.content}</a>
              <p>{getMatterProperty(matters.results, row.id, 'client_matter_id')}</p>
              <Ellipsis>{getMatterProperty(matters.results, row.id, 'description')}</Ellipsis>
            </div>
          )
        }
      },
      {
        columnKey: 'open_date',
        content: 'Date Opened',
        isSortable: true,
        isFilterable: true,
        render: cell => {
          if (cell.content === '----') return cell.content
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'mattergroups',
        content: matterGroupLabels[0],
        isSortable: true,
        render: cell => {
          if (!cell.content.length) return '----'
          return cell.content.map(r => r.name).join(', ')
        }
      },
      {
        columnKey: 'lead',
        content: 'Matter Lead',
        isSortable: true,
        render: cell => {
          if (!cell.content.id) return '----'
          return cell.content.name
        }
      },
      {
        columnKey: 'vendors',
        content: 'Vendors',
        isSortable: false,
        render: cell => {
          if (!cell.content.length) return '----'
          const str = cell.content.map(r => r).join(', ')
          return <Ellipsis>{str}</Ellipsis>
        }
      },
      {
        columnKey: 'legal_entity',
        content: entityLabels[0],
        isSortable: true,
        render: cell => {
          if (!cell.content.id) return '----'
          return cell.content.short_name
        }
      },
      {
        columnKey: 'status',
        content: 'Status',
        isSortable: true,
        style: {
          textAlign: 'center'
        },
        render: cell => {
          return cell.content
        }
      },
      {
        columnKey: 'needs_attention',
        content: 'Template Update',
        isSortable: false,
        style: {
          textAlign: 'center'
        },
        render: (cell, row) => {
          return row.needs_attention ? 'Yes' : 'No'
        }
      },
      {
        columnKey: 'last_billed',
        content: 'Last Worked',
        isSortable: true,
        isFilterable: true,
        render: cell => {
          if (cell.content === '----') return cell.content
          return moment(cell.content).format('M/D/YYYY')
        }
      }
    ]
    return {
      columns,
      rows: results,
      totalEntries: total,
      filteredTotal: total
    }
  }, [matters, matterGroupLabels, entityLabels])

  const updateTable = updatedParams => {
    resetRowSelection()
    dispatch({
      type: ACT.MATTER_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...params,
          ...updatedParams,
          search: {
            ...params.search,
            keyword: updatedParams.search
          }
        },
        status: selectedTab
      }
    })
  }

  useEffect(() => {
    const isFiltered = window.credentials.user.role === 'filtered'
    const categoryHash = qs.parse(window.location.hash).category

    const DEFAULT_TAB = isFiltered ? 'mine' : 'all'
    const initialTab = categoryHash || DEFAULT_TAB

    dispatch({
      type: ACT.MATTER_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        status: initialTab,
        params: {
          ...params,
          search: {
            ...params.search,
            ...(isFiltered && !categoryHash
              ? {
                  filter_by: +window.credentials.user.id
                }
              : {}),
            ...(categoryHash === 'mine'
              ? {
                  filter_by: +window.credentials.user.id
                }
              : {})
          }
        }
      }
    })

    dispatch({
      type: ACT.MATTER_SUMMARY_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: { params }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCustomAction = row => {
    if ((row.status !== MATTER_STATUS.CLOSED || !row.not_deletable) && row.editable) {
      return (
        <ActionsPopover
          canDelete={!row.not_deletable}
          canEdit={row.status !== MATTER_STATUS.CLOSED}
          rowId={row.id}
          editMatterLead={editMatterLead}
          editMatterGroup={editMatterGroup}
          deleteMatter={deleteMatter}
          matterGroupLabels={matterGroupLabels}
        />
      )
    } else {
      return null
    }
  }

  const editMatter = id => {
    const practiceArea = getMatterProperty(matters.results, id, 'mattergroups')[0]
    const lead = getMatterProperty(matters.results, id, 'lead')
    setEditedMatterId(id)
    setIsEditModalVisible(true)
    setNewPracticeArea(
      practiceArea && practiceArea.id ? { value: practiceArea.id, label: practiceArea.name } : null
    )
    setNewMatterLead(lead.id ? { value: lead.id, label: lead.name } : null)
  }

  const editMatterLead = id => {
    setIsEditMatterLead(true)
    editMatter(id)
  }

  const editMatterGroup = id => {
    setIsEditMatterGroup(true)
    editMatter(id)
  }

  const deleteMatter = async id => {
    const willDelete = await swal({
      title: 'Delete matter',
      text:
        'Are you sure you want to delete ' +
        getMatterProperty(matters.results, id, 'short_name') +
        '?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willDelete) {
      dispatch({
        type: ACT.MATTER_LIST_DELETE_REQUESTED,
        payload: {
          id,
          selectedTab
        }
      })
    }
  }

  const onSearch = ({ filterParams: updatedFilterParams }) => {
    resetRowSelection()
    dispatch({
      type: ACT.MATTER_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...params,
          pageSize: params.pageSize,
          ordering: params.ordering,
          page: 1,
          search: {
            keyword: params.search.keyword,
            ...updatedFilterParams
          }
        },
        status: selectedTab
      }
    })
  }

  const selectRow = ({ id, editable }) => {
    setSelectedRows(prevSelectedRows => {
      const index = prevSelectedRows.indexOf(id)
      if (index !== -1) {
        const newSelectedRows = [...prevSelectedRows]
        newSelectedRows.splice(index, 1)
        return newSelectedRows
      } else {
        return editable ? [...prevSelectedRows, id] : prevSelectedRows
      }
    })
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    if (allRowsSelected) {
      setSelectedRows([])
    } else {
      setSelectedRows(matters.results.filter(m => m.editable).map(m => m.id))
    }
  }
  const resetRowSelection = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const closeEditModal = () => {
    setIsEditModalVisible(false)
    setNewMatterLead(null)
    setNewPracticeArea(null)
    setEditedMatterId('')
    setIsBulkEdit(false)
    setIsEditMatterGroup(false)
    setIsEditMatterLead(false)
  }

  const updateMatter = () => {
    dispatch({
      type: ACT.UPDATE_MATTER_REQUESTED,
      payload: {
        practiceArea: newPracticeArea,
        matterLead: newMatterLead,
        id: editedMatterId,
        selectedTab
      }
    })
    closeEditModal()
  }

  const downloadXlsx = async openModal => {
    const response = await makePostRequest(
      '/manage/matters/v2/xlsx_download/',
      serializeParams(params)
    )

    openModal()
  }

  const deleteMatters = async () => {
    const willApprove = await swal({
      title: `You are deleting ${selectedRowsLength} selected ${
        selectedRowsLength === 1 ? 'matter' : 'matters'
      }.`,
      text: 'Are you sure you want to delete?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willApprove) {
      setIsUploadProgressModalVisible(true)
      setAction('delete')
      allRequests('delete', finalArr => {
        dispatch({
          type: ACT.SELECTED_MATTERS_DELETED,
          payload: {
            ids: finalArr,
            selectedTab
          }
        })
      })
    }
  }

  const showEditMattersModal = () => {
    setIsEditModalVisible(true)
    setIsBulkEdit(true)
  }

  const updateMatters = async () => {
    const willApprove = await swal({
      title: `You are updating ${selectedRowsLength} selected ${
        selectedRowsLength === 1 ? 'matter' : 'matters'
      }.`,
      text: 'Are you sure you want to update?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willApprove) {
      setIsUploadProgressModalVisible(true)
      setAction('update')
      allRequests('update', finalArr => {
        dispatch({
          type: ACT.SELECTED_MATTERS_UPDATED,
          payload: {
            ids: finalArr,
            practiceArea: newPracticeArea,
            matterLead: newMatterLead,
            selectedTab
          }
        })
      })
      closeEditModal()
    }
  }

  const closeMatters = async () => {
    const willApprove = await swal({
      title: `You are closing ${selectedRowsLength} selected ${
        selectedRowsLength === 1 ? 'matter' : 'matters'
      }.`,
      text: 'Are you sure you want to close?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willApprove) {
      setIsUploadProgressModalVisible(true)
      setAction('close')
      allRequests('close', finalArr => {
        dispatch({
          type: ACT.SELECTED_MATTERS_CLOSED,
          payload: {
            ids: finalArr
          }
        })
      })
    }
  }

  const changeTab = tab => {
    dispatch({ type: ACT.MATTER_LIST_RESET })
    setSelectedTab(tab)
    setSelectedRows([])
  }

  const triggerBulkAction = option => {
    if (option.value === 'close') {
      closeMatters()
    }
    if (option.value === 'edit') {
      showEditMattersModal()
    }
    if (option.value === 'delete') {
      deleteMatters()
    }
  }

  const setBulkOptions = () => {
    return [
      { label: 'Close', value: 'close' },
      { label: 'Edit', value: 'edit' },
      { label: 'Delete', value: 'delete' }
    ]
  }

  const bulkButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRows.length})`}
      options={setBulkOptions()}
      onSelect={triggerBulkAction}
      listStyles={{ width: '120px' }}
      listItemStyles={{ fontSize: '14px', lineHeight: '15px' }}
      isLoading={isLoading}
    />
  )

  const addMatterButton = window.credentials.quickNavUrls.some(e => e.name === 'add_new_matter')
  return (
    <section>
      {backLink ? (
        <div style={{ marginBottom: 20 }}>
          <a href={backLink}>{`<< Back To ${attributeName}`}</a>
        </div>
      ) : null}
      <div className="box">
        <div className="box-content" style={{ minHeight: '80vh' }}>
          <div className={s.header}>
            <h2 className={s.title}>{pageTitle}</h2>
            <span className={s.rightActions}>
              <ModalContainer
                title="We are working on your download"
                content="You’ll receive an email once your report is ready."
                confirmText="OK"
                confirmCb={() => {}}
                size="sm"
                hasCloseIcon={false}
              >
                {openModal => (
                  <Button onClick={() => downloadXlsx(openModal)} isSecondary key="xlsx">
                    Download XLSX
                  </Button>
                )}
              </ModalContainer>
              {addMatterButton && (
                <Button
                  onClick={() => navigate('/v2/matters/templates/select')}
                  isPrimary
                  key="add"
                >
                  Add Matter
                </Button>
              )}
            </span>
          </div>
          <Tabs
            hasConfirmation={selectedRows.length}
            selectedTab={selectedTab}
            setSelectedTab={changeTab}
          />
          <DataTableWrapper
            params={params}
            isLoading={isLoading}
            remotePagination
            tableHeight="500px"
            categories={[]}
            rows={results.rows}
            columns={results.columns}
            totalEntries={results.totalEntries}
            filteredTotal={results.filteredTotal}
            updateTable={updateTable}
            panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
            hasActions
            hasTooltip
            bulkDismiss
            customAction={renderCustomAction}
            pageSize={50}
            filters={
              <Filters
                hasConfirmation={selectedRows.length}
                searchParams={params}
                onSearch={onSearch}
                onClear={() => {
                  onSearch({
                    filterParams: {
                      status: [{ value: 'open', label: 'Open' }],
                      ...(selectedTab === 'mine' ? { filter_by: +window.credentials.user.id } : {})
                    }
                  })
                }}
                entityLabels={entityLabels}
                matterGroupLabels={matterGroupLabels}
              />
            }
            selectAllRows={selectAllRows}
            selectRow={selectRow}
            selectedRows={new Set(selectedRows)}
            allRowsSelected={allRowsSelected}
            theme={coreThemes.EB}
            checkboxSize="md"
            bulkActions={bulkButton}
          />

          {isUploadProgressModalVisible && (
            <ModalContainer
              title={`${
                action === 'delete' ? 'Deleting' : action === 'close' ? 'Closing' : 'Updating'
              } ${selectedRowsLength} matters`}
              size="md"
              confirmText="Close"
              confirmCb={() => {
                setIsUploadProgressModalVisible(false)
                resetRowSelection()
                setPercent(0)
              }}
              isDisabled={percent !== selectedRowsLength}
              hideCancelBtn
              hasCloseIcon={false}
              content={
                <UploadProgress action={action} percent={percent} total={selectedRowsLength} />
              }
            />
          )}
          {isEditModalVisible && (
            <ModalContainer
              title={`Edit ${
                isBulkEdit
                  ? selectedRowsLength === 1
                    ? selectedRowsLength + ' matter'
                    : selectedRowsLength + ' matters'
                  : getMatterProperty(matters.results, editedMatterId, 'short_name')
              }`}
              size="md"
              cancelText="Cancel"
              cancelCb={() => {
                closeEditModal()
              }}
              isDisabled={isBulkEdit && !newMatterLead && !newPracticeArea}
              confirmText="Update"
              confirmCb={() => {
                isBulkEdit ? updateMatters() : updateMatter()
              }}
              hasCloseIcon
              content={
                <EditMatterModal
                  matterGroupLabels={matterGroupLabels}
                  newPracticeArea={newPracticeArea}
                  newMatterLead={newMatterLead}
                  setNewMatterLead={setNewMatterLead}
                  setNewPracticeArea={setNewPracticeArea}
                  isBulkEdit={isBulkEdit}
                  canEditPracticeArea={
                    isBulkEdit ||
                    getMatterProperty(matters.results, editedMatterId, 'mattergroup_editable')
                  }
                  isEditMatterGroup={isEditMatterGroup}
                  isEditMatterLead={isEditMatterLead}
                />
              }
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default MattersListContainer
