import { Component } from 'react'
import { Tabs, Tab } from 'simple-core-ui'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import ACT from 'app/actions'
import queryString from 'query-string'
import { connect } from 'react-redux'
import s from '../styles/SearchResults.scss'
import { MatterFileResult } from '../components/SearchResult'
import DocSearchFilters from '../components/DocSearchFilters'
import { initialState } from 'app/reducer'

@withFetchFlow({
  flag: 'SearchResults',
  getFetchAction: props => {
    const { q, scope } = queryString.parse(props.router.location.search)
    const { searchParams } = props
    return {
      type: ACT.SEARCH_RESULTS_FETCH_REQUESTED,
      payload: {
        q,
        scope,
        params: initialState.searchParams
      }
    }
  }
})
@connect(({ app }) => {
  const { searchResults, searchParams } = app
  return {
    searchResults,
    searchParams
  }
})
class SearchResultsContainer extends Component {
  onSearch = payload => {
    this.props.dispatch({
      type: ACT.SEARCH_RESULTS_FETCH_REQUESTED,
      loadingLock: 'on',
      payload
    })
  }
  render() {
    const { searchResults, searchParams } = this.props
    const { q, scope } = queryString.parse(this.props.router.location.search)

    let searchHeaderText

    const numFiltersUsed = Object.keys(searchParams).length

    if (!searchResults.length && !numFiltersUsed) {
      searchHeaderText = 'None of your matter files matched this search. Try another search.'
    } else if (!searchResults.length && numFiltersUsed) {
      searchHeaderText =
        'No files were found with the applied filters. Try modifying the filters or clear them to return back to your search results.'
    } else {
      searchHeaderText = `${searchResults.length} results for ${q}`
    }

    return (
      <main>
        <h2>Search Results</h2>
        <section style={{ marginTop: 12 }}>
          <Tabs>
            {[
              <Tab key={0} header={`Matter Files (${searchResults.length})`}>
                <section className={s.searchResultsSection}>
                  <DocSearchFilters
                    onClear={this.onSearch}
                    onSearch={this.onSearch}
                    q={q}
                    scope={scope}
                  />
                  <header className={s.searchBodyHeader}>{searchHeaderText}</header>

                  {searchResults.map((result, i) => {
                    switch (scope) {
                      case 'matter-files':
                        return <MatterFileResult key={i} {...result} query={q} />
                      default:
                        return <div></div>
                    }
                  })}
                </section>
              </Tab>
            ]}
          </Tabs>
        </section>
      </main>
    )
  }
}

export default SearchResultsContainer
