import { createReducer } from 'redux-create-reducer'
import ACT from './actions'

export const initialState = {
  isError: false,
  loadingFlags: {},
  credentials: {
    urls: {}
  },
  searchResults: [],
  searchParams: {},
  loading: {},
  initialLoad: {},
  pageTitle: {},
  scope: {}
}

const appReducer = createReducer(initialState, {
  [ACT.EBILL_INITIALIZE](state, action) {
    const { credentials } = action.payload

    const sessionTime = window.serverContext
      ? window.serverContext.get('session_expiration_seconds') * 1000 - 60000
      : null

    const refreshUrl = window.serverContext ? window.serverContext.get('refresh_session') : ''

    return {
      ...state,
      credentials,
      sessionTime,
      refreshUrl
    }
  },

  [ACT.PUSH_NOTIFICATION](state, action) {
    return {
      ...state,
      notifications: {
        title: action.payload.title,
        message: action.payload.message,
        level: action.payload.level,
        position: action.payload.position || 'tc',
        autoDismiss:
          typeof action.payload.autoDismiss === 'number' ? action.payload.autoDismiss : 10,
        dismissible: action.payload.dismissible || 'both',
        uid: action.payload.uid || null,
        onRemove: action.payload.onRemove || null,
        getStyles: action.payload.getStyles || null
      }
    }
  },
  [ACT.CLEAR_NOTIFICATION](state, action) {
    return {
      ...state,
      notifications: {
        clear: true,
        uid: action.payload.uid
      }
    }
  },
  [ACT.UPDATE_PAGE_TITLE](state, action) {
    return {
      ...state,
      pageTitle: {
        ...state.pageTitle,
        ...action.payload
      }
    }
  },
  [ACT.UPDATE_SCOPE](state, action) {
    return {
      ...state,
      scope: action.payload
    }
  },
  [ACT.SET_LOADING](state, action) {
    return {
      ...state,
      loading: {
        ...state.loading,
        ...action.payload
      }
    }
  },

  [ACT.SET_INITIAL_LOAD](state, action) {
    return {
      ...state,
      initialLoad: {
        ...state.initialLoad,
        ...action.payload
      }
    }
  },
  [ACT.SEARCH_RESULTS_FETCH_REQUESTED](state, action) {
    return {
      ...state
    }
  },
  [ACT.SEARCH_RESULTS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      searchResults: action.payload.results,
      searchParams: action.payload.params
    }
  }
})

export default appReducer
