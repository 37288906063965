import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Panel, useLoading } from 'simple-core-ui'

import { makeFormDataPost, makePostRequest } from 'utils/api'

import ActivityDetailsPanel from './ActivityDetailsPanel'
import { IntegratedAssignedContacts } from 'contacts'

import AttributesPanel from './AttributesPanel'
import { CODE_MAP } from './constants'
import { ScopedReviewLadder } from 'reviews/review_ladder'
import { setAttributeValue } from './serializers'
import { MATTER_STATUS } from 'matters/constants'
import s from '../MatterDetailContainer.scss'
import { AttrsLoadingSkeleton, ActivityLoadingSkeleton } from './Skeleton'

const OverviewTab = ({
  isAttrsLoading,
  isSettingsLoading,
  matterId,
  customAttributes,
  otherAttributes,
  updateAttributeValue,
  updateConditionalAttributeValue,
  settings
}) => {
  const rDispatch = useDispatch()
  const [isLoading, withLoadingLocks] = useLoading()
  const [key, setKey] = useState(0)

  const { Row, Column } = Grid

  const updateGeneralAttribute = async (value, attr, sectionIndex, panel, attrGroupIndex) => {
    try {
      await withLoadingLocks(
        makeFormDataPost('/manage/matters/v2/rename/', {
          name: attr.name,
          value: value?.value ? value.value : value,
          pk: attr.id,
          field: CODE_MAP[attr.name] ?? attr.name
        })
      )
      updateAttributeValue({ attr, value, sectionIndex, panel, attrGroupIndex })
    } catch (e) {
      rDispatch({ type: 'API_ERROR', error: e })
    }
  }

  const updateCustomAttribute = async (value, attr) => {
    try {
      await withLoadingLocks(
        makePostRequest('/manage/matters/attribute-selected-value/', {
          name: attr.label,
          value: setAttributeValue(value, attr.type),
          pk: attr.id,
          field: attr.name,
          fieldType: attr.type,
          attributeID: attr.id,
          relatedObjectModel: 'matters',
          relatedObjectID: matterId
        })
      )
    } catch (e) {
      rDispatch({ type: 'API_ERROR', error: e })
    }
  }

  const updateConditionalAttribute = async (value, attr, relationId) => {
    try {
      await withLoadingLocks(
        makePostRequest('/manage/matters/attribute-selected-value/', {
          name: attr.label,
          value: value?.value ? value.value : value,
          pk: attr.id,
          field: attr.name,
          fieldType: attr.type,
          attributeID: attr.id,
          relationship_id: relationId,
          attribute_value_id: value?.value ? value.value : value,
          relatedObjectModel: 'matters',
          relatedObjectID: matterId
        })
      )
    } catch (e) {
      rDispatch({ type: 'API_ERROR', error: e })
    }
  }

  const updateAttribute = async ({
    attr,
    value,
    sectionIndex,
    panel,
    attrGroupIndex,
    relationId,
    group,
    attrIndex
  }) => {
    try {
      if (attr.isGeneral) {
        await updateGeneralAttribute(value, attr, sectionIndex, panel, attrGroupIndex)
        if (['matter_group', 'legal_entity'].includes(attr.name)) {
          setKey(key + 1)
        }
      } else if (relationId) {
        updateConditionalAttributeValue({
          attr,
          value,
          sectionIndex,
          panel,
          attrGroupIndex,
          relationId,
          group,
          attrIndex
        })
        await updateConditionalAttribute(value, attr, relationId)
      } else {
        updateAttributeValue({ attr, value, sectionIndex, panel, attrGroupIndex })
        await updateCustomAttribute(value, attr)
      }
    } catch (e) {
      rDispatch({ type: 'API_ERROR', error: e })
    }
  }

  return (
    <Row>
      <Column span={6}>
        {isAttrsLoading ? (
          <AttrsLoadingSkeleton />
        ) : (
          <AttributesPanel
            reRenderKey={key}
            panel="customAttributes"
            updateAttribute={updateAttribute}
            title="Attributes"
            attributes={customAttributes}
            matterId={matterId}
            readOnly={
              settings?.status?.slug === MATTER_STATUS.CLOSED ||
              !settings?.permissions?.has_edit_capability
            }
            className={s.panel}
            isConfidential={settings?.is_confidential}
          />
        )}
        <IntegratedAssignedContacts
          id={matterId}
          readOnly={
            settings?.status?.slug === MATTER_STATUS.CLOSED ||
            !settings?.permissions?.has_edit_capability
          }
          className={s.contactsPanel}
        />
      </Column>
      <Column span={6}>
        {settings?.reviews?.matter_is_in_review && <ScopedReviewLadder className={s.reviewPanel} />}
        {isAttrsLoading ? (
          <AttrsLoadingSkeleton />
        ) : (
          <AttributesPanel
            reRenderKey={key}
            panel="otherAttributes"
            updateAttribute={updateAttribute}
            title="Other Attributes"
            attributes={otherAttributes}
            matterId={matterId}
            readOnly={
              settings?.status?.slug === MATTER_STATUS.CLOSED ||
              !settings?.permissions?.has_edit_capability
            }
            className={s.panel}
            isConfidential={settings?.is_confidential && customAttributes?.length === 0}
          />
        )}
        <Panel className={s.panel} isBodyOnly>
          {isSettingsLoading ? (
            <ActivityLoadingSkeleton />
          ) : (
            <ActivityDetailsPanel
              id={matterId}
              details={settings?.details}
              permissions={settings?.permissions}
              readOnly={
                settings?.status?.slug === MATTER_STATUS.CLOSED ||
                !settings?.permissions?.has_edit_capability
              }
              className={s.panel}
            />
          )}
        </Panel>
      </Column>
    </Row>
  )
}

export default OverviewTab
