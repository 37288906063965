import { toCategories } from 'simple_review/serializers'
import { RuleVersionDifference } from 'simple_review/@types/api'
import { ContextState } from 'simple_review/@types/context'
import { BaseSerializer } from './base.serializer'
import { RuleActionEditSerializer } from './rule-action-edit.serializer'
import { RuleCategoryEditSerializer } from './rule-category-edit.serializer'
import { RuleConditionEditSerializer } from './rule-condition-edit.serializer'
import { RuleCreatedSerializer } from './rule-created.serializer'
import { RuleInfoEditSerializer } from './rule-info-edit.serializer'
import { RuleStatusSerializer } from './rule-status.serializer'
import { RuleOpSerializer } from './rule-op.serializer'

export function RuleVersionSerializer(
  ruleVersion: RuleVersionDifference,
  context: ContextState
): BaseSerializer {
  const serializers = ruleVersion.changes.map(change => {
    switch (change.field) {
      case 'operands':
        return RuleConditionEditSerializer(change.old, change.new, change.type, context)
      case 'actions':
        return RuleActionEditSerializer(change.old, change.new, context.availableActions)
      case 'category_id':
        return RuleCategoryEditSerializer(change.old, change.new, toCategories(context.categories))
      case 'name':
      case 'description':
        return RuleInfoEditSerializer(change.old, change.new, change.field)
      case 'is_draft':
        return RuleStatusSerializer(change.old, change.new)
      case 'new_rule':
        return RuleCreatedSerializer(change.new)
      case 'condition':
        return RuleConditionEditSerializer(change.old, change.new, change.type, context)
      case 'op':
        return RuleOpSerializer(change.old, change.new)
    }
  })

  return {
    toString() {
      if (!serializers) {
        return 'Error parsing'
      }
      return (
        <>
          {serializers.map((serializer, index) => (
            <div key={index}>{serializer.toString()}</div>
          ))}
        </>
      )
    }
  }
}
