import { If } from 'simple-core-ui'

import s from './TeamsListTitle.scss'

const TeamsListTitle = ({ teamCount, teamLimit }) => (
  <section className={s.title}>
    Team Management
    <If condition={teamLimit}>
      <span className={s.limitText}>
        <span className={s.leftParen}>(</span>
        Using <strong>{teamCount}</strong> out of <strong>{teamLimit}</strong> teams.
        <span className={s.rightParen}>)</span>
      </span>
    </If>
  </section>
)

export default TeamsListTitle
