import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

const ActionsPopover = ({
  rowId,
  downloadPdf,
  exportCsv,
  hasResendNotification,
  resendApprovalEmails
}) => {
  const handleClick = (event, action) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    ReactTooltip.hide()
    switch (action) {
      case 'DOWNLOAD_PDF':
        downloadPdf(rowId)
        return
      case 'EXPORT_CSV':
        exportCsv(rowId)
        return
      case 'RESEND_APPROVAL':
        resendApprovalEmails(rowId)
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DOWNLOAD_PDF')
            }}
          >
            Download PDF
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EXPORT_CSV')
            }}
          >
            Export CSV
          </li>
          {hasResendNotification ? (
            <li
              className={s.actionItem}
              onClick={e => {
                handleClick(e, 'RESEND_APPROVAL')
              }}
            >
              Resend Approval Emails
            </li>
          ) : null}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
