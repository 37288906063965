import { useState, useEffect, useMemo } from 'react'
import {
  Button,
  DataTableWrapper,
  Ellipsis,
  Modal,
  HoverAction,
  useLoading,
  Loading,
  withConfirmation
} from 'simple-core-ui'
import { useImmer } from 'use-immer'
import { useDispatch } from 'react-redux'
import s from './FileUpload.scss'
import { Dropzone } from './Dropzone'
import { ExtensionImage } from './ExtensionImage'
import { filesize } from 'filesize'
import moment from 'moment'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { openLink, getFileNameExtension } from 'utils/helpers'
import { ActionsPopover } from './ActionsPopover'
import { FaDownload } from 'react-icons/fa'
import { FILE_STATUS_SCANNING, FILE_STATUS_INFECTED, FILE_STATUS_TEMPORARY } from './constants'
import { FileStatusIcon } from './FileStatusIcon'
import { MdFileDownload } from 'react-icons/md'
import { List } from './List'
import { IoIosClose } from 'react-icons/io'
import { hasDupFileNames } from './utils'

const newFileUploadEnabled = window.credentials.newFileUploadEnabled === 'True'

const DELETE_URL_PREFIX = {
  invoice: 'invoices',
  vendor: 'invoices/delete/vendor'
}

const DELETE_CONF = {
  title: 'You are deleting an attachment',
  text: 'Do you wish to continue?'
}

const useParams = initialOrdering => {
  const initialParams = {
    pageSize: 5,
    ordering: { columnKey: initialOrdering, isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }

  const [params, setParams] = useState(initialParams)

  return [params, setParams]
}

const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = cells.find(cell => cell.columnKey === columnKey)?.content

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'fileStatus':
    case 'file_name':
      return cellContent.toLowerCase()
    case 'file_type':
      return getFileNameExtension(row.file_name)?.extension
    case 'file_size':
    case 'file_upload_date':
    case 'user_full_name':
    case 'source':
      return cellContent
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

const FileUpload = ({
  scopeId,
  isEditable,
  scope,
  files,
  readOnly,
  lifespan,
  onChangeAttachments,
  uploadFiles: uploadFilesProp,
  deleteAttachment: deleteAttachmentProp,
  view = 'table',
  texts,
  simpleViewProps,
  downloadAll,
  hideScroll = true,
  willOverwriteDuplicates = true,
  limit,
  maxLimitReachedError = 'You have reached the maximum allowed number of attachments',
  existingFilesNumber = 0
}) => {
  const [attachments, setAttachments] = useImmer([])
  const [prevAttachments, setPrevAttachments] = useImmer([])
  const [filesToUpload, setFilesToUpload] = useImmer([])
  const [rejectedFiles, setRejectedFiles] = useImmer([])
  const [params, setParams] = useParams('file_name')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPreviewWaitingOpen, setIsPreviewWaitingOpen] = useState(false)
  const [isLoading, withLoadingLocks] = useLoading()
  const [, withLoadingLocksDelete] = useLoading()
  const [, withLoadingLocksPreview] = useLoading()
  const dispatch = useDispatch()

  if (files && files !== prevAttachments) {
    setPrevAttachments(files)
    setAttachments(files)
  }

  async function fetchAttachments() {
    const { attachments } = await makeGetRequest(`/upload/${scope}/${scopeId}/attachment/`)
    setAttachments(attachments)
    onChangeAttachments && onChangeAttachments(attachments)
  }

  const openPreview = async attachmentId => {
    try {
      setIsPreviewWaitingOpen(true)
      const response = await withLoadingLocksPreview(
        makeGetRequest(`/upload/attachment/s3_url/${attachmentId}/?as_attachment=True`)
      )
      setIsPreviewWaitingOpen(false)

      if (response.s3_file_url) {
        openLink(response.s3_file_url)
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const fileColumns = useMemo(() => {
    return [
      {
        columnKey: 'file_type',
        content: 'Type',
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          return <ExtensionImage file={{ path: row.file_name }} />
        }
      },
      ...(newFileUploadEnabled
        ? [
            {
              columnKey: 'fileStatus',
              content: 'Scan Status',
              isSortable: true,
              isFilterable: false,
              render: (cell, row) => {
                return (
                  <div className={s.fileNameContainer}>
                    <FileStatusIcon file={row} />
                  </div>
                )
              }
            }
          ]
        : []),
      {
        columnKey: 'file_name',
        content: 'Name',
        isSortable: true,
        isFilterable: true,
        style: { width: '50%' },
        render: (cell, row) => {
          return row.s3_file_url ? (
            <a href={row.s3_file_url} target="_blank" rel="noopener noreferrer">
              <Ellipsis>{cell.content}</Ellipsis>
            </a>
          ) : (
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                if (
                  ![FILE_STATUS_SCANNING, FILE_STATUS_INFECTED].includes(row.fileStatus) ||
                  !newFileUploadEnabled
                ) {
                  openPreview(row.id)
                }
              }}
            >
              <Ellipsis>{cell.content}</Ellipsis>
            </a>
          )
        }
      },
      {
        columnKey: 'file_upload_date',
        content: 'Uploaded',
        isSortable: true,
        render: (cell, row) => {
          return moment(cell.content).format('MM/DD/YYYY')
        }
      },
      {
        columnKey: 'file_size',
        content: 'Size',
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          if (cell.content === '----') {
            return cell.content
          }

          return filesize(cell.content).toUpperCase()
        }
      },
      {
        columnKey: 'source',
        content: 'Source',
        isSortable: true,
        isFilterable: true,
        render: cell => {
          if (cell.content === '----') {
            return cell.content
          } else if (cell.content === 'ebill') {
            return 'SimpleLegal'
          } else if (cell.content === 'counselgo') {
            return 'CounselGO'
          }
          return cell.content
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadFiles = async () => {
    const files = filesToUpload.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.url]: {
          name: curr.name,
          size: curr.size,
          type: curr.type,
          ...(curr.will_overwrite_file ? { will_overwrite_file: curr.will_overwrite_file } : {})
        }
      }
    }, {})

    if (limit && Object.entries(files).length + existingFilesNumber > limit) {
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Please contact customer success',
          message: maxLimitReachedError,
          level: 'error'
        }
      })
      return
    }

    try {
      if (uploadFilesProp) {
        uploadFilesProp(files)
        setIsModalOpen(false)
        setRejectedFiles([])
        setFilesToUpload([])
      } else {
        const response = await withLoadingLocks(
          makePostRequest(`/upload/${scope}/${scopeId}/attachment/`, {
            files
          })
        )

        setIsModalOpen(false)
        setRejectedFiles([])
        setFilesToUpload([])
        if (response.attachments.length) {
          fetchAttachments()
        }

        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Files uploaded successfully',
            message: 'Files were successfully uploaded.',
            level: 'success'
          }
        })
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const downloadFile = async row => {
    if (
      ![FILE_STATUS_SCANNING, FILE_STATUS_INFECTED].includes(row.fileStatus) ||
      !newFileUploadEnabled
    ) {
      openPreview(row.id)
    }
  }

  const deleteAttachment = attachmentId => {
    if (deleteAttachmentProp) {
      deleteAttachmentProp(attachmentId)
      return
    }
    withConfirmation(async () => {
      const response = await withLoadingLocksDelete(
        makePostRequest(`/${DELETE_URL_PREFIX[scope]}/${scopeId}/attachment/${attachmentId}/`)
      )

      const newAttachments = attachments.filter(a => a.id !== attachmentId)

      setAttachments(newAttachments)

      onChangeAttachments && onChangeAttachments(newAttachments)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Attachment deleted successfully',
          message: 'Attachment was successfully deleted.',
          level: 'success'
        }
      })
    }, DELETE_CONF)()
  }

  const renderCustomAction = row => {
    const isInfected = row.fileStatus === FILE_STATUS_INFECTED
    if (row.fileStatus === FILE_STATUS_SCANNING && newFileUploadEnabled) {
      return <span className={s.actionsPlaceholder}>...</span>
    }

    return (
      <div className={s.customAction}>
        {!isInfected &&
          (view === 'simple' ? (
            row.fileStatus !== FILE_STATUS_TEMPORARY && (
              <MdFileDownload className={s.dldIcon} onClick={() => downloadFile(row)} />
            )
          ) : (
            <div className={s.downloadActionContainer}>
              <HoverAction
                hasNewDesign
                tip="Download"
                icon={<FaDownload />}
                size="xsmall"
                className={s.hoverAction}
                onClick={() => downloadFile(row)}
              />
            </div>
          ))}
        {view === 'simple' &&
        row.fileStatus === FILE_STATUS_TEMPORARY &&
        simpleViewProps?.hideHoverAction ? (
          <IoIosClose className={s.remove} onClick={() => deleteAttachment(row.id)} />
        ) : (
          isEditable &&
          !readOnly && <ActionsPopover rowId={row.id} deleteAttachment={deleteAttachment} />
        )}
      </div>
    )
  }

  useEffect(() => {
    if (!scopeId) return

    fetchAttachments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="row-fluid">
      {view === 'simple' ? (
        <List
          attachments={attachments}
          downloadAll={downloadAll}
          setIsModalOpen={setIsModalOpen}
          renderCustomAction={renderCustomAction}
          style={simpleViewProps?.hideHoverAction ? { maxHeight: 185 } : {}}
          readOnly={readOnly}
          isFilesLimitReached={limit && existingFilesNumber >= limit}
        />
      ) : (
        <div className={`span12 box ${s.uploadBox}`}>
          {!attachments.length ? (
            <div className={s.emptyFiles}>
              <b className={s.empyTitle}>File Attachments</b>
              {!readOnly && (
                <Button isPrimary onClick={() => setIsModalOpen(true)}>
                  Upload Files
                </Button>
              )}
            </div>
          ) : (
            <div className={s.filesContainer}>
              <div className={s.fileActions}>
                <div className={s.filesTitleContainer}>
                  <b className={s.filesTitle}>File Attachments</b>
                </div>
                {!readOnly && (
                  <Button className={s.uploadButton} isPrimary onClick={() => setIsModalOpen(true)}>
                    Upload Files
                  </Button>
                )}
              </div>
              <div style={{ flexGrow: 1, paddingLeft: 12 }}>
                <div style={{ fontSize: 16 }}>{attachments.length} files attached</div>
                <div style={{ marginTop: -24 }}>
                  <DataTableWrapper
                    initialValues={params}
                    hasActionsHeader={false}
                    shouldShowHeader={false}
                    categories={[]}
                    totalEntries={attachments.length}
                    params={params}
                    hasActions
                    panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                    columns={fileColumns}
                    customAction={renderCustomAction}
                    getNormalizedCellContent={getNormalizedCellContent}
                    updateTable={p => {
                      setParams(p)
                    }}
                    rows={attachments}
                    hasTooltip
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isPreviewWaitingOpen && (
        <Modal
          title={texts?.previewModalTitle ?? 'Preview File'}
          size="sm"
          hasCloseIcon={false}
          content={
            <div>
              <div>{texts?.previewModalContent ?? 'We are waiting for your file to load '}</div>{' '}
              <div>
                <Loading />
              </div>
            </div>
          }
          isVisible={isPreviewWaitingOpen}
          hideScroll={hideScroll}
        />
      )}
      {isModalOpen && (
        <Modal
          isLoading={isLoading}
          title="Upload Files"
          cancelText="Cancel"
          size="xl"
          cancelCb={() => {
            setRejectedFiles([])
            setFilesToUpload([])
            setIsModalOpen(false)
          }}
          confirmText="Upload"
          confirmCb={uploadFiles}
          hasCloseIcon={false}
          isDisabled={
            isLoading ||
            filesToUpload.some(f => f.processing) ||
            hasDupFileNames(filesToUpload) ||
            !filesToUpload.length
          }
          content={
            <Dropzone
              lifespan={lifespan}
              filesToUpload={filesToUpload}
              setFilesToUpload={setFilesToUpload}
              rejectedFiles={rejectedFiles}
              setRejectedFiles={setRejectedFiles}
              attachments={attachments}
              willOverwriteDuplicates={willOverwriteDuplicates}
            />
          }
          isVisible={isModalOpen}
          hideScroll={hideScroll}
        />
      )}
    </div>
  )
}

export default FileUpload
