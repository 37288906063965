import { Tag, If } from 'simple-core-ui'

import { IoIosClose } from 'react-icons/io'

import ScopeContext from 'context/ScopeContext'
import { ConfirmableContainer } from 'containers'
import Contact from '../../Contact/Contact'
import UnassignText from '../../UnassignText/UnassignText'

import { containsCounselGo, isCounselGo } from '../../utils'

import s from './AssignedContact.scss'

const AssignedContact = ({
  contact,
  unassignContactCb,
  unassignRoleCb,
  setContactToUpdateCb,
  isEditable,
  userCanEditContactCG,
  readOnly
}) => (
  <section className={s.container}>
    <ConfirmableContainer
      promptText={<UnassignText removesCounselGo={containsCounselGo(contact.roles)} />}
      confirmCallback={() => unassignContactCb(contact)}
      isEditable={isEditable}
      render={openPrompt => (
        <section
          className={`${s.contact} ${isEditable ? '' : s.notEditable} ${
            contact.active ? '' : s.inactive
          }`}
          data-testid={contact.firstName + '_' + contact.lastName + '_section'}
        >
          <ScopeContext.Consumer>
            {({ scope, scopeId }) => (
              <Contact
                firstName={contact.firstName}
                lastName={contact.lastName}
                title={contact.title}
                company={contact.org ? contact.org.name : ''}
                phone={contact.phone}
                email={contact.email}
                editCb={() => setContactToUpdateCb(contact)}
                isEditable={isEditable && !readOnly}
                active={contact.active}
                url={`/v2/contacts/profile/${contact.id}?scope=${scope}&scopeId=${scopeId}`}
              />
            )}
          </ScopeContext.Consumer>
          <section className={s.tagGroup}>
            {contact.roles.map(role =>
              isCounselGo(role) ? (
                <Tag key={role.id} color="red">
                  {role.label}
                </Tag>
              ) : (
                <Tag
                  key={role.id}
                  color={role.singular ? 'blue' : 'black'}
                  deleteCb={
                    contact.roles.length > 1 && isEditable
                      ? () => unassignRoleCb(contact, role.id)
                      : null
                  }
                >
                  {role.label}
                </Tag>
              )
            )}

            <If condition={!contact.active}>
              <div data-testid="inactive_user" className={s.inactiveText}>
                {'This user is inactive. '}
                <span className={s.drainText} onClick={openPrompt}>
                  {'Click here '}
                </span>
                {'to remove.'}
              </div>
            </If>
          </section>
          {isEditable && userCanEditContactCG && (
            <IoIosClose
              className={s.delete}
              data-testid={'contact_delete_button'}
              onClick={openPrompt}
            />
          )}
        </section>
      )}
    />
  </section>
)

export default AssignedContact
