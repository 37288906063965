import { useEffect } from 'react'
import { ModalContainer } from 'simple-core-ui'
import { CharLimitInput } from 'components'
import ReactTooltip from 'react-tooltip'
import s from './EventTypeModal.scss'
import { EventType } from '../types'

interface Props {
  toggleEventTypeModal: () => void
  showDuplicateError: boolean
  eventType: EventType
  updateEventType: (value: string | number | null, property: string) => void
  saveEventType: () => void
}

const MAX_NAME_LENGTH = 50

const EventTypeModal = ({
  toggleEventTypeModal,
  showDuplicateError,
  eventType,
  updateEventType,
  saveEventType
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const errorObject = (eventType: EventType) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (eventType.name && eventType.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }
    if (showDuplicateError) {
      hasError = true
      errorMessage = 'Name must be unique.'
    }

    return { hasError, errorMessage }
  }

  return (
    <ModalContainer
      title={eventType.id ? 'Edit Event Type' : 'Add Event Type'}
      subtitle="Use types to categorize your events."
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleEventTypeModal}
      confirmText="Save"
      confirmCb={saveEventType}
      isDisabled={showDuplicateError || !eventType.name.trim() || eventType.name[0] === ' '}
      content={
        <div className={s.statusItem}>
          <div className={s.info}>Event Type</div>
          <CharLimitInput
            maxLength={MAX_NAME_LENGTH}
            value={eventType.name}
            onChangeCb={e => updateEventType(e.target.value, 'name')}
            placeholder="Enter an event type"
            dynamicCharCalculation
            customErrorMessage={errorObject(eventType).errorMessage}
            hasError={errorObject(eventType).hasError}
          />
        </div>
      }
    />
  )
}

export default EventTypeModal
