import { AvailableField, Operator, Rule as APIRule } from 'simple_review/@types/api'
import { Rule } from 'simple_review/@types/editor'
import { RuleConditionSerializer } from './rule-condition.serializer'
import { getRootCondition } from '../content/constants'

const getCondition = (condition: Rule['condition']) => {
  const parsedCondition = JSON.parse(RuleConditionSerializer().toJSON(condition, true))
  const { operands } = parsedCondition

  if (operands.length === 0) return null

  return parsedCondition
}
const getActions = (actions: Rule['actions']) => {
  const filteredActions = actions.filter(action => action.name)
  // to create inactive rule with empty actions, 'null' value should be passed
  return filteredActions.length ? filteredActions : null
}

export function RuleSerializer() {
  return {
    toJSON(rule: Rule, id: string | undefined, isActive: boolean) {
      return JSON.stringify({
        name: rule.name,
        text: '',
        description: rule.description,
        category_id: rule.categoryId as number,
        condition: getCondition(rule.condition),
        actions: getActions(rule.actions),
        id: id && id !== 'new' ? id : null,
        is_draft: !isActive,
        pre_seed_code: rule.preSeedCode || null
      })
    },
    fromJSON(rule: APIRule, fields: Array<AvailableField>, operators: Array<Operator>): Rule {
      return {
        name: rule.name,
        text: rule.text,
        description: rule.description,
        categoryId: rule.category_id,
        isActive: !rule.is_draft,
        condition: rule.condition
          ? RuleConditionSerializer().fromJSON(rule.condition, fields, operators, true)
          : getRootCondition(),
        actions: rule.actions,
        preSeedCode: rule.pre_seed_code,
        type: rule.type
      }
    }
  }
}
