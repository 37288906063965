import { Component } from 'react'
import { connect } from 'react-redux'
import s from 'common/Filters/SearchFilter.scss'
import {
  DateFilter,
  SizeFilter,
  LabelFilter,
  CategoryFilter,
  MatterFilter,
  UserFilter
} from 'common/Filters'

@connect(({ app }) => {
  const { searchParams } = app
  return {
    searchParams
  }
})
class DocSearchFilters extends Component {
  state = {
    reset: 0
  }

  filterSearch = (filterType, itemValue) => {
    const { searchParams, q, scope, onSearch } = this.props
    const params = {
      ...searchParams,
      [filterType]: itemValue
    }

    onSearch &&
      onSearch({
        q,
        scope,
        params
      })
  }

  clearAll = () => {
    const { q, scope, onClear } = this.props
    this.setState(
      {
        reset: this.state.reset + 1
      },
      () => {
        onClear &&
          onClear({
            q,
            scope,
            params: {}
          })
      }
    )
  }

  render() {
    const { searchParams } = this.props
    const { reset } = this.state

    return (
      <section className={s.filterContainer}>
        <DateFilter reset={reset} onSelect={item => this.filterSearch('date', item)} />
        <SizeFilter reset={reset} onSelect={item => this.filterSearch('size', item)} />
        <CategoryFilter reset={reset} onSelect={item => this.filterSearch('category', item)} />
        <LabelFilter reset={reset} onSelect={item => this.filterSearch('label', item)} />
        <MatterFilter reset={reset} onSelect={item => this.filterSearch('matter', item)} />
        <UserFilter reset={reset} onSelect={item => this.filterSearch('modifiedBy', item)} />
        <div className={s.clear} onClick={this.clearAll}>
          Clear All Filters
        </div>
      </section>
    )
  }
}

export default DocSearchFilters
