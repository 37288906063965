import { useState, useEffect } from 'react'
import { ModalContainer, Button, Alert } from 'simple-core-ui'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { makePostRequest } from 'utils/api'

interface Props {
  toggleModal: () => void
}

const CalendarSyncModal = ({ toggleModal }: Props) => {
  const [value, setValue] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    const getCalendarLink = async () => {
      const { calendar_url } = await makePostRequest('/event-management/calendar/', {})
      setValue(calendar_url)
    }
    getCalendarLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalContainer
      title="Sync to my calendar"
      hideButtons
      size="sm"
      cancelCb={toggleModal}
      content={
        <div>
          <p style={{ marginBottom: 20 }}>
            Use the link below to sync tasks to Apple Calendar, Google Calendar, Microsoft Outlook,
            or other calendar applications. Tasks and Events will contain links back to SimpleLegal.
          </p>
          <ol style={{ marginBottom: 20, textIndent: -15, marginLeft: 20 }}>
            <li style={{ listStylePosition: 'inside' }}>Copy the link below</li>
            <li style={{ listStylePosition: 'inside' }}>Open your calendar application</li>
            <li style={{ listStylePosition: 'inside' }}>
              Create a new calendar and use the link you copied as the From web, From URL, or
              Subscribe link.
            </li>
            <li style={{ listStylePosition: 'inside' }}>
              For specific instructions on how to sync your calendar, visit the{' '}
              <a href="/help/?article_id=11278829317399" target="_blank" rel="noreferrer">
                help center
              </a>
              .
            </li>
          </ol>
          <hr style={{ borderTop: '1px solid #EEEEEE', borderBottom: 'none' }} />
          <div style={{ padding: 20 }}>
            <p style={{ marginBottom: 20 }}>
              <b>Calendar link</b>
            </p>
            <div style={{ display: 'flex' }}>
              <input type="text" value={value} readOnly />
              <CopyToClipboard text={value} onCopy={() => setShowSuccess(true)}>
                <Button style={{ marginLeft: 10 }} hasNewDesign isPrimary>
                  Copy
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          {showSuccess && (
            <Alert
              status="success"
              message="Link copied to clipboard"
              style={{ width: 'calc(100% - 48px)', marginBottom: 30 }}
            />
          )}
        </div>
      }
    />
  )
}

export default CalendarSyncModal
