import capitalize from 'lodash/capitalize'
import { Condition as ApiCondition, Operand as ApiOperand, Arg } from '../@types/api'
import { Condition as EditorCondition, Operand as EditorOperand } from '../@types/editor'
import { BooleanOperator } from '../expressions/boolean-expression/boolean-expression-model'

export const formatArgOptionLabel = (argName: string): string => {
  return argName
    .split('_')
    .map(capitalize)
    .join(' ')
}

export const formatArgLabel = (argName: string, constant: Arg['constant']): string => {
  if (argName === 'vendor') {
    return constant && 'model_field' in constant ? 'Vendor' : 'Vendor Name'
  }
  return argName
    .split('_')
    .map(capitalize)
    .join(' ')
}

const BOOLEAN_OPERATORS: Array<BooleanOperator> = ['AND', 'OR']

export function isConditionAiPowered(condition: EditorCondition | EditorOperand) {
  if (!('operands' in condition)) return false
  const operand = condition.operands[0] as EditorOperand
  return Boolean(operand.lhs && 'aiOperand' in operand.lhs)
}

export function getTotalLevels(condition: ApiCondition | EditorCondition) {
  let total = 1
  function traverse(condition: ApiCondition | EditorCondition, level: number) {
    if (level > total) {
      total = level
    }

    if (condition.operands.length) {
      for (let i = 0; i < condition.operands.length; i++) {
        const operand = condition.operands[i]
        if (BOOLEAN_OPERATORS.some(o => o === operand.op) && 'operands' in operand) {
          traverse(operand, level + 1)
        }
      }
    }
  }

  traverse(condition, 1)
  return total
}

export const getNumberOfConditions = (condition: EditorCondition) => {
  const numLevels = getTotalLevels(condition)

  // Level 2 means it's a simple (not grouped) condition, return number of non ai-powered conditions
  if (numLevels === 2) {
    return condition.operands.reduce(
      (acc, operand) => (!isConditionAiPowered(operand) ? acc + 1 : acc),
      0
    )
  }

  let numberOfConditions = 0
  for (const operand of condition.operands) {
    if (BOOLEAN_OPERATORS.some(o => o === operand.op)) {
      numberOfConditions += getNumberOfConditions(operand as EditorCondition) + 1
    }
  }
  return numberOfConditions
}

interface FlatCondition {
  booleanOp: BooleanOperator
  condition: ApiCondition | ApiOperand
}

export function getFlatConditions(condition: ApiCondition): Array<FlatCondition> {
  const numLevels = getTotalLevels(condition)

  if (numLevels === 2)
    return condition.operands.map(x => ({ booleanOp: condition.op, condition: x }))

  const conditions: Array<FlatCondition> = []

  for (const operand of condition.operands) {
    if (BOOLEAN_OPERATORS.some(o => o === operand.op)) {
      conditions.push(...getFlatConditions(operand as ApiCondition))
    } else {
      conditions.push({ booleanOp: condition.op, condition: operand })
    }
  }

  return conditions
}

export function isOperandCondition(
  condition: ApiCondition | ApiOperand | EditorCondition | EditorOperand
) {
  return BOOLEAN_OPERATORS.some(o => o === condition.op)
}
