import * as React from 'react'
import APP_ACT from 'app/actions'
import ACT from '../actions'

import { Dispatchable } from 'components'

import s from './ContactsSyncWarning.scss'

const ContactsSyncWarning = ({ scope, scopeId }) => (
  <React.Fragment>
    The contact being updated is currently out of sync.{' '}
    <Dispatchable
      actions={[
        {
          type: ACT.ASSIGNED_CONTACTS_SYNC,
          payload: { scope, scopeId }
        },
        {
          type: APP_ACT.CLEAR_NOTIFICATION,
          payload: {
            uid: ACT.ASSIGNED_CONTACTS_SYNC
          }
        }
      ]}
      className={s.dispatch}
    >
      Click here
    </Dispatchable>{' '}
    to sync the latest contact information.
  </React.Fragment>
)

export default ContactsSyncWarning
