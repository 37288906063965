import moment from 'moment'
import { currency2Num } from 'utils/formatting'
import get from 'lodash/get'
import s from './styles/BudgetsList.scss'
import { BUDGET_STATES } from 'budgets/constants'

export const budgetRequestColumns = [
  {
    columnKey: 'budgetName',
    content: 'Name',
    isSortable: true,
    isFilterable: true,
    style: { width: '17.5%' }
  },
  {
    columnKey: 'matterName',
    content: 'Matter',
    isSortable: true,
    isFilterable: true,
    style: { width: '17.5%' }
  },
  {
    columnKey: 'vendorName',
    content: 'Vendor',
    isSortable: true,
    isFilterable: true,
    style: { width: '15%' }
  },
  {
    columnKey: 'practiceName',
    content: 'Practice Area',
    isSortable: true,
    isFilterable: true,
    style: { width: '10%' }
  },
  {
    columnKey: 'costCode',
    content: 'Cost Code',
    isSortable: true,
    isFilterable: true,
    style: { width: '2.5%' }
  },
  {
    columnKey: 'startDate',
    content: 'Start Date',
    isSortable: true,
    isFilterable: true,
    style: { width: '5%' }
  },
  {
    columnKey: 'endDate',
    content: 'End Date',
    isSortable: true,
    isFilterable: true,
    style: { width: '5%' }
  },
  {
    columnKey: 'amount',
    content: 'Amount',
    isSortable: true,
    isFilterable: true,
    style: { width: '10%', textAlign: 'right' }
  },
  {
    columnKey: 'status',
    content: 'Status',
    isSortable: true,
    isFilterable: true,
    style: { width: '15%' }
  },
  {
    columnKey: 'lastAction',
    content: 'Last Action',
    isSortable: true,
    isFilterable: true,
    style: { width: '2.5%' }
  }
]

export const budgetRequestCategories = [
  { label: 'With Vendor', value: 'withVendor' },
  { label: 'Approval Needed', value: 'approvalNeeded' },
  { label: 'All', value: 'all' }
]

export const activeBudgetColumns = [
  {
    columnKey: 'budgetName',
    content: 'Name',
    isSortable: true,
    isFilterable: true,
    style: { width: '25%', minWidth: '150px' }
  },
  { columnKey: 'matterName', content: 'Matter', isSortable: true, isFilterable: true },
  {
    columnKey: 'vendorName',
    content: 'Vendor',
    isSortable: true,
    isFilterable: true,
    style: { width: '10%', minWidth: '100px' }
  },
  { columnKey: 'practiceName', content: 'Practice Area', isSortable: true, isFilterable: true },
  { columnKey: 'costCode', content: 'Cost Code', isSortable: true, isFilterable: true },
  { columnKey: 'startDate', content: 'Start Date', isSortable: true, isFilterable: true },
  { columnKey: 'endDate', content: 'End Date', isSortable: true, isFilterable: true },
  {
    columnKey: 'budgetAmount',
    content: 'Budget',
    isSortable: false,
    isFilterable: false,
    style: { maxWidth: '100px', textAlign: 'right' }
  },
  {
    columnKey: 'approvedTotal',
    content: 'Approved Invoices',
    isSortable: false,
    isFilterable: false,
    style: { width: '10%', maxWidth: '100px', whiteSpace: 'initial', textAlign: 'right' }
  },
  {
    columnKey: 'pendingTotal',
    content: (
      <div>
        <div>Pending Invoices</div>
        <div className={s.accrualCell}>Pending Accruals</div>
      </div>
    ),
    isSortable: false,
    isFilterable: false,
    style: { width: '10%', maxWidth: '100px', whiteSpace: 'initial', textAlign: 'right' }
  },
  {
    columnKey: 'totalInvoiced',
    content: 'Budget Health',
    isSortable: false,
    isFilterable: false,
    style: { width: '25%', minWidth: '200px' }
  }
]

export const activeBudgetCategories = [
  { label: 'All', value: 'all' },
  { label: 'In Budget', value: 'inBudget' },
  { label: 'Almost Overbudget', value: 'almostOverbudget' },
  { label: 'Overbudget', value: 'overbudget' }
]

export const activeBudgetOrderByMap = {
  budgetName: 'budget_name',
  budgetMatter: 'matter_name',
  vendorName: 'vendor_name',
  matterName: 'matter_name',
  matterleadName: 'matter_lead',
  mattergroupName: 'mattergroup_name',
  budgetType: 'budget_type',
  budgetCategory: 'budget_category',
  budgetStartDate: 'budget_start_date',
  amounts: 'budget_amount',
  budgetEndDate: 'budget_end_date',
  costcodeName: 'costcode_name',
  practiceName: 'mattergroup_name',
  vendorName: 'vendor_name',
  entityName: 'entity_name',
  costCode: 'costcode_name',
  startDate: 'budget_start_date',
  endDate: 'budget_end_date'
}

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'budgetName':
      return cellContent.toLowerCase()
    case 'matterName':
      return cellContent.toLowerCase()
    case 'practiceName':
      return cellContent.toLowerCase()
    case 'vendorName':
      return cellContent.toLowerCase()
    case 'costCode':
      return cellContent.toLowerCase()
    case 'startDate':
      return moment(cellContent, 'MM/YYYY').unix()
    case 'endDate':
      return moment(cellContent, 'MM/YYYY').unix()
    case 'amount':
      return currency2Num(cellContent)
    case 'status':
      return cellContent.toLowerCase()
    case 'lastAction':
      return Number(cellContent.slice(0, cellContent.length - 1))
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

export const budgetRequestsColumnsOrderByMap = {
  ...activeBudgetOrderByMap,
  amount: 'budget_last_proposal_amount',
  status: 'budget_last_proposal_status',
  lastAction: 'budget_last_proposal_action'
}

export const budgetRequestsCategoriesByMap = {
  approvalNeeded: [BUDGET_STATES.PROPOSED],
  withVendor: [BUDGET_STATES.DRAFT, BUDGET_STATES.OVERDUE, BUDGET_STATES.EXPIRED]
}
