import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { MouseEvent } from 'react'
import { Priority } from '../types'
import { isBasicTaskManagementPlan } from 'utils/helpers'
import cn from 'classnames'

interface Props {
  priority: Priority
  editPriority: (row: Priority) => void
}

const ActionsPopover = ({ priority, editPriority }: Props) => {
  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'EDIT':
        if (isBasicTaskManagementPlan()) return
        editPriority(priority)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${priority.id}`}
        aria-describedby={`item-actions-${priority.id}`}
        aria-label={`item-${priority.id}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${priority.id}`}
      >
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${priority.id}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        delayHide={1}
        isCapture
        border
        clickable
      >
        <ul className={s.actionsList}>
          <>
            <li
              className={cn(s.actionItem, {
                [s.disabled]: isBasicTaskManagementPlan()
              })}
              onClick={e => {
                handleClick(e, 'EDIT')
              }}
              data-for={`upgradeTier-${priority.id}`}
              data-tip
            >
              Edit
            </li>
            {isBasicTaskManagementPlan() && (
              <ReactTooltip
                id={`upgradeTier-${priority.id}`}
                type="light"
                effect="solid"
                place="top"
                border
                className={s.tooltipPopup}
              >
                Contact customer success to upgrade.
              </ReactTooltip>
            )}
          </>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
