import {
  ContactToEdit,
  CreateContactFormType,
  Role
} from 'contacts/AssignedContactsTableContainer/types'
import { Grid, Input } from 'simple-core-ui'
import Select, { GroupBase, StylesConfig } from 'react-select'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import s from './CreateEditContactForm.scss'
import { extractCgRoles, extractLabels } from 'contacts/AssignedContactsTableContainer/utils'
import VendorsSelect from 'contacts/VendorsSelect/VendorsSelect'
import { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { FaExclamationTriangle } from 'react-icons/fa'
import { SPECIAL_ACCESS_ROLES } from 'utils/constants'
import { filterRoleOptions, inferContactType } from 'contacts/utils'

interface Props {
  formValues: CreateContactFormType
  onChangeHandler: (
    key: keyof CreateContactFormType,
    value: CreateContactFormType[keyof CreateContactFormType]
  ) => void
  roles: Role[]
  vendorId: number
  isOtherCompany: boolean
  maxAdminsCountReached: boolean
  isEditMode: boolean
  contactToEdit: ContactToEdit
  setShowRevokeCgAccessModal: (val: boolean) => void
  setShowRemoveRolesModal: (val: boolean) => void
  setShowRemoveLabelsModal: (val: boolean) => void
  setUpdatedRoleFieldValues: (val: Role[]) => void
  userCanEditContactCG: boolean
}

const roleDropdownStyle: StylesConfig<Role, true, GroupBase<Role>> = {
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'white',
    border: '0.5px solid #040404',
    height: '30px',
    borderRadius: '3px'
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '400',
    paddingTop: '4.5px'
  })
}

const inputStyles = { padding: '8px', borderRadius: '4px', borderColor: '#979797' }

const CreateEditContactForm = ({
  formValues,
  onChangeHandler,
  roles,
  vendorId,
  isOtherCompany,
  maxAdminsCountReached,
  isEditMode,
  contactToEdit,
  setShowRevokeCgAccessModal,
  setShowRemoveRolesModal,
  setShowRemoveLabelsModal,
  setUpdatedRoleFieldValues,
  userCanEditContactCG
}: Props) => {
  const { Row, Column } = Grid

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const { cgRoles, labels, company } = formValues

  const selectedContact = inferContactType(contactToEdit, {
    vendor: company,
    roles: [...(cgRoles || []), ...(labels || [])]
  })

  const cgRolesOptions = useMemo(
    () =>
      filterRoleOptions(
        selectedContact,
        extractCgRoles(roles),
        cgRoles,
        SPECIAL_ACCESS_ROLES,
        userCanEditContactCG
      ),
    [roles]
  )

  const labelsOptions = useMemo(
    () =>
      filterRoleOptions(
        selectedContact,
        extractLabels(roles),
        labels,
        SPECIAL_ACCESS_ROLES,
        userCanEditContactCG
      ),
    [roles]
  )

  const { org, type, isCounselGoUser, isCounselGoAdmin } = contactToEdit

  const subtitleText = (selected = 0, total: number) => (
    <p className={s.subtitle}>{`${selected} of ${total} selected`}</p>
  )

  const isClientContact = ['client', 'legal_entity'].includes(type)
  const isVendorContact = type === 'vendor'

  const disableEmail = isEditMode && (isClientContact || isCounselGoUser === 'Yes')
  const disableSelectCompany = isEditMode && (isClientContact || org)

  const showEmailWarning = isEditMode && isVendorContact && isCounselGoUser === 'Yes'
  const showCompanyWarning = isEditMode && isVendorContact && org

  const isAdminRadioDisabled =
    (!isEditMode && maxAdminsCountReached) ||
    (isEditMode && (isClientContact || (maxAdminsCountReached && isCounselGoAdmin === 'No')))

  return (
    <div className={s.formContainer} id="create-contact-modal">
      {isEditMode && isClientContact ? (
        <p className={s.warningMessage} style={{ margin: '0 0 2rem -4px' }}>
          <FaExclamationTriangle /> Contacts corresponding to a login user can only be modified from
          the <a href="/company/">User Management Page</a>
        </p>
      ) : null}
      <Row style={{ marginBottom: '1rem' }}>
        <Column span={6}>
          <label>
            First Name<span className={s.asterisk}>*</span>
          </label>
          <Input
            className={s.inputField}
            style={inputStyles}
            text={formValues.firstName ?? ''}
            type="text"
            onChangeCb={eve => onChangeHandler('firstName', eve.target.value)}
            testid="first-name-input"
            isDisabled={isEditMode && isClientContact}
          />
        </Column>
        <Column span={6}>
          <label>
            Last Name<span className={s.asterisk}>*</span>
          </label>
          <Input
            className={s.inputField}
            style={inputStyles}
            text={formValues.lastName ?? ''}
            type="text"
            onChangeCb={eve => onChangeHandler('lastName', eve.target.value)}
            testid="last-name-input"
            isDisabled={isEditMode && isClientContact}
          />
        </Column>
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <Column span={6}>
          <label>
            Email<span className={s.asterisk}>*</span>
          </label>
          <Input
            className={s.inputField}
            style={inputStyles}
            text={formValues.email ?? ''}
            type="text"
            onChangeCb={eve => onChangeHandler('email', eve.target.value)}
            testid="email-input"
            isDisabled={disableEmail}
          />
          {showEmailWarning ? (
            <p className={s.warningMessage}>
              <FaExclamationTriangle /> Cannot update email on CounseGO user. Delete and re-add
              contact instead.
            </p>
          ) : null}
        </Column>
        <Column span={6}>
          <label>Phone</label>
          <Input
            className={s.inputField}
            style={inputStyles}
            text={formValues.phone ?? ''}
            type="text"
            onChangeCb={eve => onChangeHandler('phone', eve.target.value)}
            testid="phone-input"
            isDisabled={isEditMode && isClientContact}
          />
        </Column>
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <Column span={6}>
          <label htmlFor="select company">
            Company <span className={s.asterisk}>*</span>
          </label>
          {/* @ts-expect-error */}
          <VendorsSelect
            id="testid_contact_company"
            className={s.vendorsSelect}
            value={company}
            onChange={(value: { value: number; label: string }) =>
              onChangeHandler('company', value)
            }
            scopeId={vendorId}
            isVendorScoped
            disabled={disableSelectCompany}
          />
          {showCompanyWarning ? (
            <p className={s.warningMessage}>
              <FaExclamationTriangle /> Contacts corresponding to a vendor cannot be re-assigned to
              another company.
            </p>
          ) : null}
        </Column>
        <Column span={6}>
          <label>Title</label>
          <Input
            className={s.inputField}
            style={inputStyles}
            text={formValues.title ?? ''}
            type="text"
            onChangeCb={eve => onChangeHandler('title', eve.target.value)}
            isDisabled={isEditMode && isClientContact}
          />
        </Column>
      </Row>
      {isOtherCompany ? (
        <Row style={{ marginBottom: '1rem' }}>
          <Column span={6}>
            <label>
              Company Name<span className={s.asterisk}>*</span>
            </label>
            <Input
              className={s.inputField}
              style={inputStyles}
              text={formValues.companyName ?? ''}
              type="text"
              onChangeCb={eve => onChangeHandler('companyName', eve.target.value)}
              isDisabled={isEditMode && isClientContact}
            />
          </Column>
        </Row>
      ) : null}
      {!isOtherCompany ? (
        <Row style={{ marginBottom: '1rem' }}>
          <Column span={6}>
            <div className={s.cgColumn}>
              <div className={s.headingText}>
                CounselGO Access <span className={s.asterisk}>*</span>
                <AiOutlineInfoCircle
                  className={s.infoTooltip}
                  data-for="cgAccessInfoTooltip"
                  data-tip
                />
                <ReactTooltip
                  className={s.tooltip}
                  id="cgAccessInfoTooltip"
                  type="light"
                  effect="solid"
                  place="right"
                  border
                  borderColor="#939393"
                  offset={{ right: 6 }}
                >
                  <span>
                    CounselGO access allows a user to view and submit invoices and view referred
                    matters
                  </span>
                </ReactTooltip>
              </div>
              <section className={s.radioContainer}>
                <section className={s.radioField}>
                  <input
                    aria-label="is-cg-user"
                    type="radio"
                    checked={formValues.isCgUser}
                    onChange={() => onChangeHandler('isCgUser', true)}
                    disabled={isEditMode && isClientContact}
                  />
                  Yes
                </section>
                <section className={s.radioField}>
                  <input
                    aria-label="is-not-cg-user"
                    type="radio"
                    checked={!formValues.isCgUser}
                    onChange={() => {
                      if (isEditMode && contactToEdit.isCounselGoUser === 'Yes') {
                        setShowRevokeCgAccessModal(true)
                      } else {
                        onChangeHandler('isCgUser', false)
                      }
                    }}
                    disabled={isEditMode && isClientContact}
                  />
                  No
                </section>
              </section>
            </div>
          </Column>
          {formValues.isCgUser ? (
            <Column span={6}>
              <div className={s.cgColumn}>
                <div className={s.headingText}>
                  CounselGO Admin<span className={s.asterisk}>*</span>
                  <AiOutlineInfoCircle
                    className={s.infoTooltip}
                    data-for="cgAdminInfoTooltip"
                    data-tip
                  />
                  <ReactTooltip
                    className={s.tooltip}
                    id="cgAdminInfoTooltip"
                    type="light"
                    effect="solid"
                    place="right"
                    border
                    borderColor="#939393"
                    offset={{ right: 6 }}
                  >
                    <span>
                      CounselGO admins have the ability to add new CounselGO users and admins
                    </span>
                  </ReactTooltip>
                </div>
                <section className={s.radioContainer}>
                  <section className={s.radioField} data-for="isCgAdmin" data-tip>
                    <input
                      aria-label="is-cg-admin"
                      type="radio"
                      className={s.cgUserInput}
                      checked={formValues.isCgAdmin}
                      onChange={() => onChangeHandler('isCgAdmin', true)}
                      disabled={isAdminRadioDisabled}
                    />
                    {isAdminRadioDisabled ? (
                      <ReactTooltip
                        className={s.tooltip}
                        id="isCgAdmin"
                        type="light"
                        effect="solid"
                        place="bottom"
                        border
                        event="click"
                        borderColor="#939393"
                        globalEventOff="click"
                        clickable
                        isCapture
                        offset={{ bottom: 6 }}
                      >
                        <span>
                          The maximum limit of 2 CounselGO Admins has been reached. Click{' '}
                          {/* TODO: Replace span with <Link /> */}
                          <span className={s.helpLink}>here</span> to learn more.
                        </span>
                      </ReactTooltip>
                    ) : null}
                    Yes
                  </section>
                  <section className={s.radioField}>
                    <input
                      aria-label="is-not-cg-admin"
                      type="radio"
                      checked={!formValues.isCgAdmin}
                      onChange={() => onChangeHandler('isCgAdmin', false)}
                      disabled={isEditMode && isClientContact}
                    />
                    No
                  </section>
                </section>
              </div>
            </Column>
          ) : null}
        </Row>
      ) : null}
      {!isOtherCompany && formValues.isCgUser ? (
        <Row>
          <Column>
            <section className={s.headingText}>
              CounselGO Roles
              <div className={s.descriptionText}>
                {`(Default Roles: Invoices, Matter Basics, Budgets, Timekeepers${
                  formValues.isCgAdmin ? ', User Management' : ''
                })`}
              </div>
              <AiOutlineInfoCircle
                className={s.infoTooltip}
                data-tip
                data-for="cgRoleInfoTooltip"
              />
              <ReactTooltip
                className={cn(s.tooltip, s.largeTooltip)}
                id="cgRoleInfoTooltip"
                type="light"
                effect="solid"
                place="right"
                event="click"
                border
                borderColor="#939393"
                globalEventOff="click"
                clickable
                isCapture
                offset={{ right: 6 }}
              >
                <span>
                  Add roles for CounselGO users who need advanced functionality (Accruals
                  Submission, General File Sharing, Matter File Sharing, and Matter Updates). Click{' '}
                  <span className={s.helpLink}>here</span> to learn more.
                </span>
              </ReactTooltip>
            </section>
            <section className={s.selectRoles}>
              <p style={{ paddingBottom: '5px' }}>Select all that apply:</p>
              <Select
                options={cgRolesOptions}
                styles={roleDropdownStyle}
                isMulti
                onChange={(selectedRoles, { action }) => {
                  if (isEditMode && action === 'remove-value') {
                    setShowRemoveRolesModal(true)
                    setUpdatedRoleFieldValues(selectedRoles as Role[])
                  } else {
                    onChangeHandler('cgRoles', selectedRoles as Role[])
                  }
                }}
                aria-label="select cg role"
                value={cgRoles}
                isDisabled={isEditMode && isClientContact}
              />
              {subtitleText(cgRoles?.length, cgRolesOptions.length)}
            </section>
          </Column>
        </Row>
      ) : null}
      <Row>
        <Column>
          <section className={s.headingText}>
            Labels
            <AiOutlineInfoCircle className={s.infoTooltip} data-for="labelsInfoTooltip" data-tip />
            <ReactTooltip
              className={s.tooltip}
              id="labelsInfoTooltip"
              type="light"
              effect="solid"
              place="right"
              event="click"
              border
              borderColor="#939393"
              globalEventOff="click"
              clickable
              isCapture
              offset={{ right: 6 }}
            >
              <span>
                Tag users with labels that correspond to their daily tasks. Click{' '}
                <span className={s.helpLink}>here</span> to learn more.
              </span>
            </ReactTooltip>
          </section>
          <section className={s.selectRoles}>
            <p style={{ paddingBottom: '5px' }}>Select all that apply:</p>
            <Select
              options={labelsOptions}
              styles={roleDropdownStyle}
              isMulti
              onChange={(selectedRoles, { action }) => {
                if (isEditMode && action === 'remove-value') {
                  setShowRemoveLabelsModal(true)
                  setUpdatedRoleFieldValues(selectedRoles as Role[])
                } else {
                  onChangeHandler('labels', selectedRoles as Role[])
                }
              }}
              value={labels}
            />
            {subtitleText(labels?.length, labelsOptions.length)}
          </section>
        </Column>
      </Row>
    </div>
  )
}

export default CreateEditContactForm
