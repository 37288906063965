import { Component, KeyboardEventHandler, CSSProperties } from 'react'
import { GroupBase, StylesConfig } from 'react-select'
import cn from 'classnames'
import { SearchableSelect } from 'containers'
import currencies from 'data/currencies'
import s from './Currency.scss'
import get from 'lodash/get'
import { TextInput } from 'simple-core-ui'

type Props = {
  onChange(params: { code: string; amount: number }): void
  classNameMap?: Record<string, string>
  selectedOption: string
  amount: number
  disabled: boolean
  size: 'md' | 'lg'
  isPortal: boolean
  hasError: boolean
  inputStyles: CSSProperties
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectStyles: StylesConfig<any, false, GroupBase<any>>
  bulkInput: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectProps: any
}

type KeyboardEventType = KeyboardEventHandler<HTMLInputElement> &
  KeyboardEventHandler<HTMLTextAreaElement>

class Currency extends Component<Props> {
  keyPressHandler: KeyboardEventType = event => {
    if (event.which != 46 && (event.which < 48 || event.which > 57)) {
      event.preventDefault()
    }
  }

  render() {
    const {
      onChange,
      selectedOption,
      amount,
      disabled,
      classNameMap,
      size,
      isPortal,
      hasError,
      inputStyles,
      selectStyles,
      bulkInput,
      selectProps
    } = this.props

    return (
      <div
        className={cn(
          s.currencyWrapper,
          {
            [s.medium]: size === 'md',
            [s.large]: size === 'lg'
          },
          !!classNameMap && classNameMap.currencyWrapper
        )}
      >
        <div className={cn(s.flex2, !!classNameMap && classNameMap.inputWrapper)}>
          <TextInput
            className={cn(
              hasError && !amount ? 'bulk-currency-error' : 'bulk-currency',
              s.currencyInput,
              {
                [classNameMap?.input || '']: !!classNameMap && 'input' in classNameMap
              }
            )}
            style={inputStyles}
            placeholder="Value"
            type="number"
            value={amount}
            onChange={(value: number) => onChange({ code: selectedOption, amount: value })}
            isDisabled={disabled}
            id={bulkInput ? 'bulk-currency-input-container' : ''}
            onKeyPress={this.keyPressHandler}
            containerClassName={classNameMap?.inputContainer}
          />
        </div>
        <div className={cn(s.flex1, !!classNameMap && classNameMap.selectWrapper)}>
          <SearchableSelect
            isPortal={isPortal}
            className={cn(s.currencySelect, !!classNameMap && classNameMap.select)}
            options={currencies}
            selectedOption={currencies.find(o =>
              typeof selectedOption === 'string'
                ? o.value === selectedOption
                : o.value === get(selectedOption, 'value')
            )}
            property="code"
            onChange={(value: string) => onChange({ code: value, amount })}
            isDisabled={disabled}
            styles={selectStyles}
            {...selectProps}
          />
        </div>
      </div>
    )
  }
}

export default Currency
