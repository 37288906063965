import * as React from 'react'
import ACT from '../actions'

import Subscribers from '../components/Subscribers'

class SubscribersContainer extends React.Component {
  state = { subscribers: this.props.subscribers }

  updateSubscribers = () => {
    const {
      dispatch,
      reviewerTiers,
      isLeadReviewer,
      reviewerConfigId,
      scope,
      scopeId,
      reviewerScope
    } = this.props

    const { subscribers } = this.state

    dispatch({
      type: ACT.UPDATE_REVIEWER_CONFIG_REQUESTED,
      payload: {
        reviewerTiers,
        subscribers,
        isLeadReviewer,
        reviewerConfigId,
        scope,
        scopeId,
        reviewerScope
      }
    })
  }

  revertSubscribers = () => {
    const { subscribers } = this.props

    this.setState({ subscribers })
  }

  editSubscribers = newSubscriber => {
    const isSelectedAlready = this.state.subscribers?.find(e => e?.id === newSubscriber?.[0]?.id)
    if (isSelectedAlready) {
      return
    }
    this.setState({ subscribers: this.state.subscribers.concat(newSubscriber) })
  }

  removeSubscribers = subscribers => {
    this.setState({ subscribers })
  }

  clearSubscribers = () => {
    this.setState({ subscribers: [] }, this.updateSubscribers)
  }

  render() {
    return (
      <Subscribers
        {...this.props}
        {...this.state}
        savedSubscribers={this.props.subscribers}
        editableSubscribers={this.state.subscribers}
        updateSubscribers={this.updateSubscribers}
        revertSubscribers={this.revertSubscribers}
        editSubscribers={this.editSubscribers}
        clearSubscribers={this.clearSubscribers}
        removeSubscribers={this.removeSubscribers}
      />
    )
  }
}

export default SubscribersContainer
