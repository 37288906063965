import s from './HistoryList.scss'
import { format, isSameDay } from 'date-fns'
import { History } from '../../types'
import { timezoneDate } from 'utils/helpers'

interface Props {
  history: History[]
}

const loggedInUser = window.credentials.user
const isLoggedUser = (author: { id: number; full_name: string }): boolean => {
  return author?.id === +loggedInUser.id
}

const HistoryList = ({ history }: Props) => {
  return (
    <div className={s.list}>
      {history.map((item, index) => {
        return (
          <div key={index} className={s.historyItem}>
            <div className={s.info}>
              <span>
                <b>{isLoggedUser(item.user) ? 'You' : item.user.full_name}</b>{' '}
                <span>{item.description}</span>
              </span>
            </div>
            <div className={s.date}>
              {format(
                timezoneDate(item.log_date),
                isSameDay(timezoneDate(item.log_date), new Date())
                  ? "'[Today]' hh:mm a"
                  : 'MM/dd/yyyy hh:mm a'
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default HistoryList
