import moment from 'moment'
import s from '../styles/SearchResults.scss'
import { filesize } from 'filesize'
import { makeGetRequest } from 'utils/api'
import { ROOT_PATH } from 'matters/detail/files/MatterAttachmentsTab/constants'

const displayPath = (matterName, path) => {
  const formattedPath = path.replace(/\/\//g, '').replace(/\//g, ' > ')
  return `${matterName} ${formattedPath ? ' ∙ ' + formattedPath : ''}`
}

const getBreadcrumbItems = (matterName, matterId, path) => {
  let pathItems = [
    {
      title: matterName,
      url: `/v2/matters/${matterId}/?tab=files`
    }
  ]
  if (path === ROOT_PATH) {
    return pathItems
  } else {
    const items = path.split(ROOT_PATH)[1] ? path.split(ROOT_PATH)[1].split('/') : []
    items.forEach((item, index) => {
      const path = ROOT_PATH + items.slice(0, index + 1).join('/')
      const url = `/manage/matters/v2/${matterId}/?directory=${path}#pane-attachments`
      pathItems.push({
        title: item,
        url: url
      })
    })
    return pathItems
  }
}

const previewAttachment = async attachmentId => {
  try {
    const response = await makeGetRequest(`/doc_management/native_docs/preview/${attachmentId}`)

    window.open(
      response.previewURL,
      'targetWindow',
      'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1000, height=1000'
    )
  } catch (error) {
    window.store.dispatch({
      type: 'API_ERROR',
      error
    })
  }
}

export const MatterFileResult = ({
  category,
  client_matter_id,
  matter_id,
  file_name,
  file_size,
  headline,
  labels,
  matter_description,
  matter_short_name,
  matter_status,
  path,
  rank,
  upload_date,
  uploaded_by,
  upload_id,
  query,
  url
}) => {
  const navUrl = `/manage/matters/v2/${matter_id}/?directory=${path}#pane-attachments`
  const highlightHeadline = headline
    .replace(/<b>/g, '<span style="background: #FFF3CF; font-weight: 600;">')
    .replace(/<\/b>/g, '</span>')
  const reg = new RegExp(query, 'i')
  const fileNameQueryMatch = file_name.match(reg)
  const categoryQueryMatch = category.match(reg)
  const bcrumbs = getBreadcrumbItems(matter_short_name, matter_id, path)
  return (
    <div className={s.searchResultRow}>
      <div className={s.searchResultHeader}>
        <span
          onClick={() => previewAttachment(upload_id)}
          className={s.searchItemLink}
          dangerouslySetInnerHTML={{
            __html: fileNameQueryMatch
              ? file_name.replace(
                  reg,
                  `<span style="background: #FFF6DC;">${fileNameQueryMatch}</span>`
                )
              : file_name
          }}
        ></span>
        <span className={s.searchLabelWrapper}>
          {labels.map(l => {
            const labelMatchQuery = l.name.match(reg) // Correct placement

            return (
              <span key={l.id} className={s.searchLabel} style={{ background: l.color + '50' }}>
                <span className={s.circle} style={{ background: l.color }}></span>
                <span
                  style={{
                    background: labelMatchQuery ? '#FFF6DC' : 'transparent',
                    fontWeight: labelMatchQuery ? '600' : 'auto'
                  }}
                  dangerouslySetInnerHTML={{
                    __html: labelMatchQuery
                      ? l.name.replace(
                          reg,
                          `<span style="background: #FFF6DC;">${labelMatchQuery}</span>`
                        )
                      : l.name
                  }}
                ></span>
              </span>
            )
          })}
        </span>
      </div>
      <div className={s.searchResultsSummary}>
        <span>
          {bcrumbs.map((crumb, i) => {
            return (
              <span className={s.bcrumb} key={i}>
                <a target="_blank" rel="noopener noreferrer" href={crumb.url}>
                  {crumb.title}
                </a>
                {i < bcrumbs.length - 1 ? ' > ' : ''}
              </span>
            )
          })}
        </span>
        {category && (
          <span>
            <span style={{ padding: '0 8px' }}>∙</span>
            <span
              style={{
                background: categoryQueryMatch ? '#FFF6DC' : 'transparent'
              }}
              dangerouslySetInnerHTML={{
                __html: categoryQueryMatch
                  ? category.replace(
                      reg,
                      `<span style="background: #FFF6DC;">${categoryQueryMatch}</span>`
                    )
                  : category
              }}
            ></span>
          </span>
        )}
      </div>
      <div
        className={s.searchResultsHeadline}
        style={{ fontStyle: 'italic' }}
        dangerouslySetInnerHTML={{ __html: highlightHeadline }}
      />
      <div className={s.searchItemListSubText}>
        Last Modified {moment(upload_date).format('MM/DD/YYYY')} by {uploaded_by}{' '}
        {' ∙ ' + filesize(file_size)}
      </div>
    </div>
  )
}
