import { MouseEvent } from 'react'
import { FaTimes, FaChevronDown } from 'react-icons/fa'
import s from './SelectCustomInput.scss'
import noop from 'lodash/noop'
import cn from 'classnames'

interface Props {
  value: string | null | 0 | false | undefined
  onClick: () => void
  onClear: (e: MouseEvent) => void
  isDisabled?: boolean
}

const SelectCustomInput = ({ value, onClick, onClear, isDisabled }: Props) => {
  return (
    <div
      className={cn(s.header, {
        [s.disabled]: isDisabled
      })}
      onClick={isDisabled ? noop : onClick}
    >
      <label className={s.label}>{value ? value : 'Select...'}</label>
      <div className={s.controlsContainer}>
        {!!value && <FaTimes className={s.clearIcon} onClick={isDisabled ? noop : onClear} />}
        <div className={s.expandIconBox}>
          <FaChevronDown className={s.expandIcon} />
        </div>
      </div>
    </div>
  )
}

export default SelectCustomInput
