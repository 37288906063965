import { useState, useEffect } from 'react'
import {
  Panel,
  Button,
  DataTableWrapper,
  AvatarList,
  SwitchToggle,
  useLoading
} from 'simple-core-ui'
import { EventTypeModal } from './EventTypeModal'
import { getNormalizedCellContent } from './utils'
import { checkByNameIfInArray, openLink } from 'utils/helpers'
import s from './EventTypes.scss'
import { ActionsPopover } from './ActionsPopover'
import { useImmer } from 'use-immer'
import { EventType } from './types'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import { makeGetRequest, makePatchRequest, makePostRequest, makeDeleteRequest } from 'utils/api'
import { toEventTypes } from './serializers'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { DeleteConfirmation } from './DeleteConfirmation'
import { format } from 'date-fns'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const emptyEventType = {
  name: ''
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 10,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const columns = [
  {
    columnKey: 'name',
    content: 'Type',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'relatedEvents',
    content: 'Related Events',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'lastEdited',
    content: 'Last Edited',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'editedBy',
    content: 'Edited By',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'isActive',
    content: (
      <>
        Active
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="infoTooltip" data-tip />
        <ReactTooltip id="infoTooltip" type="light" effect="solid" place="top" border>
          Active settings can be applied to an event
        </ReactTooltip>
      </>
    )
  }
]

const EventTypes = () => {
  const [showEventTypeModal, setShowEventTypeModal] = useState(false)
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [eventTypes, setEventTypes] = useImmer<EventType[]>([])
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [eventType, setEventType] = useState({} as EventType)
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const fetchEventTypes = async () => {
    try {
      const { rows } = await withLoadingLocks(makeGetRequest('/event-management/event-types/'))
      setEventTypes(toEventTypes(rows))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchEventTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleStatus = async (id: number) => {
    const index = eventTypes.findIndex(t => t.id === id)
    try {
      const updatedEventType = await withLoadingLocks(
        makePatchRequest(`/event-management/event-types/${id}/`, {
          is_active: !eventTypes[index].isActive
        })
      )
      setEventTypes(draft => {
        draft[index] = toEventTypes([updatedEventType])[0]
      })

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Event type ${eventTypes[index].name} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onToggleStatus = (id: number) => {
    const index = eventTypes.findIndex(p => p.id === id)
    const eventType = eventTypes[index]
    setEventType(eventType)
    toggleStatus(id)
  }

  const renderCell = ({
    rowId,
    columnKey,
    content
  }: {
    rowId: number
    columnKey: string
    content: any
  }) => {
    if (!content && content !== 0) {
      return '--'
    }

    if (columnKey === 'isActive') {
      const isActive = content !== '----'
      return (
        <SwitchToggle
          id={`eventType-${rowId}`}
          dataTestId={String(rowId)}
          checked={isActive}
          onToggle={() => onToggleStatus(rowId)}
        />
      )
    }
    if (columnKey === 'relatedEvents') {
      return content || 0
    }
    if (columnKey === 'lastEdited') {
      return content === '----' ? '--' : format(new Date(content), 'M/d/yyyy')
    }
    if (columnKey === 'editedBy') {
      return content === '----' ? (
        '--'
      ) : (
        <AvatarList size="md" wrapperStyles={{ width: 36 }} entries={[{ label: content }]} />
      )
    }
    return content
  }

  const toggleEventTypeModal = () => {
    setShowEventTypeModal(!showEventTypeModal)
    setShowDuplicateError(false)
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const showEditModal = (eventType: EventType) => {
    toggleEventTypeModal()
    setEventType(eventType)
  }

  const handleDelete = (eventType: EventType) => {
    setEventType(eventType)
    setShowDeleteConfirmation(true)
  }

  const onDeleteType = async () => {
    const index = eventTypes.findIndex(e => e.id === eventType.id)
    try {
      await makeDeleteRequest(`/event-management/event-types/${eventType.id}/`)

      const arr = [...eventTypes]
      arr.splice(index, 1)
      setEventTypes(arr)

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Event type successfully deleted',
          level: 'success'
        }
      })
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was a problem deleting the event type',
          level: 'error'
        }
      })
    }

    setShowDeleteConfirmation(false)
  }

  const updateEventType = (value: string | number | null, property: string) => {
    if (property === 'name' && typeof value === 'string') {
      setShowDuplicateError(checkByNameIfInArray(value, eventTypes, { id: eventType.id }))
    }
    setEventType({
      ...eventType,
      [property]: value
    })
  }

  const saveEventType = async () => {
    const { id, name } = eventType
    try {
      if (id) {
        const index = eventTypes.findIndex(p => p.id === id)

        const updatedEventType = await withLoadingLocks(
          makePatchRequest(`/event-management/event-types/${id}/`, { name })
        )
        setEventTypes(draft => {
          draft[index] = toEventTypes([updatedEventType])[0]
        })

        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: `Event type ${eventType.name} successfully updated.`,
            level: 'success'
          }
        })
      } else {
        const newEventType = await withLoadingLocks(
          makePostRequest(`/event-management/event-types/`, {
            name
          })
        )
        setEventTypes(draft => {
          draft.push(toEventTypes([newEventType])[0])
        })

        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: `Event type ${eventType.name} successfully created.`,
            level: 'success'
          }
        })
      }

      toggleEventTypeModal()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
      toggleEventTypeModal()
    }
  }

  const downloadXlsx = () => {
    const { columnKey, isDesc } = params.ordering
    openLink(`/event-management/event-types/export/?columnKey=${columnKey}&isDesc=${+isDesc}`)
  }

  const renderCustomAction = (row: EventType) => (
    <ActionsPopover
      type={row}
      deleteType={handleDelete}
      canDelete={+row.relatedEvents === 0}
      editType={showEditModal}
    />
  )

  return (
    <Panel
      title="Manage Event Types"
      subtitle="Use types to categorize your events."
      rightActions={[
        <Button hasNewDesign key="download" onClick={downloadXlsx} isPrimary isOutline>
          Download
        </Button>,
        <Button
          hasNewDesign
          key="add"
          onClick={() => showEditModal(emptyEventType as EventType)}
          isPrimary
        >
          Add Type
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={eventTypes}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.itemsTable}
        customAction={renderCustomAction}
        hasActions
        hasTooltip
        alwaysShowActions
        categoryKey="eventType"
        getNormalizedCellContent={getNormalizedCellContent}
        renderCell={renderCell}
        multiSort
        customActionsMemoizationDeps={[eventTypes]}
      />
      {showEventTypeModal && (
        <EventTypeModal
          saveEventType={saveEventType}
          eventType={eventType}
          updateEventType={updateEventType}
          toggleEventTypeModal={toggleEventTypeModal}
          showDuplicateError={showDuplicateError}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          onConfirm={onDeleteType}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
    </Panel>
  )
}

export default EventTypes
