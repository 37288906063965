import s from '../styles/AccountSettings.scss'
import AccountSettings from './AccountSettings'
import { Avatar } from 'simple-core-ui'
import { IoIosArrowDown } from 'react-icons/io'

const AccountDropdown = ({ isExpanded, openDropdown }) => {
  const userInfo = window.credentials.user
  const accountLinks = window.credentials.accountUrls
  const fullName = `${userInfo.firstName} ${userInfo.lastName}`

  const avatar = (
    <Avatar
      initials={fullName}
      className={s.avatar}
      styles={{ height: '28px', width: '28px', border: '1px solid #ddd', marginLeft: '0.5rem' }}
    />
  )

  return (
    <section className={isExpanded ? s.open : s.dropdownContainer} onClick={openDropdown}>
      {avatar}
      <span className={s.caret}>
        <IoIosArrowDown />
      </span>
      {isExpanded && <AccountSettings userInfo={userInfo} accountLinks={accountLinks} />}
    </section>
  )
}

export default AccountDropdown
