export const CONDITIONS_TO_INCLUDE = {
  Allocation: 'Allocation',
  AnnualSpend: 'Annual Spend',
  Invoice: 'Invoice',
  invoice_discount: 'Invoice Discount',
  LegalEntity: 'Legal Entity',
  LineItem: 'Line Item',
  Matter: 'Matter',
  MatterType: 'Matter Type',
  matter_annual: 'Matter Annual',
  matter_vendor_activity_code: 'Matter Vendor Activity Code',
  matter_vendor_expense_code: 'Matter Vendor Expense Code',
  matter_vendor_monthly: 'Matter Vendor Monthly',
  matter_vendor_task_code: 'Matter Vendor Task Code',
  MonthlySpend: 'Monthly Spend',
  Spend: 'Spend',
  Vendor: 'Vendor',
  vendor_annual: 'Vendor Annual',
  MatterGroup: 'Practice Area'
}

export const LHS_TYPES = {
  FUNC_CALL: 'func',
  FIELD_VAR: 'field',
  LIST_ATTR_FIELD: 'list_custom_attr'
}

export const FUNC_ARG_TYPES = {
  FIELD: 'field',
  CONST: 'const'
} as const
