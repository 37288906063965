// @ts-nocheck
import { configureStore } from '@reduxjs/toolkit'
import { fetchFlowMiddleware, isRequest, isSuccess } from 'simple-core-ui/fetchFlow'
import createSagaMiddleware from 'redux-saga'

import { createBrowserHistory } from 'history'
import { parseErrorMessage } from './utils/helpers'
import reducers from './rootReducer'
import { createReduxHistoryContext } from 'redux-first-history'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
})

export const sagaMiddleware = createSagaMiddleware()

const stripActionType = (actionType, str) => {
  const idx = actionType.indexOf(str)
  return actionType.slice(0, idx)
}

const handleStatusCodes = (error, response, dispatch) => {
  const msgIsNotDisplayable = !Array.isArray(response.data) && typeof response.data === 'object'
  const validResponseMessage = parseErrorMessage(response.data)

  if (response.status === 403) {
    const DEFAULT_403 = 'You do not have permission to make this request'
    const { data } = response
    const message = msgIsNotDisplayable
      ? validResponseMessage || data.errors || data || DEFAULT_403
      : DEFAULT_403
    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: error.errorTitle || response.statusText || '403 Forbidden',
        message,
        level: 'error'
      }
    })
  } else if (response.status === 404) {
    const DEFAULT_404 = 'The requested entity does not exist.'
    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: error.errorTitle || response.statusText || '404 Not Found',
        message: msgIsNotDisplayable ? DEFAULT_404 : response.data,
        level: 'error'
      }
    })
  } else if (response.status === 500) {
    const DEFAULT_500 = 'A server error occurred. Please contact the administrator.'
    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: error.errorTitle || response.statusText || '500 Internal Server Error',
        message: msgIsNotDisplayable ? DEFAULT_500 : response.data,
        level: 'error'
      }
    })
  } else if (response.status === 422) {
    const DEFAULT_422 =
      "Make sure you have all required fields. If that doesn't work, reload the page and try again."
    const { data } = response
    const notDisplayable = data.errors ? !Array.isArray(data.errors) : msgIsNotDisplayable
    const message = data.errors || data
    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: 'Validation Error',
        message: notDisplayable ? DEFAULT_422 : message,
        level: 'error'
      }
    })
  } else if (response.status === 400) {
    const DEFAULT_400 =
      "Make sure you have all required fields. If that doesn't work, reload the page and try again."
    const { data } = response
    const message = validResponseMessage || data.errors || data || DEFAULT_400
    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: error.errorTitle || response.statusText || '400 Bad Request',
        message,
        level: 'error'
      }
    })
  } else if (response.status === 409) {
    const DEFAULT_409 =
      'A similar request is already being processed. Please wait some time before you try again, or refresh the page to check the status.'
    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: error.errorTitle || response.statusText || '409 Conflict',
        message: msgIsNotDisplayable
          ? validResponseMessage
            ? validResponseMessage
            : DEFAULT_409
          : response.data,
        level: 'error'
      }
    })
  }
}

const errorDispatch = (error, dispatch) => {
  let response = error.response
  // jquery response objects are different from axios
  if (error.responseText) {
    response = {
      statusText: error.statusText,
      data: error.responseText,
      status: error.status
    }
  } else {
    if (error.isAxiosError || error.name === 'AxiosError') {
      if (response) {
        // Server was able to send us a response, so this is an API Error.
        response = {
          statusText: response.statusText,
          data: response.data?.message ?? response.data,
          status: response.status
        }
        handleStatusCodes(error, response, dispatch)
      } else {
        // Axios was not able to get a response at all. This is a Network-Level Error.
        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Network Error',
            message: 'No Response Received From Server',
            level: 'error'
          }
        })
      }
    } else {
      // Non-HTTP Error, Standard JS Error (Syntax, etc...)
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Runtime Error',
          message: error.message,
          level: 'error'
        }
      })
      const e = new Error(JSON.stringify(error))
      e.stack = error.stack
      throw e
    }
  }
}

const loadingLockMiddleware = ({ dispatch }) => next => action => {
  if (!action.type) {
    next(action)
  }

  if (isRequest(action)) {
    const actionLoading = { [stripActionType(action.type, '_REQUESTED')]: true }
    dispatch({ type: 'SET_LOADING', payload: actionLoading })
  }

  if (isSuccess(action)) {
    const actionType = stripActionType(action.type, '_SUCCESS')
    const actionLoaded = {
      [actionType]: false
    }
    const initialLoaded = {
      [actionType]: true
    }

    if (action.success)
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          message: action.success,
          level: 'success'
        }
      })

    dispatch({ type: 'SET_LOADING', payload: actionLoaded })
    dispatch({ type: 'SET_INITIAL_LOAD', payload: initialLoaded })
  }

  if (!document || !document.getElementById('react-app')) {
    next(action)
    return
  }

  if (action.type === 'API_ERROR') {
    document.body.style.cursor = 'default'
    document.getElementById('react-app').style.pointerEvents = 'auto'
    errorDispatch(action.error, dispatch)
  }

  if (action.loadingLock === 'on' && !action.disableLoadingCursor) {
    document.body.style.cursor = 'wait'
    document.getElementById('react-app').style.pointerEvents = 'none'
  } else if (action.loadingLock === 'off') {
    document.body.style.cursor = 'default'
    document.getElementById('react-app').style.pointerEvents = 'auto'
    if (action.loadingType) {
      const actionLoading = { [stripActionType(action.loadingType, '_REQUESTED')]: false }
      dispatch({ type: 'SET_LOADING', payload: actionLoading })
    }
  }

  next(action)
}

const middleware = [loadingLockMiddleware, routerMiddleware, sagaMiddleware, fetchFlowMiddleware]

const __DEMO__ = window.location.host === 'demo.simplelegal.com'
window.__DEMO__ = __DEMO__
window.__DEV__ = __DEV__

if (__DEV__ || __DEMO__) {
  const logger = require('redux-logger').default
  middleware.push(logger)
}

export const setupStore = (initialState = {}) => {
  return configureStore({
    reducer: {
      ...reducers,
      router: routerReducer
    },
    middleware,
    preloadedState: initialState
  })
}

export const store = setupStore()

export const history = createReduxHistory(store)
