import s from './AssignedContactsTableContainer.scss'
import {
  Panel,
  Button,
  DataTableWrapper,
  ModalContainer,
  DataTableSerializer
} from 'simple-core-ui'
import { useEffect, useMemo, useState } from 'react'
import { columns } from './tableDefinitions'
import { serializeAssignedContacts, toContact, toRoles } from './serializers'
import { ActionsPopover } from './ActionsPopover'
import { Params } from '@common_types/common'
import {
  createPredicates,
  getNormalizedCellContent,
  filterContacts,
  determineEditableFields
} from './utils'
import { Filters } from './Filters'
import {
  ContactsFilters,
  AssignedContact,
  Role,
  AssignRoleFormType,
  SelectedContact,
  CreateContactFormType,
  ContactToEdit
} from './types'
import { makeGetRequest, makePostRequest } from 'simple-core-ui/utils/api'
import { CONTACTS_SEARCH_URL } from 'common/Selects/urls'
import {
  CONTACTS_VENDOR_BASE_URL,
  CONTACTS_VENDOR_ROLES_URL,
  filterRoleOptions,
  onlyAssignedContacts,
  patchRoleTimestamp,
  sortContacts,
  sortRoles
} from 'contacts/utils'
import { fromContact, fromContacts, fromRoles } from 'contacts/serializer'
import APP_ACT from 'app/actions'
import { useDispatch } from 'react-redux'
import { AssignRolesForm } from './AssignedContactForms/AssignRolesForm'
import { SPECIAL_ACCESS_ROLES } from 'utils/constants'
import CreateEditContactForm from 'contacts/AssignedContactsForms/CreateEditContactForm'
import ReactTooltip from 'react-tooltip'

interface Contact {
  id: number
  roleTimestamp: string
}

interface Rows {
  all: { id: number }[]
}

const initialParams: Params = {
  pageSize: 10,
  ordering: { columnKey: 'firstName', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

const vendorId = window.serverContext.get('object_id')

const initialTaskFilters = {
  labelIds: null,
  roleIds: null
}

const initialAssignRoleFormValues: AssignRoleFormType = {
  selectedContact: null,
  isCgUser: true,
  isCgAdmin: false,
  cgRoles: [],
  labels: []
}

const initialCreateEditFormValues: CreateContactFormType = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  company: null,
  companyName: '',
  title: '',
  isCgUser: true,
  isCgAdmin: false,
  cgRoles: [],
  labels: []
}

const AssignedContactsTableContainer = () => {
  const [assignedContacts, setAssignedContacts] = useState<AssignedContact[]>([])
  const [roles, setRoles] = useState<Role[]>([])
  const [params, setParams] = useState(initialParams)
  const [totalEntries, setTotalEntries] = useState(0)
  const [contactFilters, setContactFilters] = useState<ContactsFilters>(initialTaskFilters)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showResendInviteConfirmation, setShowResendInviteConfirmation] = useState(false)
  const [contact, setContact] = useState({} as ContactToEdit)
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [assignRoleFormValues, setAssignRoleFormValues] = useState(initialAssignRoleFormValues)
  const [userCanEditContactCG, setUserCanEditContactCG] = useState(false)
  const [filteredRoles, setFilteredRoles] = useState<Role[]>([])
  const [isCgRoleAllowed, setIsCgRoleAllowed] = useState(true)
  const [currentCGAdminsCount, setCurrentCGAdminsCount] = useState(0)
  const [maxAllowedCGAdminsCount, setMaxAllowedCGAdminsCount] = useState(0)
  const [isEditMode, setIsEditMode] = useState(false)
  const [showRevokeCgAccessModal, setShowRevokeCgAccessModal] = useState(false)
  const [showRemoveRolesModal, setShowRemoveRolesModal] = useState(false)
  const [showRemoveLabelsModal, setShowRemoveLabelsModal] = useState(false)
  const [updatedRoleFieldValues, setUpdatedRoleFieldValues] = useState<Role[]>([])

  const [showCreateContactModal, setShowCreateContactModal] = useState(false)
  const [createEditFormValues, setCreateEditFormValues] = useState(initialCreateEditFormValues)

  const dispatch = useDispatch()

  const isOtherCompany = createEditFormValues.company?.value === -1
  const maxAdminsCountReached = currentCGAdminsCount === maxAllowedCGAdminsCount

  const canSubmit = () => {
    const requiredFields = ['firstName', 'lastName', 'email', 'company']

    let isValid = false

    isValid = requiredFields.every(
      key => !!createEditFormValues[key as keyof CreateContactFormType]
    )

    if (isOtherCompany) {
      isValid = isValid && !!createEditFormValues.companyName
    }

    if (isEditMode && contact.type === 'client') {
      isValid = true
    }

    return isValid
  }

  const getRequest = async (url: string, config?: Record<string, unknown>) => {
    const response = await makeGetRequest(url, config)
    return response
  }

  const { selectedContact } = assignRoleFormValues

  const fetchContacts = () =>
    getRequest(CONTACTS_SEARCH_URL, {
      params: { v: vendorId, l: 'true', f: 'true', a: 'true' }
    })

  const fetchContactsAndRoles = async () => {
    const [
      { results, userCanEditContactCG, currentCGAdminsNo, maxAllowedCGAdmins },
      { roles }
    ] = await Promise.all([fetchContacts(), getRequest(CONTACTS_VENDOR_ROLES_URL)])

    const roleSet = sortRoles(fromRoles(roles))
    const assignedContacts = serializeAssignedContacts(
      sortContacts(onlyAssignedContacts(fromContacts(results, roleSet)))
    )

    setAssignedContacts(assignedContacts)
    setRoles(roleSet)
    setFilteredRoles(roleSet)
    setTotalEntries(assignedContacts.length)
    setUserCanEditContactCG(userCanEditContactCG)
    setCurrentCGAdminsCount(currentCGAdminsNo)
    setMaxAllowedCGAdminsCount(maxAllowedCGAdmins)
  }

  useEffect(() => {
    fetchContactsAndRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearFilters = () => {
    setContactFilters(initialTaskFilters)
  }

  const updateTable = (updatedParams: Params) => {
    setParams(updatedParams)
  }

  const allFilteredContacts = useMemo(() => {
    const predicates = createPredicates(contactFilters)
    const filteredContacts = filterContacts(assignedContacts, predicates)
    const { all: allRows } = DataTableSerializer.getRowsByCategory(
      { all: filteredContacts },
      columns,
      '---'
    ) as Rows

    return allRows
  }, [assignedContacts, contactFilters])

  const handleDelete = (contact: ContactToEdit) => {
    setContact(contact)
    setShowDeleteConfirmation(true)
  }

  const handleResendInvite = (contact: ContactToEdit) => {
    setContact(contact)
    setShowResendInviteConfirmation(true)
  }

  const handleEditContact = (contact: ContactToEdit) => {
    setIsEditMode(true)
    setContact(contact)
    setCreateEditFormValues(determineEditableFields(contact, roles))
    setShowCreateContactModal(true)
  }

  const onDelete = async () => {
    try {
      const selectedContact = patchRoleTimestamp(contact, assignedContacts)
      const url = `${CONTACTS_VENDOR_BASE_URL}${vendorId}/${selectedContact.id}/assign/`
      await makePostRequest(url, {
        roles: [],
        role_timestamp: selectedContact.roleTimestamp
      })

      fetchContactsAndRoles()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `${selectedContact.firstName} ${selectedContact.lastName} successfully deleted.`,
          level: 'success'
        }
      })
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was a problem deleting the contact.',
          level: 'error'
        }
      })
    }
    setShowDeleteConfirmation(false)
  }

  const onResendInvite = async () => {
    const url = `/manage/contacts/${contact.id}/resend-cg-invite`
    try {
      await makePostRequest(url)
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Invitation sent successfully!',
          level: 'success'
        }
      })
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was a problem while resending the invite.',
          level: 'error'
        }
      })
    }
    setShowResendInviteConfirmation(false)
  }

  const renderCustomAction = (row: ContactToEdit) => (
    <ActionsPopover
      rowId={row.id}
      contact={row}
      showResendInvite={row.isCounselGoUser === 'Yes'}
      deleteContact={handleDelete}
      resendInvite={handleResendInvite}
      editContact={handleEditContact}
    />
  )

  const fetchRoleOptions = (value: Role[]) =>
    filterRoleOptions(
      assignRoleFormValues.selectedContact,
      roles,
      value,
      SPECIAL_ACCESS_ROLES,
      userCanEditContactCG
    )

  useEffect(() => {
    const filteredRoles = fetchRoleOptions([])
    setFilteredRoles(filteredRoles)

    const isCgUser = filteredRoles.some((el: Role) => el.systemName === '_cg')
    setAssignRoleFormValues({
      ...assignRoleFormValues,
      isCgUser
    })
    setIsCgRoleAllowed(isCgUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact])

  const assignChangeHandler = (key: string, value: string | boolean | Role[] | SelectedContact) => {
    setAssignRoleFormValues({ ...assignRoleFormValues, [key]: value })
    if (!value && key === 'isCgUser') {
      setAssignRoleFormValues({ ...assignRoleFormValues, isCgUser: false, isCgAdmin: false })
    }
    if (value && key === 'isCgAdmin') {
      setAssignRoleFormValues({
        ...assignRoleFormValues,
        isCgAdmin: true,
        isCgUser: true
      })
    }
    if (Array.isArray(value) && ['cgRoles', 'labels'].includes(key)) {
      setAssignRoleFormValues({
        ...assignRoleFormValues,
        [key]: value.sort((a, b) => a.label.localeCompare(b.label))
      })
    }
  }

  const clearAssignRoleFormValues = () => {
    setShowAssignRoleModal(false)
    setAssignRoleFormValues(initialAssignRoleFormValues)
  }

  const fetchSelectedRoles = () => {
    let selectedRoles = [...assignRoleFormValues.cgRoles, ...assignRoleFormValues.labels]

    if (assignRoleFormValues.isCgUser) {
      const cgUserRole = roles.filter(role => role.systemName == '_cg')
      selectedRoles = [...selectedRoles, ...cgUserRole]
    }
    if (assignRoleFormValues.isCgAdmin) {
      const cgAdminRole = roles.filter(role => role.systemName == '_cg_admin')
      selectedRoles = [...selectedRoles, ...cgAdminRole]
    }
    return selectedRoles
  }

  const handleAssignRoleSubmit = async () => {
    const selectedContact = patchRoleTimestamp(
      assignRoleFormValues.selectedContact,
      assignedContacts
    )
    const selectedRoles = fetchSelectedRoles()

    const url = `${CONTACTS_VENDOR_BASE_URL}${vendorId}/${selectedContact.id}/assign/`
    try {
      await makePostRequest(url, {
        roles: selectedRoles,
        role_timestamp: selectedContact.roleTimestamp
      })
      fetchContactsAndRoles()
      clearAssignRoleFormValues()
    } catch (e) {
      clearAssignRoleFormValues()
    }
  }

  const createEditChangeHandler = (
    key: keyof CreateContactFormType,
    value: CreateContactFormType[keyof CreateContactFormType]
  ) => {
    setCreateEditFormValues(prevFormValues => {
      return {
        ...prevFormValues,
        [key]: value,
        ...(Array.isArray(value) && ['cgRoles', 'labels'].includes(key)
          ? { [key]: value.sort((a, b) => a.label.localeCompare(b.label)) }
          : {}),
        ...(!value && key === 'isCgUser' ? { isCgUser: false, isCgAdmin: false, cgRoles: [] } : {}),
        ...(value && key === 'isCgAdmin' ? { isCgUser: true, isCgAdmin: true } : {})
      }
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isValid = useMemo(() => canSubmit(), [createEditFormValues])

  const createOrEditContact = async () => {
    const url = isEditMode ? `/manage/contacts/${contact.id}/` : '/manage/contacts/new/'
    const addEditResponse = await makePostRequest(url, toContact(createEditFormValues))

    const serializedContact = fromContact(addEditResponse)
    const selectedRoles = toRoles(createEditFormValues, roles, isOtherCompany)

    return { serializedContact, selectedRoles }
  }

  const assignVendor = async (selectedRoles: Role[], contactDetails: Contact) => {
    const selectedContact = patchRoleTimestamp(contact, assignedContacts)
    const url = `${CONTACTS_VENDOR_BASE_URL}${vendorId}/${contactDetails.id}/assign/`
    await makePostRequest(url, {
      roles: selectedRoles,
      role_timestamp: isEditMode ? selectedContact.roleTimestamp : contactDetails.roleTimestamp
    })
  }

  const resetModal = () => {
    ReactTooltip.rebuild()
    setIsEditMode(false)
    setShowCreateContactModal(false)
    setCreateEditFormValues(initialCreateEditFormValues)
  }

  const handleCreateEditSubmit = async () => {
    try {
      const { serializedContact, selectedRoles } = await createOrEditContact()
      await assignVendor(selectedRoles, serializedContact)
      fetchContactsAndRoles()
      resetModal()

      const successMessageTitle = isEditMode
        ? 'The contact info has been successfully updated!'
        : 'The contact has been successfully created!'

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: successMessageTitle,
          level: 'success'
        }
      })
    } catch (error) {
      resetModal()
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: `There was an issue ${isEditMode ? 'updating' : 'creating'} the contact.`,
          level: 'error'
        }
      })
    }
  }

  return (
    <section data-testid="contacts_table_container">
      <Panel
        title="Assigned Contacts"
        className={s.panel}
        rightActions={[
          <Button
            style={{ padding: '10px 15px', fontWeight: 400 }}
            isOutline
            isPrimary
            hasNewDesign
            key="contacts_secondary_btn"
            testid="testid_assign_role_table"
            className={s.button}
            onClick={() => setShowAssignRoleModal(true)}
          >
            Assign Role
          </Button>,
          <Button
            style={{ padding: '10px 15px', borderRadius: '4px' }}
            isPrimary
            hasNewDesign
            key="contacts_primary_btn"
            testid="testid_create_contact_table"
            onClick={() => setShowCreateContactModal(true)}
          >
            + Create New Contact
          </Button>
        ]}
      >
        <section className={s.content}>
          <DataTableWrapper
            params={params}
            categories={[]}
            rows={allFilteredContacts}
            customSearchOptions={{
              placeholder: 'Start typing to search',
              styles: { width: 270, fontStyle: 'italic' }
            }}
            customActionsMemoizationDeps={[allFilteredContacts]}
            columns={columns}
            updateTable={updateTable}
            totalEntries={totalEntries}
            hasActions
            hasToolTip
            alwaysShowActions
            customAction={renderCustomAction}
            panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
            getNormalizedCellContent={getNormalizedCellContent}
            remotePagination={false}
            filters={
              <Filters
                contactFilters={contactFilters}
                setContactFilters={filters => {
                  setContactFilters(filters)
                }}
                clearFilters={clearFilters}
                roles={roles}
              />
            }
            customStatusText="There are currently no contacts assigned"
            className={s.table}
          />
        </section>
      </Panel>
      {showCreateContactModal ? (
        <ModalContainer
          title={`${isEditMode ? 'Edit' : 'Create New'} Contact`}
          size="lg"
          confirmText={`${isEditMode ? 'Update' : 'Create'}`}
          confirmCb={handleCreateEditSubmit}
          cancelCb={() => {
            resetModal()
          }}
          hasCloseIcon
          content={
            <CreateEditContactForm
              formValues={createEditFormValues}
              onChangeHandler={createEditChangeHandler}
              roles={roles}
              vendorId={vendorId}
              isOtherCompany={isOtherCompany}
              maxAdminsCountReached={maxAdminsCountReached}
              isEditMode={isEditMode}
              contactToEdit={contact}
              setShowRevokeCgAccessModal={setShowRevokeCgAccessModal}
              setShowRemoveRolesModal={setShowRemoveRolesModal}
              setShowRemoveLabelsModal={setShowRemoveLabelsModal}
              setUpdatedRoleFieldValues={setUpdatedRoleFieldValues}
              userCanEditContactCG={userCanEditContactCG}
            />
          }
          hasNewButtons
          cancelText="Cancel"
          isDisabled={!isValid}
        />
      ) : null}
      {showAssignRoleModal && (
        <ModalContainer
          title="Assign Role"
          size="lg"
          confirmText="Assign"
          hasCloseIcon
          content={
            <AssignRolesForm
              formValues={assignRoleFormValues}
              onChangeHandler={assignChangeHandler}
              roles={filteredRoles}
              vendorId={vendorId}
              clearFormValues={() => setAssignRoleFormValues(initialAssignRoleFormValues)}
              isCgRoleAllowed={isCgRoleAllowed}
              maxAdminsCountReached={maxAdminsCountReached}
            />
          }
          hasNewButtons
          confirmCb={handleAssignRoleSubmit}
          cancelCb={clearAssignRoleFormValues}
          isDisabled={
            !selectedContact ||
            !(assignRoleFormValues.labels.length || assignRoleFormValues.isCgUser)
          }
        />
      )}
      {showDeleteConfirmation && (
        <ModalContainer
          title="Delete Contact"
          hasNewButtons
          size="sm"
          cancelCb={() => setShowDeleteConfirmation(false)}
          confirmText="Delete"
          confirmCb={onDelete}
          content={
            contact.isCounselGoUser === 'Yes' ? (
              <div>
                Deleting contact will revoke CounselGO access and remove the contact from this
                vendor
              </div>
            ) : (
              <div> Delete contact from this vendor? </div>
            )
          }
          contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
        />
      )}
      {showResendInviteConfirmation && (
        <ModalContainer
          title="Resend Invite"
          hasNewButtons
          size="sm"
          cancelCb={() => setShowResendInviteConfirmation(false)}
          confirmText="Resend"
          confirmCb={onResendInvite}
          content={
            <div>
              Do you want to resend CounselGO invite to {contact.firstName} {contact.lastName}
            </div>
          }
          contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
        />
      )}
      {showRevokeCgAccessModal && (
        <ModalContainer
          title="Revoke Access"
          hasNewButtons
          size="sm"
          cancelCb={() => {
            createEditChangeHandler('isCgUser', true)
            setShowRevokeCgAccessModal(false)
          }}
          confirmText="Yes"
          cancelText="No"
          confirmCb={() => {
            createEditChangeHandler('isCgUser', false)
            setShowRevokeCgAccessModal(false)
          }}
          content={<div>Are you sure you want to revoke CounselGO access?</div>}
          contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
          hasCloseIcon
        />
      )}
      {(showRemoveRolesModal || showRemoveLabelsModal) && (
        <ModalContainer
          title={`Remove ${showRemoveRolesModal ? 'Role' : 'Label'}(s)?`}
          hasNewButtons
          size="sm"
          cancelCb={() => {
            setShowRemoveRolesModal(false)
            setShowRemoveLabelsModal(false)
            setUpdatedRoleFieldValues([])
          }}
          confirmText="Yes"
          cancelText="No"
          confirmCb={() => {
            showRemoveRolesModal && createEditChangeHandler('cgRoles', updatedRoleFieldValues)
            showRemoveLabelsModal && createEditChangeHandler('labels', updatedRoleFieldValues)
            setShowRemoveRolesModal(false)
            setShowRemoveLabelsModal(false)
          }}
          content={
            <div>{`Are you sure you want to remove ${
              showRemoveRolesModal ? 'role' : 'label'
            }(s)?`}</div>
          }
          contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
          hasCloseIcon
        />
      )}
    </section>
  )
}

export default AssignedContactsTableContainer
