import { useEffect, useState, useCallback, useRef, memo } from 'react'
import s from './File.scss'
import { useFilesUpload } from 'simple-core-ui'
import cn from 'classnames'
import { IoIosClose } from 'react-icons/io'
import axios from 'axios'
import { filesize } from 'filesize'
import { ExtensionImage } from '../../ExtensionImage'
import { IoWarningOutline } from 'react-icons/io5'

const CancelToken = axios.CancelToken

const CancelButton = memo(({ onClick }) => {
  return (
    <div>
      <IoIosClose className={s.cancel} onClick={onClick} />
    </div>
  )
})

const File = ({
  file,
  setCanCancelFilesArr,
  index,
  removeFile,
  setFileError,
  setFileCompleted,
  lifespan,
  isDuplicate
}) => {
  const [percent, setPercent] = useState(0)
  const [error, setError] = useState(false)
  const [source] = useState(CancelToken.source())
  const [completed, setCompleted] = useState(false)
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)
  const spanRef = useRef()

  useEffect(() => {
    uploadFile(file)
    setCanCancelFilesArr(prev => {
      prev.push(source)
      return prev
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadFile = file => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFilesUpload({
      uploadUrl: null,
      successCb: () => null,
      errorCb: () => {
        setError(true)
        setFileError({ file, error: { message: 'Failed to upload', code: 'upload-error' } }, index)
      },
      s3successCb: (AWS_S3_BUCKET_URL, key, name, size, filetype) => {
        setFileCompleted(file, key)
        setCompleted(true)
      },
      cancelCb: () => {
        source.cancel()
      },
      config: {
        onUploadProgress: function(progressEvent) {
          setPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
        cancelToken: source.token
      },
      files: [file?.file],
      lifespan
    })
  }

  const cancelUpload = useCallback(() => {
    source.cancel()
    removeFile(index, file)
  }, [source, index, file, removeFile])

  useEffect(() => {
    const isEllipsisActive =
      spanRef.current.offsetHeight < spanRef.current.scrollHeight ||
      spanRef.current.offsetWidth < spanRef.current.scrollWidth
    setIsEllipsisActive(isEllipsisActive)
  }, [spanRef])

  return (
    <div className={s.row}>
      <ExtensionImage file={file} />
      <div style={{ width: 'calc(100% - 70px)', padding: '0px 8px' }}>
        <div
          className={cn(s.fileName, {
            [s.fileNameFull]: completed
          })}
        >
          <span
            className={s.fileNameSpan}
            data-tip={isEllipsisActive ? file.name : ''}
            ref={spanRef}
          >
            {file.name}
          </span>{' '}
          {completed && isDuplicate ? (
            <div className={s.fileIsDuplicate}>
              <IoWarningOutline size={20} />
              <span>DUPLICATE</span>
            </div>
          ) : null}
          <span className={s.fileSize}>{file.size ? filesize(file.size).toUpperCase() : ''}</span>
        </div>
        {!completed && (
          <div className={s.chart}>
            <div
              className={cn(s.segment, {
                [s.error]: error
              })}
              style={{ width: `${error ? 100 : percent}%` }}
            />
          </div>
        )}
      </div>
      {!completed && (
        <div
          className={cn(s.progressInfo, {
            [s.completed]: percent === 100 && !error && completed,
            [s.errorInfo]: error
          })}
        >
          {error
            ? 'Failed'
            : completed
            ? 'Complete'
            : percent === 100
            ? 'Processing...'
            : `${percent}% Complete`}
        </div>
      )}
      <CancelButton onClick={cancelUpload} />
    </div>
  )
}

export default File
