import Select, { StylesConfig } from 'react-select'
import { Option } from '../types'
import { getReminderValues } from '../utils'
import s from './Reminder.scss'

interface Props {
  reminder?: { type: string; value: number } | null
  readOnly?: boolean
  isPortal?: boolean
  customStyle?: StylesConfig<{ value: string | number; label: string }, false>
  onChangeType: (value: Option<string>) => void
  onChangeTypeValue: (value: Option) => void
}

const defaultSelectStyle: StylesConfig<{ value: string | number; label: string }, false> = {
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

const reminderTypes = [
  {
    value: 'none',
    label: 'None'
  },
  {
    value: 'minutes',
    label: 'Minutes'
  },
  {
    value: 'hours',
    label: 'Hours'
  },
  {
    value: 'days',
    label: 'Days'
  },
  {
    value: 'weeks',
    label: 'Weeks'
  }
]

const Reminder = ({
  reminder,
  readOnly,
  isPortal = false,
  customStyle,
  onChangeType,
  onChangeTypeValue
}: Props) => {
  return (
    <div className={s.reminderContainer}>
      {reminder?.type && (
        <Select
          options={getReminderValues(reminder?.type)}
          value={
            reminder?.value
              ? getReminderValues(reminder?.type).find(item => item.value === reminder?.value)
              : null
          }
          placeholder="Select..."
          className={s.typeValuesSelect}
          styles={customStyle || defaultSelectStyle}
          isDisabled={readOnly}
          {...(isPortal && { menuPortalTarget: document.body, menuPosition: 'fixed' })}
          onChange={item => {
            onChangeTypeValue(item as Option)
          }}
        />
      )}
      <Select
        options={reminderTypes}
        value={reminder?.type ? reminderTypes.find(item => item.value === reminder?.type) : null}
        placeholder="Select..."
        className={s.typeSelect}
        styles={customStyle || defaultSelectStyle}
        isDisabled={readOnly}
        {...(isPortal && { menuPortalTarget: document.body, menuPosition: 'fixed' })}
        onChange={item => {
          onChangeType(item as Option<string>)
        }}
      />
    </div>
  )
}

export default Reminder
