import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { MouseEvent } from 'react'
import { Task } from '../types'

interface Props {
  task: Task
  editTask: (row: Task) => void
  deleteTask: (row: Task) => void
  duplicateTask: (row: Task) => void
}

const ActionsPopover = ({ task, deleteTask, editTask, duplicateTask }: Props) => {
  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    switch (action) {
      case 'DELETE':
        deleteTask(task)
        return
      case 'EDIT':
        editTask(task)
        return
      case 'COPY':
        duplicateTask(task)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${task.id}`}
        aria-describedby={`item-actions-${task.id}`}
        aria-label={`item-${task.id}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${task.id}`}
      >
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${task.id}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
        className={s.tooltip}
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EDIT')
            }}
          >
            Edit
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'COPY')
            }}
          >
            Copy
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DELETE')
            }}
          >
            Delete
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
