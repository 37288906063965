import s from '../DynamicBulkTable.scss'
import { Button, ModalContainer } from 'simple-core-ui'
import { useDispatch } from 'react-redux'
import { serializeJobParams } from 'bulk/serializer'
import { makeBlobRequest } from 'utils/api'
import { useState } from 'react'
import ACT from 'bulk/actions'

const BulkTableHeader = ({
  setStep,
  recordType,
  caType = '',
  params,
  columns,
  templateId = ''
}) => {
  const dispatch = useDispatch()
  const [showDownloadPendingModal, setShowDownloadPendingModal] = useState(false)

  const handleDownload = async () => {
    const downloadParams = serializeJobParams(params)
    const downloadUrl = '/bulkv2/bulk_selection?export=true'
    try {
      const data = await makeBlobRequest({
        url: downloadUrl,
        method: 'POST',
        data: {
          ...downloadParams,
          record_type: recordType,
          ...(caType ? { ca_type: caType } : {}),
          ...(templateId ? { template_id: templateId } : {}),
          record_fields: columns.map(col => col.columnKey)
        }
      })
      if (data.type === 'application/json') {
        setShowDownloadPendingModal(true)
      } else if (
        data.size &&
        data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = 'Bulk Information.xlsx'
        link.click()
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const handleEdit = () => {
    setStep(4)
    dispatch({
      type: ACT.CLEAR_ROWS
    })
  }

  return (
    <>
      <div className={s.rightActions}>
        <Button isSecondary onClick={handleDownload} className={s.headerButton}>
          Download XLSX
        </Button>
        <Button key={1} isPrimary onClick={handleEdit} className={s.headerButton}>
          Edit Results
        </Button>
      </div>
      {!templateId ? (
        <p className={s.infoBanner}>
          Matters that require an update from a recent template change are not available to edit and
          do not show in the below results.
        </p>
      ) : null}
      {showDownloadPendingModal && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your report is ready."
          confirmText="OK"
          confirmCb={() => setShowDownloadPendingModal(false)}
          size="sm"
          hasCloseIcon={false}
        />
      )}
    </>
  )
}

export default BulkTableHeader
