import { Tabs, Tab } from 'simple-core-ui'
import { Details } from './Details'
import { ActivityLogContainer } from 'logbook'

const ActivityDetailsPanel = ({ id, details, permissions, readOnly }) => {
  const user = window.credentials.user
  return (
    <Tabs>
      <Tab header="Details">
        {details && (
          <Details modelId={id} details={details} permissions={permissions} readOnly={readOnly} />
        )}
      </Tab>
      <Tab header="Activity">
        <ActivityLogContainer
          model={{ name: 'Matters', id }}
          secondaryModel={{ name: 'Matters', id, or: true }}
          isBodyOnly
        />
      </Tab>
    </Tabs>
  )
}

export default ActivityDetailsPanel
