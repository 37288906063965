import { ChangeEventHandler, CSSProperties, forwardRef, InputHTMLAttributes } from 'react'
import cn from 'classnames'
import { IoIosArrowDown } from 'react-icons/io'

import {
  OutsideClickContainer,
  useAutosizeField,
  useSyncStateWithProps,
  useWithPopper
} from 'simple-core-ui'
import s from './AutoComplete.scss'

import { flip } from '@floating-ui/react'

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled'> {
  value?: string
  isOpen: boolean
  isReadOnly?: boolean
  disableSearch?: boolean
  minWidth?: CSSProperties['minWidth']
  classNameMap?: {
    dropdown?: string
  }
  shouldOverflow?: boolean
  testid?: string
  closeBox(): void
  openBox(): void
}

const AutoComplete = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    isOpen,
    isReadOnly = false,
    disableSearch = false,
    children,
    classNameMap,
    shouldOverflow = true,
    testid,
    closeBox,
    openBox,
    ...inputProps
  } = props

  const [value, setValue] = useSyncStateWithProps(props.value)

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.target.value)
    if (props.onChange) {
      props.onChange(e)
    }
  }

  const { setReference, setFloating, floatingStyles } = useWithPopper({
    placement: 'bottom-start',
    middleware: [
      flip({
        padding: {
          bottom: shouldOverflow ? 40 : 114 // editor bottom padding + margin
        }
      })
    ]
  })

  const placeholder = inputProps.placeholder || (isOpen ? 'Type in value' : 'Select Value')

  const { width } = useAutosizeField({
    autoWidth: true,
    value,
    placeholder,
    paddingLeft: s.inputPaddingLeft,
    paddingRight: s.inputPaddingRight
  })

  return (
    <OutsideClickContainer closeComponent={closeBox} styles={{ width }}>
      <div className={s.container} data-testid={testid}>
        <div
          data-testid="auto-complete-trigger-box"
          className={cn(s.triggerBox, { [s.readOnly]: isReadOnly })}
          onClick={() => {
            if (!isOpen) {
              openBox()
            }
          }}
          ref={setReference}
        >
          <input
            className={cn(s.input, { [s.open]: isOpen })}
            type="text"
            placeholder={isOpen ? 'Type in value' : 'Select Value'}
            ref={ref}
            {...inputProps}
            value={value}
            onChange={onChange}
            style={{ width }}
            disabled={disableSearch}
          />
          {!isReadOnly && (
            <IoIosArrowDown
              className={cn(s.arrow, { [s.flipped]: isOpen })}
              onClick={e => {
                if (isOpen) {
                  e.stopPropagation()
                  closeBox()
                }
              }}
            />
          )}
        </div>
        {isOpen && (
          <div
            className={cn(s.dropdown, classNameMap?.dropdown)}
            style={floatingStyles}
            ref={setFloating}
          >
            {children}
          </div>
        )}
      </div>
    </OutsideClickContainer>
  )
})

export default AutoComplete
