import { MouseEvent } from 'react'
import ReactTooltip from 'react-tooltip'
import { IoIosMore } from 'react-icons/io'

import { HoverAction } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  ruleId: string | undefined
  onDelete(): void
  onClone(): void
  onToggleVersionHistory(): void
}

const popoverConfig = [
  { action: 'CLONE', label: 'Clone' },
  { action: 'DELETE', label: 'Delete' },
  { action: 'VERSION_HISTORY', label: 'Version History' }
]

const ActionsPopover = ({ ruleId = 'new', onClone, onDelete, onToggleVersionHistory }: Props) => {
  const handleClick = (e: MouseEvent, action: string) => {
    e.preventDefault()
    e.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'CLONE':
        onClone()
        return
      case 'DELETE':
        onDelete()
        return
      case 'VERSION_HISTORY':
        onToggleVersionHistory()
        return
      default:
        return
    }
  }

  const isDisabled = ruleId === 'new'

  return (
    <>
      <span
        data-for={`item-actions-${ruleId}`}
        aria-describedby={`item-actions-${ruleId}`}
        aria-label={`item-${ruleId}`}
        className={s.actionsContainer}
        data-event="click"
        data-testid={`rule-actions`}
        data-tip=""
      >
        <HoverAction
          icon={<IoIosMore />}
          size="medium"
          className={s.hoverAction}
          isDisabled={isDisabled}
        />
      </span>
      {ruleId && ruleId !== 'new' && (
        <ReactTooltip
          id={`item-actions-${ruleId}`}
          type="light"
          effect="solid"
          place="bottom"
          globalEventOff="click"
          border
          clickable
          delayHide={1}
          isCapture
          className={s.tooltipContainer}
        >
          <ul className={s.actionsList} data-testid="rule-actions-list">
            {popoverConfig.map(({ action, label }) => (
              <li
                key={action}
                className={s.actionItem}
                onClick={e => {
                  handleClick(e, action)
                }}
              >
                {label}
              </li>
            ))}
          </ul>
        </ReactTooltip>
      )}
    </>
  )
}

export default ActionsPopover
