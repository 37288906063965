import { Fragment, useState } from 'react'
import { MoveItem } from './MoveItem'
import { VersionHistory } from './VersionHistory'
import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import cn from 'classnames'
import { EMAIL_FOLDER_TYPE } from '../constants'
import ReactTooltip from 'react-tooltip'

const ActionsPopover = ({
  matterId,
  currentItem,
  currentFolder,
  rowId,
  isFolder,
  folderType,
  downloadAttachment,
  disableDownload,
  deleteAttachment,
  openRenameFileModal,
  openRenameFolderModal,
  getFolderItems,
  openEditCategoryModal,
  openEditLabelsModal,
  deleteFolder,
  isInfected,
  openAddVersionModal,
  viewEmail,
  hasVendorCollaboration,
  newTooltipDesignClass
}) => {
  const [showMoveItem, setShowMoveItem] = useState(false)
  const [showVersionHistory, setShowVersionHistory] = useState(false)

  const clickableTooltipProps = {
    isCapture: true,
    event: 'click',
    globalEventOff: 'click',
    className: newTooltipDesignClass
  }

  const toggleMoveItem = () => {
    setShowMoveItem(!showMoveItem)
  }

  const toggleVersionHistory = () => {
    setShowVersionHistory(prevVersionHistory => !prevVersionHistory)
  }

  const handleClick = (event, action) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    ReactTooltip.hide()
    switch (action) {
      case 'RENAME_FILE':
        openRenameFileModal(rowId)
        return
      case 'RENAME_FOLDER':
        openRenameFolderModal(rowId)
        return
      case 'MOVE_ITEM':
        toggleMoveItem()
        return
      case 'DELETE_ATTACHMENT':
        deleteAttachment(rowId)
        return
      case 'VIEW_EMAIL':
        viewEmail(rowId)
        return
      case 'EDIT_CATEGORY':
        openEditCategoryModal(rowId)
        return
      case 'EDIT_LABELS':
        openEditLabelsModal(rowId)
        return
      case 'FILE_VERSION_HISTORY':
        toggleVersionHistory()
        return
      case 'DELETE_FOLDER':
        deleteFolder(rowId)
        return
      case 'DOWNLOAD_ATTACHMENT':
        downloadAttachment({ id: rowId })
        return
      case 'ADD_VERSION':
        const file = event.target.files[0]

        let fr = new FileReader()
        fr.onload = () => {
          openAddVersionModal(rowId, file)
        }
        if (file) {
          fr.readAsDataURL(file)
        }
        return
      default:
        return
    }
  }

  return (
    <Fragment>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction
          icon={<IoIosMore />}
          className={s.hoverAction}
          {...(hasVendorCollaboration
            ? {
                hasNewDesign: true,
                size: 'small'
              }
            : {
                tip: 'More',
                size: 'xsmall'
              })}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
        {...(hasVendorCollaboration ? clickableTooltipProps : {})}
      >
        <ul className={cn(s.actionsList, { [s.centerAlign]: !hasVendorCollaboration })}>
          {!isFolder && (
            <Fragment>
              {!isInfected && (
                <Fragment>
                  {currentFolder.type !== EMAIL_FOLDER_TYPE && (
                    <li
                      className={s.actionItem}
                      onClick={e => {
                        handleClick(e, 'RENAME_FILE')
                      }}
                    >
                      Rename
                    </li>
                  )}
                  <li className={s.actionItem} onClick={e => handleClick(e, 'EDIT_LABELS')}>
                    Edit Labels
                  </li>
                  <li className={s.actionItem} onClick={e => handleClick(e, 'EDIT_CATEGORY')}>
                    Edit Category
                  </li>
                  {currentFolder.type !== EMAIL_FOLDER_TYPE && (
                    <li className={s.actionItem} onClick={e => handleClick(e, 'MOVE_ITEM')}>
                      Move
                    </li>
                  )}
                  {hasVendorCollaboration && !disableDownload && (
                    <li
                      className={s.actionItem}
                      onClick={e => handleClick(e, 'DOWNLOAD_ATTACHMENT')}
                    >
                      Download
                    </li>
                  )}
                  {currentFolder.type !== EMAIL_FOLDER_TYPE && (
                    <li className={cn(s.actionItem, s.addVersionContainer)}>
                      <span>Add New Version</span>
                      <input
                        type="file"
                        onChange={e => handleClick(e, 'ADD_VERSION')}
                        className={s.fileInput}
                      />
                    </li>
                  )}
                </Fragment>
              )}
              {currentFolder.type !== EMAIL_FOLDER_TYPE && (
                <li className={s.actionItem} onClick={e => handleClick(e, 'FILE_VERSION_HISTORY')}>
                  See Version History
                </li>
              )}
              {currentFolder.type === EMAIL_FOLDER_TYPE && (
                <li className={s.actionItem} onClick={e => handleClick(e, 'VIEW_EMAIL')}>
                  View Associated Email
                </li>
              )}
              <li className={s.actionItem} onClick={e => handleClick(e, 'DELETE_ATTACHMENT')}>
                Delete
              </li>
            </Fragment>
          )}
          {isFolder && (
            <Fragment>
              <li
                className={s.actionItem}
                onClick={e => {
                  handleClick(e, 'RENAME_FOLDER')
                }}
              >
                Rename
              </li>
              {folderType !== EMAIL_FOLDER_TYPE && (
                <Fragment>
                  <li className={s.actionItem} onClick={e => handleClick(e, 'MOVE_ITEM')}>
                    Move
                  </li>
                  <li className={s.actionItem} onClick={e => handleClick(e, 'DELETE_FOLDER')}>
                    Delete
                  </li>
                </Fragment>
              )}
            </Fragment>
          )}
        </ul>
      </ReactTooltip>
      <MoveItem
        matterId={matterId}
        currentItem={currentItem}
        currentFolder={currentFolder}
        itemId={rowId}
        toggleMoveItem={e => handleClick(e, 'MOVE_ITEM')}
        showMoveItem={showMoveItem}
        getFolderItems={getFolderItems}
      />
      <VersionHistory
        fileId={rowId}
        showVersionHistory={showVersionHistory}
        toggleVersionHistory={toggleVersionHistory}
        hasVendorCollaboration={hasVendorCollaboration}
      />
    </Fragment>
  )
}

export default ActionsPopover
