import { Alert, KeyValuePairs } from 'simple-core-ui'
import s from './AddRelationship.scss'
import Select from 'react-select'
import { RELATIONSHIP_STATUS } from '../constants'
import { CharLimitInput } from 'components'

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
)
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '2px 5px',
  textAlign: 'center'
}

const AddRelationship = ({
  showDuplicateError,
  relationship,
  changeProperty,
  checkDuplicateName,
  setShowDuplicateError,
  customAttributes
}) => {
  return (
    <div className={s.container}>
      {showDuplicateError && (
        <Alert
          className={s.alert}
          message="The relationship name already exists. Please specify a different name."
          status="error"
        />
      )}
      <KeyValuePairs
        pairs={[
          {
            key: 'Relationship Name*',
            keyClass: s.keyClass,
            sectionClass: s.sectionClass,
            value: (
              <CharLimitInput
                cssClass={s.input}
                value={relationship?.name || ''}
                placeholder="Name"
                onChangeCb={({ target }) => {
                  changeProperty('name', target.value)
                  setShowDuplicateError(checkDuplicateName(target.value, relationship?.id))
                }}
                isDisabled={relationship?.status === RELATIONSHIP_STATUS.INACTIVE}
              />
            )
          },
          {
            key: 'Description',
            keyClass: s.keyClass,
            sectionClass: s.sectionClass,
            value: (
              <CharLimitInput
                focused={false}
                cssClass={s.textarea}
                type="textarea"
                maxLength="150"
                value={relationship?.description || ''}
                placeholder="Description"
                onChangeCb={({ target }) => {
                  changeProperty('description', target.value)
                }}
                isDisabled={relationship?.status === RELATIONSHIP_STATUS.INACTIVE}
              />
            )
          },
          {
            key: 'Level 1*',
            keyClass: s.keyClass,
            sectionClass: s.sectionClass,
            value: (
              <Select
                value={relationship?.level1?.value ? relationship?.level1 : ''}
                aria-label="level1"
                options={customAttributes}
                onChange={value => changeProperty('level1', value)}
                formatGroupLabel={formatGroupLabel}
                isDisabled={relationship?.status === RELATIONSHIP_STATUS.INACTIVE}
              />
            )
          },
          {
            key: 'Level 2*',
            keyClass: s.keyClass,
            sectionClass: s.sectionClass,
            value: (
              <Select
                value={relationship?.level2?.value ? relationship?.level2 : ''}
                aria-label="level2"
                options={customAttributes}
                onChange={value => changeProperty('level2', value)}
                formatGroupLabel={formatGroupLabel}
                isDisabled={relationship?.status === RELATIONSHIP_STATUS.INACTIVE}
              />
            )
          },
          {
            key: 'Level 3 (optional)',
            keyClass: s.keyClass,
            sectionClass: s.sectionClass,
            value: (
              <Select
                value={relationship?.level3?.value ? relationship?.level3 : ''}
                aria-label="level3"
                options={customAttributes}
                onChange={value => changeProperty('level3', value)}
                formatGroupLabel={formatGroupLabel}
                isDisabled={relationship?.status === RELATIONSHIP_STATUS.INACTIVE}
                isClearable
              />
            )
          }
        ]}
      />
    </div>
  )
}

export default AddRelationship
