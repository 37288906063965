export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  {
    label: 'Matter ∙ is_confidential',
    value: 'is_confidential',
    model_field: 'is_confidential',
    model_name: 'Matter',
    arg_type: 'field'
  }
]

export const defaultMatterOptions = [
  {
    label: 'All matters by ID',
    value: 'id',
    model_field: 'id',
    model_name: 'Matter',
    arg_type: 'field'
  }
]

export const defaultVendorOptions = [
  {
    label: 'All Vendors',
    value: 'id',
    model_field: 'id',
    model_name: 'Vendor',
    arg_type: 'field'
  }
]

export const codeOptions = [
  {
    model_name: 'Invoice',
    model_field: 'invoice_number',
    display_name: 'Number',
    arg_type: 'field'
  },
  {
    model_name: 'Invoice',
    model_field: 'invoice_subject',
    display_name: 'Subject',
    arg_type: 'field'
  },
  {
    model_name: 'Invoice',
    model_field: 'native_currency',
    display_name: 'Currency',
    arg_type: 'field'
  },
  {
    model_name: 'LineItem',
    model_field: 'line_item_desc',
    display_name: 'Description',
    arg_type: 'field'
  },
  {
    model_name: 'LineItem',
    model_field: 'line_item_task_code',
    display_name: 'Task Code',
    arg_type: 'field'
  },
  {
    model_name: 'LineItem',
    model_field: 'line_item_activity_code',
    display_name: 'Activity Code',
    arg_type: 'field'
  },
  {
    model_name: 'LineItem',
    model_field: 'line_item_expense_code',
    display_name: 'Expense Code',
    arg_type: 'field'
  },
  {
    model_name: 'LineItem',
    model_field: 'line_item_type',
    display_name: 'Type',
    arg_type: 'field'
  },
  {
    model_name: 'Vendor',
    model_field: 'vendor_name',
    display_name: 'name',
    arg_type: 'field'
  },
  {
    model_name: 'matter_vendor_activity_code',
    model_field: 'activity_code',
    display_name: 'Activity Code',
    arg_type: 'field'
  },
  {
    model_name: 'matter_vendor_expense_code',
    model_field: 'expense_code',
    display_name: 'Expense Code',
    arg_type: 'field'
  },
  {
    model_name: 'matter_vendor_task_code',
    model_field: 'task_code',
    display_name: 'Task Code',
    arg_type: 'field'
  }
]
