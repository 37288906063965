import { useContext } from 'react'
import InvoiceAI from './InvoiceAI'
import {
  Panel,
  ButtonDropdown,
  Anchor,
  Input,
  If,
  Tabs,
  Tab,
  ConditionalRender
} from 'simple-core-ui'
import { useAppTabs } from 'hooks'

import RuleCardContainer from 'rules/card'
import s from '../styles/RulesList.scss'
import { FILTER_TYPE, EMPTY_TEXT, ENGINE_LABEL, ENABLED_ENGINES, ENGINE } from 'rules/constants'
import { sortRules } from 'rules/utils'
import { EngineContext } from 'rules/context'
import { Navigate } from 'react-router-dom'

const ENGINE_TABS = ENABLED_ENGINES.map(engine => ({
  label: ENGINE_LABEL[engine],
  value: engine
}))

const RulesList = ({
  rulesList,
  engine,
  addNewRule,
  filterType,
  updateFilterType,
  updateFilterSearch,
  updateEngine,
  filterSearch,
  isSaving,
  ruleIndexMap,
  rulePriorityMap,
  ruleNameMap,
  newRulePending
}) => {
  const { canEdit } = useContext(EngineContext)

  useAppTabs({ tabs: ENGINE_TABS, selected: engine, onClick: updateEngine })

  return (
    <section className={s.container}>
      <ConditionalRender
        conditions={[
          {
            condition: engine === ENGINE.INVOICE_AI,
            content: <InvoiceAI />
          },
          {
            condition: engine === ENGINE.SIMPLE_REVIEW,
            content: <Navigate to="/v2/rules/simplereview/list" />
          }
        ]}
        fallback={
          <>
            <header className={s.header}>
              <If condition={window.credentials.user.isCSM}>
                <Anchor url={`/v2/rules/dsl?engine=${engine}`}>
                  Navigate to Rules Text Editor
                </Anchor>
              </If>
              <div className={s.filter}>
                <Input
                  placeholder="Search Rules..."
                  text={filterSearch}
                  onChangeCb={({ target: { value } }) => updateFilterSearch(value)}
                />
              </div>
            </header>
            <Panel
              title={`${ENGINE_LABEL[engine]} Rules`}
              className={s.panel}
              rightActions={[
                canEdit && (
                  <ButtonDropdown
                    options={[
                      { label: 'Standard Rule', value: false },
                      { label: 'Unconditional Rule', value: true }
                    ]}
                    displayText="Create New"
                    onSelect={({ value }) => addNewRule(value)}
                    style={{ marginRight: 0 }}
                    isDisabled={isSaving || newRulePending}
                    isPrimary
                    alignRight
                    key="a"
                  />
                )
              ]}
            >
              <Tabs alignRight>
                <Tab
                  selected={filterType === FILTER_TYPE.ALL}
                  header="All"
                  onClick={() => updateFilterType(FILTER_TYPE.ALL)}
                />
                <Tab
                  selected={filterType === FILTER_TYPE.ACTIVE}
                  header="Active"
                  onClick={() => updateFilterType(FILTER_TYPE.ACTIVE)}
                />
                <Tab
                  selected={filterType === FILTER_TYPE.INACTIVE}
                  header="Inactive"
                  onClick={() => updateFilterType(FILTER_TYPE.INACTIVE)}
                />
              </Tabs>
              <If condition={rulesList.length}>
                {() => (
                  <ul className={s.list}>
                    {sortRules(rulesList, rulePriorityMap, ruleNameMap).map(rule => {
                      const ruleIndex = ruleIndexMap[rule.id]
                      return (
                        <RuleCardContainer
                          rule={rule}
                          key={`rule_index_${ruleIndex}`}
                          ruleIndex={ruleIndex}
                        />
                      )
                    })}
                  </ul>
                )}
              </If>
              <If condition={rulesList.length === 0}>
                <p className={s.empty}>
                  <If condition={filterSearch} fallback={EMPTY_TEXT[filterType]}>
                    <span>
                      No {filterType === FILTER_TYPE.ALL ? '' : filterType.toLowerCase()} rules
                      found matching:
                    </span>
                    <strong className={s.searchValue}>{filterSearch}</strong>
                  </If>
                </p>
              </If>
            </Panel>
          </>
        }
      />
    </section>
  )
}

export default RulesList
