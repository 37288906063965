import { Event, LocalEvent, Attachment, APIAttachment } from '../types'
import shortid from 'shortid'

export const toLocalEvent = (event: Event | null): LocalEvent | null => {
  if (!event) {
    return null
  }
  const {
    id,
    name,
    eventId,
    startDate,
    endDate,
    timezone,
    eventType,
    description,
    isPrivate,
    attendees,
    fileAttachments,
    createdDate,
    createdBy,
    relatedMatter,
    reminder,
    calendar,
    location
  } = event
  return {
    id,
    name,
    eventId,
    startDate,
    endDate,
    timezone,
    eventType: eventType
      ? {
          value: eventType.id,
          label: eventType.name
        }
      : null,
    description,
    createdBy,
    createdDate,
    isPrivate,
    attendees,
    fileAttachments,
    ...(relatedMatter !== undefined ? { relatedMatter } : {}),
    ...(reminder !== undefined ? { reminder } : {}),
    calendar,
    location
  }
}

export const toEvent = (event: LocalEvent): Event => {
  const {
    id,
    name,
    eventId,
    startDate,
    endDate,
    timezone,
    eventType,
    description,
    isPrivate,
    attendees,
    fileAttachments,
    createdDate,
    createdBy,
    reminder,
    calendar,
    location
  } = event
  return {
    id,
    name,
    eventId,
    startDate,
    endDate,
    timezone,
    ...(eventType !== undefined
      ? eventType === null
        ? { eventType: null }
        : {
            eventType: {
              id: eventType.value,
              name: eventType.label
            }
          }
      : {}),
    description,
    isPrivate,
    attendees,
    comments: [],
    commentsCount: 0,
    fileAttachments,
    fileAttachmentsCount: fileAttachments?.length ?? 0,
    createdBy,
    createdDate,
    reminder,
    calendar,
    ...(location !== undefined
      ? location === null
        ? { location: null }
        : {
            location: {
              address: location?.address ?? '',
              latitude: location?.latitude ?? null,
              longitude: location?.longitude ?? null,
              google_place_id: location?.google_place_id ?? '',
              name: location?.name ?? ''
            }
          }
      : {})
  }
}

export const toAPIAttachments = (attachments: Attachment): APIAttachment[] => {
  return Object.keys(attachments).reduce((acc: APIAttachment[], key: string) => {
    const attachment = attachments[key]
    const { name, size, type, will_overwrite_file } = attachment

    acc.push({
      id: shortid.generate(),
      file_name: name,
      file_url: key,
      file_size: size,
      file_type: type,
      s3_file_url: null,
      source: 'ebill',
      fileStatus: 'Temporary',
      will_overwrite_file
    })

    return acc
  }, [])
}
