import { ModalContainer, LawRuleIcon } from 'simple-core-ui'
import s from './NavigateAwayModal.scss'
import { BsBoxArrowUpRight } from 'react-icons/bs'

const NavigateAwayModal = ({
  toggleNavigateAwayModal
}: {
  toggleNavigateAwayModal: () => void
}) => {
  return (
    <ModalContainer
      title="You are now leaving SimpleLegal "
      hasNewButtons
      size="sm"
      cancelCb={toggleNavigateAwayModal}
      confirmText={
        <>
          I agree <BsBoxArrowUpRight style={{ marginLeft: 5 }} />
        </>
      }
      confirmCb={() => {
        toggleNavigateAwayModal()
        window.open('http://ruleslist.com/get-quote.php?par=CRO', '_blank')
      }}
      content={
        <div className={s.statusItem}>
          CalendarRules integration can be used to automate deadline calculations using court rule
          sets.
          <div style={{ margin: '20px 0', textAlign: 'center' }}>
            <LawRuleIcon />
          </div>
          You will be redirected to a third-party website, where you can request a quote for a
          CalendarRules account and obtain a token which you will use in SimpleLegal. SimpleLegal
          does not govern services on the third-party platform, and their terms and privacy policies
          will apply.
        </div>
      }
    />
  )
}

export default NavigateAwayModal
