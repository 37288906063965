import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ModalContainer } from 'simple-core-ui'
import AddUserForm from './AddUserForm'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { fromTeamsManageMeta } from 'teams/serializers'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import { serializeUserData, toFormData } from './serializers'
import APP_ACT from 'app/actions'
import { TeamSettings, InitialForm, UserRoles, LoginPref, FormValues } from './types'

const { sso_enabled, soft_sso_enabled } = window.serverContext.all()

const USER_ROLES: UserRoles[] = [
  { label: 'Power User (no Admin rights)', value: 'poweruser', default_notify: ['arrival'] },
  { label: 'Assigned User (filter)', value: 'filtered', default_notify: ['arrival'] },
  { label: 'Email Only', value: 'emailonly', default_notify: ['approval'] },
  { label: 'Email Only - Filtered', value: 'emailonly_filtered', default_notify: ['approval'] },
  { label: 'Finance (limit line item detail)', value: 'finance', default_notify: ['approval'] },
  { label: 'Administrator', value: 'admin', default_notify: ['arrival'] },
  ...(hasModule(MODULE.LEGAL_REQUESTS)
    ? [{ label: 'Legal Requests Only', value: 'requestonly', default_notify: ['arrival'] }]
    : [])
]

const LOGIN_PREFERENCE: LoginPref[] = [
  { label: 'SSO', value: 'sso', isDisabled: !(sso_enabled || soft_sso_enabled) },
  { label: 'Password', value: 'password', isDisabled: sso_enabled && !soft_sso_enabled }
].sort((a, b) => {
  return a.isDisabled - b.isDisabled
})

const initialState: InitialForm = {
  firstName: '',
  lastName: '',
  email: '',
  role: USER_ROLES[1],
  teams: [],
  loginPreference: LOGIN_PREFERENCE[0],
  notifyOn: USER_ROLES[1].default_notify
}

const AddUser = () => {
  const [isAddUserModalVisible, setAddUserModalVisible] = useState(false)
  const [formData, setFormData] = useState(initialState)
  const [teamSettings, setTeamSettings] = useState<TeamSettings | null>(null)
  const [error, setError] = useState<Record<string, string> | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchTeamsSetting = async () => {
      const response = await makeGetRequest('/teams/manage/')
      setTeamSettings(fromTeamsManageMeta(response))
    }
    fetchTeamsSetting()
  }, [])

  const onChangeHandler = (key: string, value: string | FormValues) => {
    if (key === 'notifyOn' && typeof value === 'string') {
      const notifyArr = [...formData.notifyOn]
      const updatedArr = notifyArr.includes(value)
        ? notifyArr.filter(ele => ele !== value)
        : [...notifyArr, value]
      setFormData({ ...formData, [key]: updatedArr })
    } else if (key === 'role') {
      const roleValue = value as UserRoles
      setFormData({
        ...formData,
        role: roleValue,
        notifyOn: roleValue.default_notify
      })
    } else {
      setFormData({ ...formData, [key]: value })
    }
  }

  const closeModal = () => {
    setAddUserModalVisible(false)
    setFormData(initialState)
    setError(null)
  }

  const handleSubmit = async () => {
    const serializedForm = serializeUserData(formData)
    const formBody = toFormData(serializedForm)
    try {
      await makePostRequest('/company/user/add/', formBody)
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `New User ${formData.firstName} ${formData.lastName} added successfully.`,
          level: 'success'
        }
      })
      closeModal()
      setTimeout(() => window.location.reload(), 2000)
    } catch (err) {
      const {
        status,
        data: { field, msg }
        //@ts-expect-error
      } = err?.response
      if (status === 400) {
        setError({
          [field]:
            msg === 'Custom user with this Email address already exists.'
              ? 'A user with this email address already exists.'
              : msg
        })
      } else {
        closeModal()
        dispatch({ type: 'API_ERROR', err })
      }
    }
  }

  return (
    <>
      <Button isPrimary onClick={() => setAddUserModalVisible(true)}>
        Add User
      </Button>
      {isAddUserModalVisible && (
        <ModalContainer
          title="Add User"
          cancelCb={closeModal}
          confirmCb={handleSubmit}
          shouldModalClose={false}
          hasNewButtons
          confirmText="Add User"
          content={
            <AddUserForm
              error={error}
              formData={formData}
              teamSettings={teamSettings}
              onChangeHandler={onChangeHandler}
              userRoles={USER_ROLES}
              loginPreference={LOGIN_PREFERENCE}
            />
          }
        />
      )}
    </>
  )
}

export default AddUser
