import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { MouseEvent } from 'react'
import { Location } from '../../types'

interface Props {
  deleteLocation: () => void
  toggleDraft: () => void
  onEdit?: (value: Location) => void
  location: Location | null
}

const ActionsPopover = ({ deleteLocation, toggleDraft, location, onEdit }: Props) => {
  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'EDIT':
        if (location) {
          onEdit?.(location)
        } else {
          toggleDraft()
        }
        return
      case 'DELETE':
        deleteLocation()
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-location`}
        aria-describedby={`item-actions-location`}
        aria-label={`item`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-location`}
      >
        <HoverAction hasNewDesign icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-location`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EDIT')
            }}
          >
            Edit
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DELETE')
            }}
          >
            Delete
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
