import React from 'react'

import { withRouter } from 'simple-core-ui/hocs'

import { OutsideClickContainer } from 'simple-core-ui'
import { IoIosArrowDown, IoIosSearch, IoMdCheckmark } from 'react-icons/io'

import { encodeText, hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import KEY_CODES from 'data/key_codes'
import s from '../styles/Header.scss'
import queryString from 'query-string'
import find from 'lodash/find'
import get from 'lodash/get'
import { connect } from 'react-redux'

@withRouter
@connect(({ app }) => {
  const entityLabels = get(app, 'credentials.custom.names.legal_entity_management', [
    'Legal Entity',
    'Legal Entities'
  ])

  const categories = [
    { label: 'Invoices', value: 'invoices' },
    { label: 'Matters', value: 'matters' },
    { label: 'Vendors', value: 'vendors' },
    { label: 'Contacts', value: 'contacts' },
    { label: 'Timekeepers', value: 'timekeepers' },
    { label: 'Matter Files', value: 'matter-files' },
    { label: 'Vendor Files', value: 'vendor-files' },
    { label: entityLabels[1], value: 'entity' }
  ]

  return {
    categories
  }
})
class SearchContainer extends React.Component {
  constructor(props) {
    super(props)
    const categories = props.categories
    const q = queryString.parse(props.router.location.search).q || ''
    const scope =
      queryString.parse(props.router.location.search).scope ||
      window.localStorage.getItem('searchCategory')
    const category = find(categories, c => c.value === scope) || categories[0]

    this.state = {
      query: q,
      scope: category,
      isFocused: false
    }
  }

  enterSearch = event => {
    const { query, scope } = this.state
    if (event.keyCode === KEY_CODES.enter && query) {
      this.search()
    }
  }

  updateText = event => {
    this.setState({ query: event.target.value })
  }

  openDropdown = () => {
    this.setState({ isFocused: true })
  }

  blurComponent = () => {
    this.setState({ isFocused: false })
  }

  selectCategory = category => {
    this.setState({ isFocused: false, scope: category })
    this.inputWrapper.focus()
  }

  search = () => {
    const { scope, query } = this.state
    this.setState({ isFocused: false })
    if (query.length) {
      try {
        window.localStorage.setItem('searchCategory', scope.value)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Local Storage is full. Please empty data')
      }

      this.blurComponent()
      if (hasModule(MODULE.NATIVE_DMS) && scope.value === 'matter-files') {
        window.location = `/v2/search?q=${encodeText(query)}&scope=${scope.value}`
      } else {
        window.location = `/search?q=${encodeText(query)}&scope=${scope.value}`
      }
    }
  }

  render() {
    const { categories } = this.props
    const { query, isFocused, scope } = this.state

    const styles = {
      outsideClickContainer: {
        width: this.props.isMobile ? '60%' : '100%',
        position: 'relative'
      }
    }

    return (
      <li className={s.searchContainer}>
        <OutsideClickContainer closeComponent={isFocused ? this.blurComponent : () => {}}>
          <div
            className={s.searchDropdown}
            onClick={this.openDropdown}
            data-testid="slm-search-dropdown"
          >
            <span className={s.selectedLabel}>{scope.label}</span>
            <span className={s.caret}>
              <IoIosArrowDown />
            </span>
            {isFocused && (
              <div className={s.categoryList}>
                <ul>
                  {categories.map((c, i) => {
                    const isSelected = scope.value === c.value
                    return (
                      <li
                        key={c.value}
                        className={isSelected ? s.selected : ''}
                        onClick={e => {
                          e.stopPropagation()
                          this.selectCategory(c)
                        }}
                        data-testid={`slm-${c.value}-search`}
                      >
                        <div className={s.checkmarkContainer}>
                          {isSelected && <IoMdCheckmark />}
                        </div>
                        <div className={s.labelContainer}>{c.label}</div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </OutsideClickContainer>
        <input
          data-testid="global_search"
          ref={ref => (this.inputWrapper = ref)}
          onKeyDown={this.enterSearch}
          value={query}
          onChange={this.updateText}
          type={'text'}
          placeholder="Search for invoices, matters, vendors..."
          spellCheck={false}
          className={s.searchInput}
        />
        <div title={'Search'} className={s.searchIcon} onClick={this.search}>
          <IoIosSearch />
        </div>
      </li>
    )
  }
}

export default SearchContainer
