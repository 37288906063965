import { useMemo } from 'react'
import s from './Tabs.scss'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import ACT from 'invoices/actions'
import { Badge, withConfirmation } from 'simple-core-ui'

const TAB_CONF = {
  title: 'List of invoices selected will be lost.',
  text: 'Do you wish to continue?',
  buttons: ['Cancel', 'Yes'],
  icon: 'warning'
}

const Tabs = ({ selectedTab, setSelectedTab, hasConfirmation }) => {
  const statusCounts = useSelector(state => state.invoices.invoiceSummary)
  const params = useSelector(state => state.invoices.invoiceListParams)
  const dispatch = useDispatch()
  const tabs = useMemo(() => {
    return [
      {
        label: 'My Invoices',
        value: 'Mine',
        count: statusCounts['Mine'].count || null
      },
      {
        label: 'Received',
        value: 'Received',
        count: statusCounts['Received'].count || null
      },
      {
        label: 'On Hold',
        value: 'On Hold',
        count: statusCounts['Hold'].count || null
      },
      {
        label: 'Approved',
        value: 'Approved',
        count: statusCounts['Approved'].count || null
      }
    ]
  }, [statusCounts])

  const selectTab = value => {
    const selectCb = () => {
      setSelectedTab(value)
      dispatch({
        type: ACT.INVOICE_LIST_FETCH_REQUESTED,
        loadingLock: 'on',
        payload: {
          params: {
            ...params,
            category: value,
            page: 1
          }
        }
      })
    }
    hasConfirmation
      ? withConfirmation(() => {
          selectCb()
        }, TAB_CONF)()
      : selectCb()
  }

  return (
    <ul className={cn(s.tabs, s.alignRight)}>
      {tabs.map(tab => (
        <li
          onClick={() => selectTab(tab.value)}
          key={tab.value}
          className={`${s.tab} ${selectedTab === tab.value ? s.selected : ''}`}
        >
          {tab.count !== null ? (
            tab.value === 'Mine' ? (
              <Badge
                asPill
                className={`${s.pill} ${selectedTab !== 'Mine' && s.transparent}`}
                content={tab.count}
                size="sm"
              />
            ) : (
              <span className={s.count}>{tab.count}</span>
            )
          ) : null}
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
