import { useState, useEffect } from 'react'
import Select from 'react-select'
import s from './BaseAsyncSelect.scss'
import { toCategories } from '../serializers'
import { makeGetRequest } from 'utils/api'
import { alphabeticObjectCompare } from 'utils/helpers'
import { DOC_CATEGORIES_URL } from '../urls'

const CategorySelect = ({
  value,
  isClearable = true,
  onChange,
  isPortal = true,
  isMulti = false,
  isDisabled = false,
  components
}) => {
  const [categories, setCategories] = useState([])
  useEffect(() => {
    ;(async () => {
      // Mounted and return callback is here for cleanup purposes -
      // in case the Select is unmounted during fetchMatters
      // or before setMatters is called
      let mounted = true
      const categories = await makeGetRequest(DOC_CATEGORIES_URL)
      if (mounted) {
        setCategories(
          toCategories(
            categories.results.sort((first, second) =>
              alphabeticObjectCompare(first, second, 'name')
            )
          )
        )
      }
      return () => (mounted = false)
    })()
  }, [])

  return (
    <Select
      placeholder="Search category"
      noOptionsMessage={() => 'No results...'}
      value={value}
      onChange={onChange}
      options={categories}
      isMulti={isMulti}
      isClearable={isClearable}
      className={s.select}
      styles={isPortal ? { menuPortal: base => ({ ...base, zIndex: 9999 }) } : {}}
      menuPortalTarget={isPortal ? document.body : null}
      isDisabled={isDisabled}
      components={components}
    />
  )
}

export default CategorySelect
