import { CSSProperties, useMemo, useState } from 'react'
import BaseSelect, { StylesConfig } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import cn from 'classnames'
import moment from 'moment'

import { TextInput, useAutosizeField } from 'simple-core-ui'
import { DueDatePicker } from 'common/DueDatePicker'
import { MatterSelect, VendorSelect } from 'common/Selects'
import AutoComplete from 'simple_review/common/AutoComplete'
import DropdownItem from 'simple_review/common/DropdownItem'
import { formatArgOptionLabel, formatArgLabel } from 'simple_review/utils/helpers'
import { Arg, ArgModel } from 'simple_review/@types/api'
import { Constant } from 'simple_review/@types/common'
import { Actions } from './actions'
import {
  booleanOptions,
  defaultMatterOptions,
  defaultVendorOptions,
  codeOptions
} from './constants'
import s from './Args.scss'
import autoCompleteStyles from 'simple_review/common/AutoComplete.scss'
import { DateRangePopper } from 'common/DateRangePopper'
import { AllVendorsOption, VendorAutocomplete } from './VendorAutocomplete'

const OUTLINE_GREY = '#9a9a9a'

const selectStyles: StylesConfig = {
  container: base => ({ ...base, minWidth: 240 }),
  control: base => ({
    ...base,
    borderRadius: '5px',
    minHeight: '32px',
    borderColor: OUTLINE_GREY
  }),
  valueContainer: base => ({ ...base, padding: '0 8px' }),
  indicatorSeparator: base => ({ ...base, display: 'none' }),
  indicatorsContainer: base => ({ ...base, height: '30px' }),
  menu: base => ({ ...base, borderRadius: '5px' }),
  option: base => ({ ...base, height: '32px' })
}

interface Props {
  baseArgs: Array<Arg>
  args: Array<Arg>
  lhsText: string
  id: string
  onAddArg(arg: Arg): void
  onChangeArg(
    argName: string,
    newValue:
      | string
      | Constant<string | number>
      | Constant[]
      | ArgModel
      | AllVendorsOption
      | undefined
      | null,
    index: number
  ): void
  onDeleteArg(argName: string): void
}

export const Args = ({
  baseArgs,
  args,
  lhsText,
  id,
  onAddArg,
  onChangeArg,
  onDeleteArg
}: Props) => {
  const [isBoxOpen, setIsBoxOpen] = useState(false)
  const [vendorAutocompleteOpen, setVendorAutocompleteOpen] = useState(false)

  const handleOpenBox = () => {
    setIsBoxOpen(true)
  }

  const handleCloseBox = () => {
    setIsBoxOpen(false)
  }

  const handleSelectArg = (arg: Arg) => {
    if (arg.arg_name === 'matter') {
      arg.constant = defaultMatterOptions[0]
    } else if (arg.arg_name === 'vendor') {
      arg.constant = defaultVendorOptions[0]
      setVendorAutocompleteOpen(true)
    }
    onAddArg(arg)
    handleCloseBox()
  }

  const handleDeleteSubParameter = (arg_name: string) => {
    onDeleteArg(arg_name)
  }

  const { selectedArgs, availableArgs } = useMemo(() => {
    const selectedArgs = args
    const availableArgs = baseArgs.filter(arg => {
      return !selectedArgs.some(({ arg_name }) => {
        if (arg_name === 'vendor_group') {
          return arg.arg_name === 'vendor'
        }

        return arg_name === arg.arg_name
      })
    })

    return {
      selectedArgs,
      availableArgs
    }
  }, [args, baseArgs])

  const { width: lhsWidth } = useAutosizeField({
    autoWidth: true,
    value: lhsText,
    paddingLeft: autoCompleteStyles.inputPaddingLeft,
    paddingRight: autoCompleteStyles.inputPaddingRight
  })

  const rowStyle: CSSProperties = {
    marginLeft: `calc(${lhsWidth} / 2 + 40px)`
  }

  return (
    <div className={s.container}>
      <div className={s.header} style={{ width: lhsWidth }}>
        <p>AND</p>
      </div>
      {selectedArgs.map(({ arg_name, constant, type }, argIndex) => {
        return (
          <div key={`${arg_name}_${argIndex}`} className={s.row} style={rowStyle}>
            <div className={s.argContainer}>
              <p>{formatArgLabel(arg_name, constant)}</p>
              <div className={s.arg}>
                {arg_name === 'matter' ? (
                  // @ts-expect-error
                  <MatterSelect
                    value={constant || null}
                    onChange={(item: Constant<number | string>) => {
                      onChangeArg(arg_name, item, argIndex)
                    }}
                    isClearable={false}
                    paginated
                    className={s.select}
                    classNamePrefix={s.select}
                    style={selectStyles}
                    defaultOptions={defaultMatterOptions}
                    isPortal={false}
                  />
                ) : arg_name === 'vendor' || arg_name === 'vendor_group' ? (
                  window.credentials.kochErIIWEnabled || Array.isArray(constant) ? (
                    <VendorAutocomplete
                      value={
                        Array.isArray(constant) || (constant && 'model_field' in constant)
                          ? (constant as Constant[])
                          : !constant
                          ? null
                          : !(constant && 'model_field' in constant && !Array.isArray(constant))
                          ? ([constant] as Constant[])
                          : null
                      }
                      arg_name={arg_name}
                      onChangeArg={(name, item) => {
                        setVendorAutocompleteOpen(false)
                        onChangeArg(name, item, argIndex)
                      }}
                      defaultIsOpen={vendorAutocompleteOpen}
                    />
                  ) : (
                    // @ts-expect-error
                    <VendorSelect
                      value={constant || null}
                      onChange={(item: Constant<number>) => {
                        onChangeArg(arg_name, item, argIndex)
                      }}
                      isClearable={false}
                      paginated
                      className={s.select}
                      classNamePrefix={s.select}
                      style={selectStyles}
                      defaultOptions={defaultVendorOptions}
                      isPortal={false}
                    />
                  )
                ) : ['task_code', 'activity_code', 'expense_code'].includes(arg_name) ? (
                  <CreatableSelect
                    value={constant}
                    onChange={item => {
                      if (item) {
                        onChangeArg(arg_name, item as Constant, argIndex)
                      }
                    }}
                    options={codeOptions.map(opt => ({
                      ...opt,
                      label: `${opt.model_name} · ${opt.model_field}`,
                      value: `${opt.model_name} · ${opt.model_field}`
                    }))}
                    className={s.select}
                    classNamePrefix={s.select}
                    styles={selectStyles}
                    placeholder="Type in value or select an option"
                  />
                ) : type === 'boolean' ? (
                  <BaseSelect
                    value={constant}
                    onChange={item => {
                      if (item) {
                        onChangeArg(arg_name, item as Constant, argIndex)
                      }
                    }}
                    options={booleanOptions as Array<Constant>}
                    className={s.select}
                    classNamePrefix={s.select}
                    styles={selectStyles}
                  />
                ) : type === 'date' ? (
                  <DateRangePopper
                    value={
                      constant
                        ? () => (!Array.isArray(constant) ? (constant.value as string) : '')
                        : []
                    }
                    classNameMap={{ pill: s.pill, popper: s.popper }}
                  >
                    <DueDatePicker
                      // @ts-expect-error
                      value={constant ? constant.value : undefined}
                      onConfirm={value => {
                        const stringified = moment(value).format('L')
                        onChangeArg(arg_name, { value: stringified, label: stringified }, argIndex)
                      }}
                    />
                    {null}
                  </DateRangePopper>
                ) : (
                  <TextInput
                    value={String(!Array.isArray(constant) ? constant?.value ?? '' : '')}
                    placeholder="Type in value"
                    onChange={newValue =>
                      onChangeArg(arg_name, { value: newValue, label: newValue }, argIndex)
                    }
                    containerClassName={s.textInputContainer}
                    className={s.textInput}
                    type="text"
                  />
                )}
                <Actions
                  argName={`${id}_${argIndex}`}
                  onDelete={() => handleDeleteSubParameter(arg_name)}
                />
              </div>
            </div>
          </div>
        )
      })}
      {availableArgs.length > 0 && (
        <div className={cn(s.row, s.add)} style={rowStyle}>
          <div>
            <AutoComplete
              isOpen={isBoxOpen}
              disableSearch
              openBox={handleOpenBox}
              closeBox={handleCloseBox}
              width={240}
              placeholder="Add additional subparameters"
              shouldOverflow={false}
            >
              <ul className={s.options}>
                {availableArgs
                  .filter(arg => arg.arg_name !== 'vendor_group')
                  .map(arg => (
                    <DropdownItem
                      key={arg.arg_name}
                      label={formatArgOptionLabel(arg.arg_name)}
                      onSelect={() => handleSelectArg(arg)}
                      showArrow={false}
                    />
                  ))}
              </ul>
            </AutoComplete>
          </div>
        </div>
      )}
    </div>
  )
}
