import { ModalContainer, Alert, ColorPicker } from 'simple-core-ui'
import { CharLimitInput } from 'components'
import s from './StatusModal.scss'
import { Status } from '../types'
import { PHASES } from '../constants'

interface Props {
  toggleStatusModal: () => void
  showDuplicateError: boolean
  status: Status
  updateStatus: (value: string, property: string) => void
  saveStatus: () => void
}

const StatusModal = ({
  toggleStatusModal,
  showDuplicateError,
  status,
  updateStatus,
  saveStatus
}: Props) => {
  return (
    <ModalContainer
      title="Edit Status"
      subtitle="Use statuses to indicate a task's stage toward completion."
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleStatusModal}
      confirmText="Save"
      confirmCb={saveStatus}
      isDisabled={showDuplicateError || !status.name.trim()}
      content={
        <div className={s.modalBody}>
          {showDuplicateError && (
            <Alert
              className={s.alert}
              message="The status name already exists. Please specify a different name."
              status="error"
            />
          )}
          <div className={s.statusItem}>
            <div className={s.info}>Status</div>
            <CharLimitInput
              value={status.name}
              placeholder="Status name"
              onChangeCb={e => updateStatus(e.target.value, 'name')}
              maxLength={25}
            />
          </div>
          <div className={s.statusItem}>
            <div className={s.info}>Color</div>
            <ColorPicker
              initialColor={status.color}
              onChange={(value: string) => updateStatus(value, 'color')}
            />
          </div>
          <div className={s.statusItem}>
            <div className={s.info}>Phase</div>
            <div className={s.readOnly}>{PHASES[+status.phase]}</div>
          </div>
        </div>
      }
    />
  )
}

export default StatusModal
