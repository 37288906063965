import s from './Card.scss'
import cn from 'classnames'
import { Button } from 'simple-core-ui'

interface Props {
  title: string
  description: string
  active: boolean
  className?: string
  onConnect: () => void
  onDisconnect: () => void
  logo?: JSX.Element
}

const Card = ({ title, description, active, className, onConnect, logo, onDisconnect }: Props) => {
  return (
    <div className={cn(s.card, className)}>
      <div>
        <div style={{ display: 'flex' }}>
          {logo && <div className={s.logo}>{logo}</div>}
          <div className={s.title}>{title}</div>
        </div>
        <div className={s.description}>{description}</div>
      </div>
      {active ? (
        <div>
          <Button
            style={{ padding: '10px 15px' }}
            onClick={onConnect}
            isPrimary
            isOutline
            hasNewDesign
          >
            Reconfigure
          </Button>
          <Button onClick={onDisconnect} isPrimary hasNewDesign style={{ padding: '10px 15px' }}>
            Disconnect
          </Button>
        </div>
      ) : (
        <Button onClick={onConnect} isPrimary hasNewDesign style={{ padding: '10px 15px' }}>
          Connect
        </Button>
      )}
    </div>
  )
}

export default Card
