import { Option } from './types'

interface Obj {
  id: number
  name: string
}

export const toOptions = (list: Obj[]): Option[] => {
  return list.map(el => ({
    value: el.id,
    label: el.name
  }))
}
