import { ModalContainer, Alert, ColorPicker } from 'simple-core-ui'
import { CharLimitInput } from 'components'
import s from './LabelModal.scss'
import ReactTooltip from 'react-tooltip'
import { useEffect } from 'react'

const LabelModal = ({
  label,
  toggleLabelModal,
  addLabel,
  updateLabel,
  showDuplicateError,
  isEdit,
  editLabel
}) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <ModalContainer
      size="sm"
      cancelCb={toggleLabelModal}
      title={isEdit ? 'Edit Label' : 'Add Label'}
      confirmText="Save"
      cancelText="Cancel"
      confirmCb={isEdit ? editLabel : addLabel}
      isDisabled={showDuplicateError || !label.name.trim()}
      content={
        <div className={s.modalBody}>
          {showDuplicateError && (
            <Alert
              className={s.alert}
              message="The document label name already exists. Please specify a different name."
              status="error"
            />
          )}
          <div className={s.labelItem}>
            <div className={s.info}>New label name</div>
            <CharLimitInput
              value={label.name}
              onChangeCb={e => updateLabel(e.target.value, 'name')}
              placeholder="Label"
            />
          </div>
          <div className={s.labelItem}>
            <div className={s.info}>Select label color</div>
            <span
              data-for="colorPicker"
              className={s.selectedColor}
              style={{ backgroundColor: label.color }}
              data-event="click"
              data-tip
            />
            <ReactTooltip
              id="colorPicker"
              type="light"
              effect="solid"
              place="right"
              border
              clickable
              offset={{ right: 10 }}
            >
              <ColorPicker
                initialColor={label.color}
                onChange={value => updateLabel(value, 'color')}
              />
            </ReactTooltip>
          </div>
        </div>
      }
    />
  )
}

export default LabelModal
