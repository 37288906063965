import ReactTooltip from 'react-tooltip'
import s from '../FileUpload.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'

const ActionsPopover = ({ rowId, deleteAttachment }) => {
  const handleClick = (event, action) => {
    event.preventDefault()
    event.stopPropagation()
    switch (action) {
      case 'DELETE_ATTACHMENT':
        deleteAttachment(rowId)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
        globalEventOff="click"
        delayHide={1}
        isCapture
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DELETE_ATTACHMENT')
              ReactTooltip.hide()
            }}
          >
            Delete
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
