import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'

import { Button, Modal } from 'simple-core-ui'
import {
  fetchWorkspaces,
  fetchTemplates,
  fetchCreatingWorkspaceStatus,
  sendSelectedWorkspace,
  sendSelectedTemplate
} from './api'
import { unlinkFolder } from '../api'
import {
  GET_STATUS_ATTEMPTS,
  MIN_CHARS_TO_SEARCH,
  TIMEOUT_ON_ATTEMPT,
  DEBOUNCE_TIME_ON_SEARCHING
} from './constants'
import { IMANAGE } from '../constants'
import { serializeIManageSelectOptions } from '../serializers'
import UnlinkModal from '../UnlinkModal/UnlinkModal'
import s from './IManage.scss'
import { BaseAsyncSelect } from 'common/Selects'

const delay = async ms => await new Promise(resolve => setTimeout(resolve, ms))

const IManage = ({ config, matterId, updateConfig }) => {
  const dispatch = useDispatch()

  const [isSelectWorkspaceOpen, setSelectWorkspaceOpen] = useState(false)
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const [isSelectTemplateOpen, setSelectTemplateOpen] = useState(false)
  const [templateOptions, setTemplateOptions] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [sentTemplateMessage, setSentTemplateMessage] = useState('')
  const [isConfirmUnlinkOpen, setConfirmUnlinkOpen] = useState(false)

  const handleApiActions = async callback => {
    try {
      return await callback()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const applyWorkspace = () => {
    handleApiActions(async () => {
      setSelectWorkspaceOpen(false)
      await sendSelectedWorkspace(matterId, selectedWorkspace.value)
      updateConfig()
    })
  }

  const applyTemplate = () => {
    setSelectTemplateOpen(false)
    handleApiActions(async () => {
      const { message, task_id: taskId } = await sendSelectedTemplate(
        matterId,
        selectedTemplate.value
      )
      setSentTemplateMessage(message)
      let iterations = 0
      let creatingStatus = ''
      do {
        await delay(TIMEOUT_ON_ATTEMPT)
        const { status } = await fetchCreatingWorkspaceStatus(taskId)
        creatingStatus = status
        iterations++
      } while (iterations < GET_STATUS_ATTEMPTS && creatingStatus !== 'done')
      setSentTemplateMessage('')
      updateConfig()
    })
  }

  const loadWorkspaceOptions = inputValue => {
    if (inputValue.length >= MIN_CHARS_TO_SEARCH) {
      return handleApiActions(async () => {
        const { results } = await fetchWorkspaces(inputValue)
        return serializeIManageSelectOptions(results)
      })
    }
  }

  const openTemplateModal = () => {
    setSelectTemplateOpen(true)
    if (!templateOptions.length) {
      handleApiActions(async () => {
        const { templates } = await fetchTemplates()
        setTemplateOptions(serializeIManageSelectOptions(templates))
      })
    }
  }

  const unlinkIManageFolder = () => {
    setConfirmUnlinkOpen(false)
    handleApiActions(async () => await unlinkFolder(matterId))
    updateConfig()
  }

  return (
    <>
      {config?.auth_url && !config.connected && (
        <Button>
          <a className={s.linkBtn} href={config.auth_url}>
            Connect to iManage
          </a>
        </Button>
      )}
      {config?.connected && !config.folder_info.folder_url && (
        <>
          <div className={s.flexContainer}>
            <Button onClick={openTemplateModal}>Create Workspace</Button>
            <Button onClick={() => setSelectWorkspaceOpen(true)}>Select Existing Workspace</Button>
          </div>
          {sentTemplateMessage && <div className={s.message}>{sentTemplateMessage}</div>}
        </>
      )}
      {config?.folder_info?.folder_url && (
        <>
          <iframe
            title="iManage Matter"
            src={config.folder_info.folder_url}
            width="100%"
            height="400"
          />
          <Button isSecondary onClick={() => setConfirmUnlinkOpen(true)}>
            Unlink from iManage
          </Button>
        </>
      )}
      <Modal
        title="Choose a workspace"
        confirmText="Use this workspace"
        cancelText="Close"
        size="md"
        content={
          <BaseAsyncSelect
            placeholder="Select a Workspace"
            noOptionsMessage={() => 'Nothing found...'}
            value={selectedWorkspace}
            onChange={workspace => setSelectedWorkspace(workspace)}
            loadOptions={loadWorkspaceOptions}
            dbTime={DEBOUNCE_TIME_ON_SEARCHING}
            isClearable
            defaultOptions
          />
        }
        isVisible={isSelectWorkspaceOpen}
        confirmCb={applyWorkspace}
        cancelCb={() => setSelectWorkspaceOpen(false)}
        isDisabled={!selectedWorkspace}
      />
      <Modal
        title="Choose a template"
        confirmText="Use this template"
        cancelText="Close"
        size="md"
        content={
          <Select
            placeholder="Select a Template"
            value={selectedTemplate}
            options={templateOptions}
            onChange={template => setSelectedTemplate(template)}
          />
        }
        isVisible={isSelectTemplateOpen}
        confirmCb={applyTemplate}
        cancelCb={() => setSelectTemplateOpen(false)}
        isDisabled={!selectedTemplate}
      />
      <UnlinkModal
        title={IMANAGE}
        isVisible={isConfirmUnlinkOpen}
        confirm={unlinkIManageFolder}
        cancel={() => setConfirmUnlinkOpen(false)}
      />
    </>
  )
}

export default IManage
