import { FILTER_TYPE } from 'rules/constants'
import { Categories } from 'simple_review/@types/list'

export const INIT_CATEGORIES: Categories = [
  { label: FILTER_TYPE.ACTIVE, value: FILTER_TYPE.ACTIVE },
  { label: FILTER_TYPE.INACTIVE, value: FILTER_TYPE.INACTIVE },
  { label: 'All Rules', value: FILTER_TYPE.ALL }
]

export const COLUMNS = [
  {
    columnKey: 'name',
    content: 'Rule Name',
    isSortable: true,
    isFilterable: true,
    style: {
      minWidth: 180,
      maxWidth: 240
    }
  },
  {
    columnKey: 'category',
    content: 'Category',
    isSortable: true,
    isFilterable: true,
    style: {
      minWidth: 140,
      maxWidth: 200
    }
  },
  {
    columnKey: 'description',
    content: 'Description',
    isSortable: true,
    style: {
      maxWidth: 480
    }
  },
  {
    columnKey: 'type',
    content: 'Type',
    isSortable: true
  },
  {
    columnKey: 'modified_by',
    content: 'Modified By',
    isSortable: true,
    isFilterable: true,
    style: {
      minWidth: 140
    }
  },
  {
    columnKey: 'modified_date',
    content: 'Last Modified',
    isSortable: true,
    isFilterable: true,
    style: {
      minWidth: 140
    }
  },
  {
    columnKey: 'is_draft',
    content: 'Status',
    isSortable: true,
    isFilterable: true,
    style: { textAlign: 'center' }
  },
  {
    columnKey: 'actions',
    content: 'Actions',
    isSortable: false,
    isFilterable: false,
    style: { textAlign: 'center' }
  }
]
