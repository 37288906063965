import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContainer, SearchableTreeView, Alert } from 'simple-core-ui'
import { Row, Folder } from '../types'
import * as ACT from 'matters/templates/actions'
import { findNodeById, checkDuplicateFolderName, deleteFromTree } from '../helpers'
import { toTreeViewData } from 'matters/detail/files/MatterAttachmentsTab/serializers'
import { cloneDeep, isEqual } from 'lodash'

interface Props {
  rootNode: Folder
  closeModalCb: () => void
  selectedFolder: Folder
  collapseChildrenOnMove: () => void
  expandedNodeIds: Row['id'][]
}

const MoveFolderModal = ({
  rootNode,
  selectedFolder,
  closeModalCb,
  collapseChildrenOnMove,
  expandedNodeIds
}: Props) => {
  const [newParent, setNewParent] = useState<Folder | null>(null)
  const [treeData, setTreeData] = useState<Folder | null>(null)
  const [oldTreeData, setOldTreeData] = useState<Folder | null>(null)
  const [parentHasDuplicateFolder, setParentHasDuplicateFolder] = useState(false)
  const dispatch = useDispatch()

  if (!treeData || !isEqual(treeData, oldTreeData)) {
    const updatedTree = toTreeViewData(deleteFromTree(cloneDeep(rootNode), selectedFolder.id))
    setTreeData(updatedTree)
    setOldTreeData(updatedTree)
  }

  const duplicateFolderName = useMemo(() => {
    // fetch immediate nodes of newParent, check if id exist in that.
    if (newParent) {
      const newParentNode = findNodeById(treeData as Folder, newParent?.id)
      const childNodeNames =
        newParentNode?.children?.map(node => node.name.trim().toLowerCase()) || []
      return checkDuplicateFolderName(childNodeNames, selectedFolder.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newParent])

  const moveFolder = () => {
    if (duplicateFolderName) {
      setParentHasDuplicateFolder(true)
    } else {
      if (expandedNodeIds.includes(selectedFolder.id)) {
        collapseChildrenOnMove()
      }
      dispatch({
        type: ACT.MOVE_FOLDER,
        payload: {
          selectedFolderId: selectedFolder.id,
          newParentId: newParent!.id
        }
      })
      setNewParent(null)
      closeModalCb()
    }
  }

  return (
    <>
      <ModalContainer
        title="Move Folder"
        size="sm"
        confirmText="Move"
        confirmCb={moveFolder}
        cancelCb={closeModalCb}
        isDisabled={!newParent}
        content={
          <div
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <SearchableTreeView
              inputData={treeData}
              hasNewDesign
              inputLabel="Search and select new folder"
              onNodeSelect={(node: Folder) => {
                setNewParent(node)
              }}
            />
          </div>
        }
      />
      {parentHasDuplicateFolder && (
        <ModalContainer
          title="Move Folder"
          size="sm"
          confirmText="Ok"
          confirmCb={() => {
            setParentHasDuplicateFolder(false)
            setNewParent(null)
            closeModalCb()
          }}
          cancelCb={closeModalCb}
          hideCancelBtn
          content={
            <Alert
              message={`A folder with the name ${selectedFolder.name} already exists at this location. Rename it and try again.`}
              status="error"
            />
          }
        />
      )}
    </>
  )
}

export default MoveFolderModal
