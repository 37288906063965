import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { Row } from '../types'

interface Props {
  row: Row
  onDelete(id: number): void
  onEdit(id: number): void
}

const ActionsPopover = ({
  row: { id, count, is_default: isDefaultCategory, active },
  onDelete,
  onEdit
}: Props) => {
  return !isDefaultCategory && count < 1 ? (
    <>
      <span
        data-for={`item-actions-${id}`}
        aria-describedby={`item-actions-${id}`}
        aria-label={`item-${id}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip=""
        data-testid={`item-actions-${id}`}
      >
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${id}`}
        className={s.tooltipContainer}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        delayHide={1}
        isCapture
        border
        clickable
      >
        <ul className={s.actionsList}>
          <li>
            <button
              className={s.actionItem}
              onClick={() => {
                ReactTooltip.hide()
                onEdit(id)
              }}
              disabled={active}
            >
              Edit
            </button>
          </li>
          <li>
            <button
              className={s.actionItem}
              onClick={() => {
                ReactTooltip.hide()
                onDelete(id)
              }}
              disabled={active}
            >
              Delete
            </button>
          </li>
        </ul>
      </ReactTooltip>
    </>
  ) : (
    <></>
  )
}

export default ActionsPopover
