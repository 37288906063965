import { useState, ChangeEvent } from 'react'
import s from './Location.scss'
import { Button } from 'simple-core-ui'
import cn from 'classnames'
import { ActionsPopover } from './ActionsPopover'
import { IoIosClose } from 'react-icons/io'
import { Location as LocationType } from '../types'
import { FaLocationDot } from 'react-icons/fa6'
import Autocomplete from 'react-google-autocomplete'
import { GoogleMap } from './GoogleMap'

interface Props {
  value: LocationType | null | undefined
  onSave: (value: LocationType | null) => void
  isEdit?: boolean
  readOnly?: boolean
  classNames?: { [key: string]: string }
}

const initialLocation = {
  address: '',
  name: '',
  latitude: null,
  longitude: null,
  draft: true,
  google_place_id: ''
}

const apiKey = window.serverContext.get('data_for_react')?.google_maps_api_key

const Location = ({ value, onSave, isEdit = false, readOnly, classNames }: Props) => {
  const [location, setLocation] = useState<LocationType | null | undefined>(value)

  const addDraftLocation = () => {
    setLocation(initialLocation)
  }

  const toggleDraft = () => {
    location &&
      setLocation({
        ...location,
        draft: true
      })
  }

  const onPlaceSelected = (place: any) => {
    const { formatted_address, geometry, place_id, name = '' } = place

    const latitude = geometry.location.lat()
    const longitude = geometry.location.lng()

    setLocation({
      address: formatted_address,
      latitude,
      longitude,
      draft: true,
      google_place_id: place_id,
      name
    })
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (!value) {
      addDraftLocation()
    }
  }

  const getDefaultLocationValue = (location: LocationType) => {
    if (location.name) {
      return location.name.trim() === location.address.trim()
        ? location.name
        : `${location.name} ${location.address}`
    } else {
      return location.address
    }
  }

  return (
    <div className={cn(s.locationWrapper, classNames?.wrapper)}>
      {location && <label className={cn(s.label, classNames?.locationLabel)}>Location</label>}
      {location && (
        <div
          className={cn(
            s.location,
            { [s.draft]: location.draft },
            location.draft && classNames?.locationDraft
          )}
        >
          <div
            style={{
              display: 'flex',
              flexGrow: 1
            }}
          >
            <div style={{ width: '95%', marginRight: '10px', marginLeft: '5px' }}>
              {location.draft ? (
                <Autocomplete
                  apiKey={apiKey}
                  onPlaceSelected={onPlaceSelected}
                  className={s.autoComplete}
                  onChange={onInputChange}
                  placeholder="Enter Location"
                  defaultValue={getDefaultLocationValue(location)}
                  options={{
                    types: ['geocode', 'establishment'],
                    fields: [
                      'address_components',
                      'geometry',
                      'place_id',
                      'formatted_address',
                      'name'
                    ]
                  }}
                />
              ) : (
                <>
                  <FaLocationDot className={s.addIcon} />
                  <a className={s.name}>{location.name || location.address}</a>
                </>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', position: 'relative', top: '2px' }}>
            <>
              {location.draft ? (
                <Button
                  style={{
                    background: '#0957ae',
                    color: '#fff',
                    height: '25px',
                    position: 'relative',
                    top: 2
                  }}
                  onClick={() => {
                    setLocation({
                      ...location,
                      draft: false
                    })
                    onSave?.(location)
                  }}
                  isDisabled={!location.address}
                >
                  Save
                </Button>
              ) : isEdit && !readOnly ? (
                <ActionsPopover
                  location={location}
                  deleteLocation={() => {
                    setLocation(null)
                    onSave?.(null)
                  }}
                  toggleDraft={toggleDraft}
                  onEdit={location => {
                    setLocation({
                      ...location,
                      draft: true
                    })
                  }}
                />
              ) : null}
              {(!isEdit || (isEdit && location.draft)) && (
                <IoIosClose
                  onClick={() => {
                    setLocation(null)
                    onSave?.(null)
                  }}
                  className={s.remove}
                />
              )}
            </>
          </div>
        </div>
      )}
      {isEdit && location && !location.draft && location.latitude && location.longitude && (
        <div className={s.mapWrapper}>
          <GoogleMap
            lat={location.latitude}
            lng={location.longitude}
            style={{
              width: '100%',
              height: 300,
              padding: '0 50px',
              marginTop: 20
            }}
          />
        </div>
      )}
      {!readOnly && !location && (
        <p className={s.addWrapper} onClick={addDraftLocation}>
          <FaLocationDot className={s.addIcon} /> Add Location
        </p>
      )}
    </div>
  )
}

export default Location
