import { useState, useEffect, useMemo, useContext } from 'react'
import { AxiosError } from 'axios'
import pluralize from 'pluralize'
import moment from 'moment'
import {
  Button,
  DataTableWrapper,
  AvatarList,
  ButtonDropdown,
  Ellipsis,
  useLoading,
  ModalContainer,
  GoogleCalendarIcon,
  MicrosoftOfficeOutlookIcon,
  AppleCalendarIcon
} from 'simple-core-ui'
import s from './Tasks.scss'
import { Tabs } from './Tabs'
import {
  canEditTask,
  getSubtaskIds,
  createQueryParamsUrl,
  isTaskCalendarSyncEnabled,
  getCategoryFromUrl,
  createFiltersObjectFromQueryString
} from './utils'
import { ActionsPopover } from './ActionsPopover'
import { Task, Subtask, TaskFilters, BulkEditValues, Cell } from './types'
import { useImmer, Updater } from 'use-immer'
import { toTasks, fromTask, serializeBulkEditOptions } from './serializers'
import {
  hex2rgba,
  openLink,
  sortAlphabeticallyByProperty,
  isBasicTaskManagementPlan,
  removeLabelsFromURL,
  updateUrlFragment,
  removeHashFromUrl
} from 'utils/helpers'
import { BsPersonPlus } from 'react-icons/bs'
import { MdOutlineComment, MdOutlineAttachFile } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import { BiSubdirectoryRight } from 'react-icons/bi'
import cn from 'classnames'
import { FaList, FaEyeSlash, FaCalendarAlt } from 'react-icons/fa'
import { AddTask } from './AddTask'
import { makeGetRequest, makeDeleteRequest, makePostRequest, makePatchRequest } from 'utils/api'
import { ConfirmationDialog } from './ConfirmationDialog'
import cloneDeep from 'lodash/cloneDeep'
import { Filters } from './Filters'
import ReactTooltip from 'react-tooltip'
import { CopyMethods } from './CopyMethods'
import { BulkEditModal } from './BulkEditModal'
import { BULK_EDIT_INITIAL_VALUES } from './constants'
import { AddTaskFromTemplateModal } from './AddTaskFromTemplateModal'
import { useLocation, Link } from 'react-router-dom'
import qs from 'query-string'
import ThemeContext from 'context/ThemeContext'
import { CalendarSyncModal } from 'common/CalendarSyncModal'
import { CalendarView } from 'common/CalendarView'

interface Props {
  scopeId?: string
  readOnly?: boolean
  scopeName?: string
  context: 'workbench' | 'matter'
  baseUrl: string
  restrictAllActions?: boolean
}

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const BULK_OPTIONS = [
  { label: 'Edit', value: 'edit' },
  { label: 'Delete', value: 'delete' }
]

const bulkEditInitialState = {
  isBulkEditDialog: false,
  options: BULK_EDIT_INITIAL_VALUES,
  isBulkEditConfirmationDialog: false
}

const updateUrlHash = (id: number | string) => {
  const parsedQuery = qs.parse(window.location.search)
  const parsedHash = qs.parse(window.location.hash, { decode: false })
  const mergedQuery = {
    ...parsedQuery,
    id
  }
  const queryString = qs.stringify(mergedQuery)
  const hashString = qs.stringify(parsedHash, { encode: false })

  window.history.replaceState(null, '', `?${queryString}${hashString ? `#${hashString}` : ''}`)
}

const isBasicPlan = isBasicTaskManagementPlan()

const Tasks = ({
  scopeId = '',
  readOnly = false,
  scopeName = '',
  context,
  baseUrl,
  restrictAllActions
}: Props) => {
  const initialTaskFilters = {
    taskType: null,
    assignees: null,
    followers: null,
    status: null,
    priority: null,
    id: null,
    createdBy: null,
    comments: null,
    fileAttachments: null,
    dueDate: null,
    createdDate: null,
    taskId: null,
    ...(context === 'workbench' ? { relatedMatter: null } : {})
  }

  const [localState, setLocalState] = useState({
    params: {
      pageSize: context === 'workbench' ? 50 : 10,
      ordering: { columnKey: 'dueDate', isDesc: false },
      search: '',
      page: 1,
      category: getCategoryFromUrl() || 'all'
    }
  })
  const { params } = localState
  const [selectedTab, setSelectedTab] = useState(params.category)
  const [oldSelectedTab, setOldSelectedTab] = useState(params.category)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [tasks, setTasks]: [Task[], Updater<Task[]>] = useImmer<Task[]>([])
  const dispatch = useDispatch()
  const [view, setView] = useState('list')
  const [oldView, setOldView] = useState('list')
  const [isAddTaskVisible, setIsAddTaskVisible] = useState(false)
  const [isAddTaskFromTemplateModalVisible, setIsAddTaskFromTemplateModalVisible] = useState(false)
  const [editedTask, setEditedTask] = useState<Task | null>(null)
  const [oldTask, setOldTask] = useState<Task | null>(null)
  const [isLoading, withLoadingLocksGetTasks] = useLoading()
  const [, withLoadingLocks] = useLoading()
  const [subTaskToDelete, setSubTaskToDelete] = useState<Task | undefined>(undefined)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showCopyMethods, setShowCopyMethods] = useState(false)
  const [showBulkDeleteConfirmation, setShowBulkDeleteConfirmation] = useState(false)
  const [showBulkDeleteNotAllowed, setShowBulkDeleteNotAllowed] = useState(false)
  const [taskFilters, setTaskFilters] = useState<TaskFilters>({
    ...initialTaskFilters,
    ...createFiltersObjectFromQueryString(window.location.href, context)
  })
  const [oldTaskFilters, setOldTaskFilters] = useState<TaskFilters>(taskFilters)
  const [bulkEdit, setBulkEdit] = useState(bulkEditInitialState)
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const location = useLocation()
  const [_scopeId, setScopeId] = useState(scopeId)
  const { state } = useContext(ThemeContext)
  const [totalEntries, setTotalEntries] = useState(0)
  const [filteredTotal, setFilteredTotal] = useState(0)
  const [myFollowingCount, setMyFollowingCount] = useState(0)
  const [myRequestsCount, setMyRequestsCount] = useState(0)
  const [myTasksCount, setMyTasksCount] = useState(0)
  const [isCalendarSyncModalVisible, setIsCalendarSyncModalVisible] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

  const clearAllSelectedRows: () => void = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const editTask = async (task: Task | Subtask) => {
    try {
      if (task.id) {
        const tsk = await withLoadingLocks(makeGetRequest(`${baseUrl}/tasks/${task.id}/`))
        setIsAddTaskVisible(true)
        setEditedTask(toTasks([tsk])[0])
        setOldTask(toTasks([tsk])[0])
        updateUrlHash(task.id)
      } else {
        setIsAddTaskVisible(true)
        setEditedTask(task as Task)
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'The task no longer exists',
            message: `We couldn't find the task you referenced. It may have been deleted.`,
            level: 'error'
          }
        })
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
    clearAllSelectedRows()
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Task Name',
        isSortable: true,
        isFilterable: true,
        style: { maxWidth: '400px', position: 'relative' },
        render: (cell: Cell, row: Task) => {
          return (
            <>
              {row?.parent && <BiSubdirectoryRight className={s.subTaskBigIcon} />}
              <div style={{ float: 'left' }} className={cn({ [s.nameWrapper]: row?.parent })}>
                {row?.parent && (
                  <div className={s.parentName}>
                    {row.parent.isPrivate && !tasks.find(t => t.id === row.parent?.id) ? (
                      <i>
                        <FaEyeSlash className={s.hiddenIcon} />
                        Private: Parent task hidden
                      </i>
                    ) : (
                      <Ellipsis
                        className={cn(s.nameCell, {
                          [s.smallerWidth]: state.isMenuExpanded
                        })}
                        lines={1}
                      >
                        {row.parent.name}
                      </Ellipsis>
                    )}
                  </div>
                )}
                <a style={{ cursor: 'pointer' }} onClick={() => row && editTask(row)}>
                  <Ellipsis
                    className={cn(s.nameCell, {
                      [s.smallerWidth]: state.isMenuExpanded
                    })}
                  >
                    {typeof cell.content === 'string' && cell.content}
                  </Ellipsis>
                </a>
              </div>
              {!!row?.children &&
                tasks.some(t => {
                  return row?.subtasks.map(s => +s.id).includes(t.id)
                }) && (
                  <div className={s.subTasksCountWrapper}>
                    <BiSubdirectoryRight className={s.subTaskIcon} />
                    <span className={s.subTasksCount}>{row?.children}</span>
                    <span
                      onClick={() =>
                        setTasks(draft => {
                          const index = draft.findIndex(t => t.id === row?.id)
                          draft[index].expanded = !draft[index].expanded
                        })
                      }
                      className={s.plusSign}
                    >
                      {row.expanded ? '-' : '+'}
                    </span>
                  </div>
                )}
            </>
          )
        }
      },
      {
        columnKey: 'taskId',
        content: 'Task ID',
        isSortable: true,
        isFilterable: true
      },
      ...(context === 'workbench'
        ? [
            {
              columnKey: 'relatedMatter',
              content: 'Related To',
              isSortable: true,
              isFilterable: true,
              filterableBy: 'label',
              render: (cell: Cell) => {
                if (typeof cell.content === 'object' && 'label' in cell.content) {
                  return (
                    <Link to={`/v2/matters/${cell.content.value}?fromTasksWorkbench=true`}>
                      <Ellipsis width={120} lines={1}>
                        {cell.content.label}
                      </Ellipsis>
                    </Link>
                  )
                }
                return '--'
              }
            }
          ]
        : []),
      {
        columnKey: 'assignees',
        content: 'Assignee',
        isSortable: false,
        isFilterable: true,
        filterableBy: 'label',
        render: (cell: Cell, row: Task) => {
          return Array.isArray(cell.content) && !cell.content.length ? (
            !readOnly && row && canEditTask(row, context) ? (
              <span onClick={() => editTask(row)} className={s.noAssignee}>
                <BsPersonPlus />
              </span>
            ) : (
              '--'
            )
          ) : (
            <AvatarList
              size={Array.isArray(cell.content) && cell.content.length > 1 ? 'sm' : 'md'}
              limit={2}
              avatarStyles={{ marginLeft: 0 }}
              entries={sortAlphabeticallyByProperty(
                Array.isArray(cell.content) ? cell.content : [],
                'label'
              )}
            />
          )
        }
      },
      {
        columnKey: 'status',
        content: 'Status',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        style: { maxWidth: '140px' },
        render: (cell: Cell, row: Task) => {
          if (cell.content === '----') {
            return '--'
          }
          const color = row?.status?.color
          return (
            <div
              className={s.level}
              style={{
                backgroundColor: color && hex2rgba(color, 0.15),
                border: `1px solid ${color}`
              }}
            >
              <Ellipsis width={100} lines={1}>
                {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
              </Ellipsis>
            </div>
          )
        }
      },
      {
        columnKey: 'dueDate',
        content: 'Due',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell, row: Task) => {
          return (
            <span
              style={
                moment(cell.content as string).isBefore(new Date()) &&
                row?.status?.phase !== 'Complete'
                  ? { color: '#bb342f' }
                  : {}
              }
            >
              {cell.content === '----'
                ? '--'
                : moment(cell.content as string).format(
                    moment(cell.content as string).isSame(new Date(), 'year')
                      ? 'MMM DD'
                      : 'MMM DD YYYY'
                  )}
            </span>
          )
        }
      },
      {
        columnKey: 'priority',
        content: 'Priority',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        style: { maxWidth: '140px' },
        render: (cell: Cell, row: Task) => {
          if (cell.content === '----') {
            return '--'
          }
          const color = row?.priority?.color
          return (
            <div
              className={s.level}
              style={{
                backgroundColor: color && hex2rgba(color, 0.15),
                border: `1px solid ${color}`
              }}
            >
              <Ellipsis width={100} lines={1}>
                {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
              </Ellipsis>
            </div>
          )
        }
      },
      {
        columnKey: 'taskType',
        content: 'Task Type',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        render: (cell: Cell) => {
          return cell.content === '----' ? (
            '--'
          ) : (
            <Ellipsis width={150} lines={1}>
              {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
            </Ellipsis>
          )
        }
      },
      {
        columnKey: 'commentsCount',
        content: <MdOutlineComment style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Task) => {
          const content = cell.content as string
          return (
            <>
              <MdOutlineComment
                style={{ fontSize: 20, position: 'relative', top: 5, marginRight: 5 }}
              />
              {+cell.content > 10 ? (
                <>
                  <span data-for={`comments-${row.id}`} data-tip>
                    10+
                  </span>
                  <ReactTooltip
                    id={`comments-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : cell.content === '----' ? (
                0
              ) : (
                cell.content
              )}
            </>
          )
        }
      },
      {
        columnKey: 'fileAttachmentsCount',
        content: <MdOutlineAttachFile style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Task) => {
          const content = cell.content as string
          return (
            <>
              <MdOutlineAttachFile style={{ fontSize: 20, position: 'relative', top: 5 }} />
              {+cell.content > 10 ? (
                <>
                  <span data-for={`files-${row.id}`} data-tip>
                    10+
                  </span>
                  <ReactTooltip
                    id={`files-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : cell.content === '----' ? (
                0
              ) : (
                cell.content
              )}
            </>
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, tasks])

  if (taskFilters !== oldTaskFilters && view === 'list') {
    setOldTaskFilters(taskFilters)
  }

  const isFiltered = useMemo(() => {
    return Object.values(taskFilters).some(f => f !== null)
  }, [taskFilters])

  const filteredTasks = useMemo(() => {
    return tasks.filter(t => !t.hidden)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, taskFilters])

  useEffect(() => {
    setLocalState(state => ({
      ...state,
      params: {
        ...state.params,
        page: 1,
        category: selectedTab
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters, selectedTab, view])

  useEffect(() => {
    clearAllSelectedRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters, selectedTab, localState.params.search, view])

  const updateUrl = (queryString: string) => {
    const frg = updateUrlFragment(queryString)

    window.history.replaceState(null, '', frg)
  }

  const fetchTasks = async ({
    filters,
    tableParams,
    cb,
    hash,
    tab
  }: {
    filters?: TaskFilters
    tableParams?: Params
    cb?: (url: string) => void
    hash?: string
    tab?: string
  } = {}) => {
    const taskFiltersMap = {
      taskType: 'type',
      status: 'status',
      priority: 'priority',
      name: 'name',
      dueDate: 'due_date',
      taskId: 'task_id',
      relatedMatter: 'matter_id'
    }
    try {
      const base = `columnKey=${
        taskFiltersMap[(tableParams || params).ordering.columnKey as keyof typeof taskFiltersMap]
      }&isDesc=${Number((tableParams || params).ordering.isDesc)}&search=${
        (tableParams || params).search
      }&page_number=${(tableParams || params).page}&page_size=${(tableParams || params).pageSize}`

      const queryString = hash
        ? removeLabelsFromURL(hash.split('#')[1])
        : `${base}&${createQueryParamsUrl(filters || taskFilters)}`

      const { rows, totalEntries } = await withLoadingLocksGetTasks(
        makeGetRequest(`${baseUrl}/tasks/?${queryString}`)
      )

      setTasks(
        toTasks(rows, (tasks: Task[]) => {
          if (context === 'workbench') {
            return tasks.map(t =>
              t?.relatedMatter?.status === 'closed' || !t?.relatedMatter?.canEdit
                ? { ...t, canEdit: false }
                : t
            )
          }
          return tasks
        })
      )
      setFilteredTotal(totalEntries)

      cb?.(
        hash
          ? hash.split('#')[1]
          : `${base}&category=${tab ?? selectedTab}&${createQueryParamsUrl(
              filters || taskFilters,
              true
            )}`
      )
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const getInitFiltersForSelectedTab = (selectedTab: string) => {
    const loggedInUser = window.credentials.user
    const loggedInUserFilterObj = {
      operator: {
        label: 'Is',
        value: 'IS'
      },
      values: [
        {
          value: loggedInUser.id,
          label: loggedInUser.firstName + ' ' + loggedInUser.lastName
        }
      ]
    }

    switch (selectedTab) {
      case 'mine':
        return {
          ...initialTaskFilters,
          assignees: loggedInUserFilterObj
        }
      case 'requests':
        return {
          ...initialTaskFilters,
          createdBy: loggedInUserFilterObj
        }
      case 'watching':
        return {
          ...initialTaskFilters,
          followers: loggedInUserFilterObj
        }
      default:
        return initialTaskFilters
    }
  }

  const clearFilters = () => {
    const filters = getInitFiltersForSelectedTab(selectedTab)
    setTaskFilters(filters)
    fetchTasks({ filters, cb: updateUrl })
  }

  const onChangeTabCb = (selectedTab: string) => {
    setOldSelectedTab(selectedTab)

    if (selectedTab === oldSelectedTab) return

    const newFilters = getInitFiltersForSelectedTab(selectedTab)

    fetchTasks({ filters: newFilters, cb: updateUrl, tab: selectedTab })
    setTaskFilters(newFilters)
  }

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
    onChangeTabCb(tab)
  }

  useEffect(() => {
    const hash = location.hash
    const parsedHash = qs.parse(hash)

    const taskFiltersMap = {
      type: 'taskType',
      status: 'status',
      priority: 'priority',
      name: 'name',
      due_date: 'dueDate',
      task_id: 'taskId',
      matter_id: 'relatedMatter'
    }

    fetchTasks({ cb: updateUrl, hash })

    setLocalState(state => ({
      ...state,
      params: {
        ...state.params,
        page: parsedHash.page_number ? +parsedHash.page_number : 1,
        pageSize: parsedHash.page_size ? +parsedHash.page_size : context === 'workbench' ? 50 : 10,
        search: parsedHash.search && typeof parsedHash.search === 'string' ? parsedHash.search : '',
        ordering: {
          columnKey: parsedHash.columnKey
            ? taskFiltersMap[parsedHash.columnKey as keyof typeof taskFiltersMap]
            : 'dueDate',
          isDesc: Boolean(parsedHash.isDesc ? +parsedHash.isDesc : false)
        },
        category: getCategoryFromUrl() || 'all'
      }
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (oldView !== view && view === 'list') {
      fetchTasks({ cb: updateUrl })
    }
    setOldView(view)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  const fetchTasksCounts = async () => {
    try {
      const {
        totalEntries,
        myTasksCount,
        myRequestsCount,
        myFollowingCount
      } = await withLoadingLocks(makeGetRequest(`${baseUrl}/tasks/counts`))
      setTotalEntries(totalEntries)
      setMyTasksCount(myTasksCount)
      setMyRequestsCount(myRequestsCount)
      setMyFollowingCount(myFollowingCount)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    if (view !== 'list') return
    fetchTasksCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (view !== 'list') return
    const parsedQuery = qs.parse(location.search)

    if (parsedQuery.id) {
      editTask({ id: +parsedQuery.id } as Task)
    }
    if (parsedQuery.subtab) {
      changeTab(parsedQuery.subtab as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    if (view !== 'list') return
    // toggle subtasks visibility if parent is collapsed/expanded
    for (const task of tasks) {
      if (!task.parent && task.subtasks.length) {
        const subtasksArr = task.subtasks.map(s => s.id)
        setTasks(draft => {
          for (const i of subtasksArr) {
            const index = draft.findIndex(t => t.id === +i)
            if (draft[index]) {
              draft[index].hidden = task.expanded ? false : true
            }
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, view])

  const selectRow = ({ id }: { id: number }) => {
    setAllRowsSelected(false)
    const subtaskIds = getSubtaskIds(tasks, id)

    if (selectedRows.includes(id)) {
      const parentId = tasks.find(task => task.id === id)?.parent?.id
      setSelectedRows(prevSelectedRows =>
        prevSelectedRows.filter(
          rowId => rowId !== id && (parentId ? rowId !== parentId : !subtaskIds.includes(rowId))
        )
      )
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id, ...subtaskIds])])
    }
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(
      allRowsSelected ? [] : filteredTasks.filter(r => r.canEdit !== false).map(t => t.id)
    )
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })

    fetchTasks({ filters: taskFilters, tableParams: params, cb: updateUrl })
  }

  const downloadXlsx = async () => {
    const { columnKey, isDesc } = params.ordering
    const mappedTab: Record<string, string> = {
      requests: 'myRequests',
      mine: 'myTasks',
      watching: 'following'
    }

    clearAllSelectedRows()

    const url = `${baseUrl}/tasks/export/?columnKey=${columnKey}&isDesc=${+isDesc}${
      selectedTab !== 'all' ? `&${mappedTab[selectedTab]}` : ''
    }&${createQueryParamsUrl(taskFilters)}&search=${params.search}${
      context === 'workbench' ? '&template_id=is_not_set' : ''
    }`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(url)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const showDeleteModal = (task: Task) => {
    setShowDeleteConfirmation(true)
    setEditedTask(task)
  }

  const deleteSubTask = (id: number) => {
    setSubTaskToDelete(tasks.find(task => task.id === id))
    setShowDeleteConfirmation(true)
  }

  const showCopyMethodsModal = (task: Task) => {
    setShowCopyMethods(true)
    setEditedTask(task)
  }

  const updateTableRowsWithNewValues = (rows: Task[], subtaskId?: number) => {
    if (editedTask?.parent || subtaskId) {
      const subtask = subtaskId ? tasks.find(t => t.id === subtaskId) : editedTask
      rows = rows.map(row =>
        row.id === subtask?.parent?.id
          ? {
              ...row,
              subtasks: row.subtasks.filter(({ id }) => +id !== subtask.id),
              children: row.children - 1
            }
          : row
      )
    }
    if (editedTask?.children && !subtaskId) {
      const children = rows.filter(t => t.parent?.id === editedTask?.id)
      children.forEach(c => {
        const index = rows.findIndex(t => t.id === c.id)
        rows.splice(index, 1)
      })
    }

    return rows
  }

  const onDeleteTask = async (subtaskId?: number) => {
    const taskIdToDelete = subtaskId ?? editedTask?.id
    try {
      await makeDeleteRequest(`${baseUrl}/tasks/${taskIdToDelete}/`)

      let rows = cloneDeep(tasks)

      rows = updateTableRowsWithNewValues(rows, subtaskId)

      setTasks(rows.filter(e => e.id !== taskIdToDelete))
      subtaskId && setEditedTask(rows.filter(e => e.id === editedTask?.id)[0])
      clearAllSelectedRows()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Task successfully deleted',
          level: 'success'
        }
      })
      fetchTasksCounts()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    setShowDeleteConfirmation(false)
    subTaskToDelete && setSubTaskToDelete(undefined)
  }

  const duplicateTask = async (
    task: Task | Subtask,
    parentId?: number | string | null,
    includeSubtasks?: boolean
  ) => {
    try {
      await withLoadingLocks(
        makePostRequest(
          `/task-management/matters/${_scopeId ||
            String(task?.relatedMatter?.value ?? '')}/tasks/clone/`,
          {
            object_id: task.id,
            ...(includeSubtasks ? { include_subtasks: true } : {})
          }
        )
      )

      if (parentId) {
        editTask({ ...task, id: +parentId })
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task ${task.name} successfully copied.`,
          level: 'success'
        }
      })

      fetchTasks()
      fetchTasksCounts()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const renderCustomAction = (row: Task) => {
    if (readOnly || !canEditTask(row, context)) {
      return <span className={s.actionsPlaceholder}>...</span>
    }
    return (
      <ActionsPopover
        task={row}
        editTask={() => editTask(row)}
        deleteTask={showDeleteModal}
        duplicateTask={row.children > 0 ? showCopyMethodsModal : duplicateTask}
      />
    )
  }

  const toggleAddTaskSidebar = () => {
    setIsAddTaskVisible(!isAddTaskVisible)
    setEditedTask(null)
  }

  const toggleAddTaskFromTemplateModal = () => {
    setIsAddTaskFromTemplateModalVisible(!isAddTaskFromTemplateModalVisible)
  }

  const saveTask = async (
    task: Task,
    isEdit: boolean | undefined,
    callback?: (task?: Task) => void
  ) => {
    try {
      if (task.id) {
        const index = tasks.findIndex(p => p.id === task.id)
        const scopeIdFallback = _scopeId || String(editedTask?.relatedMatter?.value ?? '')

        const response = await withLoadingLocks(
          makePatchRequest(
            `/task-management/matters/${scopeIdFallback}/tasks/${task.id}/`,
            fromTask(task, scopeIdFallback, isEdit)
          )
        )
        const formattedResponse = toTasks([response])[0]
        setTasks(draft => {
          draft[index] = formattedResponse
        })
        callback && callback(formattedResponse)
        setOldTask(formattedResponse)
        setEditedTask(formattedResponse)
      } else {
        const newTask = await withLoadingLocks(
          makePostRequest(`/task-management/matters/${_scopeId}/tasks/`, fromTask(task, _scopeId))
        )
        // fetch newly created task
        editTask(toTasks([newTask])[0])
        fetchTasksCounts()
      }
      fetchTasks()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task ${task?.name ?? editedTask?.name} successfully ${
            isEdit ? 'updated' : 'created'
          }.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const updateBulkTasks = async () => {
    try {
      await withLoadingLocks(
        makePatchRequest(
          `${baseUrl}/tasks/bulk-operations/`,
          serializeBulkEditOptions(bulkEdit.options, selectedRows)
        )
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('task', selectedRows.length, true)} successfully updated.`,
          level: 'success'
        }
      })
      fetchTasks()
      fetchTasksCounts()
    } catch (err) {
      const error = err as AxiosError
      const customMessage = `Failed to bulk edit the ${pluralize(
        'task',
        selectedRows.length
      )}. Please try again later. If the issue persists, please contact support for further assistance.`
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    setBulkEdit(bulkEditInitialState)
    clearAllSelectedRows()
  }

  const deleteBulkTasks = async () => {
    setShowBulkDeleteConfirmation(false)
    try {
      await withLoadingLocks(
        makeDeleteRequest(`${baseUrl}/tasks/bulk-operations/`, {
          data: { task_ids: selectedRows }
        })
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('task', selectedRows.length, true)} successfully deleted.`,
          level: 'success'
        }
      })
      fetchTasks()
      fetchTasksCounts()
    } catch (err) {
      const error = err as AxiosError
      const customMessage =
        'Failed to bulk delete the tasks. Please try again later. If the issue persists, please contact support for further assistance.'
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    clearAllSelectedRows()
  }

  const handleBulkDeleteAction = () => {
    const isSelectedTasksWithHiddenSubs = filteredTasks.some(
      task => selectedRows.includes(task.id) && task.hasHiddenSubtasks
    )
    isSelectedTasksWithHiddenSubs
      ? setShowBulkDeleteNotAllowed(true)
      : setShowBulkDeleteConfirmation(true)
  }

  const triggerBulkAction = (option: { value: string; label: string }) => {
    switch (option.value) {
      case 'edit':
        setBulkEdit(state => ({ ...state, isBulkEditDialog: true }))
        break
      case 'delete':
        handleBulkDeleteAction()
        break
      default:
        break
    }
  }

  const bulkActionsButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRows.length})`}
      options={BULK_OPTIONS}
      style={{ padding: '10px', borderRadius: '4px' }}
      listStyles={{ width: '100%', top: '42px' }}
      listItemStyles={{ fontSize: '14px' }}
      onSelect={triggerBulkAction}
    />
  )

  const cantBulkDeleteConfirmationContent = () => {
    const cantDeleteTasksList = filteredTasks
      .filter(task => selectedRows.includes(task.id) && task.hasHiddenSubtasks)
      .map(task => task.taskId)
    const cantDeleteTasks = cantDeleteTasksList.join(', ')
    const tasks = pluralize('task', cantDeleteTasksList.length)
    const these = pluralize('this', cantDeleteTasksList.length)
    return (
      <>
        <div className={s.cantDeleteReason}>
          {`The following ${tasks} have associated private task(s) and cannot be deleted: ${cantDeleteTasks}.`}
        </div>
        <div>{`Remove ${these} ${tasks} from your bulk selection to proceed.`}</div>
      </>
    )
  }

  return (
    <section>
      {isAddTaskVisible && (
        <AddTask
          toggleAddTaskSidebar={toggleAddTaskSidebar}
          tasks={tasks.map(t => ({ id: t.id, name: t.name }))}
          fetchTasks={fetchTasks}
          scopeName={scopeName}
          task={editedTask}
          saveTask={saveTask}
          onDeleteTask={deleteSubTask}
          setTasks={setTasks}
          scopeId={scopeId}
          oldTask={oldTask}
          onEditSubtask={editTask}
          duplicateTask={duplicateTask}
          readOnly={readOnly}
          context={context}
          setScopeId={setScopeId}
        />
      )}
      <div className={cn('box', { [s.noActions]: isAddTaskVisible || restrictAllActions })}>
        <div className="box-content" style={{ minHeight: '70vh' }}>
          <div className={s.header}>
            <h2 className={s.title} data-testid="title">
              Tasks
            </h2>
            <div className={s.tabs}>
              <span
                className={cn({ [s.selected]: view === 'list' })}
                onClick={() => setView('list')}
              >
                <FaList /> List
              </span>
              {context !== 'workbench' && (
                <span
                  className={cn({ [s.selected]: view === 'calendar' })}
                  onClick={() => {
                    setView('calendar')
                    window.history.replaceState(null, '', removeHashFromUrl())
                  }}
                >
                  <FaCalendarAlt /> Calendar
                </span>
              )}
            </div>
            <span className={s.rightActions}>
              {!readOnly && isTaskCalendarSyncEnabled() && (
                <>
                  <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                    <Button
                      style={{
                        padding: '8px 15px',
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        bottom: 2
                      }}
                      key="sync"
                      onClick={() => setIsCalendarSyncModalVisible(true)}
                      isPrimary
                      isOutline
                      hasNewDesign
                      isDisabled={isBasicPlan}
                    >
                      Calendar Sync
                      <MicrosoftOfficeOutlookIcon className={s.calendarIcon} />
                      <GoogleCalendarIcon className={s.calendarIcon} />
                      <AppleCalendarIcon className={cn(s.calendarIcon, s.appleCalendarIcon)} />
                    </Button>
                  </div>
                  <ReactTooltip
                    id="buttonTooltip"
                    type="light"
                    effect="solid"
                    place="top"
                    border
                    disable={!isBasicPlan}
                    className={s.tooltipPopup}
                  >
                    Contact customer success to upgrade.
                  </ReactTooltip>
                </>
              )}
              {view === 'list' && (
                <>
                  <Button
                    style={{ padding: '10px 15px' }}
                    key="download"
                    onClick={downloadXlsx}
                    isPrimary
                    isOutline
                    hasNewDesign
                  >
                    Download
                  </Button>
                  {!readOnly && (
                    <ButtonDropdown
                      displayText="Add"
                      onSelect={option => {
                        if (option.value === 'simpleTask') {
                          toggleAddTaskSidebar()
                        } else {
                          toggleAddTaskFromTemplateModal()
                        }
                        clearAllSelectedRows()
                      }}
                      options={[
                        { label: 'Create a task', value: 'simpleTask' },
                        {
                          label: 'Create task from template',
                          value: 'fromTemplateTask',
                          isDisabled: isBasicPlan,
                          hasTooltip: isBasicPlan
                        }
                      ]}
                      isPrimary
                      alignRight
                      listStyles={{ marginTop: 12, fontSize: 14 }}
                      style={{
                        padding: '10px 15px',
                        borderRadius: '4px',
                        marginLeft: 10,
                        position: 'relative',
                        bottom: 2
                      }}
                      listItemStyles={{ fontSize: 14, margin: '10px 0' }}
                      hasNewDesign
                    />
                  )}
                </>
              )}
            </span>
          </div>
          {view === 'list' ? (
            <>
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={changeTab}
                myTasksCount={myTasksCount}
                myRequestsCount={myRequestsCount}
                myFollowingCount={myFollowingCount}
                totalTasksCount={totalEntries}
              />
              <DataTableWrapper
                isLoading={isLoading}
                remotePagination
                alwaysShowLoadingSkeleton
                params={params}
                categories={[]}
                rows={filteredTasks}
                totalEntries={totalEntries}
                filteredTotal={filteredTotal}
                columns={columns}
                updateTable={updateTable}
                panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                className={s.itemsTable}
                customAction={renderCustomAction}
                hasActions={!readOnly}
                alwaysShowActions
                categoryKey="task"
                hasTooltip
                selectAllRows={!readOnly ? selectAllRows : undefined}
                selectRow={!readOnly ? selectRow : undefined}
                selectedRows={new Set(selectedRows)}
                allRowsSelected={allRowsSelected}
                checkboxSize="md"
                borderPosition="left"
                tooltipClassName={s.tooltip}
                filters={
                  <Filters
                    taskFilters={taskFilters}
                    setTaskFilters={filters => {
                      setTaskFilters(filters)
                      fetchTasks({ filters, cb: updateUrl })
                    }}
                    clearFilters={clearFilters}
                    baseUrl={baseUrl}
                    scopeId={scopeId}
                    context={context}
                  />
                }
                bulkActions={bulkActionsButton}
                customStatusText={
                  !isFiltered && filteredTasks.length === 0
                    ? 'There are currently none, click Add to get started.'
                    : undefined
                }
              />
            </>
          ) : (
            context !== 'workbench' && (
              <CalendarView context={context} baseUrl={baseUrl} changeTab={setView} />
            )
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title={`Delete this ${editedTask?.parent || subTaskToDelete ? 'subtask' : 'task'}?`}
          confirmText="Delete"
          content={`This will also delete all of it's ${
            editedTask?.subtasks.length && !subTaskToDelete ? 'subtasks, ' : ''
          }files and comments.`}
          onConfirm={() => (subTaskToDelete ? onDeleteTask(subTaskToDelete.id) : onDeleteTask())}
          onCancel={() => {
            setShowDeleteConfirmation(false)
            subTaskToDelete && setSubTaskToDelete(undefined)
          }}
        />
      )}
      {showBulkDeleteConfirmation && (
        <ConfirmationDialog
          title={`Delete ${pluralize('task', selectedRows.length, true)}?`}
          confirmText="Delete"
          content="This will also delete all associated subtasks, files, and comments? This action cannot be undone."
          onConfirm={deleteBulkTasks}
          onCancel={() => setShowBulkDeleteConfirmation(false)}
        />
      )}
      {showBulkDeleteNotAllowed && (
        <ConfirmationDialog
          title={`Can’t delete ${selectedRows.length} selected ${pluralize(
            'task',
            selectedRows.length
          )}`}
          content={cantBulkDeleteConfirmationContent()}
          hideButtons
          onCancel={() => setShowBulkDeleteNotAllowed(false)}
        />
      )}
      {showCopyMethods && (
        <CopyMethods
          onConfirm={(includeSubtasks: boolean) => {
            editedTask && duplicateTask(editedTask, null, includeSubtasks)
            setShowCopyMethods(false)
          }}
          onCancel={() => setShowCopyMethods(false)}
        />
      )}
      {bulkEdit.isBulkEditDialog && (
        <BulkEditModal
          tasksNumber={selectedRows.length}
          matterId={+scopeId}
          onUpdate={(options: BulkEditValues) =>
            setBulkEdit({ isBulkEditDialog: false, options, isBulkEditConfirmationDialog: true })
          }
          onCancel={() => setBulkEdit(bulkEditInitialState)}
          context={context}
        />
      )}
      {bulkEdit.isBulkEditConfirmationDialog && (
        <ConfirmationDialog
          title={`Update ${pluralize('task', selectedRows.length, true)}`}
          confirmText="Update"
          content={`Are you sure you want to update the ${pluralize(
            'task',
            selectedRows.length,
            true
          )} you selected?`}
          onConfirm={updateBulkTasks}
          onCancel={() => setBulkEdit(bulkEditInitialState)}
        />
      )}
      {isAddTaskFromTemplateModalVisible && (
        <AddTaskFromTemplateModal
          toggleAddTaskFromTemplateModal={toggleAddTaskFromTemplateModal}
          scopeId={scopeId}
          fetchTasks={fetchTasks}
          fetchTasksCounts={fetchTasksCounts}
          context={context}
        />
      )}
      {isCalendarSyncModalVisible && (
        <CalendarSyncModal
          toggleModal={() => setIsCalendarSyncModalVisible(!isCalendarSyncModalVisible)}
        />
      )}
      {isDownloadModalOpen && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your export is ready."
          confirmText="OK"
          confirmCb={() => setIsDownloadModalOpen(false)}
          cancelCb={() => setIsDownloadModalOpen(false)}
          size="sm"
          hideCancelBtn
        />
      )}
    </section>
  )
}

export default Tasks
