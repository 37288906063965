import { FaExclamationCircle, FaCheckCircle } from 'react-icons/fa'
import cn from 'classnames'
import s from './FileStatusIcon.scss'
import { FILE_STATUS_SCANNING, FILE_STATUS_CLEAN, FILE_STATUS_INFECTED } from '../constants'
import ReactTooltip from 'react-tooltip'

const FileStatusIcon = ({ fileStatus }) => {
  let status = ''
  let tip = ''

  if (fileStatus === FILE_STATUS_SCANNING) {
    status = <FaCheckCircle className={s.status} />
    tip = 'File is still being scanned for malware.'
  } else if (fileStatus === FILE_STATUS_CLEAN) {
    status = <FaCheckCircle className={cn(s.status, s.green)} />
    tip = 'No malware detected.'
  } else {
    if (fileStatus === FILE_STATUS_INFECTED) {
      status = <FaExclamationCircle className={cn(s.status, s.red)} />
      tip = 'Malware was detected.'
    }
  }

  return (
    <>
      <span data-for="tooltipStatus" className={s.checkboxWrapper} data-tip>
        {status}
      </span>
      <ReactTooltip id="tooltipStatus" type="light" effect="solid" place="top" border clickable>
        {tip}
      </ReactTooltip>
    </>
  )
}

export default FileStatusIcon
