import s from './List.scss'
import { FaFileUpload } from 'react-icons/fa'
import { FileStatusIcon } from '../FileStatusIcon'
import { ExtensionImage } from '../ExtensionImage'
import { MdFileDownload } from 'react-icons/md'
import { useMemo, CSSProperties } from 'react'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'

export interface APIAttachment {
  id: number | string
  file_name: string
  file_type: string
  file_url: string
  s3_file_url: string | null
  download_url?: string
  file_size: number
  file_upload_date?: string
  source: 'ebill' | 'counselgo'
  user_full_name?: string
  user_email?: string
  user_id?: number
  fileStatus: 'Scanning' | 'Uploaded' | 'Clean' | 'Temporary'
}

interface Props {
  attachments: APIAttachment[]
  setIsModalOpen: (isOpen: boolean) => void
  renderCustomAction: (attachment: APIAttachment) => JSX.Element
  style?: CSSProperties
  downloadAll?: () => void
  readOnly?: boolean
  isFilesLimitReached?: boolean
}

const List = ({
  attachments,
  setIsModalOpen,
  renderCustomAction,
  style,
  downloadAll,
  readOnly,
  isFilesLimitReached
}: Props) => {
  const canDownloadAny = useMemo(() => {
    return attachments.some(attachment => attachment.fileStatus === 'Clean')
  }, [attachments])

  return (
    <div className={s.simpleViewContainer}>
      {attachments.length > 0 && (
        <div className={s.label}>
          {attachments.length} Files
          {canDownloadAny && (
            <div className={s.downloadAllWrapper} onClick={downloadAll}>
              <MdFileDownload className={s.downloadAllIcon} />
              <p className={s.downloadAllText}>All Files</p>
            </div>
          )}
        </div>
      )}
      <div style={style} className={s.attachmentsWrapper}>
        {attachments.map(attachment => (
          <div key={attachment.id} className={s.attachment}>
            <div style={{ display: 'flex' }}>
              <ExtensionImage width={25} file={{ path: attachment.file_name }} />
              <p className={s.name}>{attachment.file_name}</p>
            </div>
            <div style={{ display: 'flex', height: '32px', alignItems: 'flex-end' }}>
              <div className={s.fileNameContainer}>
                <FileStatusIcon file={attachment} className={s.statusIcon} />
              </div>
              {renderCustomAction(attachment)}
            </div>
          </div>
        ))}
      </div>
      {!readOnly && (
        <>
          <p
            className={cn(s.addWrapper, { [s.disabled]: isFilesLimitReached })}
            onClick={isFilesLimitReached ? undefined : () => setIsModalOpen(true)}
          >
            <span data-for="upgradeTier" data-tip>
              <FaFileUpload className={s.addIcon} /> Upload Files
            </span>
          </p>
          {isFilesLimitReached && (
            <ReactTooltip id="upgradeTier" type="light" effect="solid" place="top" border>
              Contact customer success to upgrade.
            </ReactTooltip>
          )}
        </>
      )}
    </div>
  )
}

export default List
