import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTableWrapper, Button, ModalContainer, Checkbox } from 'simple-core-ui'
import ACT from 'bulk/actions'
import s from './DynamicBulkTable.scss'
import BulkEditModalContainer from '../BulkEditModal/BulkEditModalContainer'
import BulkTableHeader from './Header/BulkTableHeader'
import axios from 'axios'
import APP_ACT from 'app/actions'
import { fromFragment, serializeBulkFormData } from 'bulk/serializer'
import { omit } from 'lodash'
import Filters from './Filters/Filters'
import UpdateProgress from '../UpdateProgress/UpdateProgress'
import { makeGetRequest } from 'utils/api'
import { BULK_TAB_NAMES_BY_MAP, ACTIVE_JOB_STATUS_URL } from 'bulk/constants'
import useFilterState from './hooks/useFilterState'
import { WarningLine, CheckboxLine } from 'bulk/common/Messages'
import ReactTooltip from 'react-tooltip'
import { MATTER_TEMPLATES, PROCESSING_STATE, ACTION_TYPE } from 'bulk/record_types'

const DynamicBulkTable = ({ setStepCb, setActiveTab }) => {
  const dispatch = useDispatch()
  const frag = fromFragment()
  const columns = useSelector(state => state.bulk.columns)
  const rows = useSelector(state => state.bulk.results)
  const totalEntries = useSelector(state => state.bulk.totalEntries)
  const params = useSelector(state => state.bulk.bulkParams)
  const bulkFields = useSelector(state => state.bulk.bulkFields)
  const bulkFieldValues = useSelector(state => state.bulk.bulkForm)
  const builderParams = useSelector(state => state.bulk.builderParams)
  const isLoading = useSelector(({ app }) => app?.loading?.BULK_FETCH)

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [isProcessingModalVisible, setProcessingModalVisible] = useState(false)
  const [showUpdateWarning, setShowUpdateWarning] = useState(false)

  const [refreshId, setRefreshId] = useState(null)
  const [ack, setAck] = useState(false)

  const { history } = BULK_TAB_NAMES_BY_MAP

  const [
    filterValues,
    setFilterValues,
    currentRadioSelection,
    setCurrentRadioSelection,
    currentInputSelection,
    setCurrentInputSelection,
    getFilterLabel,
    handleFilterRadioChange,
    getInputValue,
    handleFilterInputChange,
    reset,
    handleReset,
    handleClear,
    handleCancel,
    handleFilter,
    getUpdatedFilterValues
  ] = useFilterState({})

  const [activeJobProcessingModal, setActiveJobProcessingModal] = useState(false)

  const [filters, setFilters] = useState([])
  const [selectedFields, setSelectedFields] = useState({})
  const [errors, setErrors] = useState({})
  const [total, setTotal] = useState(0)
  const [percent, setPercent] = useState(0)
  const [errorCount, setErrorCount] = useState(0)
  const [status, setStatus] = useState('')
  const [globalSelection, setGlobalSelection] = useState(false)
  const [excludedRows, setExcludedRows] = useState([])
  const [backupEnabled, setBackupEnabled] = useState(true)
  const [pollStatus, setPollStatus] = useState(null)

  const selectedRowsLength = globalSelection
    ? totalEntries - excludedRows.length
    : selectedRows.length
  const { record, attrType, templateType, fields, filters: bulkFilters } = frag.fields.length
    ? frag
    : builderParams
  const recordType = record?.value
  const caType = attrType?.value
  const templateId = templateType?.value

  useEffect(() => {
    dispatch({
      type: ACT.BULK_FETCH_REQUESTED,
      payload: {
        recordType: recordType,
        recordFields: fields.map(field => field.value),
        ...(caType ? { caType } : {}),
        ...(templateId ? { templateId } : {}),
        params: {
          ...params
        }
      },
      loadingLock: 'on'
    })
    setFilters(bulkFilters)

    dispatch({
      type: ACT.BULK_FIELDS_FETCH_REQUESTED,
      payload: {
        recordType: recordType,
        ...(caType ? { caType } : {}),
        ...(templateId ? { templateId } : {})
      }
    })

    return () => {
      dispatch({
        type: ACT.CLEANUP_BULK,
        payload: { key: 'bulkParams' }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bulkFields) {
      const filtersObj = bulkFields.filter(bulkField =>
        bulkFilters.some(filter => filter.value === bulkField.field)
      )
      setFilters(filtersObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkFields])

  useEffect(() => {
    globalSelection && setSelectedRows(rows.map(m => !excludedRows.includes(m.id) && m.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    if (!PROCESSING_STATE.includes(pollStatus)) {
      clearInterval(refreshId)
      setRefreshId(null)
      setPollStatus(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollStatus])

  useEffect(() => {
    return () => clearInterval(refreshId)
  }, [refreshId])

  const updateTable = updatedParams => {
    dispatch({
      type: ACT.BULK_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        recordType: recordType,
        recordFields: fields.map(field => field.value),
        ...(caType ? { caType } : {}),
        ...(templateId ? { templateId } : {}),
        params: {
          ordering: updatedParams.ordering,
          page: updatedParams.page,
          pageSize: updatedParams.pageSize,
          filters: filterValues
        }
      }
    })
  }

  const allRequests = async action => {
    try {
      const params = serializeBulkFormData(
        bulkFieldValues,
        action,
        recordType,
        selectedRows,
        globalSelection,
        filterValues,
        excludedRows,
        backupEnabled,
        columns,
        caType,
        templateId
      )
      const response = await axios.post('/bulkv2/bulk_submit', params)

      const jobId = response.data.job_id
      const jobStatus = response.data.status

      setBackupEnabled(response.data.backup_enabled)

      if (PROCESSING_STATE.includes(jobStatus)) {
        dispatch({
          type: ACT.SET_ACTIVE_JOB_STATUS,
          payload: { activeJob: true }
        })
        setTotal(response.data.total_count)
        setStatus(jobStatus)
        setProcessingModalVisible(true)

        const intervalId = setInterval(async () => {
          try {
            const pollResponse = await axios.get(`/bulkv2/job/${jobId}`)
            const {
              status: updatedStatus,
              success_count: success,
              error_count: error,
              total_count: total,
              backup_enabled: backupeEnabled
            } = pollResponse.data

            setPollStatus(updatedStatus)

            if (!PROCESSING_STATE.includes(updatedStatus)) {
              setActiveJobProcessingModal(false)
              dispatch({
                type: ACT.SET_ACTIVE_JOB_STATUS,
                payload: { activeJob: false }
              })
            }
            setTotal(total)
            setStatus(updatedStatus)
            setErrorCount(error)
            setPercent(success + error)
          } catch (e) {
            setPercent(0)
          }
        }, 5000)
        setRefreshId(intervalId)
      }
    } catch (e) {
      const errMsg =
        e.response.status === 400 || e.response.status === 409
          ? e.response.data.error
          : 'Bulk request could not be submitted'
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: errMsg,
          level: 'error'
        }
      })
      setProcessingModalVisible(false)
    }
    resetRowSelection()
  }

  const bulkButton = (
    <>
      <div
        data-for="buttonTooltip"
        data-tip
        onClick={() => selectedRowsLength && showBulkEditModal()}
        style={{ cursor: !selectedRowsLength ? 'not-allowed' : 'pointer' }}
      >
        <Button
          style={{ marginLeft: '-10px', pointerEvents: 'none' }}
          displayText="Bulk"
          isDisabled={!selectedRowsLength}
        >
          {`Bulk Actions ${selectedRowsLength ? `(${selectedRowsLength})` : ''}`}
        </Button>
      </div>
      <ReactTooltip
        id="buttonTooltip"
        type="light"
        effect="solid"
        place="bottom"
        border
        disable={selectedRowsLength > 0}
      >
        <p className={s.bulkTooltip}>Select 1+ records to perform a bulk action</p>
      </ReactTooltip>
    </>
  )

  const showBulkEditModal = () => {
    setIsEditModalVisible(true)
  }

  const closeEditModal = () => {
    setSelectedFields({})
    setErrors({})
    setIsEditModalVisible(false)
    resetBulkFieldValues()
    setBackupEnabled(true)
    setShowUpdateWarning(false)
  }

  const selectRow = ({ id }) => {
    setSelectedRows(prevSelectedRows => {
      const isRowSelected = prevSelectedRows.includes(id)
      const isRowExcluded = excludedRows.includes(id)

      if (isRowSelected) {
        const updatedSelectedRows = prevSelectedRows.filter(rowId => rowId !== id)
        if (globalSelection && !isRowExcluded) {
          setExcludedRows(prevExcludedRows => [...prevExcludedRows, id])
        }
        return updatedSelectedRows
      } else {
        if (globalSelection && isRowExcluded) {
          const updatedExcludedRows = excludedRows.filter(rowId => rowId !== id)
          setExcludedRows(updatedExcludedRows)
        }
        return [...prevSelectedRows, id]
      }
    })
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    if (allRowsSelected) {
      setSelectedRows([])
      setExcludedRows([])
      setGlobalSelection(false)
    } else {
      setSelectedRows(rows.map(m => m.id))
      setGlobalSelection(true)
    }
  }

  const resetRowSelection = () => {
    setSelectedRows([])
    setExcludedRows([])
    setAllRowsSelected(false)
    setGlobalSelection(false)
  }

  const resetBulkFieldValues = () => {
    dispatch({
      type: ACT.RESET_BULK_FIELDS_VALUES,
      payload: {}
    })
  }

  const updateRecords = async () => {
    setAck(false)
    closeEditModal()
    const { active_job } = await makeGetRequest(ACTIVE_JOB_STATUS_URL)
    active_job ? setActiveJobProcessingModal(true) : allRequests(ACTION_TYPE.EDIT)
  }

  const onFilterApply = (field, selectedOpt) => {
    if (!selectedOpt) {
      setCurrentRadioSelection(draft => omit(currentRadioSelection, field))
      setCurrentInputSelection(draft => omit(currentInputSelection, field))
    }
    const updatedFilterValues = getUpdatedFilterValues(field, selectedOpt)

    setFilterValues(draft => {
      return updatedFilterValues
    })
    resetRowSelection()
    handleFilter(updatedFilterValues)
  }

  const onReset = () => {
    resetRowSelection()
    handleReset()
  }

  const closeProcessingModal = () => {
    setProcessingModalVisible(false)
    setPercent(0)
    setStatus('')
    setErrorCount(0)
    setTotal(0)
    setBackupEnabled(true)
    if (PROCESSING_STATE.includes(status)) {
      setActiveTab(history)
      setStepCb(4)
    } else {
      updateTable(params)
    }
  }

  const closeActiveJobProcessingModal = () => {
    setActiveJobProcessingModal(false)
    resetRowSelection()
    setActiveTab(history)
    setStepCb(4)
  }

  const validateCurrencyField = () => {
    const formArr = Object.values(bulkFieldValues)
    return !(
      formArr.length ===
      formArr.filter(f => {
        if (f.dataType === 'currency') {
          return f.value.amount && f.value.code
        }
        return f.value !== null
      }).length
    )
  }

  const validateRequiredFields = () =>
    !(
      bulkFields.filter(field => field.required).length ===
      bulkFields.filter(bulkField => {
        if (bulkField.required) {
          if (bulkField.type === 'currency') {
            return (
              bulkFieldValues.hasOwnProperty(bulkField.field) &&
              bulkFieldValues[bulkField.field].value.amount &&
              bulkFieldValues[bulkField.field].value.code
            )
          }
          return bulkFieldValues.hasOwnProperty(bulkField.field)
        }
      }).length
    )

  const disableUpdate = () => {
    const formArr = Object.values(bulkFieldValues)

    if (!formArr.length || Object.keys(errors).length) return true
    else if (recordType === MATTER_TEMPLATES) {
      return validateRequiredFields() || validateCurrencyField()
    } else {
      return validateCurrencyField()
    }
  }

  const editModalFooterContent = (
    <div className={s.modalFooterContent}>
      <Checkbox
        hasGreyBorder
        size="sm"
        isChecked={backupEnabled}
        triggerCheckbox={() => setBackupEnabled(!backupEnabled)}
        className={s.footerCheckbox}
      />
      <div className={s.textContainer}>
        <p>Backup Enabled</p>
        <p className={s.description}>
          When enabled, a backup file with each record’s original values will save to the Job
          History page. Processing time may increase depending on file size.
        </p>
      </div>
    </div>
  )

  const WarningModalBody = (
    <>
      <WarningLine message="Processing cannot be canceled once the update starts" />
      <WarningLine message="Changes cannot be reverted once the update finishes" />
      {recordType === MATTER_TEMPLATES && (
        <WarningLine message="Closed matters will reopen for processing only and will be saved in a closed status when processing completes" />
      )}
      <CheckboxLine
        message="I acknowledge I've read and understand the above message"
        acknowledgement={ack}
        setAcknowledgement={setAck}
      />
    </>
  )

  return (
    <>
      <BulkTableHeader
        caType={caType}
        params={params}
        columns={columns}
        setStep={setStepCb}
        recordType={recordType}
        templateId={templateId}
      />
      {columns.length > 0 && (
        <div style={{ margin: '0 25px' }}>
          {columns.length > 0 && (
            <DataTableWrapper
              params={params}
              customSearchOptions={{
                placeholder: 'Press enter to search',
                styles: { width: 270, fontStyle: 'italic' }
              }}
              remotePagination
              tableHeight="500px"
              categories={[]}
              rows={rows}
              filters={
                <div className={s.filterContainer}>
                  <Filters
                    filters={filters}
                    getFilterLabel={getFilterLabel}
                    getInputValue={getInputValue}
                    handleFilterApply={onFilterApply}
                    filterValues={filterValues}
                    onFilterInputChange={handleFilterInputChange}
                    onChange={handleFilterRadioChange}
                    currentSelection={currentRadioSelection}
                    currentInputSelection={currentInputSelection}
                    onClear={handleClear}
                    onCancel={handleCancel}
                    resetCb={onReset}
                    dispatch={dispatch}
                    hasConfirmation={selectedRowsLength || globalSelection}
                    reset={reset}
                    recordType={recordType}
                  />
                </div>
              }
              isLoading={isLoading}
              columns={columns}
              totalEntries={totalEntries ?? 0}
              updateTable={updateTable}
              panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
              hasActions
              hasTooltip
              hasSearch={false}
              bulkActions={bulkButton}
              selectRow={selectRow}
              selectAllRows={selectAllRows}
              selectedRows={new Set(selectedRows)}
              allRowsSelected={allRowsSelected}
              checkboxSize="md"
              customBlankValue={<span>&#8212;</span>}
              selectedRowsLength={selectedRowsLength}
              revealBulkButton
            />
          )}
          {isEditModalVisible && (
            <ModalContainer
              title="Bulk Actions"
              size="md"
              cancelText="Cancel"
              cancelCb={closeEditModal}
              shouldModalClose={false}
              confirmText="Update"
              confirmCb={() => setShowUpdateWarning(true)}
              hasCloseIcon
              isDisabled={disableUpdate()}
              footerContent={editModalFooterContent}
              content={
                <BulkEditModalContainer
                  recordsCount={selectedRowsLength}
                  selectedFields={selectedFields}
                  setSelectedFields={setSelectedFields}
                  errors={errors}
                  setErrors={setErrors}
                />
              }
            />
          )}
          {isProcessingModalVisible && (
            <ModalContainer
              title={
                backupEnabled && status === 'submitted'
                  ? 'Please Wait...'
                  : `Updates ${
                      status === 'success'
                        ? 'Completed'
                        : status === 'failed'
                        ? 'Failed'
                        : 'Processing...'
                    }`
              }
              size="md"
              confirmText="Close"
              confirmCb={closeProcessingModal}
              hasCloseIcon={false}
              content={
                <UpdateProgress
                  backupEnabled={backupEnabled}
                  status={status}
                  percent={percent}
                  total={total}
                  error={errorCount}
                />
              }
            />
          )}
          {activeJobProcessingModal && (
            <ModalContainer
              title="Existing update in progress"
              size="md"
              cancelText="Close"
              cancelCb={closeActiveJobProcessingModal}
              hasCloseIcon={false}
              content="You cannot start a bulk update at this time because a previous update is still processing. Go to the Job History page to check the status of the previous update."
            />
          )}
          {showUpdateWarning && (
            <ModalContainer
              title="Do you wish to start the update?"
              size="md"
              cancelText="Cancel"
              confirmText="Continue"
              cancelCb={() => {
                setAck(false)
                closeEditModal()
              }}
              confirmCb={updateRecords}
              hasCloseIcon={false}
              content={WarningModalBody}
              isDisabled={!ack}
            />
          )}
        </div>
      )}
    </>
  )
}

export default DynamicBulkTable
