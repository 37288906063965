import { If, Button } from 'simple-core-ui'
import s from './EntityActionsFooter.scss'

const EntityActionsFooter = ({
  entity,
  archive,
  saveAndPublish,
  save,
  cancelChanges,
  showDelete,
  deleteEntity,
  status,
  buttonsDisabled
}) => {
  const isPublished = entity.status === status

  return (
    <div className={s.buttons}>
      <div>
        <If condition={showDelete}>
          <Button isDisabled={buttonsDisabled} onClick={() => deleteEntity()}>
            Delete
          </Button>
        </If>
        <If condition={isPublished}>
          <Button
            isDisabled={buttonsDisabled}
            onClick={() => archive(entity)}
            testid="archive_button"
          >
            Archive
          </Button>
        </If>
      </div>
      <div>
        <Button isDisabled={buttonsDisabled} onClick={() => cancelChanges()} isSecondary>
          Cancel
        </Button>
        <Button
          isDisabled={buttonsDisabled}
          onClick={() => save(entity)}
          isPrimary={isPublished}
          isSecondary={!isPublished}
        >
          Save
        </Button>
        <If condition={!isPublished}>
          <Button
            isDisabled={buttonsDisabled}
            onClick={() => saveAndPublish(entity)}
            isPrimary
            testid="save_publish_button"
          >
            Save & Publish
          </Button>
        </If>
      </div>
    </div>
  )
}

export default EntityActionsFooter
