import { Constant } from 'simple_review/@types/common'
import { BaseSerializer } from './base.serializer'
import { RuleInfoEditSerializer } from './rule-info-edit.serializer'

export function RuleCategoryEditSerializer(
  oldValue: number | null,
  newValue: number,
  categories: Array<Constant<number>>
): BaseSerializer {
  return {
    toString() {
      const oldCategory = categories.find(category => category.value === oldValue)
      const newCategory = categories.find(category => category.value === newValue)

      if (!newCategory) return 'Changed Category'

      if (!oldCategory) {
        return RuleInfoEditSerializer(null, newCategory.label, 'category').toString()
      }

      return RuleInfoEditSerializer(oldCategory.label, newCategory.label, 'category').toString()
    }
  }
}
