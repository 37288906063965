import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { MouseEvent } from 'react'
import { Event } from '../types'

interface Props {
  event: Event
  editEvent: (row: Event) => void
  deleteEvent: (row: Event) => void
  duplicateEvent: (row: Event) => void
}

const ActionsPopover = ({ event, deleteEvent, editEvent, duplicateEvent }: Props) => {
  const handleClick = (e: MouseEvent, action: string) => {
    e.preventDefault()
    e.stopPropagation()
    switch (action) {
      case 'DELETE':
        deleteEvent(event)
        return
      case 'EDIT':
        editEvent(event)
        return
      case 'COPY':
        duplicateEvent(event)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${event.id}`}
        aria-describedby={`item-actions-${event.id}`}
        aria-label={`item-${event.id}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${event.id}`}
      >
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${event.id}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
        className={s.tooltip}
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EDIT')
            }}
          >
            Edit
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'COPY')
            }}
          >
            Copy
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DELETE')
            }}
          >
            Delete
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
