import s from './Filters.scss'
import cn from 'classnames'
import { DropdownPopper } from 'common/DropdownPopper'
import { CheckboxList } from 'common/Selects'
import { ContactsFilters, Role, LabelIds } from '../types'
import { extractCgRoles, extractLabels } from '../utils'
import Filter from './Filter/Filter'
import { useMemo } from 'react'

interface Props {
  className?: string
  contactFilters: ContactsFilters
  setContactFilters: (filters: ContactsFilters) => void
  clearFilters: () => void
  roles: Role[]
}

export interface FilterOption {
  label: string
  value: string | number
}

const serializeRoles = (rolesList: Role[]) =>
  rolesList.map(role => ({ id: role.id, name: role.label }))

const Filters = ({ className, contactFilters, setContactFilters, clearFilters, roles }: Props) => {
  const cgRolesOptions = useMemo(() => serializeRoles(extractCgRoles(roles)), [roles])

  const labelsOptions = useMemo(() => serializeRoles(extractLabels(roles)), [roles])

  return (
    <div className={cn(s.filters, className)}>
      <Filter
        label="CounselGO Roles"
        value={contactFilters.roleIds || null}
        onConfirm={(value, cb) => {
          setContactFilters({ ...contactFilters, roleIds: value })
          cb?.()
        }}
        labelPosition="right"
      >
        {({ filters, setFilters }) => (
          <DropdownPopper value={filters?.values ?? []} placeholder="Select role(s)" label="role">
            <CheckboxList
              value={filters?.values ?? []}
              onConfirm={setFilters}
              options={cgRolesOptions}
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="Labels"
        value={contactFilters.labelIds || null}
        onConfirm={(value, cb) => {
          setContactFilters({ ...contactFilters, labelIds: value as LabelIds })
          cb?.()
        }}
        labelPosition="right"
      >
        {({ filters, setFilters }) => (
          <DropdownPopper value={filters?.values ?? []} placeholder="Select label(s)" label="label">
            <CheckboxList
              value={filters?.values ?? []}
              onConfirm={setFilters}
              options={labelsOptions}
            />
          </DropdownPopper>
        )}
      </Filter>
      <div className={s.reset} onClick={clearFilters}>
        Reset
      </div>
    </div>
  )
}

export default Filters
