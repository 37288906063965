import { Component } from 'react'
import { connect } from 'react-redux'
import ACT from 'matters/requestForms/detail/actions'
import RequestAttributeFilter from './components/RequestAttributeFilter'

@connect()
class RequestAttributeFilterContainer extends Component {
  state = {
    allOptions: [],
    filteredOptions: []
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { allOptions, filteredOptions } = nextProps.item
    if (!prevState.allOptions.length && !prevState.filteredOptions.length) {
      return {
        allOptions,
        filteredOptions
      }
    }

    return null
  }
  updateSelectedOptions = filteredOptions => {
    this.setState({
      filteredOptions
    })
  }
  saveFilter = () => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_CHANGE_ATTRIBUTE_FILTER_OPTIONS,
      payload: {
        attrIndex: this.props.attrIndex,
        value: this.state.filteredOptions
      }
    })
    this.props.toggleFilter()
  }

  render() {
    const { toggleFilter, filterVisible } = this.props
    const { allOptions, filteredOptions } = this.state

    return (
      <RequestAttributeFilter
        toggleFilter={toggleFilter}
        filterVisible={filterVisible}
        allOptions={allOptions}
        filteredOptions={filteredOptions}
        updateSelectedOptions={this.updateSelectedOptions}
        saveFilter={this.saveFilter}
        isConfirmDisabled={!filteredOptions.length}
        inactiveOptions={this.props.item.inactiveOptions}
      />
    )
  }
}

export default RequestAttributeFilterContainer
