import { MouseEvent, useState } from 'react'
import { format, parse } from 'date-fns'
import cn from 'classnames'
import { OutsideClickContainer } from 'simple-core-ui'
import SelectCustomInput from '../SelectCustomInput/SelectCustomInput'
import { Time } from 'common/Events/types'
import { TimePicker } from 'common/TimePicker'
import { timezoneUtils } from 'utils/helpers'
import s from './TimePickerSelect.scss'

interface Props {
  value: Time
  isDisabled?: boolean
  onConfirm: (time: Time) => void
}

const TimePickerSelect = ({ value: { startTime, endTime }, isDisabled, onConfirm }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const clearValue = (e: MouseEvent) => {
    e.stopPropagation()
    onConfirm({ startTime: null, endTime: null })
  }

  const getTime = (dateObj: Date | null, isAmPm = true): string | null => {
    const timeFormat = isAmPm ? 'hh:mm a' : 'HH:mm'
    return dateObj ? format(dateObj, timeFormat) : dateObj
  }

  const getSelectValue = () => {
    return startTime && endTime
      ? `${getTime(startTime)} - ${getTime(endTime)} ${timezoneUtils.getUserTimezone()}`
      : null
  }

  const convertToDate = (time: string | null): Date | null => {
    return time !== null ? parse(time, 'HH:mm', new Date()) : time
  }

  return (
    <OutsideClickContainer closeComponent={() => setIsExpanded(false)}>
      <section className={cn(s.container, { [s.expanded]: isExpanded })}>
        <SelectCustomInput
          value={getSelectValue()}
          onClick={() => setIsExpanded(expanded => !expanded)}
          onClear={clearValue}
          isDisabled={isDisabled}
        />
        {isExpanded && (
          <div className={s.timePickerContainer}>
            <TimePicker
              values={[getTime(startTime, false), getTime(endTime, false)]}
              className={s.timePicker}
              isRange
              onConfirm={(values: (string | null)[]) => {
                onConfirm({
                  startTime: convertToDate(values[0]),
                  endTime: convertToDate(values[1])
                })
                setIsExpanded(false)
              }}
            />
          </div>
        )}
      </section>
    </OutsideClickContainer>
  )
}

export default TimePickerSelect
