import * as React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'

import { setPageTitle } from 'hocs'
import { ORG_ROLE } from 'utils/constants'

import ContactProfile from './ContactProfile'

import { emptyContact } from '../utils'
import { initialState } from '../reducer'
import { withRouter } from 'simple-core-ui/hocs'
import queryString from 'query-string'
import ScopeContext from 'context/ScopeContext'

import ACT from '../actions'

@setPageTitle(({ credentials }) => {
  return {
    client: get(credentials, 'user.clientName', ''),
    view: 'Contacts',
    detail: 'Profile'
  }
})
@connect(({ contacts }) => {
  const { contact, avatar } = contacts
  return { contact, avatar }
})
@withFetchFlow({
  flag: 'contactProfile',
  getFetchAction: props => ({
    type: ACT.CONTACT_PROFILE_FETCH_REQUESTED,
    payload: { contactId: props.router.params.id },
    loadingStateDisabled: true
  })
})
@withRouter
class ContactProfileContainer extends React.Component {
  static defaultProps = {
    contact: emptyContact,
    avatar: initialState.avatar,
    dispatch: obj => {}
  }

  uploadAvatar = upload => {
    const { router, dispatch } = this.props
    const contactId = router.params.id

    dispatch({
      type: ACT.CONTACT_AVATAR_UPLOAD_REQUESTED,
      payload: { contactId, ...upload }
    })
  }

  deleteAvatar = () => {
    const { router, dispatch } = this.props
    const contactId = router.params.id

    dispatch({
      type: ACT.CONTACT_AVATAR_DELETE_REQUESTED,
      payload: contactId
    })
  }

  isReadOnly = () => {
    const { id, isCSM, role } = get(window, 'credentials.user', {})
    const { contact } = this.props
    const contactIsLoginUser = contact.userId
    const isAdmin = role === ORG_ROLE.ADMIN
    const isRequestingUser = Number(id) === Number(contact.userId)

    if (!contactIsLoginUser || isAdmin || isCSM || isRequestingUser) {
      return false
    } else {
      return true
    }
  }

  render = () => {
    const { scope, scopeId } = queryString.parse(this.props.router.location.search)
    return (
      <ScopeContext.Provider value={{ scope, scopeId }}>
        <ContactProfile
          {...this.props}
          readOnly={this.isReadOnly()}
          uploadAvatar={this.uploadAvatar}
          deleteAvatar={this.deleteAvatar}
          contactId={this.props.router.params.id}
        />
      </ScopeContext.Provider>
    )
  }
}

export default ContactProfileContainer
