import { MouseEvent } from 'react'
import ReactTooltip from 'react-tooltip'
import { IoIosMore } from 'react-icons/io'

import { HoverAction } from 'simple-core-ui'
import s from './Popover.scss'

interface Props {
  conditionId: string
  isCopyDisabled: boolean
  onCopy(): void
  onDelete(): void
}

const popoverConfig = [
  { action: 'COPY', label: 'Copy' },
  { action: 'DELETE', label: 'Delete' }
]

const ActionsPopover = ({ conditionId, isCopyDisabled, onCopy, onDelete }: Props) => {
  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'COPY':
        onCopy()
        return
      case 'DELETE':
        onDelete()
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${conditionId}`}
        aria-describedby={`item-actions-${conditionId}`}
        aria-label={`item-${conditionId}`}
        className={s.actionsContainer}
        data-event="click"
        data-testid={`param-actions`}
        data-tip=""
      >
        <HoverAction icon={<IoIosMore />} size="medium" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${conditionId}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
        className={s.tooltipContainer}
      >
        <ul className={s.actionsList}>
          {popoverConfig
            .filter(({ action }) => !(action === 'COPY' && isCopyDisabled))
            .map(({ action, label }) => (
              <li
                key={action}
                className={s.actionItem}
                onClick={e => {
                  handleClick(e, action)
                }}
              >
                {label}
              </li>
            ))}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
