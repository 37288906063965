import { useEffect, useRef } from 'react'
import { CheckboxContainer, useSyncStateWithProps } from 'simple-core-ui'
import BaseFilter from './BaseFilter'
import SearchSelectBody from './SearchSelectBody'
import s from '../SearchFilter.scss'
import cn from 'classnames'

const CheckboxFilter = ({
  onSelect,
  reset,
  title,
  hasConfirmation,
  options,
  initialValue,
  testid,
  styles = {},
  listStyles = {},
  checkIconClassName,
  labelClassName,
  defaultValue
}) => {
  const [selection, setSelection] = useSyncStateWithProps(initialValue)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setSelection(defaultValue || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  const toggleCheck = option => {
    if (option.toggleAll || !selection) {
      setSelection([option])
    } else {
      const arr = [...selection]
      const index = arr.findIndex(e => e.toggleAll)
      if (~index) arr.splice(index, 1)

      if (arr.find(el => el.value === option.value)) {
        const index = arr.findIndex(e => e.value === option.value)
        arr.splice(index, 1)
        setSelection(arr)
      } else {
        setSelection([...arr, option])
      }
    }
  }

  return (
    <BaseFilter
      testid={testid}
      title={title}
      reset={reset}
      onSelect={onSelect}
      initialValue={initialValue}
      defaultValue={defaultValue}
      customLabel={selected => {
        if (!selected || !selected.length) return title
        if (selected.length === 1) {
          if (selected[0].toggleAll) return title
          return selected[0].label
        }
        return `${title} (${selected.length})`
      }}
      hasConfirmation={hasConfirmation}
      render={(setFilterSelection, setIsOpen) => {
        return (
          <SearchSelectBody
            bodyClassName={s.searchSelectMaxHeight}
            onClear={() => {
              setSelection(null)
            }}
            onSubmit={() => {
              setFilterSelection(selection && selection.length ? selection : null)
            }}
          >
            <div style={{ ...listStyles }}>
              {options.map(option => (
                <div className={s.checkboxesWrapper} key={option.value}>
                  <CheckboxContainer
                    styles={{
                      borderColor: '#000',
                      border: '1px solid #000',
                      ...styles
                    }}
                    size="sm"
                    isChecked={
                      selection ? !!selection.find(el => el.value === option.value) : false
                    }
                    cb={() => toggleCheck(option)}
                    checkIconClassName={cn(s.checkIcon, {
                      [checkIconClassName]: checkIconClassName
                    })}
                  />
                  <label
                    className={cn(s.checkboxLabel, {
                      [labelClassName]: labelClassName
                    })}
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </SearchSelectBody>
        )
      }}
    />
  )
}

export default CheckboxFilter
