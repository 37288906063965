import { useState, CSSProperties, useEffect } from 'react'
import s from './CalendarView.scss'
import { Calendar, dateFnsLocalizer, DateLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import { CustomHeader } from './CustomHeader'
import cn from 'classnames'
import { makeGetRequest } from 'utils/api'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import { useLoading, CheckboxContainer } from 'simple-core-ui'
import { useDispatch } from 'react-redux'
import { Task } from './types'
import { toTasks } from './serializers'
import { AiFillCheckCircle } from 'react-icons/ai'
import { TaskModal } from './TaskModal'
import { DayPicker } from 'react-day-picker8'

const locales = {
  'en-US': enUS
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
  dayFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
    localizer.format(date, 'd', culture)
})

const addIcon = (task: Task) => {
  return (
    <>
      <AiFillCheckCircle className={s.eventIcon} /> {task.title}
    </>
  )
}

const CustomWeekHeader = ({
  date,
  weekdayStyles,
  dayStyles,
  styles = {}
}: {
  date: Date
  weekdayStyles?: CSSProperties
  dayStyles?: CSSProperties
  styles?: CSSProperties
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...styles }}>
      <span className={cn('weekday', s.weekday)} style={weekdayStyles}>
        {format(date, 'E').toUpperCase()}
      </span>
      <span className={cn('day', s.day)} style={dayStyles}>
        {format(date, 'dd')}
      </span>
    </div>
  )
}

interface Props {
  context: 'workbench' | 'matter'
  baseUrl: string
  changeTab?: (tab: string) => void
}

const DATE_FORMAT = 'yyyy-MM-dd'

const CalendarView = ({ context, baseUrl, changeTab }: Props) => {
  const [date, setDate] = useState(new Date())
  const [currentView, setCurrentView] = useState('month')
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [tasks, setTasks] = useState<Task[]>([])
  const [selectedTask, setSelectedTask] = useState<Task | null>(null)
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false)
  const [tasksEventsSelected, setTasksEventsSelected] = useState(true)

  const handleTaskClick = (task: Task) => {
    setSelectedTask(task)
    setIsTaskModalOpen(true)
  }

  const closeTaskModal = () => {
    setSelectedTask(null)
    setIsTaskModalOpen(false)
  }

  const fetchTasks = async () => {
    const startDate = startOfMonth(date)
    const endDate = endOfMonth(date)
    try {
      let endpoint = ''

      if (currentView === 'month') {
        endpoint = `${baseUrl}/tasks/?columnKey=due_date&isDesc=0&due_date=is_between:${format(
          startDate,
          DATE_FORMAT
        )},${format(endDate, DATE_FORMAT)}`
      } else if (currentView === 'week') {
        const firstDayOfWeek = startOfWeek(date)
        const lastDayOfWeek = endOfWeek(date)
        endpoint = `${baseUrl}/tasks/?columnKey=due_date&isDesc=0&due_date=is_between:${format(
          firstDayOfWeek,
          DATE_FORMAT
        )},${format(lastDayOfWeek, DATE_FORMAT)}`
      } else if (currentView === 'day') {
        endpoint = `${baseUrl}/tasks/?columnKey=due_date&isDesc=0&due_date=is:${format(
          date,
          DATE_FORMAT
        )}`
      }

      const { rows } = await withLoadingLocks(makeGetRequest(endpoint))

      const serializedTasks = toTasks(rows, (tasks: Task[]) => {
        if (context === 'workbench') {
          return tasks.map(t =>
            t?.relatedMatter?.status === 'closed' ? { ...t, canEdit: false } : t
          )
        }
        return tasks
      })
      setTasks(sortAlphabeticallyByProperty(serializedTasks, 'name'))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, currentView])

  const handleDaySelect = (date: Date) => {
    setDate(date)
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={cn(s.calendarWrapper, {
          [s.weekView]: currentView === 'week',
          [s.dayView]: currentView === 'day',
          [s.monthView]: currentView === 'month'
        })}
      >
        <Calendar
          localizer={localizer}
          views={['month', 'week', 'day']}
          components={{
            toolbar: props => (
              <CustomHeader
                {...props}
                date={date}
                onNavigate={setDate}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            ),
            week: {
              header: CustomWeekHeader
            },
            timeGutterHeader: () => {
              if (currentView !== 'day') return null
              return (
                <CustomWeekHeader
                  date={date}
                  styles={{ alignItems: 'center' }}
                  weekdayStyles={{ color: '#0957AE', marginBottom: -1, marginTop: 3 }}
                  dayStyles={{ color: '#0957AE' }}
                />
              )
            }
          }}
          date={date}
          onNavigate={setDate}
          startAccessor="start"
          endAccessor="end"
          events={tasksEventsSelected ? tasks : []}
          popup
          onSelectEvent={task => handleTaskClick(task)}
          // @ts-expect-error
          titleAccessor={task => addIcon(task)}
          allDayMaxRows={3}
          formats={{
            dateFormat: 'd',
            timeGutterFormat: (date, culture, localizer) =>
              localizer ? localizer.format(date, 'h a', culture) : ''
          }}
        />
      </div>
      <div className={s.dayPicker}>
        <DayPicker
          mode="single"
          defaultMonth={date}
          selected={date}
          onSelect={date => (date ? handleDaySelect(date) : null)}
          modifiersStyles={{
            selected: { background: '#E2F0FF', border: '2px solid #3C98FD', color: '#000000' }
          }}
        />
        <div className={s.calendars}>
          <p className={s.label}>Calendars</p>
          <CheckboxContainer
            className={cn(s.tasksCheckbox, tasksEventsSelected && s.selected)}
            size="md"
            isChecked={tasksEventsSelected}
            cb={() => setTasksEventsSelected(!tasksEventsSelected)}
          />
          <span style={{ fontSize: 16, fontWeight: 700, color: '#047857' }}>
            <AiFillCheckCircle className={s.eventIcon} /> Tasks
          </span>
        </div>
      </div>
      {isTaskModalOpen && (
        <TaskModal
          task={selectedTask}
          closeTaskModal={closeTaskModal}
          changeTab={changeTab}
          setTasks={setTasks}
        />
      )}
    </div>
  )
}

export default CalendarView
