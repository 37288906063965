import { useMemo } from 'react'
import s from './Tabs.scss'
import cn from 'classnames'
import { Badge } from 'simple-core-ui'

interface Props {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  upcomingEventsCount: number
  myEventsCount: number
  invitationsCount: number
  pastCount: number
  totalEventsCount: number
}

const Tabs = ({
  selectedTab,
  setSelectedTab,
  upcomingEventsCount,
  myEventsCount,
  invitationsCount,
  pastCount,
  totalEventsCount
}: Props) => {
  const tabs = useMemo(() => {
    return [
      {
        label: 'All',
        value: 'all',
        count: totalEventsCount
      },
      {
        label: 'Upcoming Events',
        value: 'upcoming',
        count: upcomingEventsCount
      },
      {
        label: 'My Events',
        value: 'mine',
        count: myEventsCount
      },
      {
        label: 'My Invitations',
        value: 'invitations',
        count: invitationsCount
      },
      {
        label: 'Past Events',
        value: 'past',
        count: pastCount
      }
    ]
  }, [myEventsCount, totalEventsCount, pastCount, invitationsCount, upcomingEventsCount])

  return (
    <ul className={cn(s.tabs, s.alignRight)}>
      {tabs.map(tab => (
        <li
          onClick={() => setSelectedTab(tab.value)}
          key={tab.value}
          data-testid={tab.value}
          className={`${s.tab} ${selectedTab === tab.value ? s.selected : ''}`}
        >
          {tab.count !== null ? (
            <Badge asPill className={s.pill} content={String(tab.count)} size="sm" />
          ) : null}
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
