import { Component } from 'react'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import {
  Button,
  DataTableContainer,
  DataTableSerializer,
  ButtonDropdown,
  DataTableService,
  ModalContainer
} from 'simple-core-ui'
import { connect } from 'react-redux'
import ACT from 'budgets/actions'
import APP_ACT from 'app/actions'
import { serializeBudgetRequestParams } from 'budgets/serializers'
import s from '../styles/BudgetsList.scss'
import find from 'lodash/find'
import get from 'lodash/get'
import cn from 'classnames'
import {
  budgetRequestColumns as columns,
  budgetRequestCategories as categories,
  getNormalizedCellContent
} from '../tableDefinitions'
import { makePostRequest, handleError, makeGetRequest } from 'utils/api'
import swal from 'sweetalert'

import { lastAction2Num } from 'budgets/utils'
import { uriEncodeObject } from 'utils/helpers'

const OVERDUE = 14
const WARNING = 8

const REMOTE_PAGINATION = true
const FILTER = false

@withFetchFlow({
  flag: 'BudgetRequestList',
  getFetchAction: () => ({
    type: ACT.BUDGET_REQUESTS_LIST_FETCH_REQUESTED,
    params: {
      pageSize: 10,
      ordering: { columnKey: 'startDate', isDesc: true },
      search: '',
      page: 1,
      category: 'all'
    }
  })
})
@connect(({ budgets }) => {
  const budgetRequests = budgets.list
  const rowsByCategory = {
    all: budgetRequests,
    withVendor: budgetRequests.filter(b => b.status === 'With Vendor'),
    approvalNeeded: budgetRequests.filter(b => b.status === 'Approval Needed')
  }
  const requestListParams = budgets.requestListParams
  const total = budgets.totalBudgetRequests
  const filteredTotal = budgets.filteredTotalBudgetRequests
  const isLoading = budgets.isLoading

  return {
    budgetRequests,
    rowsByCategory,
    requestListParams,
    total,
    filteredTotal,
    isLoading
  }
})
class BudgetRequestsListContainer extends Component {
  state = {
    params: {
      pageSize: 10,
      ordering: { columnKey: 'startDate', isDesc: true },
      search: '',
      page: 1,
      category: 'all'
    }
  }

  renderCell = ({ rowId, columnKey, content }) => {
    if (columnKey === 'lastAction') {
      const row = find(this.props.budgetRequests, b => b.id === rowId)
      const laNum = lastAction2Num(content)
      return (
        <span
          className={cn(s.lastAction, {
            [s.red]: laNum >= OVERDUE,
            [s.orange]: laNum >= WARNING && laNum < OVERDUE
          })}
        >
          {content}
        </span>
      )
    }

    return content
  }

  remind = async ({ value }) => {
    const sendReminders = await swal({
      title: 'Send Reminders?',
      text: `This will potentially send emails to many users.`,
      icon: 'warning',
      buttons: ['Cancel', 'Send Reminders']
    })
    const url = '/budgets/global_reminder/'
    const params = { users_to_notify: value }

    if (sendReminders) {
      this.setState({ isLoading: true })
      try {
        const response = await makePostRequest(url, params)
        this.props.dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            text: 'Reminders Send Successful',
            message: 'Successfully sent reminders.',
            level: 'success'
          }
        })
      } catch (e) {
        handleError(e, 'Could not send reminders.')
      }
      this.setState({ isLoading: false })
    }
  }

  updateTable = updatedParams => {
    this.props.dispatch({
      type: ACT.BUDGET_REQUESTS_LIST_FETCH_REQUESTED,
      params: updatedParams
    })
    this.setState({
      params: updatedParams
    })
  }

  clickRow = row => {
    window.location.href = `/budgets/${row.id}`
  }

  downloadXLSX = async openModal => {
    const url = '/budgets/requested/excel/download/'
    const { requestListParams } = this.props
    const baseParams = serializeBudgetRequestParams(requestListParams)
    try {
      const { count } = await makeGetRequest(url, {
        params: { ...baseParams, count_only: true }
      })
      // Used same approach as downloading Active Budgets.
      if (count < 50) {
        window.location.href = `${url}?${uriEncodeObject(baseParams)}`
      } else {
        await makeGetRequest(url, {
          params: { ...baseParams, generate_email: true }
        })
        openModal()
      }
    } catch (e) {
      this.props.dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          level: 'error',
          message: 'There was an issue computing the budget requests list on the server.'
        }
      })
    }
  }

  render() {
    const { rowsByCategory, requestListParams, total, filteredTotal } = this.props
    const { page, pageSize } = requestListParams

    const rows = DataTableSerializer.getRowsByCategory(rowsByCategory, columns)
    const budgetRequestsService = new DataTableService(
      rows,
      columns,
      getNormalizedCellContent,
      FILTER,
      REMOTE_PAGINATION
    )
    const results = budgetRequestsService.getUpdatedResults(requestListParams)
    const { getSurroundingPages } = DataTableService
    const { previousPage, nextPage } = getSurroundingPages(page, pageSize, filteredTotal)

    const OPTIONS = [
      { label: 'Remind Vendors', value: 'external' },
      { label: 'Remind Users', value: 'internal' },
      { label: 'Remind Both', value: 'all' }
    ]

    const actionButtons =
      get(window, 'credentials.user.role', '') === 'admin'
        ? [
            <ModalContainer
              key={0}
              title="We are working on your download"
              content="You’ll receive an email once your report is ready."
              confirmText="OK"
              size="sm"
              hasCloseIcon={false}
              confirmCb={() => null}
            >
              {openModal => (
                <Button isSecondary onClick={() => this.downloadXLSX(openModal)}>
                  Download XLSX
                </Button>
              )}
            </ModalContainer>,
            <ButtonDropdown
              key={1}
              displayText="Send Reminders"
              options={OPTIONS}
              onSelect={this.remind}
              isSecondary
              isLoading={this.state.isLoading}
            />
          ]
        : []

    return (
      <div>
        <DataTableContainer
          title="Budget Requests"
          actionButtons={actionButtons}
          initialValues={requestListParams}
          previousPage={previousPage}
          nextPage={nextPage}
          totalEntries={total}
          renderCell={this.renderCell}
          filteredTotal={filteredTotal}
          categories={categories}
          rows={results.rows}
          columns={results.columns}
          clickRow={this.clickRow}
          updateTable={this.updateTable}
          remotePagination
        />
      </div>
    )
  }
}
export default BudgetRequestsListContainer
