import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

type Props = {
  rowId: number
  editReason(id: number): void
}

const ActionsPopover = ({ rowId, editReason }: Props) => {
  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
        delayHide={1}
        isCapture
        event="click"
        globalEventOff="click"
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={() => {
              ReactTooltip.hide()
              editReason(rowId)
            }}
          >
            Edit
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
