import PropTypes from 'prop-types'
import s from './Empty.scss'

const Empty = ({ children }) => <figure className={s.container}>{children}</figure>

Empty.defaultProps = {
  children: null
}

Empty.propTypes = {
  children: PropTypes.node
}

export default Empty
