import { Fragment } from 'react'
import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

const ActionsPopover = ({
  rowId,
  deleteMatter,
  editMatterLead,
  editMatterGroup,
  canDelete,
  canEdit,
  matterGroupLabels
}) => {
  const handleClick = (event, action) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    ReactTooltip.hide()
    switch (action) {
      case 'EDIT_MATTER_LEAD':
        editMatterLead(rowId)
        return
      case 'EDIT_MATTER_GROUP':
        editMatterGroup(rowId)
        return
      case 'DELETE_MATTER':
        deleteMatter(rowId)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
      >
        <ul className={s.actionsList}>
          {canEdit && (
            <Fragment>
              <li
                className={s.actionItem}
                onClick={e => {
                  handleClick(e, 'EDIT_MATTER_LEAD')
                }}
              >
                Edit Matter Lead
              </li>
              <li
                className={s.actionItem}
                onClick={e => {
                  handleClick(e, 'EDIT_MATTER_GROUP')
                }}
              >
                {`Edit ${matterGroupLabels[0]}`}
              </li>
            </Fragment>
          )}
          {canDelete && (
            <li
              className={s.actionItem}
              onClick={e => {
                handleClick(e, 'DELETE_MATTER')
              }}
            >
              Delete
            </li>
          )}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
