import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { IoIosMore } from 'react-icons/io'

import { HoverAction } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  rowId: number
  cloneRule(): void
  deleteRule(): void
}

const popoverConfig = [
  { action: 'EDIT', label: 'Edit' },
  { action: 'CLONE', label: 'Clone' },
  { action: 'DELETE', label: 'Delete' }
]

const ActionsPopover = ({ rowId, deleteRule, cloneRule }: Props) => {
  const navigate = useNavigate()

  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'EDIT':
        navigate(`/v2/rules/simplereview/${rowId}`)
        return
      case 'CLONE':
        cloneRule()
        return
      case 'DELETE':
        deleteRule()
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-testid={`item-actions-${rowId}`}
        data-tip=""
      >
        <HoverAction icon={<IoIosMore />} size="medium" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
        className={s.tooltipContainer}
      >
        <ul className={s.actionsList}>
          {popoverConfig.map(({ action, label }) => (
            <li
              key={action}
              className={s.actionItem}
              onClick={e => {
                handleClick(e, action)
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
