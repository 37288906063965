import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react'
import { IoIosArrowBack, IoIosInformationCircle } from 'react-icons/io'
import shortid from 'shortid'
import { useImmer } from 'use-immer'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'

import { ER6_FIELDS } from 'rules/constants'
import { hasModule, sortAlphabeticallyByProperty } from 'utils/helpers'
import { Arg, AvailableField } from 'simple_review/@types/api'
import { Operand } from 'simple_review/@types/editor'
import AutoComplete from 'simple_review/common/AutoComplete'
import DropdownItem from 'simple_review/common/DropdownItem'
import { Constant } from 'simple_review/@types/common'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { Args } from './args'
import s from './Lhs.scss'
import { lhsHasCustomAttr } from 'simple_review/editor/iiw/serializers/rule-operand-lhs.serializer'

import { defaultVendorOptions } from './args/constants'

export const filterSearch = <T extends Record<string, unknown>>(
  options: Array<T>,
  key: keyof T,
  filter: string
): Array<T> => {
  if (!filter.length) return options
  return options.filter(option => {
    if (typeof option[key] !== 'string') return false
    return (option[key] as string).toLowerCase().includes(filter.toLowerCase())
  })
}

interface Props {
  value: Operand['lhs']
  onChangeLhs(newLhs: Operand['lhs'], keepRhs?: boolean): void
  operandId: string
  shouldShowInfo: boolean
  isReadOnly: boolean
}

export const Lhs = ({ onChangeLhs, shouldShowInfo, isReadOnly, operandId, ...props }: Props) => {
  const { state: contextState } = useSimpleReviewContext()

  const [state, setState] = useImmer({
    isOpen: false,
    search: ''
  })

  const [value, setValue] = useState(props.value)
  const [lhsPath, setLhsPath] = useState(props.value?.lhsPath ?? [])

  const closeBox = (newValue?: Operand['lhs']) => {
    setState(draft => {
      draft.isOpen = false
      draft.search = ''
    })

    setValue(newValue ?? props.value)
    setLhsPath(get(newValue ?? props.value, 'lhsPath', []))
  }

  const openBox = () => {
    setState(draft => {
      draft.isOpen = true
    })
  }

  const lastInLhsPath = lhsPath[lhsPath.length - 1] as AvailableField | undefined

  const getOptions = () => {
    if (!lastInLhsPath) return contextState.availableFields
    const options = [lastInLhsPath.fields || [], lastInLhsPath.list_custom_attrs || []]
      .flat()
      /** Koch ER6 filters */
      .filter(opt => {
        if (!('name' in opt)) return true
        if (ER6_FIELDS.includes(opt.name)) {
          return window.credentials.kochErIIWEnabled
        }
        if (opt.name === 'Age') {
          return hasModule('has_invoice_age_migration_to_ivr')
        }
        return true
      })

    return options as Array<AvailableField>
  }

  const filteredOptions = sortAlphabeticallyByProperty(
    filterSearch(getOptions(), 'display_name', state.search),
    'display_name'
  )

  const onSelect = (newLhsValue: AvailableField) => () => {
    const newLhs = cloneDeep(newLhsValue)
    if (newLhs.args) {
      newLhs.base_args = newLhsValue.args
      newLhs.args = []

      if (lhsHasCustomAttr(newLhs, newLhs)) {
        newLhs.isCustomAttrEnabled = true
      }
    }
    if (newLhs.fields) {
      setState(draft => {
        draft.search = ''
      })
      setLhsPath(prev => [...prev, newLhs])
    } else {
      if (lastInLhsPath) {
        newLhs.lhsPath = lhsPath
        if (lastInLhsPath.name === 'invoice_discount') {
          newLhs.rhsType = 'slider'
        }

        if (lhsHasCustomAttr(newLhs, lastInLhsPath)) {
          newLhs.isCustomAttrEnabled = true
        }
      }
      setValue(newLhs)
      onChangeLhs(newLhs)
      closeBox(newLhs)
    }
  }

  const getValue = () => {
    if (lhsPath.length > 0) {
      const path = lhsPath.map(field => field.display_name).join(' - ')
      if (value) {
        return `${path} - ${value.display_name}`
      }
      return path
    }
    return value?.display_name ?? ''
  }

  const onJumpBack = () => {
    setLhsPath(prev => prev.slice(0, -1))
    setValue(null)
    setState(draft => {
      draft.search = ''
    })
  }

  const onSearch = (path: string): ChangeEventHandler<HTMLInputElement> => e => {
    e.preventDefault()
    setState(draft => {
      set(draft, path, e.target.value)
    })
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key !== 'Backspace' || (!value && !lhsPath.length)) return
    e.preventDefault()
    onJumpBack()
  }

  const handleAddArg = (arg: Arg) => {
    const newLhs = cloneDeep(props.value)
    if (newLhs?.args) {
      newLhs.args.push(arg)
    }
    setValue(newLhs)
    onChangeLhs(newLhs, true)
  }

  const handleChangeArg = (
    argName: string,
    newValue: Constant | Constant[] | null,
    index: number
  ) => {
    const newLhs = cloneDeep(props.value)
    if (newLhs && newLhs.args) {
      // switch the arg to vendor and vendor group if user is switching
      if (argName === 'vendor_group' && newLhs.args[index].arg_name === 'vendor') {
        newLhs.args[index].arg_name = 'vendor_group'
        newLhs.args[index].sub_type = 'VendorGroup'
      } else if (argName === 'vendor' && newLhs.args[index].arg_name === 'vendor_group') {
        newLhs.args[index].arg_name = 'vendor'
        newLhs.args[index].sub_type = 'Vendor'
      }
      newLhs.args[index].constant = newValue

      if (Array.isArray(newValue) && !newValue.length) {
        newLhs.args[index].constant = defaultVendorOptions[0]
      }
    }

    setValue(newLhs)
    onChangeLhs(newLhs, true)
  }

  const handleDeleteArg = (argName: string) => {
    const newLhs = cloneDeep(props.value)
    if (newLhs && newLhs.args) {
      const index = newLhs.args.findIndex(arg => arg.arg_name === argName)
      newLhs.args.splice(index, 1)
    }
    setValue(newLhs)
    onChangeLhs(newLhs, true)
  }

  return (
    <div className={s.container} data-testid="lhs">
      {shouldShowInfo && (
        <a
          className={s.info}
          href="/help/?article_id=8906422767127"
          target="_blank"
          rel="noreferrer"
        >
          <IoIosInformationCircle size={16} />
          <span>How to set up condition values</span>
        </a>
      )}
      <AutoComplete
        isOpen={state.isOpen}
        isReadOnly={isReadOnly}
        openBox={openBox}
        closeBox={closeBox}
        disableSearch={isReadOnly}
        value={getValue()}
        onChange={onSearch('search')}
        onKeyDown={onKeyDown}
      >
        <>
          {(!!lastInLhsPath || value?.isFunction) && (
            <div className={s.parentCondition}>
              <div data-testid="go-back" className={s.goBack} onClick={onJumpBack}>
                <IoIosArrowBack />
                <span>
                  {lastInLhsPath ? get(lastInLhsPath, 'display_name') : value?.display_name}
                </span>
              </div>
              {lastInLhsPath && !lastInLhsPath?.isFunction ? (
                <input
                  className={s.subConditionSearch}
                  placeholder={`Enter ${get(lastInLhsPath, 'display_name')} Attribute`}
                  value={state.search}
                  onChange={onSearch('search')}
                />
              ) : null}
            </div>
          )}
          {!value?.args && (
            <ul className={s.options}>
              {filteredOptions.map(option => (
                <li key={shortid.generate()}>
                  <DropdownItem
                    label={option.display_name}
                    onSelect={onSelect(option)}
                    showArrow={!!option.fields}
                  />
                </li>
              ))}
            </ul>
          )}
        </>
      </AutoComplete>

      {value?.args && value?.base_args && (
        <Args
          baseArgs={value.base_args}
          args={value.args}
          id={`${operandId}_${value.name}`}
          lhsText={getValue()}
          onAddArg={handleAddArg}
          onChangeArg={handleChangeArg}
          onDeleteArg={handleDeleteArg}
        />
      )}
    </div>
  )
}
