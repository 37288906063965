import get from 'lodash/get'

import { IoIosClose } from 'react-icons/io'

import { Button, If } from 'simple-core-ui'

import ContactsSelect from '../ContactsSelect/ContactsSelect'
import RolesSelect from '../RolesSelect/RolesSelect'
import CounselGoWarning from '../CounselGoWarning/CounselGoWarning'
import SingularRoleWarning from '../SingularRoleWarning/SingularRoleWarning'

import s from './AssignContactsForm.scss'

const AssignContactsForm = ({
  contacts,
  selectedContact,
  selectedRoles,
  contactsChangeCb,
  rolesChangeCb,
  assignContactCb,
  toggleAssignFormCb,
  toggleContactQuickFormCb,
  singularRoleIntersect,
  warningMessageType
}) => (
  <section className={s.container}>
    <span className={s.newContactTitle}>Update Assigned Contacts</span>
    <section className={s.linkContainer}>
      <span
        className={s.newContact}
        data-testid="testid_create_new_contact"
        onClick={toggleContactQuickFormCb}
      >
        + Create New Contact
      </span>
    </section>
    <If condition={warningMessageType}>
      <CounselGoWarning warningMessageType={warningMessageType} />
    </If>
    <If condition={singularRoleIntersect.length}>
      {singularRoleIntersect.map(({ role, contact }, key) => (
        <SingularRoleWarning key={key} role={role} contact={contact} />
      ))}
    </If>
    <section className={s.form}>
      <section data-testid="contacts_select" className={s.contactSelect}>
        <ContactsSelect
          selectedRoles={selectedRoles}
          value={selectedContact}
          onChange={contactsChangeCb}
        />
      </section>
      <section data-testid="roles_select" className={s.roleSelect}>
        <RolesSelect
          id="testid_contacts_roles_select"
          selectedContact={selectedContact}
          value={selectedRoles}
          onChange={rolesChangeCb}
          multi
          isPortal
        />
      </section>
    </section>
    <section className={s.buttonGroup}>
      <Button
        id="testid_contacts_assign_button"
        isSecondary
        className={s.button}
        onClick={assignContactCb}
        isDisabled={!selectedContact || !selectedContact.id || !get(selectedRoles, 'length')}
        testid="assign_contact_btn"
      >
        Assign
      </Button>
    </section>
    <hr className={s.separator} />
    {!!contacts.length && <span className={s.title}>Currently Assigned Contacts</span>}
    <IoIosClose className={s.delete} onClick={toggleAssignFormCb} />
  </section>
)

export default AssignContactsForm
