import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { MouseEvent } from 'react'
import { Subtask, Task } from '../types'

interface Props {
  deleteSubtask: (index: number) => void
  duplicateTask?: (task: Task | Subtask, parentId?: number | string) => Promise<void>
  toggleDraft: (value: number) => void
  onEditSubtask?: (task: Subtask) => void
  id: string | number
  index: number
  subtask: Subtask | null
  parentId?: number | string
}

const ActionsPopover = ({
  id,
  deleteSubtask,
  index,
  toggleDraft,
  subtask,
  onEditSubtask,
  duplicateTask,
  parentId
}: Props) => {
  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'COPY':
        if (!duplicateTask || !subtask) return
        duplicateTask(subtask, parentId)
        return
      case 'DELETE':
        deleteSubtask(index)
        return
      case 'EDIT':
        if (subtask) {
          onEditSubtask?.(subtask)
        } else {
          toggleDraft(index)
        }
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-subtasks-${id}`}
        aria-describedby={`item-actions-subtasks-${id}`}
        aria-label={`item-${id}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-subtasks-${id}`}
      >
        <HoverAction hasNewDesign icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-subtasks-${id}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'COPY')
            }}
          >
            Copy
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EDIT')
            }}
          >
            Edit
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DELETE')
            }}
          >
            Delete
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
