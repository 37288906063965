import { connect } from 'react-redux'
import Select from 'react-select'

import { SPECIAL_ACCESS_ROLES } from 'utils/constants'
import { filterRoleOptions, getValuesWithEditable } from '../utils'

import s from './RolesSelect.scss'

const RolesSelect = ({
  id,
  selectedContact,
  roles,
  value,
  onChange,
  multi,
  userCanEditContactCG,
  isPortal
}) => {
  const editableValue = getValuesWithEditable(value, SPECIAL_ACCESS_ROLES, userCanEditContactCG)
  const options = filterRoleOptions(
    selectedContact,
    roles,
    value,
    SPECIAL_ACCESS_ROLES,
    userCanEditContactCG
  )

  return (
    <Select
      id={id}
      className={s.container}
      name="contact-role-select"
      placeholder="Select roles..."
      noOptionsMessage={() => 'No contact roles found...'}
      styles={{
        control: base => ({ ...base, maxHeight: 300 }),
        menu: base => ({ ...base, maxHeight: 300 }),
        ...(isPortal ? { menuPortal: base => ({ ...base, zIndex: 9999 }) } : {})
      }}
      isClearable={false}
      value={editableValue}
      onChange={value => onChange(value)}
      options={options}
      isMulti={multi}
      menuPortalTarget={isPortal ? document.body : null}
    />
  )
}

export default connect(({ contacts }) => {
  const { roles, rolesSettings } = contacts
  return {
    roles,
    userCanEditContactCG: rolesSettings.userCanEditContactCG
  }
})(RolesSelect)
