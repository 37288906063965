import { IoIosArrowBack } from 'react-icons/io'

import TYPES from './types'

import s from './ComboBox.scss'

const Header = ({ heading, navigateBackCb }) => (
  <header className={s.header} {...(navigateBackCb ? { onClick: navigateBackCb } : {})}>
    {navigateBackCb && <IoIosArrowBack className={s.left} />}
    <span>{heading}</span>
  </header>
)

Header.propTypes = {
  heading: TYPES.heading.isRequired,
  navigateBackCb: TYPES.navigateBackCb
}

export default Header
