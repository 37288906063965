import { useState, useEffect, useMemo } from 'react'
import s from '../../styles/HomeDashboard.scss'
import { DashboardSkeleton } from 'common'
import { useLoading, DataTableWrapper, Ellipsis, Panel } from 'simple-core-ui'
import { toTask } from './serializers'
import { Task, Cell, APITask } from './types'
import { Tabs } from './Tabs'
import { hex2rgba } from 'utils/helpers'
import moment from 'moment'
import Expand from '../../components/Expand'
import cn from 'classnames'
import { makeGetRequest } from 'utils/api'
import { useDispatch } from 'react-redux'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 10,
    ordering: { columnKey: 'dueDate', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const loggedInUserID = +window?.credentials?.user?.id
const COMPLETED_STATUS_ID = 3

const Tasks = () => {
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [selectedTab, setSelectedTab] = useState('mine')
  const dispatch = useDispatch()
  const [isLoading, withLoadingLocks] = useLoading()
  const [myFollowingCount, setMyFollowingCount] = useState(0)
  const [myRequestsCount, setMyRequestsCount] = useState(0)
  const [myTasksCount, setMyTasksCount] = useState(0)
  const [url, setUrl] = useState(
    `/task-management/tasks/?columnKey=due_date&isDesc=false&page_number=${params.page}&page_size=${params.pageSize}&assignees=is:${loggedInUserID}`
  )

  const generateUrl = (selectedTab: string, tableParams?: Params) => {
    const taskTabMap = {
      mine: 'assignees',
      requests: 'created_by',
      watching: 'followers'
    }

    return `/task-management/tasks/?columnKey=due_date&isDesc=${Number(
      (tableParams || params).ordering.isDesc
    )}&page_number=${(tableParams || params).page}&page_size=${(tableParams || params).pageSize}&${
      taskTabMap[selectedTab as keyof typeof taskTabMap]
    }=is:${loggedInUserID}`
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Task Name',
        isSortable: false,
        isFilterable: true,
        render: (cell: Cell, row: Task) => {
          return (
            <a
              style={{ cursor: 'pointer' }}
              href={`/v2/matters/${row?.relatedTo?.id}?id=${row?.id}&tab=tasks&subtab=${selectedTab}`}
            >
              <Ellipsis width={190}>{typeof cell.content === 'string' && cell.content}</Ellipsis>
            </a>
          )
        }
      },
      {
        columnKey: 'relatedTo',
        content: 'Related to',
        isSortable: false,
        isFilterable: true,
        render: (cell: Cell, row: Task) => {
          return (
            <a style={{ cursor: 'pointer' }} href={`/v2/matters/${row?.relatedTo?.id}`}>
              <Ellipsis width={160} lines={1}>
                {typeof cell.content === 'object' && cell.content.name}
              </Ellipsis>
            </a>
          )
        }
      },
      {
        columnKey: 'status',
        content: 'Status',
        isSortable: false,
        isFilterable: true,
        render: (cell: Cell, row: Task) => {
          if (cell.content === '----') {
            return '--'
          }
          const color = row.status?.color
          return (
            <div
              className={s.level}
              style={{
                backgroundColor: color && hex2rgba(color, 0.15),
                border: `1px solid ${color}`
              }}
            >
              {typeof cell.content === 'object' && cell.content.name}
            </div>
          )
        }
      },
      {
        columnKey: 'dueDate',
        content: 'Due',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell) => {
          const content = cell.content as string
          return (
            <span style={moment(content).isBefore(new Date()) ? { color: '#bb342f' } : {}}>
              {content === '----'
                ? '--'
                : moment(content).format(
                    moment(content).isSame(new Date(), 'year') ? 'MMM DD' : 'MMM DD YYYY'
                  )}
            </span>
          )
        }
      }
    ]
  }, [selectedTab])

  const fetchTasksCounts = async () => {
    try {
      const { myTasksCount, myRequestsCount, myFollowingCount } = await withLoadingLocks(
        makeGetRequest(`/task-management/tasks/counts?status=is_not:${COMPLETED_STATUS_ID}`)
      )
      setMyTasksCount(myTasksCount)
      setMyRequestsCount(myRequestsCount)
      setMyFollowingCount(myFollowingCount)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchTasksCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })

    setUrl(generateUrl(selectedTab, params))
  }

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
    setUrl(generateUrl(tab, initialState.params))
    setLocalState(initialState)
  }

  const getTasksTable = (expanded?: boolean) => {
    return (
      <>
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={changeTab}
          myTasksCount={myTasksCount}
          myRequestsCount={myRequestsCount}
          myFollowingCount={myFollowingCount}
        />
        <DataTableWrapper
          url={url}
          serializer={(tasks: APITask[]) => {
            return tasks.filter(t => t.status?.phase?.description !== 'Complete').map(toTask)
          }}
          params={params}
          categories={[]}
          columns={columns}
          updateTable={updateTable}
          panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
          className={cn(s.itemsTable, { [s.expanded]: expanded })}
          categoryKey="task"
          tableHeight="320px"
          fixedHeader
          hasInfinityScroll
          remotePagination
        />
      </>
    )
  }

  if (isLoading) {
    return <DashboardSkeleton />
  }
  return (
    <Panel
      className="dashboard-panel"
      title="Tasks"
      styles={{ height: 450 }}
      bodyClassName={s.tasksPanel}
      rightActions={[
        <Expand key={0} title="Tasks">
          {getTasksTable(true)}
        </Expand>
      ]}
    >
      {getTasksTable()}
    </Panel>
  )
}

export default Tasks
