import { useEffect, useState, useMemo, CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as ACT from 'matters/templates/actions'
import { Panel, Button, Table, FolderIconFill } from 'simple-core-ui'
import s from './DefaultFolderStructureContainer.scss'
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from 'react-icons/io'
import { FolderModal } from './FolderModal'
import { MoveFolderModal } from './MoveFolderModal'
import { ActionsPopover } from './ActionsPopover'
import { Breadcrumbs } from 'matters/detail/files/MatterAttachmentsTab/Breadcrumbs'
import swal from 'sweetalert'
import { isBasicTaskManagementPlan } from 'utils/helpers'
import ReactTooltip from 'react-tooltip'
import { cleanFolderName } from './serializers'
import {
  findNodeById,
  checkDuplicateFolderName,
  extractNodesAtSameDepth,
  toggleRowCb,
  createPathArray,
  renderTableRows,
  collapseChildRows
} from './helpers'
import { Folder, Row, FolderPath, State } from './types'
import shortid from 'shortid'
import { cloneDeep } from 'lodash'
interface TableCell {
  style: CSSProperties
  content: string | number
}

const DEFAULT_FOLDER_PATH: FolderPath = [{ title: 'Folders', className: s.breadcrumbItem }]
const DEFAULT_CURRENT_FOLDER: Folder = {
  id: '',
  name: ''
}

const DefaultFolderStructureContainer = ({
  readOnly = false,
  templateId
}: {
  readOnly: boolean
  templateId: number
}) => {
  const [rowList, setRowList] = useState<Row[]>([])
  const [isDuplicateFolder, setIsDuplicateFolder] = useState(false)
  const [folder, setFolder] = useState(DEFAULT_CURRENT_FOLDER)
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false)
  const [foldersPath, setFoldersPath] = useState<FolderPath>(DEFAULT_FOLDER_PATH)
  const { rootNode, currentFolder } = useSelector((state: State) => state.templateFolders)
  const [nodesAtSameDepth, setNodesAtSameDepth] = useState<string[]>([])
  const [expandedNodeIds, setExpandedNodeIds] = useState<Row['id'][]>([])
  const [isEditMode, setIsEditMode] = useState(false)
  const [isMoveFolderModalVisible, setMoveFolderModalVisible] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: ACT.DEFAULT_FOLDERS_FETCH_REQUESTED,
        payload: { templateId }
      })
    }
    return () => {
      dispatch({
        type: ACT.RESET_DEFAULT_FOLDERS
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFoldersPath(DEFAULT_FOLDER_PATH)
  }, [templateId])

  useEffect(() => {
    const currentTree = findNodeById(rootNode, currentFolder.id)
    const rows = renderTableRows(currentTree, expandedNodeIds, readOnly)
    setRowList(rows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootNode, currentFolder])

  const updateExpandedNodes = (rowId: Row['id']) => {
    setExpandedNodeIds(prevIds =>
      prevIds.includes(rowId) ? prevIds.filter(id => id !== rowId) : [...prevIds, rowId]
    )
  }

  const openMoveFolderModal = (id: Row['id'], name: string) => {
    setFolder({ id, name })
    setMoveFolderModalVisible(true)
  }

  const closeMoveFolderModal = () => {
    setMoveFolderModalVisible(false)
    setFolder(DEFAULT_CURRENT_FOLDER)
  }

  const collapseChildrenOnMove = () => {
    const copiedRows = cloneDeep(rowList)
    collapseChildRows(copiedRows, folder.id, updateExpandedNodes)
    setRowList(copiedRows)
  }

  const updateFoldersPath = (clickedRowId: Row['id']) => {
    const lastElementId =
      foldersPath.length === 1 ? rootNode.id : foldersPath[foldersPath.length - 1].id
    const updatedPath = createPathArray(
      lastElementId as number,
      clickedRowId,
      rowList,
      [],
      s.breadcrumbItem
    )
    setFoldersPath(prevPath => [...prevPath, ...updatedPath.reverse()])
  }

  const clickFolderCb = ({ id, name }: { id: Row['id']; name: string }) => {
    updateFoldersPath(id)
    setExpandedNodeIds([])
    dispatch({
      type: ACT.UPDATE_CURRENT_FOLDER,
      payload: {
        currentRow: { id, name }
      }
    })
  }

  const columns = [
    {
      columnKey: 'name',
      content: 'Name',
      style: { paddingLeft: '3rem' },
      render: (cell: TableCell, row: Row) => {
        return (
          <div style={cell.style}>
            {row.hasOwnProperty('expanded') ? (
              <div
                className={s.toggleIcon}
                {...(!readOnly
                  ? {
                      onClick: () =>
                        toggleRowCb(row.id, !row.expanded, rowList, setRowList, updateExpandedNodes)
                    }
                  : {})}
              >
                {row.expanded ? <IoIosRemoveCircleOutline /> : <IoIosAddCircleOutline />}
              </div>
            ) : (
              <div className={s.toggleIcon} />
            )}
            <div
              className={s.clickableFolder}
              style={{ cursor: readOnly ? 'default' : 'pointer' }}
              {...(!readOnly ? { onClick: () => clickFolderCb(row) } : {})}
            >
              <FolderIconFill className={s.folderIcon} />
              <div className={s.folderName}>{cell.content}</div>
            </div>
          </div>
        )
      }
    },
    {
      columnKey: 'subfolders',
      content: 'Subfolders',
      render: (cell: TableCell) => cell.content || '--'
    }
  ]

  const breadcrumbItems = useMemo(() => {
    if (foldersPath.length === 1) {
      return foldersPath
    } else {
      const pathItems: FolderPath = [
        {
          ...foldersPath[0],
          clickCb: () => {
            setFoldersPath(DEFAULT_FOLDER_PATH)
            setExpandedNodeIds([])
            dispatch({
              type: ACT.UPDATE_CURRENT_FOLDER,
              payload: {
                currentRow: { id: rootNode.id, name: '' }
              }
            })
          }
        }
      ]
      foldersPath.slice(1).forEach((item, idx) => {
        if (idx === foldersPath.length - 2) {
          pathItems.push(item as FolderPath[number])
        } else {
          pathItems.push({
            ...item,
            clickCb: () => {
              const newArr: FolderPath = foldersPath.slice(0, idx + 2)
              setFoldersPath(newArr)
              setExpandedNodeIds([])
              dispatch({
                type: ACT.UPDATE_CURRENT_FOLDER,
                payload: {
                  currentRow: { id: item.id, name: item.title }
                }
              })
            }
          })
        }
      })
      return pathItems
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foldersPath])

  const closeFolderModal = () => {
    setIsFolderModalVisible(false)
    setIsDuplicateFolder(false)
    setFolder(DEFAULT_CURRENT_FOLDER)
    setIsEditMode(false)
  }

  const createFolderCb = () => {
    const cleanedName = cleanFolderName(folder.name)
    const newFolder = { id: shortid.generate(), name: cleanedName }
    dispatch({
      type: ACT.UPDATE_DEFAULT_FOLDERS,
      payload: {
        newFolder,
        parentId: currentFolder.id
      }
    })
    closeFolderModal()
  }

  const updateFolderName = (name: string) => {
    setFolder(prevFolder => ({ ...prevFolder, name }))
    const isDuplicate = checkDuplicateFolderName(nodesAtSameDepth, name)
    setIsDuplicateFolder(isDuplicate)
  }

  const openFolderModal = (id?: Row['id'], name?: string, rowDepth = -1) => {
    const allNodeNames = extractNodesAtSameDepth(rowList, rowDepth)
    if (id && name) {
      setIsEditMode(true)
      setFolder({ id, name: name || '' })
      const idx = allNodeNames.indexOf(name.toLowerCase())
      allNodeNames.splice(idx, 1)
    }
    setNodesAtSameDepth(allNodeNames)
    setIsFolderModalVisible(true)
  }

  const editFolderCb = () => {
    const cleanedName = cleanFolderName(folder.name)
    dispatch({
      type: ACT.RENAME_FOLDER_NAME,
      payload: { folder: { id: folder.id, name: cleanedName } }
    })
    closeFolderModal()
  }

  const deleteFolderCb = async (id: Row['id'], name: string) => {
    const willDelete = await swal({
      title: `Are you sure you want to delete ${name}?`,
      buttons: ['No', 'Yes'],
      icon: 'warning'
    })
    if (willDelete) {
      dispatch({
        type: ACT.DELETE_FOLDER_NAME,
        payload: { id }
      })
      swal('Deleted!', 'The folder has been deleted', 'success')
    }
  }

  const renderCustomAction = (row: Row) => {
    return (
      <ActionsPopover
        rowId={row.id}
        folderName={row.name}
        rowDepth={row.depth}
        editFolder={openFolderModal}
        deleteFolder={deleteFolderCb}
        moveFolder={openMoveFolderModal}
      />
    )
  }

  return (
    <>
      <Panel
        className={s.panel}
        headerClassName={s.header}
        breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}
        {...(readOnly ? { styles: { backgroundColor: '#f5f5f5' } } : {})}
        rightActions={
          !readOnly
            ? [
                <>
                  <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                    <Button
                      key="folderBtn"
                      isSecondary
                      onClick={() => openFolderModal()}
                      isDisabled={isBasicTaskManagementPlan()}
                    >
                      + Folder
                    </Button>
                  </div>
                  <ReactTooltip
                    id="buttonTooltip"
                    type="light"
                    effect="solid"
                    place="left"
                    border
                    disable={!isBasicTaskManagementPlan()}
                    className={s.tooltipPopup}
                  >
                    Contact customer success to upgrade.
                  </ReactTooltip>
                </>
              ]
            : []
        }
      >
        {rowList.length ? (
          <div {...(readOnly ? { style: { opacity: '0.3' } } : {})}>
            {/* @ts-expect-error */}
            <Table
              rows={rowList}
              striped={!readOnly}
              noHover={readOnly}
              columns={columns}
              hasActions={!readOnly}
              alwaysShowActions
              customAction={renderCustomAction}
              customActionsMemoizationDeps={[rowList]}
            />
          </div>
        ) : null}
      </Panel>
      {isFolderModalVisible && (
        <FolderModal
          folderName={folder.name}
          saveFolder={isEditMode ? editFolderCb : createFolderCb}
          closeModal={closeFolderModal}
          isDuplicate={isDuplicateFolder}
          isEditMode={isEditMode}
          updateFolderName={updateFolderName}
        />
      )}
      {isMoveFolderModalVisible && (
        <MoveFolderModal
          rootNode={rootNode}
          expandedNodeIds={expandedNodeIds}
          selectedFolder={folder}
          closeModalCb={closeMoveFolderModal}
          collapseChildrenOnMove={collapseChildrenOnMove}
        />
      )}
    </>
  )
}

export default DefaultFolderStructureContainer
