import format from 'date-fns/format'
import parse from 'date-fns/parse'

export const generateTimeOptions = () => {
  const timeOptions = []

  for (let hour = 0; hour <= 11; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = String(hour === 0 ? 12 : hour).padStart(2, '0')
      const formattedMinute = String(minute).padStart(2, '0')
      timeOptions.push(`${formattedHour}:${formattedMinute}`)
    }
  }

  return timeOptions
}

export const toOptions = (options: string[]) => {
  return options.map(option => ({
    label: option,
    value: option
  }))
}

export const convertTo12HourFormat = (time: string): string[] => {
  if (!time) return ['', '']
  return format(new Date(`1970-01-01T${time}`), 'hh:mm a').split(' ')
}

export const convertTo24HourFormat = (time: string, amPm: string): string => {
  const date = parse(time + ' ' + amPm, 'h:mm a', new Date())
  return format(date, 'HH:mm')
}

export const setDefaultTime = (time: string | null, isEndTime = false) => {
  const now = new Date()
  now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15) // Round up to the nearest 15 minutes
  if (isEndTime) now.setMinutes(now.getMinutes() + 15) // Add 15 minutes to the current time if it's the end time

  return time?.split(' ')[0] || format(now, 'hh:mm')
}
