import { ModalContainer, Alert } from 'simple-core-ui'
import { CharLimitInput } from 'components'
import s from './ReasonModal.scss'
import { IoWarningOutline } from 'react-icons/io5'
import cn from 'classnames'

type Props = {
  mode: string
  inputValue: string
  title: string
  subtitle: string
  onChangeCb(): void
  onCancelCb(): void
  updateInputValue(value: string): void
  showDuplicateError: boolean
  inputPlaceholder: string
  duplicateErrMsg: string
}

const ReasonModal = ({
  mode,
  inputValue,
  title,
  subtitle,
  updateInputValue,
  showDuplicateError,
  onChangeCb,
  onCancelCb,
  inputPlaceholder,
  duplicateErrMsg
}: Props) => {
  return (
    <ModalContainer
      title={title}
      size="sm"
      confirmText="Save"
      cancelText="Cancel"
      confirmCb={onChangeCb}
      cancelCb={onCancelCb}
      isDisabled={showDuplicateError || !inputValue?.trim()}
      content={
        <div>
          {mode === 'adjustment' && (
            <div className={s.warningLine}>
              <IoWarningOutline />
              <p>
                Editing the description will apply to any previously adjusted invoices that have
                used the same description.
              </p>
            </div>
          )}
          {showDuplicateError && (
            <Alert
              className={cn(s.alert, { [s.adjustmentAlert]: mode === 'adjustment' })}
              message={duplicateErrMsg}
              status="error"
            />
          )}
          <div className={s.subtitle}>{subtitle}</div>
          <CharLimitInput
            value={inputValue || ''}
            onChangeCb={e => updateInputValue(e.target.value)}
            placeholder={inputPlaceholder}
            dynamicCharCalculation
          />
        </div>
      }
    />
  )
}

export default ReasonModal
