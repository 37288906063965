import { Task } from '../types'
import { ModalContainer } from 'simple-core-ui'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import s from './TaskModal.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { makePatchRequest } from 'utils/api'
import { fromTask } from 'common/Tasks/serializers'
import { Task as ParentTask } from 'common/Tasks/types'
import { toTasks } from '../serializers'
import { Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'

const COMPLETED_STATUS_ID = 3

interface Props {
  closeTaskModal: () => void
  task: Task | null
  changeTab?: (tab: string) => void
  setTasks: Dispatch<SetStateAction<Task[]>>
}

const TaskModal = ({ closeTaskModal, task, changeTab, setTasks }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleEditClick = (task: Task) => {
    const queryParams = queryString.parse(location.search)
    const newSearch = queryString.stringify({ ...queryParams, id: task.id })

    if (changeTab) {
      navigate({ search: newSearch })
      changeTab('list')
    } else {
      window.location.href = `/v2/matters/${task?.relatedMatter?.value}/?tab=tasks&${newSearch}`
    }
  }

  const handleRelatedClick = () => {
    if (task?.relatedMatter) {
      window.location.href = `/v2/matters/${task.relatedMatter.value}`
    }
  }

  const markCompleted = async () => {
    if (!task?.relatedMatter?.value || !task?.id) return

    try {
      const response = await makePatchRequest(
        `/task-management/matters/${task?.relatedMatter?.value}/tasks/${task?.id}/`,
        fromTask(
          ({ status: { id: COMPLETED_STATUS_ID } } as unknown) as ParentTask,
          String(task?.relatedMatter?.value),
          true
        )
      )

      setTasks(prevTasks => {
        const editedTask = toTasks([response])[0]
        const updatedTasks = prevTasks.map(task => (task.id === editedTask.id ? editedTask : task))
        return updatedTasks
      })

      closeTaskModal()

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: 'Task marked as completed',
          level: 'success'
        }
      })
    } catch (error) {
      closeTaskModal()
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <ModalContainer
      title={
        <a style={{ cursor: 'pointer' }} onClick={() => (task ? handleEditClick(task) : null)}>
          {task?.title}
        </a>
      }
      titleStyles={{ fontSize: 16, fontWeight: 700, color: '#0957AE', wordWrap: 'break-word' }}
      subtitle={
        <span style={{ color: '#767676', fontSize: 14, fontWeight: 400 }}>
          {task && task.start
            ? format(parse(task.start, 'MM/dd/yyyy', new Date()), 'EEEE, MMMM d')
            : ''}
        </span>
      }
      hideButtons
      size="xs"
      cancelCb={closeTaskModal}
      content={
        <>
          <p className={s.row}>
            <span className={s.label}>Priority:</span>
            <span>
              {task?.priority ? (
                <>
                  <span className={s.circle} style={{ background: task.priority.color }}></span>
                  <span className={s.value}>{task.priority.name}</span>
                </>
              ) : (
                '-'
              )}
            </span>
          </p>
          <p className={s.row}>
            <span className={s.label}>Related to:</span>
            <span
              onClick={handleRelatedClick}
              className={s.value}
              style={{ color: '#0957AE', cursor: 'pointer' }}
            >
              {task?.relatedMatter?.label ?? '-'}
            </span>
          </p>
          <p className={s.row}>
            <span className={s.label}>Status:</span>
            <span>
              {task?.status ? (
                <>
                  <span className={s.circle} style={{ background: task.status.color }}></span>
                  <span className={s.value}>{task.status.name}</span>
                </>
              ) : (
                '-'
              )}
            </span>
          </p>
          <p className={s.row}>
            <span className={s.label}>Type:</span>
            <span className={s.value}>{task?.taskType?.name ?? '-'}</span>
          </p>
          <div className={s.footer}>
            <div onClick={() => (task ? handleEditClick(task) : null)} className={s.button}>
              Edit
            </div>
            {task?.status?.phase !== 'Complete' && (
              <div
                style={{ borderLeft: '1px solid #ccc' }}
                className={s.button}
                onClick={markCompleted}
              >
                Mark completed
              </div>
            )}
          </div>
        </>
      }
      wrapperStyles={{ borderTop: '4px solid #10B981' }}
    />
  )
}

export default TaskModal
