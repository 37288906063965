import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createSelector } from 'reselect'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { TEMPLATE_STATUSES } from 'matters/templates/constants'
import ACT from 'matters/requestForms/detail/actions'
import * as CONST from 'matters/requestForms/constants'
import { RequestFormContent } from './RequestFormContent'

const requestFormSelector = state => state.requestForm
const extendedFormSelector = createSelector([requestFormSelector], requestForm => {
  const formCopy = cloneDeep(requestForm)
  const { items, fieldTypes, attributes } = formCopy
  items.forEach(item => {
    if (item.fieldType !== 'relationship') {
      item.filteredFieldTypes = fieldTypes.filter(fieldType =>
        fieldType.value.includes(item.fieldType)
      )
    }
    const attribute = attributes.find(attr => attr.value === item.model)
    item.inactiveOptions = item.filterOptions.length ? get(attribute, 'inactiveOptions', []) : []
    item.allOptions = get(attribute, 'options', [])
    item.filterOptions = get(item, 'filterOptions', [])
    item.filteredOptions = item.filterOptions.length
      ? item.allOptions.filter(opt => item.filterOptions.includes(opt.value.toString()))
      : item.allOptions
  })
  return formCopy
})

const templateCountSelector = state =>
  state.matterTemplates.filter(template => template.status === TEMPLATE_STATUSES.PUBLISHED).length

@withRouter
@connect(
  ({ requestForm, matterTemplatesList }) => {
    return {
      form: { ...extendedFormSelector({ requestForm }) },
      templateCount: templateCountSelector(matterTemplatesList)
    }
  },
  null,
  null,
  { forwardRef: true }
)
class RequestFormContentContainer extends Component {
  state = {
    formId: this.props.router.params.id
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      formId: nextProps.router.params.id
    }
  }
  addNewItem = () => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_FORM_ADD_NEW_ITEM
    })
  }

  dispatchSaveForm = (form, redirect) => {
    const hasValidFields = !form.items.find(item => {
      if (item.fieldType !== 'relationship') {
        return !item.label
      } else {
        if (!item.attributes.length) return true
        const validAttributesArr = item.attributes.filter(attr => attr.label)
        return !(validAttributesArr.length === item.attributes.length)
      }
    })

    const hasValidAttributes = !form.items.find(item => !item.isValid)

    const hasRequiredInvisible = form.items.find(item => !item.fieldVisible && item.required)

    if (hasRequiredInvisible) {
      return this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title:
            'You cannot make a field be both required and not visible on the Legal Request Submission Form.',
          level: 'error'
        }
      })
    }

    if (hasValidFields && hasValidAttributes) {
      const { location, navigate } = this.props.router
      this.props.dispatch({
        type: this.state.formId
          ? ACT.LEGAL_REQUEST_FORM_UPDATE_REQUESTED
          : ACT.LEGAL_REQUEST_FORM_CREATE_REQUESTED,
        payload: {
          form,
          id: this.state.formId,
          navigate,
          location,
          redirect: redirect
        }
      })
    }
    if (!hasValidFields) {
      this.props.dispatch({
        type: ACT.LEGAL_REQUEST_FORM_SET_INVALID_ITEMS
      })
      this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'You have empty label fields.',
          level: 'error'
        }
      })
    }
    if (!hasValidAttributes) {
      this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'You have invalid attributes chosen.',
          level: 'error'
        }
      })
    }
  }
  saveForm = form => {
    this.dispatchSaveForm(form)
  }
  saveAndPublishForm = form => {
    const status =
      form.status === CONST.FORM_STATUSES.PUBLISHED
        ? CONST.FORM_STATUSES.ARCHIVED
        : CONST.FORM_STATUSES.PUBLISHED
    const updatedForm = {
      ...form,
      status
    }

    this.dispatchSaveForm(updatedForm, true, true)
  }
  cancelChanges = () => {
    this.props.router.navigate('/v2/matters/requests/forms')
  }
  render() {
    const { form, navigateToSelectTemplates, templateCount, disabled } = this.props
    const displayRemove = form.items.length > 2
    const displayTemplatesSection = templateCount > 1

    return (
      <DndProvider backend={HTML5Backend}>
        <RequestFormContent
          form={form}
          displayRemove={displayRemove}
          addNewItem={this.addNewItem}
          saveAndPublishForm={this.saveAndPublishForm}
          saveForm={this.saveForm}
          cancelChanges={this.cancelChanges}
          navigateToSelectTemplates={navigateToSelectTemplates}
          displayTemplatesSection={displayTemplatesSection}
          disabled={disabled}
        />
      </DndProvider>
    )
  }
}

export default RequestFormContentContainer
