import { EventFilters, EventLike, Option } from './types'
import { OPERATORS } from './constants'
import format from 'date-fns/format'
import { utcTime, hasModule } from 'utils/helpers'
import qs from 'query-string'

const {
  IS_SET,
  IS_NOT_SET,
  WITHOUT_ATTACHMENTS,
  WITH_ATTACHMENTS,
  WITHOUT_COMMENTS,
  WITH_COMMENTS
} = OPERATORS

export const canEditPrivateEvent = <T extends EventLike>(event: T): boolean => {
  if (!event.isPrivate) return true

  const loggedInUser = window.credentials.user

  if (
    event.createdBy?.value === +loggedInUser.id ||
    event.attendees?.some(a => a.value === +loggedInUser.id) ||
    loggedInUser.role === 'admin'
  ) {
    return true
  }

  return false
}

export const canEditEvent = <T extends EventLike>(
  event: T,
  context: 'matter' | 'workbench'
): boolean => {
  if (
    context === 'workbench' &&
    (event?.relatedMatter?.status === 'closed' || !event?.relatedMatter?.canEdit)
  ) {
    return false
  }

  return canEditPrivateEvent(event)
}

export const createQueryParamsUrl = (eventFilters: EventFilters, withLabels?: boolean) => {
  const queryParams: string[] = []
  const eventFiltersMap = {
    eventType: 'event_type',
    attendees: 'attendees',
    id: 'event_names',
    createdBy: 'created_by',
    comments: 'comments',
    fileAttachments: 'attachments',
    date: 'event_date',
    time: 'event_time',
    createdDate: 'created_date',
    eventId: 'event_id',
    relatedMatter: 'matter_id'
  }

  const addQueryParam = (key: string, operator: string, values: string) => {
    const queryString = values
      ? `${eventFiltersMap[key as keyof EventFilters]}=${operator}:${values}`
      : `${eventFiltersMap[key as keyof EventFilters]}=${operator}`
    queryParams.push(queryString)
  }

  for (const key in eventFilters) {
    const filter = eventFilters[key as keyof EventFilters]
    if (filter) {
      const { operator, values } = filter
      if (['fileAttachments', 'comments'].includes(key)) {
        if (values?.length) {
          if ([WITH_ATTACHMENTS, WITH_COMMENTS].includes(String(values[0].value))) {
            addQueryParam(key, 'is_set', '')
          } else if ([WITHOUT_ATTACHMENTS, WITHOUT_COMMENTS].includes(String(values[0].value))) {
            addQueryParam(key, 'is_not_set', '')
          }
        }
      } else {
        if (values?.length) {
          const valuesString = values
            .map(val => {
              if (withLabels && !key.toLowerCase().includes('date')) {
                if (key === 'id') {
                  return `${encodeURIComponent(val.label)};${val.value}`
                }
                if (key === 'time') {
                  return `${encodeURIComponent(val.value)};${val.label}`
                }

                return `${val.value};${encodeURIComponent(val.label)}`
              }

              return key.toLowerCase().includes('date')
                ? format(new Date(val.value), 'yyyy-MM-dd')
                : key === 'id'
                ? encodeURIComponent(val.label)
                : key === 'time' && typeof val.value === 'string'
                ? encodeURIComponent(utcTime(val.value))
                : val.value
            })
            .join(',')
          addQueryParam(key, String(operator?.value).toLowerCase() ?? 'is_set', valuesString)
        } else {
          if (operator?.value === IS_SET) {
            addQueryParam(key, 'is_set', '')
          } else if (operator?.value === IS_NOT_SET) {
            addQueryParam(key, 'is_not_set', '')
          }
        }
      }
    }
  }

  return `${queryParams.join('&')}`
}

export const createFiltersObjectFromQueryString = (queryString: string, context: string) => {
  const filters: EventFilters = {
    eventType: null,
    attendees: null,
    id: null,
    createdBy: null,
    comments: null,
    fileAttachments: null,
    date: null,
    createdDate: null,
    eventId: null,
    time: null,
    ...(context === 'workbench' ? { relatedMatter: null } : {})
  }

  const parseQueryString = (queryString: string, k: string) => {
    const result: {
      operator: string | null
      values: { value: number | string; label: string }[]
    } = {
      operator: '',
      values: []
    }

    const [operator, pairsString] = queryString.split(':')

    const pairs = pairsString?.split(',') ?? []

    for (const pair of pairs) {
      const [valueNumber, label] = pair.split(';')

      if (k === 'id') {
        result.values.push({ value: label, label: decodeURIComponent(valueNumber) })
      } else if (k.toLowerCase().includes('date')) {
        result.values.push({ value: new Date(valueNumber).toString(), label: 'Date' })
      } else if (k.toLowerCase().includes('time')) {
        result.values.push({ value: decodeURIComponent(valueNumber).toString(), label: 'Time' })
      } else {
        result.values.push({
          value: valueNumber,
          label: decodeURIComponent(label)
        })
      }
    }

    return { ...result, operator: operator ? operator.toUpperCase() : operator }
  }

  const eventFiltersMap = {
    event_type: 'eventType',
    attendees: 'attendees',
    event_names: 'id',
    created_by: 'createdBy',
    comments: 'comments',
    attachments: 'fileAttachments',
    event_date: 'date',
    event_time: 'time',
    created_date: 'createdDate',
    event_id: 'eventId',
    matter_id: 'relatedMatter'
  }

  const operatorsList = [
    { value: 'IS_SET', label: 'Is set' },
    { value: 'IS_NOT_SET', label: 'Is not set' },
    { value: 'IS', label: 'Is' },
    { value: 'IS_NOT', label: 'Is not' },
    { value: 'IS_BEFORE', label: 'Is before' },
    { value: 'IS_AFTER', label: 'Is after' },
    { value: 'IS_BETWEEN', label: 'Is between' }
  ]

  const queryParams = queryString.split('&')

  for (const paramValue of queryParams) {
    const [param, value] = paramValue.split('=')

    const key = Object.entries(eventFiltersMap).find(([k, v]) => {
      return k === param
    })?.[1]

    if (key) {
      const k = key as keyof typeof filters

      if (value.toUpperCase() === 'IS_SET') {
        if (k === 'comments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITH_COMMENTS', label: 'With comments' }]
          }
        } else if (k === 'fileAttachments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITH_ATTACHMENTS', label: 'With attached files' }]
          }
        } else {
          filters[k] = { operator: { value: 'IS_SET', label: 'Is set' }, values: null }
        }
      } else if (value.toUpperCase() === 'IS_NOT_SET') {
        if (k === 'comments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITHOUT_COMMENTS', label: 'Without comments' }]
          }
        } else if (k === 'fileAttachments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITHOUT_ATTACHMENTS', label: 'Without attached files' }]
          }
        } else {
          filters[k] = { operator: { value: 'IS_NOT_SET', label: 'Is not set' }, values: null }
        }
      } else {
        const filter = parseQueryString(value, k)

        filters[k] = {
          operator: operatorsList.find(o => o.value === filter.operator) ?? null,
          values: filter.values
        }
      }
    }
  }

  return filters
}

export const updateUrlToUseUtcTime = (url: string) => {
  const match = /event_time=([^&]+)/.exec(url)

  if (!match) return url

  const eventTimeParam = match[1]

  const operator = eventTimeParam.split(':')[0]

  if (['is_set', 'is_not_set'].includes(operator)) return url

  const value = eventTimeParam.split(':')[1]

  let eventTime = ''

  if (operator === 'is_between') {
    const values = value.split(',')
    eventTime = `${operator}:${encodeURIComponent(
      utcTime(decodeURIComponent(values[0]))
    )},${encodeURIComponent(utcTime(decodeURIComponent(values[1])))}`
  } else {
    const time = value.split(',')[0]

    eventTime = `${operator}:${encodeURIComponent(utcTime(decodeURIComponent(time)))}`
  }

  const updatedUrl = url.replace(match[0], `event_time=${eventTime}`)

  return updatedUrl
}

export const isEventsCalendarSyncEnabled = () => {
  return hasModule('has_event_management') && window.credentials.externalCalendarSync === 'True'
}

export const isEventNotDeletable = <T extends EventLike>(event: T): boolean =>
  event.isPrivate && +window.credentials.user.id !== event.createdBy?.value

export const getInitParams = (context: string, hash: string) => {
  const parsedHash = qs.parse(hash)
  const eventFiltersMap = {
    event_type: 'eventType',
    name: 'name',
    start_date: 'startDate',
    event_id: 'eventId',
    matter_id: 'relatedMatter'
  }
  return {
    params: {
      page: parsedHash.page_number ? +parsedHash.page_number : 1,
      pageSize: parsedHash.page_size ? +parsedHash.page_size : context === 'workbench' ? 50 : 10,
      search: parsedHash.search && typeof parsedHash.search === 'string' ? parsedHash.search : '',
      ordering: {
        columnKey: parsedHash.columnKey
          ? eventFiltersMap[parsedHash.columnKey as keyof typeof eventFiltersMap]
          : 'startDate',
        isDesc: parsedHash.isDesc ? Boolean(+parsedHash.isDesc) : false
      },
      category:
        parsedHash.category && typeof parsedHash.category === 'string' ? parsedHash.category : 'all'
    }
  }
}

const minutesValues = [5, 10, 15, 20, 30, 45, 60, 90].map(value => ({
  value,
  label: value.toString()
}))

const hoursValues = Array.from({ length: 12 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString()
}))

const weeksValues = Array.from({ length: 4 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString()
}))

const daysValues = [1, 2, 3, 5, 7, 10, 15, 30].map(value => ({
  value,
  label: value.toString()
}))

export const getReminderValues = (reminderType: string): Option[] => {
  switch (reminderType) {
    case 'minutes':
      return minutesValues
    case 'hours':
      return hoursValues
    case 'days':
      return daysValues
    case 'weeks':
      return weeksValues
    default:
      return []
  }
}
