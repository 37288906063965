import { ModalContainer, Alert, ColorPicker } from 'simple-core-ui'
import { CharLimitInput } from 'components'
import s from './PriorityModal.scss'
import { Priority } from '../types'
import { LEVELS } from '../constants'

interface Props {
  togglePriorityModal: () => void
  showDuplicateError: boolean
  priority: Priority
  updatePriority: (value: string, property: string) => void
  savePriority: () => void
}

const PriorityModal = ({
  togglePriorityModal,
  showDuplicateError,
  priority,
  updatePriority,
  savePriority
}: Props) => {
  return (
    <ModalContainer
      title="Edit Priority"
      subtitle="Use priorities to indicate the urgency and importance of your tasks."
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={togglePriorityModal}
      confirmText="Save"
      confirmCb={savePriority}
      isDisabled={showDuplicateError || !priority.name.trim()}
      content={
        <div className={s.modalBody}>
          {showDuplicateError && (
            <Alert
              className={s.alert}
              message="The Priority name already exists. Please specify a different name."
              status="error"
            />
          )}
          <div className={s.priorityItem}>
            <div className={s.info}>Priority</div>
            <CharLimitInput
              value={priority.name}
              placeholder="Priority name"
              onChangeCb={e => updatePriority(e.target.value, 'name')}
              maxLength={25}
            />
          </div>
          <div className={s.priorityItem}>
            <div className={s.info}>Color</div>
            <ColorPicker
              initialColor={priority.color}
              onChange={(value: string) => updatePriority(value, 'color')}
            />
          </div>
          <div className={s.priorityItem}>
            <div className={s.info}>Level</div>
            <div className={s.readOnly}>{LEVELS[+priority.level]}</div>
          </div>
        </div>
      }
    />
  )
}

export default PriorityModal
