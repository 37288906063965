import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { MouseEvent } from 'react'
import { TaskType } from '../types'

interface Props {
  type: TaskType
  editType: (row: TaskType) => void
  deleteType: (row: TaskType) => void
  canDelete: boolean
}

const ActionsPopover = ({ type, deleteType, editType, canDelete }: Props) => {
  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'DELETE':
        deleteType(type)
        return
      case 'EDIT':
        editType(type)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${type.id}`}
        aria-describedby={`item-actions-${type.id}`}
        aria-label={`item-${type.id}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${type.id}`}
      >
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${type.id}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EDIT')
            }}
          >
            Edit
          </li>
          {canDelete && (
            <li
              className={s.actionItem}
              onClick={e => {
                handleClick(e, 'DELETE')
              }}
            >
              Delete
            </li>
          )}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
