import { If } from 'simple-core-ui'

import s from '../styles/ReviewLadder.scss'

const ReviewEntryMembership = ({ name, date }) => (
  <section className={s.info}>
    <span className={s.membership}>{name}</span>
    <If condition={date}>
      {' '}
      <small className={s.timestamp}>{date}</small>
    </If>
  </section>
)

export default ReviewEntryMembership
