import { Anchor, ButtonDropdown } from 'simple-core-ui'
import { FaAngleLeft } from 'react-icons/fa'
import s from './Header.scss'
import { ORG_ROLE } from 'utils/constants'

const Header = ({ vendorId, vendorName, downloadSurveyXlsx, downloadComparisonXlsx }) => (
  <>
    <Anchor style={{ border: 'none' }} url={`/invoices/vendors/${vendorId}/`}>
      <FaAngleLeft />
      Back to Vendor Page
    </Anchor>
    <div className={s.wrapper}>
      <h2 className={s.name}>{vendorName}</h2>
      {window.credentials?.user?.role === ORG_ROLE.ADMIN && (
        <div>
          <ButtonDropdown
            displayText="Download XLSX"
            onSelect={option => {
              if (option.value === 'survey') {
                downloadSurveyXlsx()
              } else if (option.value === 'comparison') {
                downloadComparisonXlsx()
              }
            }}
            options={[
              { label: 'Survey XLSX', value: 'survey' },
              { label: 'Comparison XLSX', value: 'comparison' }
            ]}
            isPrimary
            style={{
              height: 32,
              fontSize: 14
            }}
            alignRight
            listStyles={{ marginTop: 12 }}
          >
            Download XLSX
          </ButtonDropdown>
        </div>
      )}
    </div>
  </>
)

export default Header
