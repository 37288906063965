import { Tabs, Tab } from 'simple-core-ui'
import s from './AttachmentsTabContainer.scss'
import { MatterAttachmentsTab } from './MatterAttachmentsTab'

const AttachmentsTabContainer = ({ domProps }) => {
  return (
    <>
      <div className={s.marginClass}>
        <Tabs>
          <Tab header="Our Files">
            <div className={s.marginClass}>
              <MatterAttachmentsTab domProps={domProps} hasVendorCollaboration />
            </div>
          </Tab>
          <Tab header="Vendor Files">
            <p className={s.vendorFiles}>Coming Soon</p>
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export default AttachmentsTabContainer
