import { useEffect, useState } from 'react'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import Select, { components, OptionProps } from 'react-select'
import moment from 'moment'
import { ModalContainer, useLoading, Checkbox, Ellipsis, PaginatedSelect } from 'simple-core-ui'
import s from './AddTaskFromTemplateModal.scss'
import { toTemplates } from './serializers'
import { Template } from './types'
import { UsersCheckboxSelect, DatePickerSelect } from 'common/Selects'
import pluralize from 'pluralize'
import { Warnings } from './Warnings'
import ReactTooltip from 'react-tooltip'
import { toMattersOptions } from '../serializers'
import { MatterOption, APISimpleMatter } from '../types'
import cn from 'classnames'

interface Props {
  toggleAddTaskFromTemplateModal: () => void
  scopeId: string
  fetchTasks: () => void
  fetchTasksCounts: () => void
  context?: 'matter' | 'workbench'
}

interface Option {
  value: number
  label: string
}

interface Validation {
  hasInvalidAssignees: boolean
}

const initialValidation = {
  hasInvalidAssignees: false
}

const Option = (props: OptionProps<Template>) => {
  return (
    <components.Option {...props}>
      <div className={s.option}>
        <div className={s.header}>
          <div className={s.optionTitle}>
            <Ellipsis width={280} lines={1}>
              {props.data.label}
            </Ellipsis>
          </div>
          <div className={s.tasks}>
            {props.data.relatedTasks} {pluralize('task')}
          </div>
        </div>
        <div className={s.matterGroup}>
          <Ellipsis width={280} lines={1}>
            {props.data.matterGroup}
          </Ellipsis>
        </div>
        <div className={s.optionSubtitle}>
          <Ellipsis width={280} lines={3}>
            {props.data.description}
          </Ellipsis>
        </div>
      </div>
    </components.Option>
  )
}

const CustomMatterOption = (props: OptionProps<MatterOption>) => {
  return (
    <components.Option {...props}>
      <div className={s.option}>
        <div className={cn(s.header, s.displayBelow)}>
          <p className={s.optionTitle}>
            <Ellipsis width={280} lines={1}>
              {props.data.label}
            </Ellipsis>
          </p>
          <p className={s.clientMatterId}>{props.data.clientMatterId}</p>
        </div>
      </div>
    </components.Option>
  )
}

const AddTaskFromTemplateModal = ({
  toggleAddTaskFromTemplateModal,
  scopeId,
  fetchTasks,
  context,
  fetchTasksCounts
}: Props) => {
  const dispatch = useDispatch()
  const [, withLoadingLocks, resetLoadingLocks] = useLoading()
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)
  const [assignees, setAssignees] = useState<Option[]>([])
  const [shouldOverrideAssignees, setShouldOverrideAssignees] = useState(false)
  const [dueDate, setDueDate] = useState<Date | undefined>(undefined)
  const [validation, setValidation] = useState<Validation>(initialValidation)
  const [selectedMatter, setSelectedMatter] = useState<MatterOption | null>(null)
  const [_scopeId, setScopeId] = useState<string | undefined>(scopeId)

  const fetchTemplates = async (id?: string) => {
    try {
      const { rows: templates } = await withLoadingLocks(
        makeGetRequest(`/task-management/task-templates/?matter_id=${id ?? scopeId}`)
      )
      setTemplates(toTemplates(templates).filter(template => template.isActive))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    if (context === 'workbench') return

    fetchTemplates()

    return () => {
      resetLoadingLocks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkIfValid = async (templateId: number) => {
    try {
      const response = await withLoadingLocks(
        makeGetRequest(
          `/task-management/task-templates/${templateId}/validate-for-matter/${_scopeId}`
        )
      )
      setValidation(response)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const createTasks = async (cb?: () => void) => {
    try {
      await withLoadingLocks(
        makePostRequest(
          `/task-management/matters/${_scopeId}/tasks/create-from-template/${selectedTemplate?.value}/`,
          {
            assignee_ids: assignees.map(assignee => assignee.value),
            overwrite_assignees: shouldOverrideAssignees,
            assignment_date: moment(dueDate).endOf('day')
          }
        )
      )
      fetchTasks()
      fetchTasksCounts()
      cb?.()
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: 'Tasks successfully created',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <ModalContainer
      title="Select and assign a template"
      size="sm"
      hasNewButtons
      cancelText="Cancel"
      confirmText="Create Tasks"
      isDisabled={!dueDate || !selectedTemplate}
      contentStyle={{ padding: '10px 24px 0', minHeight: 0 }}
      content={
        <>
          {context === 'workbench' && (
            <>
              <div className={s.item} data-for="resetWarning" data-tip>
                <p className={s.label}>Related to*</p>
                {/* @ts-expect-error */}
                <PaginatedSelect
                  url="/manage/matters/v2/simple_matter_list/?can_edit=true"
                  value={selectedMatter}
                  onChange={(value: MatterOption) => {
                    setSelectedMatter(value)
                    fetchTemplates(String(value?.value))
                    setSelectedTemplate(null)
                    setScopeId(String(value?.value))
                    setAssignees([])
                    setValidation(initialValidation)
                  }}
                  isClearable={false}
                  placeholder="Select..."
                  className={s.select}
                  isPortal
                  comps={{ Option: CustomMatterOption }}
                  serializer={(options: APISimpleMatter[]) => toMattersOptions(options, true)}
                  resultsProperty="rows"
                  pageParam="page_number"
                  searchTermParam="search"
                  pageStart={0}
                  onMenuOpen={() => {
                    setTimeout(() => {
                      ReactTooltip.rebuild()
                    }, 0)
                  }}
                />
              </div>
              {selectedMatter && (
                <ReactTooltip id="resetWarning" type="light" effect="solid" place="bottom" border>
                  Changing what a task is Related to will clear the selected Template Task
                  Assignee(s)
                </ReactTooltip>
              )}
            </>
          )}
          <div className={s.item}>
            <p className={s.label}>Select a Template*</p>
            <Select
              className={s.select}
              options={templates}
              onChange={value => {
                setSelectedTemplate(value)
                value && checkIfValid(value.value)
              }}
              isMulti={false}
              value={selectedTemplate}
              components={{ Option }}
              isOptionDisabled={option => +option.relatedTasks === 0}
              onMenuOpen={() => {
                setTimeout(() => {
                  ReactTooltip.rebuild()
                }, 0)
              }}
              isDisabled={context === 'workbench' && !selectedMatter}
            />
            {validation.hasInvalidAssignees && (
              <Warnings hasInvalidAssignees={validation.hasInvalidAssignees} />
            )}
          </div>
          <div className={s.item}>
            <p className={s.label}>Template Task Assignees</p>
            <p className={s.subtitle}>
              Tasks that do not have an assignee listed on the task template will be assigned to:
            </p>
            <UsersCheckboxSelect
              value={assignees}
              requestParams={{
                active: true,
                ...(_scopeId ? { matterId: +_scopeId } : {}),
                canEdit: true
              }}
              updateSelectedUsers={values => {
                setAssignees(values)
              }}
              isDisabled={context === 'workbench' && !selectedMatter}
            />
            <Checkbox
              triggerCheckbox={() => setShouldOverrideAssignees(!shouldOverrideAssignees)}
              isChecked={shouldOverrideAssignees}
              size="sm"
              disabled={context === 'workbench' && !selectedMatter}
            />
            <span style={{ marginLeft: 5, position: 'relative', bottom: 4 }}>
              Overwrite and assign all tasks in this template to this assignee
            </span>
          </div>
          <div className={s.item}>
            <p className={s.label}>Assignment Date*</p>
            <p className={s.subtitle}>
              Choose a date that will apply to all tasks in this list with their due date set as
              &quot;Assignment Date&quot;.
            </p>
            <DatePickerSelect
              value={dueDate}
              selectDate={(dueDate: Date | undefined) => {
                setDueDate(dueDate)
              }}
              isDisabled={context === 'workbench' && !selectedMatter}
            />
          </div>
        </>
      }
      cancelCb={toggleAddTaskFromTemplateModal}
      confirmCb={() => {
        createTasks(toggleAddTaskFromTemplateModal)
      }}
    />
  )
}

export default AddTaskFromTemplateModal
