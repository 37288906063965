import { filesize } from 'filesize'
import moment from 'moment'
import { isFolder } from './helpers'

export const resultsSerializer = (results = [], hasVendorCollaboration, hasActions) => {
  const columns = [
    { columnKey: 'type', content: 'Type', isSortable: true, isFilterable: false },
    { columnKey: 'status', content: 'Scan Status', isSortable: true, isFilterable: false },
    {
      columnKey: 'name',
      content: `${hasVendorCollaboration ? 'File Name' : 'Name'}`,
      isSortable: true,
      isFilterable: true
    },
    { columnKey: 'category', content: 'Category', isSortable: true, isFilterable: true },
    ...(hasVendorCollaboration
      ? [
          { columnKey: 'label', content: 'Label(s)', isSortable: false, isFilterable: false },
          {
            columnKey: 'created',
            content: 'Last Updated',
            isSortable: true,
            isFilterable: true
          },
          { columnKey: 'user', content: 'Updated By', isSortable: true, isFilterable: true }
        ]
      : [
          { columnKey: 'created', content: 'Uploaded', isSortable: true, isFilterable: true },
          { columnKey: 'user', content: 'Uploaded By', isSortable: true, isFilterable: true }
        ]),
    { columnKey: 'size', content: 'Size', isSortable: true, isFilterable: false },
    ...(hasActions && hasVendorCollaboration
      ? [{ columnKey: 'share', content: 'Share', isSortable: true, isFilterable: false }]
      : [])
  ]

  const rows = results.map(result => ({
    id: result.id,
    fileUrl: result.fileUrl,
    name: result.name,
    size: result.size,
    fileStatus: result.fileStatus,
    cells: [
      {
        columnKey: 'type',
        content: hasVendorCollaboration ? { id: result.id, name: result.name } : result.id
      },
      { columnKey: 'status', content: result.fileStatus ? result.fileStatus : '--' },
      { columnKey: 'name', content: result.name },
      { columnKey: 'category', content: result.category ? result.category.label : '--' },
      ...(hasVendorCollaboration
        ? [
            { columnKey: 'label', content: result.labels },
            { columnKey: 'created', content: result.created },
            { columnKey: 'user', content: result.user }
          ]
        : [
            { columnKey: 'created', content: result.created },
            { columnKey: 'user', content: result.user }
          ]),
      { columnKey: 'size', content: !isFolder(result.id) ? filesize(result.size) : result.size },
      ...(hasActions && hasVendorCollaboration
        ? [
            {
              columnKey: 'share',
              content: result
            }
          ]
        : [])
    ]
  }))

  return {
    columns,
    rows
  }
}

export const toFilesData = (files = []) => {
  return files.map(file => ({
    ...file,
    name: file.fileName,
    created: moment(file.created).format('MM/DD/YYYY'),
    user: file.user || file.uploadedBy,
    size: file.fileSize,
    category: file.category ? toCategory(file.category) : '',
    labels: file.labels ? toLabels(file.labels) : []
  }))
}

export const toFoldersData = (folders = []) =>
  folders.map(folder => {
    const { id, created, size = 0 } = folder
    const sizeString = size === 1 ? 'item' : 'items'
    return {
      ...folder,
      id: toFolderId(id),
      created: moment(created).format('MM/DD/YYYY'),
      size: `${size} ${sizeString}`
    }
  })

export const convertKeysToPath = (object = {}) => {
  const result = {}

  for (let key in object) {
    let newPath = `//${key.replace(/\/+/g, '/').replace(/^\/+/, '')}`
    result[newPath] = object[key]
  }
  return result
}

export const toFolderId = id => `folder_${id}`

export const toTreeViewData = data => (!data.name ? { ...data, name: 'All', toggled: true } : data)

export const toCategory = category => {
  if (category === null) return null
  return { value: category.id, label: category.name }
}

export const toLabels = labels => {
  if (!labels) return []
  return labels.map(l => ({ value: l.id, label: l.name, color: l.color }))
}

export const serializeSharedWithUsers = (cgUserList = []) =>
  cgUserList.map(cgUser => {
    return {
      ...cgUser,
      value: cgUser.contact_id,
      company: cgUser.vendor.name,
      firstName: cgUser.first_name,
      lastName: cgUser.last_name,
      cgUserId: cgUser.id,
      label: `${cgUser.first_name} ${cgUser.last_name}`
    }
  })

export const toCgUsers = options => {
  return options.map(o => ({
    value: o.user_id || o.id,
    label: o.full_name,
    email: o.primary_email,
    company: o.org.name,
    firstName: o.given_name,
    lastName: o.family_name,
    cgUserId: o.cg_user_id
  }))
}
