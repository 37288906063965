import { Modal, KeyValuePairs } from 'simple-core-ui'
import { TextareaContainer } from 'containers'
import { Files } from 'components'
import partition from 'lodash/partition'
import s from './RequestModal.scss'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'
import { FileUpload } from 'common/FileUpload'
import cn from 'classnames'
import { toAttachments } from '../serializers'

const newFileUploadEnabled = window.credentials.newFileUploadEnabled === 'True'

const renderAttributes = attributes => {
  return (
    attributes &&
    attributes.map(field => {
      if (field.type === 'relationship') {
        return (
          <div key={field.key} style={{ position: 'relative' }}>
            <DynamicAttribute
              dynamicAttributeClass={s.dynamicAttribute}
              threeLevelsClass={s.threeLevels}
              twoLevelsClass={s.twoLevels}
            >
              {field.attributes.map(attr => (
                <div key={attr.id} className={s.attributeWrapper}>
                  <div className={s.attributeLabel}>{attr.label}</div>
                  <div className={s.attributeValue}>
                    <span>{attr.value || 'N/A'}</span>
                  </div>
                </div>
              ))}
            </DynamicAttribute>
          </div>
        )
      }
      return <KeyValuePairs key={field.key} pairs={[field]} />
    })
  )
}

const RequestModal = ({
  request,
  otherFields,
  additionalFields,
  saveBtnText,
  closeBtnText,
  secondaryConfirmCb,
  secondaryConfirmText,
  hideButtons,
  confirmCb,
  closeCb,
  hideCancelBtn,
  showCommentTextarea,
  changeComments
}) => {
  const [requiredFields, optionalFields] = partition(otherFields, item => item.required)
  const { files, comments, originalFormName, originalFormDescription, matterLead } = request
  return (
    <Modal
      title="Legal Request Details"
      isVisible
      size="lg"
      hideButtons={hideButtons}
      confirmText={saveBtnText}
      cancelText={closeBtnText}
      confirmCb={() => confirmCb(request)}
      secondaryConfirmCb={() => {
        secondaryConfirmCb(request)
        closeCb()
      }}
      cancelCb={closeCb}
      hideCancelBtn={hideCancelBtn}
      secondaryConfirmText={secondaryConfirmText}
      content={
        <section data-testid="legal_request_data">
          <section className={s.formDetailsWrapper}>
            <KeyValuePairs
              pairs={[
                {
                  key: 'Form Name',
                  value: originalFormName
                }
              ]}
            />
            <KeyValuePairs
              pairs={[
                {
                  key: 'Form Description',
                  value: originalFormDescription || '-----'
                }
              ]}
            />
          </section>
          <section className={s.requestFieldsWrapper}>
            {renderAttributes(requiredFields)}
            {renderAttributes(optionalFields)}
            {files && (
              <div
                className={cn(s.fileWrapper, {
                  [s.newFileUpload]: newFileUploadEnabled
                })}
                data-testid="uploaded_file_link"
              >
                {newFileUploadEnabled && files?.length ? (
                  <FileUpload files={toAttachments(files)} readOnly />
                ) : (
                  <Files
                    files={files}
                    labelClass={s.filesLabel}
                    filesContainerClass={s.filesContainer}
                  />
                )}
              </div>
            )}
          </section>
          {matterLead && (
            <section className={s.additionalFieldsWrapper}>
              <KeyValuePairs
                pairs={[
                  {
                    key: 'Matter Lead Name',
                    value: `${matterLead.firstName} ${matterLead.lastName}`
                  },
                  {
                    key: 'Matter Lead Email',
                    value: matterLead.email
                  }
                ]}
              />
            </section>
          )}
          {showCommentTextarea ? (
            <section className={s.additionalFieldsWrapper}>
              <KeyValuePairs
                pairs={[
                  {
                    key: 'Reviewer Comments',
                    value: (
                      <div className={s.textareaContainer}>
                        <TextareaContainer onChange={changeComments} value={comments} />
                      </div>
                    )
                  }
                ]}
              />
            </section>
          ) : (
            <section className={s.additionalFieldsWrapper}>
              {additionalFields && <KeyValuePairs pairs={additionalFields} />}
            </section>
          )}
        </section>
      }
    />
  )
}

export default RequestModal
