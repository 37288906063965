import { ModalContainer } from 'simple-core-ui'

interface Props {
  onConfirm: () => void
  onCancel: () => void
}

const DeleteConfirmation = ({ onConfirm, onCancel }: Props) => {
  return (
    <ModalContainer
      title="Delete an event type?"
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText="Delete"
      confirmCb={onConfirm}
      content={<div>Deleting a type removes it from existing and future events.</div>}
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default DeleteConfirmation
