import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

import {
  addOtherAttributesAsChoices,
  pushToTimeline,
  annotateTimeline,
  timelineStep,
  simplifyOperands,
  patchWithReviewerConfigId
} from 'rules/utils'

import { ENABLED_ENGINES, FILTER_TYPE } from 'rules/constants'

import ACT from './actions'
import { toOperatorsByType, fromFragment } from './serializers'

const initialRuleAction = {
  name: null,
  params: []
}

const blankCondition = {
  op: 'AND',
  operands: [
    {
      lhs: {},
      op: '',
      rhs: {}
    }
  ]
}

const blankTimeline = {
  timeline: [blankCondition],
  timelineIndex: 0
}

export const blankRule = {
  id: null,
  text: '',
  name: 'New Rule',
  description: 'Rule description here...',
  is_draft: true,
  actions: [cloneDeep(initialRuleAction)],
  condition: blankCondition,
  clearable: true,
  ...blankTimeline
}

const blankUnconditionalRule = {
  ...blankRule,
  name: 'New Unconditional Rule',
  condition: null
}

const coordsArr2Str = coordsArr => {
  return `op_${coordsArr.join('_')}`
}

const makeNestedObjWithArrayItemsAsKeys = ({ arr, str, action, arrayLevel }) => {
  return arr.reduceRight(
    (accumulator, item) => {
      let newAccumulator = {}

      if (str) {
        newAccumulator[str] = {}
        newAccumulator[str][item] = Object.assign({}, accumulator)
      } else {
        newAccumulator[item] = Object.assign({}, accumulator)
      }

      return newAccumulator
    },
    !arrayLevel ? { [str]: action } : action
  )
}

const resetRuleState = (ruleIndex, state) => {
  return update(state, {
    [state.engine]: {
      rulesList: {
        [ruleIndex]: {
          condition: {
            $set: {
              op: 'AND',
              operands: [
                {
                  lhs: {},
                  op: '',
                  rhs: {}
                }
              ]
            }
          }
        }
      }
    }
  })
}

const EMPTY_DSL_ENGINE = { rulesText: '' }

const initialDSLStates = ENABLED_ENGINES.reduce(
  (dslState, engine) => {
    dslState[engine] = EMPTY_DSL_ENGINE
    return dslState
  },
  { isLoading: true, isEdit: false }
)

const initialRulesState = {
  rulesList: [blankRule],
  savedRules: [blankRule],
  currentRuleIndex: -1,
  ops: [],
  availableFields: [],
  availableActions: [],
  filters: {
    pageSize: 50,
    ordering: { columnKey: 'name', isDesc: true },
    page: 1,
    search: '',
    category: 'active',
    reset: 0,
    ...fromFragment(window.location.hash)
  }
}

const initialRulesByEngine = ENABLED_ENGINES.reduce((rulesByEngine, engine) => {
  rulesByEngine[engine] = initialRulesState
  return rulesByEngine
}, {})

const initialState = {
  ...initialRulesByEngine,
  filterType: FILTER_TYPE.ACTIVE,
  filterSearch: '',
  isSaving: false,
  isLoading: true,
  engine: ENABLED_ENGINES[0],
  dsl: initialDSLStates,
  invoiceAi: {}
}

const rulesReducer = createReducer(initialState, {
  [ACT.FETCH_ALL_RULES_SUCCESS](state, action) {
    const immutableActions = action.payload.reduce((aggregatedActions, ruleEnginePair) => {
      if (ruleEnginePair[0] === 'invoice_ai' || ruleEnginePair[0] === 'simple_review')
        return aggregatedActions
      const [
        engineName,
        {
          rules,
          actions,
          namespace: { funcs, models, operators }
        }
      ] = ruleEnginePair

      const operatorsByType = toOperatorsByType(operators)

      const immutableAction = { $set: rules.map(annotateTimeline) }
      const immutableOps = Object.entries(operatorsByType).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: { $set: value } }),
        {}
      )

      const fields = [
        ...funcs.map(func => ({ ...func, isFunction: true })),
        ...addOtherAttributesAsChoices(models)
      ]

      // rejection_reason is not required in old IIW rules
      const filteredActions = actions.map(a => ({
        ...a,
        params:
          a.name === 'reject_invoice'
            ? a.params.filter(p => p.name !== 'rejection_reason')
            : a.params
      }))

      aggregatedActions[engineName] = {
        rulesList: immutableAction,
        savedRules: immutableAction,
        ops: immutableOps,
        availableFields: { $set: fields },
        availableActions: { $set: filteredActions }
      }

      return aggregatedActions
    }, {})

    return update(state, {
      isLoading: { $set: false },
      ...immutableActions
    })
  },

  ['SET_RULES'](state, action) {
    return update(state, {
      [state.engine]: {
        rulesList: { $set: action.payload }
      }
    })
  },

  [ACT.FETCH_AI_SUCCESS](state, action) {
    return update(state, {
      isLoading: { $set: false },
      invoiceAi: { $set: action.payload }
    })
  },

  [ACT.RULE_FIELDS_FETCH_SUCCESS](state, action) {
    const immutableActions = action.payload.reduce((aggregatedActions, fieldEnginePair) => {
      const [engine, fields] = fieldEnginePair
      const immutableAction = { $set: fields }

      aggregatedActions[engine] = {
        availableFields: immutableAction
      }

      return aggregatedActions
    }, {})

    return update(state, immutableActions)
  },

  [ACT.UPDATE_RULES_FILTER_TYPE](state, action) {
    const { filterType } = action.payload
    return {
      ...state,
      filterType,
      ...(state.engine ? { [state.engine]: { ...state[state.engine], currentRuleIndex: -1 } } : {})
    }
  },

  [ACT.UPDATE_RULES_FILTER_SEARCH](state, action) {
    const { filterSearch } = action.payload
    return {
      ...state,
      filterSearch,
      [state.engine]: { ...state[state.engine], currentRuleIndex: -1 }
    }
  },

  [ACT.UPDATE_CURRENT_RULE_INDEX](state, action) {
    const { engine } = state
    const currentRuleIndex = state[engine].currentRuleIndex
    const { isCancel } = action.payload
    const clearable = get(state, [engine, 'savedRules', currentRuleIndex, 'clearable'])

    let newState = state

    if (clearable && isCancel) {
      const transform = { $splice: [[currentRuleIndex, 1]] }

      newState = update(state, {
        [engine]: {
          rulesList: transform,
          savedRules: transform
        }
      })
    }

    let index = action.payload.index

    if (currentRuleIndex === index) {
      index = -1
    }

    return update(newState, {
      [engine]: {
        currentRuleIndex: { $set: index }
      }
    })
  },

  [ACT.UPDATE_RULE_OPERAND](state, action) {
    const { key, value, ruleIndex, refs, coords } = action.payload
    const coordArr = refs[coords].coords
    const arr = cloneDeep(coordArr)
    const updateOperandAction = { [key]: { $set: value } }

    let opts = {
      arr: arr,
      str: 'operands',
      action: updateOperandAction,
      arrayLevel: true
    }

    let finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

    const nextState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            condition: finalObj
          }
        }
      }
    })

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.CLEAR_OPERAND_OPERATOR](state, action) {
    const { ruleIndex, refs, coords } = action.payload
    const coordArr = refs[coords].coords
    const arr = cloneDeep(coordArr)
    const clearOperandAction = { op: { $set: '' }, rhs: { $set: {} } }

    let opts = {
      arr: arr,
      str: 'operands',
      action: clearOperandAction,
      arrayLevel: true
    }

    let finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

    const nextState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            condition: finalObj
          }
        }
      }
    })

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.CLEAR_OPERAND_RHS](state, action) {
    const { ruleIndex, refs, coords } = action.payload
    const coordArr = refs[coords].coords
    const arr = cloneDeep(coordArr)
    const clearOperandAction = { $unset: ['rhs'] }

    let opts = {
      arr: arr,
      str: 'operands',
      action: clearOperandAction,
      arrayLevel: true
    }

    let finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

    const nextState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            condition: finalObj
          }
        }
      }
    })

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.UPDATE_RULE_METADATA](state, action) {
    const { key, value, index } = action.payload
    return update(state, {
      [state.engine]: {
        rulesList: {
          [index]: {
            [key]: {
              $set: value
            }
          }
        }
      }
    })
  },

  [ACT.UPDATE_BOOLEAN_OPERATOR](state, action) {
    const { booleanOperator, ruleIndex, refs, coords } = action.payload
    const updateBooleanOperatorAction = { op: { $set: booleanOperator } }

    const coordArr = refs[coords].coords
    let shortArr = cloneDeep(coordArr)
    shortArr.pop()

    let opts = {
      arr: shortArr,
      str: 'operands',
      arrayLevel: true,
      action: updateBooleanOperatorAction
    }

    let finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

    const nextState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            condition: finalObj
          }
        }
      }
    })

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.ADD_AND_OPERAND](state, action) {
    const { booleanOperator, ruleIndex, refs } = action.payload
    const coord = action.payload.coord || 'op_0'

    const blankOperator = {
      lhs: {},
      op: '',
      rhs: {}
    }

    const condition = refs[coord].condition
    const operand = refs[coord].operand

    const newCondition = {
      op: booleanOperator,
      operands: [
        operand,
        {
          lhs: {},
          op: '',
          rhs: {}
        }
      ]
    }

    const coords = refs[coord].coords
    const shortArr = cloneDeep(coords)

    shortArr.pop()
    const insertIndex = coords.length && coords[coords.length - 1]
    const addNewConditionAction = { $splice: [[insertIndex + 1, 0, cloneDeep(newCondition)]] }
    const addNewOperandAction = { $splice: [[insertIndex + 1, 0, cloneDeep(blankOperator)]] }

    const delIndex = coords.length && coords[coords.length - 1]
    const removeOperandAction = { $splice: [[delIndex, 1]] }

    let opts = {
      arr: shortArr,
      str: 'operands',
      action: addNewConditionAction
    }

    let finalObj

    // this is kind of a hack - we basically want to default to "AND" if user only has one operand,
    // but if user clicks OR, the top condition should change to an OR
    if (
      state[state.engine].rulesList[ruleIndex].condition.operands.length === 1 &&
      state[state.engine].rulesList[ruleIndex].condition.op !== booleanOperator
    ) {
      const newState = update(state, {
        [state.engine]: {
          rulesList: {
            [ruleIndex]: {
              condition: {
                op: {
                  $set: booleanOperator
                }
              }
            }
          }
        }
      })

      opts.action = addNewOperandAction
      finalObj = makeNestedObjWithArrayItemsAsKeys(opts)
      const nextState = update(newState, {
        [state.engine]: {
          rulesList: {
            [ruleIndex]: {
              condition: finalObj
            }
          }
        }
      })

      return pushToTimeline({ nextState, ruleIndex })
    }

    if (condition.op !== booleanOperator) {
      finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

      // first, add condition with moved operand and new operand
      let addConditionState = update(state, {
        [state.engine]: {
          rulesList: {
            [ruleIndex]: {
              condition: finalObj
            }
          }
        }
      })

      // then, remove duplicate operand to complete the move
      opts.action = removeOperandAction
      finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

      let finalState = update(addConditionState, {
        [state.engine]: {
          rulesList: {
            [ruleIndex]: {
              condition: finalObj
            }
          }
        }
      })

      return pushToTimeline({ nextState: finalState, ruleIndex })
    }

    opts.action = addNewOperandAction
    finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

    const nextState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            condition: finalObj
          }
        }
      }
    })

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.CLEAR_RULE_CONDITION](state, action) {
    const { ruleIndex } = action.payload
    const nextState = resetRuleState(ruleIndex, state)

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.REMOVE_RULE_CONDITION](state, action) {
    const { ruleIndex, refs, coord } = action.payload
    const coords = refs[coord].coords
    let conditionCoordsArr = cloneDeep(coords)
    let newState = state

    // walk up the tree and see if there are any blank conditions, and delete them
    while (conditionCoordsArr.length) {
      const delIndex = conditionCoordsArr[conditionCoordsArr.length - 1]
      const removeConditonAction = { $splice: [[delIndex, 1]] }

      const currentCoordStr = coordsArr2Str(conditionCoordsArr)

      const currentIndex = conditionCoordsArr.pop()

      const isConditionEmpty =
        refs[currentCoordStr].condition.operands[currentIndex].op &&
        get(
          refs,
          [currentCoordStr, 'condition', 'operands', currentIndex, 'operands', 'length'],
          0
        ) === 1

      if (isConditionEmpty) {
        let opts = {
          arr: conditionCoordsArr,
          str: 'operands',
          action: removeConditonAction
        }

        let finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

        newState = update(newState, {
          [state.engine]: {
            rulesList: {
              [ruleIndex]: {
                condition: finalObj
              }
            }
          }
        })
      }
    }

    // if entire rule is deleted from this, start from blank slate
    if (!newState[newState.engine].rulesList[ruleIndex].condition.operands.length) {
      newState = resetRuleState(ruleIndex, newState)
    }

    return pushToTimeline({ nextState: newState, ruleIndex })
  },

  [ACT.REMOVE_RULE_OPERAND](state, action) {
    const { ruleIndex, refs, coord } = action.payload

    const coords = refs[coord].coords
    let shortArr = cloneDeep(coords)
    shortArr.pop()
    const delIndex = coords.length && coords[coords.length - 1]

    const removeOperandAction = { $splice: [[delIndex, 1]] }

    let opts = {
      arr: shortArr,
      str: 'operands',
      action: removeOperandAction
    }

    let finalObj = makeNestedObjWithArrayItemsAsKeys(opts)

    const nextState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            condition: finalObj
          }
        }
      }
    })

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.ADD_NEW_RULE](state, action) {
    const unconditional = get(action, 'payload.unconditional', false)
    const immutableAction = { $unshift: [unconditional ? blankUnconditionalRule : blankRule] }

    return update(state, {
      [state.engine]: {
        currentRuleIndex: { $set: 0 },
        rulesList: immutableAction,
        savedRules: immutableAction
      }
    })
  },

  [ACT.CLONE_RULE_SUCCESS](state, action) {
    const { ruleIndex, reviewerConfigId } = action.payload

    const nextRuleIndex = 0
    const _ruleCopy = cloneDeep(state[state.engine].rulesList[ruleIndex])
    let ruleCopy = {
      ..._ruleCopy,
      name: `${_ruleCopy.name} (Copy)`,
      id: null,
      clearable: true,
      ...blankTimeline
    }

    if (reviewerConfigId) {
      ruleCopy = patchWithReviewerConfigId(ruleCopy, reviewerConfigId)
    }

    const immutableAction = { $unshift: [ruleCopy] }

    return update(state, {
      [state.engine]: {
        currentRuleIndex: { $set: nextRuleIndex },
        rulesList: immutableAction,
        savedRules: immutableAction
      }
    })
  },

  [ACT.UNDO_RULE_CHANGE](state, action) {
    return timelineStep({
      state,
      ruleIndex: action.payload.ruleIndex,
      stepAmount: -1
    })
  },

  [ACT.REDO_RULE_CHANGE](state, action) {
    return timelineStep({
      state,
      ruleIndex: action.payload.ruleIndex,
      stepAmount: 1
    })
  },

  [ACT.SIMPLIFY_RULE](state, action) {
    const { ruleIndex } = action.payload

    const operands = state[state.engine].rulesList[ruleIndex].condition
    const simplifiedCondition = simplifyOperands([operands])[0]

    const nextState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            condition: { $set: simplifiedCondition }
          }
        }
      }
    })

    return pushToTimeline({ nextState, ruleIndex })
  },

  [ACT.UPDATE_RULE_ACTION](state, action) {
    const { ruleIndex, actionIndex } = action.payload
    return update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            actions: {
              [actionIndex]: {
                $set: action.payload.action
              }
            }
          }
        }
      }
    })
  },

  [ACT.RULE_DELETE_REQUESTED](state, action) {
    const { ruleIndex } = action.payload
    const immutableAction = { $splice: [[ruleIndex, 1]] }

    return update(state, {
      [state.engine]: {
        currentRuleIndex: { $set: -1 },
        rulesList: immutableAction,
        savedRules: immutableAction
      }
    })
  },

  [ACT.CREATE_RULE_ACTION](state, action) {
    const { ruleIndex } = action.payload
    return update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            actions: {
              $push: [initialRuleAction]
            }
          }
        }
      }
    })
  },

  [ACT.REMOVE_RULE_ACTION](state, action) {
    const { ruleIndex, actionIndex } = action.payload

    return update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            actions: {
              $splice: [[actionIndex, 1]]
            }
          }
        }
      }
    })
  },

  [ACT.RULE_SAVE_SUCCESS](state, action) {
    const { ruleIndex, ruleResponse } = action.payload

    let newState = update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: { $set: ruleResponse }
        }
      }
    })

    const rule = newState[newState.engine].rulesList[ruleIndex]

    return update(newState, {
      [state.engine]: {
        savedRules: {
          $set: newState[state.engine].rulesList
        },
        currentRuleIndex: {
          $set: -1
        }
      },
      isSaving: {
        $set: false
      }
    })
  },

  [ACT.REVERT_RULE_CHANGES](state, action) {
    const { engine } = state
    const { currentRuleIndex } = state[engine]

    const { clearable } = state[engine].savedRules[currentRuleIndex]

    if (clearable) {
      const transform = { $splice: [[currentRuleIndex, 1]] }

      return update(state, {
        [engine]: {
          rulesList: transform,
          savedRules: transform,
          currentRuleIndex: { $set: -1 }
        }
      })
    } else {
      return update(state, {
        [engine]: {
          rulesList: {
            [currentRuleIndex]: {
              $set: state[engine].savedRules[currentRuleIndex]
            }
          },
          currentRuleIndex: { $set: -1 }
        }
      })
    }
  },

  [ACT.RULE_IS_SAVING](state, action) {
    return update(state, {
      isSaving: {
        $set: true
      }
    })
  },

  [ACT.RULE_IS_NOT_SAVING](state, action) {
    return update(state, {
      isSaving: {
        $set: false
      }
    })
  },

  [ACT.RULES_LIST_IS_LOADING](state, action) {
    return update(state, {
      isLoading: {
        $set: true
      }
    })
  },

  [ACT.RULES_LIST_IS_NOT_LOADING](state, action) {
    return update(state, {
      isLoading: {
        $set: false
      }
    })
  },

  [ACT.UPDATE_RULE_ACTION_PARAMETERS](state, action) {
    const { ruleIndex, actionIndex, actionParameters, parameterIndex } = action.payload

    if (parameterIndex || parameterIndex === 0) {
      return update(state, {
        [state.engine]: {
          rulesList: {
            [ruleIndex]: {
              actions: {
                [actionIndex]: {
                  params: {
                    [parameterIndex]: {
                      $set: actionParameters
                    }
                  }
                }
              }
            }
          }
        }
      })
    }

    return update(state, {
      [state.engine]: {
        rulesList: {
          [ruleIndex]: {
            actions: {
              [actionIndex]: {
                params: {
                  $push: [{ ...actionParameters }]
                }
              }
            }
          }
        }
      }
    })
  },

  [ACT.UPDATE_RULE_ENGINE](state, action) {
    return update(state, {
      engine: {
        $set: action.payload
      }
    })
  },

  [ACT.FETCH_ALL_DSL_RULES_SUCCESS](state, action) {
    return {
      ...state,
      dsl: {
        ...state.dsl,
        isLoading: false,
        ...action.payload
      }
    }
  },

  [ACT.SUBMIT_RULE_DSL_SUCCESS](state, action) {
    const { rulesText, engine } = action.payload

    return {
      ...state,
      dsl: {
        ...state.dsl,
        isLoading: false,
        isEdit: false,
        [engine]: { rulesText }
      }
    }
  },

  [ACT.DSL_IS_LOADING](state, action) {
    return {
      ...state,
      dsl: {
        ...state.dsl,
        isLoading: true
      }
    }
  },

  [ACT.DSL_IS_NOT_LOADING](state, action) {
    return {
      ...state,
      dsl: {
        ...state.dsl,
        isLoading: false
      }
    }
  },

  [ACT.DSL_IS_EDIT](state, action) {
    return {
      ...state,
      dsl: {
        ...state.dsl,
        isEdit: true
      }
    }
  },

  [ACT.DSL_IS_NOT_EDIT](state, action) {
    return {
      ...state,
      dsl: {
        ...state.dsl,
        isEdit: false
      }
    }
  }
})

export default rulesReducer
