import { useEffect, useState } from 'react'
import s from './RuleExpandableContent.scss'
import { ActionsReadOnly } from '../ActionsReadOnly'
import { makeGetRequest } from 'utils/api'
import { useLoading, Grid } from 'simple-core-ui'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { useDispatch } from 'react-redux'
import ReadOnlyRule from './ReadOnlyRule'
import { Rule } from 'simple_review/@types/api'

const { Row, Column } = Grid

interface Props {
  ruleIndex: number
  id: number
}

const RuleExpandableContent = ({ id, ruleIndex }: Props) => {
  const [isLoading, withLoadingLocks] = useLoading()
  const { state } = useSimpleReviewContext()
  const { availableActions } = state
  const [rule, setRule] = useState<Rule>()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchRule() {
      try {
        const response = await withLoadingLocks(makeGetRequest(`/client/rules/${id}/`))
        setRule(response)
      } catch (e) {
        dispatch({
          type: 'API_ERROR',
          error: {
            ...(e as object),
            errorTitle: 'Error'
          }
        })
      }
    }

    fetchRule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || !rule) {
    return null
  }

  return (
    <div className={s.expandableContainer}>
      <Row>
        <Column span={7}>
          <div className={s.caption}>CONDITIONS:</div>
          <ReadOnlyRule condition={rule.condition} ruleName={rule.name} />
        </Column>
        <Column span={5}>
          <div className={s.caption}>ACTIONS:</div>
          <ActionsReadOnly
            ruleIndex={ruleIndex}
            actions={rule?.actions}
            availableActions={availableActions}
          />
        </Column>
      </Row>
    </div>
  )
}

export default RuleExpandableContent
