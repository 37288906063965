import { createReducer } from 'redux-create-reducer'
import { DataTableSerializer } from 'simple-core-ui'

import { PENDING_INVOICES_COLUMN_KEYS } from './utils'
import { fromPendingInvoices } from './serializer'
import { fromFragment, toFragment } from './list/serializers'
import update from 'immutability-helper'

import ACT from './actions'

const DEFAULT_TAB = 'Mine'

let defaultParams = {
  pageSize: 10,
  ordering: { columnKey: 'received_date', isDesc: true },
  search: '',
  filters: {},
  page: 1,
  category: DEFAULT_TAB
}

if (!!window.location.hash) {
  defaultParams = fromFragment(window.location.hash, defaultParams)
}

export const initialState = {
  invoiceList: {
    results: [],
    total: 0,
    filtered: 0,
    page: 1
  },
  invoiceSummary: {
    Mine: {},
    Received: {},
    Hold: {},
    Approved: {}
  },
  invoiceListParams: defaultParams,
  invoiceListFragment: window.location.hash.slice(1),
  pendingInvoices: {
    isLoading: false,
    rows: [],
    columns: [
      {
        columnKey: PENDING_INVOICES_COLUMN_KEYS.DATE,
        content: 'Date',
        isSortable: true,
        isFilterable: true,
        style: { width: '10%' }
      },
      {
        columnKey: PENDING_INVOICES_COLUMN_KEYS.NUMBER,
        content: 'Number',
        isSortable: true,
        isFilterable: true,
        style: { width: '10%' }
      },
      {
        columnKey: PENDING_INVOICES_COLUMN_KEYS.SUBJECT,
        content: 'Subject',
        isSortable: true,
        isFilterable: true,
        style: { width: '25%' }
      },
      {
        columnKey: PENDING_INVOICES_COLUMN_KEYS.MATTERS,
        content: 'Matters',
        isSortable: true,
        isFilterable: true,
        style: { width: '30%' }
      },
      {
        columnKey: PENDING_INVOICES_COLUMN_KEYS.VENDOR,
        content: 'Vendor',
        isSortable: true,
        isFilterable: true,
        style: { width: '15%' }
      },
      {
        columnKey: PENDING_INVOICES_COLUMN_KEYS.TOTAL,
        content: 'Total',
        isSortable: true,
        style: { width: '10%', textAlign: 'right' }
      }
    ]
  },
  attachments: []
}

const invoicesReducer = createReducer(initialState, {
  [ACT.PENDING_INVOICES_FETCH_SUCCESS](state, action) {
    const { columns } = state.pendingInvoices
    const invoices = fromPendingInvoices(action.payload)
    const rows = DataTableSerializer.getRowsByCategory({ invoices }, columns).invoices

    return {
      ...state,
      pendingInvoices: {
        ...state.pendingInvoices,
        isLoading: false,
        rows
      }
    }
  },

  [ACT.INVOICE_LIST_FETCH_SUCCESS](state, action) {
    const { invoices, params } = action.payload

    const fragment = toFragment(params)

    return {
      ...state,
      invoiceListParams: params,
      invoiceListFragment: fragment,
      invoiceList: invoices
    }
  },

  [ACT.INVOICE_LIST_RESET](state) {
    return {
      ...state,
      invoiceList: initialState.invoiceList
    }
  },

  [ACT.INVOICE_SUMMARY_FETCH_SUCCESS](state, action) {
    const { summary } = action.payload

    return {
      ...state,
      invoiceSummary: {
        Mine: {
          count: summary['Mine'].rowcount,
          amount: summary['Mine'].sum_amt
        },
        Received: {
          count: summary['Received'].rowcount,
          amount: summary['Received'].sum_amt
        },
        Hold: {
          count: summary['On Hold'].rowcount,
          amount: summary['On Hold'].sum_amt
        },
        Approved: {
          count: summary['Approved'].rowcount,
          amount: summary['Approved'].sum_amt
        }
      }
    }
  },

  [ACT.PENDING_INVOICES_LOADING](state, action) {
    return {
      ...state,
      pendingInvoices: {
        ...state.pendingInvoices,
        isLoading: true
      }
    }
  },

  [ACT.INVOICE_APPROVE_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.invoiceList.results.findIndex(i => i.id === id)
    return update(state, {
      invoiceList: {
        results: {
          [index]: {
            status: { $set: 'Approved' }
          }
        }
      }
    })
  },

  [ACT.INVOICE_REJECT_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.invoiceList.results.findIndex(i => i.id === id)
    return update(state, {
      invoiceList: {
        results: {
          [index]: {
            status: { $set: 'Rejected' }
          }
        }
      }
    })
  },

  [ACT.INVOICE_MARK_PAID_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.invoiceList.results.findIndex(i => i.id === id)
    return update(state, {
      invoiceList: {
        results: {
          [index]: {
            status: { $set: 'Paid' }
          }
        }
      }
    })
  },

  [ACT.SELECTED_INVOICES_APPROVED](state, action) {
    const { ids } = action.payload
    const invoices = state.invoiceList.results.map(inv => {
      if (ids.includes(inv.id)) {
        return {
          ...inv,
          status: 'Approved'
        }
      } else {
        return inv
      }
    })

    return update(state, {
      invoiceList: {
        results: { $set: invoices }
      }
    })
  },

  [ACT.SELECTED_INVOICES_REJECTED](state, action) {
    const { ids } = action.payload

    const invoices = state.invoiceList.results.map(inv => {
      if (ids.includes(inv.id)) {
        return {
          ...inv,
          status: 'Rejected'
        }
      } else {
        return inv
      }
    })

    return update(state, {
      invoiceList: {
        results: { $set: invoices }
      }
    })
  },

  [ACT.SELECTED_INVOICES_PAID](state, action) {
    const { ids } = action.payload

    const invoices = state.invoiceList.results.map(inv => {
      if (ids.includes(inv.id)) {
        return {
          ...inv,
          status: 'Paid'
        }
      } else {
        return inv
      }
    })

    return update(state, {
      invoiceList: {
        results: { $set: invoices }
      }
    })
  },
  [ACT.SET_ATTACHMENTS](state, action) {
    const attachments = action.payload

    return update(state, {
      attachments: {
        $set: attachments
      }
    })
  }
})

export default invoicesReducer
