import { Component } from 'react'
import RuleCard from '../components/RuleCard'
import { connect } from 'react-redux'
import ACT from 'rules/actions'
import RC_ACT from 'reviews/reviewer_config/actions'
import { updateLinkParam, deleteLinkParam } from 'utils/helpers'
import {
  isRuleComplete,
  getRulePriority,
  stripTimeline,
  getReviewerConfigIdFromRule
} from 'rules/utils'
import { ENGINE_REVIEWER_SCOPE_MAP, QUERY_PARAMS } from 'rules/constants'
import swal from 'sweetalert'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import queryString from 'query-string'

@connect(({ rules, reviews }, ownProps) => {
  const { engine, isSaving } = rules
  const { availableActions, currentRuleIndex, savedRules, rulesList } = rules[engine]
  const { rule, ruleIndex } = ownProps
  const condition = rule.condition
  const { actions, id, name, description, text } = rule
  const isActive = !rule.is_draft
  const isUnconditional = condition === null
  const isComplete = isRuleComplete(condition)
  const isEditing = ruleIndex === currentRuleIndex
  const savedVersion = savedRules.find(({ id }) => id === rule.id)
  const ruleWasUpdated =
    !isEqual(stripTimeline(savedVersion), stripTimeline(rule)) || rule.clearable
  const reviewerScope = ENGINE_REVIEWER_SCOPE_MAP[engine]
  const priority = getRulePriority(rule)
  const newRulePending = rulesList.find(({ id = null }) => id === null)

  return {
    id,
    name,
    description,
    condition,
    actions,
    isActive,
    ruleIndex,
    ruleDSL: text,
    availableActions,
    isUnconditional,
    isComplete,
    isEditing,
    ruleWasUpdated,
    engine,
    reviewerScope,
    priority,
    newRulePending,
    rule
  }
})
class RuleCardContainer extends Component {
  componentDidMount() {
    const parsedQueryString = queryString.parse(location.search)
    const ruleId = get(parsedQueryString, 'rule_id', null)

    const { dispatch, rule, ruleIndex } = this.props

    if (rule.id === ruleId)
      dispatch({ type: ACT.UPDATE_CURRENT_RULE_INDEX, payload: { index: ruleIndex } })
  }

  resetReviewers = () => {
    const { reviewerScope, dispatch } = this.props

    // TODO: Ideally we would cache the reviewer configs
    if (reviewerScope) {
      dispatch({
        type: RC_ACT.RESET_REVIEWER_CONFIG,
        payload: { reviewerScope }
      })
    }
  }

  toggleCard = ({ isCancel = false }) => {
    const { ruleIndex, dispatch, isEditing, id } = this.props

    this.resetReviewers()

    isEditing
      ? deleteLinkParam(QUERY_PARAMS.RULE_ID)
      : updateLinkParam({ key: QUERY_PARAMS.RULE_ID, value: id })

    dispatch({
      type: ACT.UPDATE_CURRENT_RULE_INDEX,
      payload: {
        index: ruleIndex,
        isCancel
      }
    })
  }

  cancel = async () => {
    const { dispatch, ruleWasUpdated } = this.props

    if (ruleWasUpdated) {
      const shouldCancel = await swal({
        title: 'Are you sure?',
        text: "The changes you've made to this rule will be discarded.",
        buttons: ['No', 'Yes'],
        icon: 'warning',
        dangerMode: true
      })

      if (shouldCancel) dispatch({ type: ACT.REVERT_RULE_CHANGES })
    } else {
      this.toggleCard({ isCancel: true })
    }
  }

  editRule = (key, value) => {
    this.props.dispatch({
      type: ACT.UPDATE_RULE_METADATA,
      payload: {
        key,
        value,
        index: this.props.ruleIndex
      }
    })
  }

  save = () => {
    const { condition, description, id, name, actions, isActive, ruleIndex, engine } = this.props
    this.props.dispatch({
      type: ACT.RULE_SAVE_REQUESTED,
      payload: {
        condition,
        actions,
        id,
        name,
        isActive,
        description,
        ruleIndex,
        engine
      }
    })
  }

  deleteRule = async () => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Once deleted you will not be able to recover this rule.',
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true
    })

    if (willDelete) {
      const { dispatch, engine, ruleIndex, id } = this.props

      dispatch({
        type: ACT.RULE_DELETE_REQUESTED,
        payload: { id, ruleIndex, engine }
      })
    }
  }

  cloneRule = () => {
    const { dispatch, ruleIndex, rule } = this.props

    const reviewerConfigId = getReviewerConfigIdFromRule(rule)

    dispatch({
      type: reviewerConfigId ? ACT.CLONE_RULE_REQUESTED : ACT.CLONE_RULE_SUCCESS,
      payload: { ruleIndex, reviewerConfigId }
    })
  }

  updateField = (key, value) => {
    this.editRule(key, value)
  }

  undo = () => {
    const { dispatch, ruleIndex } = this.props

    dispatch({
      type: ACT.UNDO_RULE_CHANGE,
      payload: { ruleIndex }
    })
  }

  redo = () => {
    const { dispatch, ruleIndex } = this.props

    dispatch({
      type: ACT.REDO_RULE_CHANGE,
      payload: { ruleIndex }
    })
  }

  simplify = () => {
    const { dispatch, ruleIndex } = this.props

    dispatch({
      type: ACT.SIMPLIFY_RULE,
      payload: { ruleIndex }
    })
  }

  render() {
    return (
      <RuleCard
        {...this.state}
        {...this.props}
        save={this.save}
        cancel={this.cancel}
        toggleCard={this.toggleCard}
        removeCard={this.deleteRule}
        cloneRule={this.cloneRule}
        updateField={this.updateField}
        undo={this.undo}
        redo={this.redo}
        simplify={this.simplify}
      />
    )
  }
}

export default RuleCardContainer
