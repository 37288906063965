import { ReactElement, useEffect, useMemo, useState } from 'react'
import { MdOutlineEvent, MdOutlineEventAvailable } from 'react-icons/md'
import { TbAlertCircle } from 'react-icons/tb'

import { Button } from 'simple-core-ui'
import { DateTypeItem } from './DateTypeItem'
import { RelativeDateContent } from './RelativeDateContent'
import { DueDateValue, RelativeDateParams, Option } from './types'
import {
  ASSIGNMENT_DATE_OPTION,
  ASSIGNMENT_DATE,
  DUE_DATE_RULE_TYPES,
  RELATIVE_DATE
} from './constants'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import s from './RelativeDueDatePicker.scss'

interface Props {
  value?: DueDateValue
  tasks: Option[]
  canClear?: boolean
  togglePopper?: () => void
  onConfirm: (value: DueDateValue) => void
}

const buttonStyle = { padding: '9px 15px', borderRadius: '4px' }

const dateTypeIcons: { [key: string]: ReactElement } = {
  [ASSIGNMENT_DATE.key]: <MdOutlineEvent className={s.icon} />,
  [RELATIVE_DATE.key]: <MdOutlineEventAvailable className={s.icon} />
}

const RelativeDueDatePicker = ({ value, tasks, canClear, togglePopper, onConfirm }: Props) => {
  const [dateType, setDateType] = useState(value?.dateType)
  const [relativeDateParams, setRelativeDateParams] = useState(value?.relativeDateParams || {})
  const [clearRelativeDueDate, setClearRelativeDueDate] = useState(false)
  const [showClearError, setShowClearError] = useState(false)

  const isParamsValid = () => {
    if (showClearError) return false
    if (!dateType) return true
    if (dateType === ASSIGNMENT_DATE.key) return true
    const { amount, timeUnit, timeCondition, task } = relativeDateParams as RelativeDateParams
    return amount !== null && amount >= 0 && !!timeUnit && !!timeCondition && !!task
  }

  const tasksAsOptions = useMemo(() => {
    return [ASSIGNMENT_DATE_OPTION, ...sortAlphabeticallyByProperty(tasks, 'label')]
  }, [tasks])

  const confirm = () => {
    togglePopper?.()
    onConfirm({
      dateType,
      relativeDateParams:
        dateType === undefined || dateType === ASSIGNMENT_DATE.key
          ? undefined
          : (relativeDateParams as RelativeDateParams),
      clearRelativeDueDate
    })
  }

  const clear = () => {
    setDateType(undefined)
    setRelativeDateParams({})
    setClearRelativeDueDate(true)
  }

  const clearDueDate = () => {
    canClear ? clear() : setShowClearError(true)
  }

  useEffect(() => {
    if (dateType !== undefined || Object.keys(relativeDateParams).length) setShowClearError(false)
  }, [dateType, relativeDateParams])

  return (
    <>
      <div className={s.mainContent}>
        <div className={s.header}>
          <div className={s.title}>Choose a date type</div>
          {value?.dateType && (
            <div className={s.clear} onClick={clearDueDate}>
              Clear due date
            </div>
          )}
        </div>
        {!canClear && showClearError && (
          <div className={s.clearError}>
            <TbAlertCircle className={s.errorIcon} />
            <div>
              Cannot clear due to task dependencies. To proceed, remove the due date dependency from
              those tasks.
            </div>
          </div>
        )}
        <div className={s.typesSection}>
          {DUE_DATE_RULE_TYPES.map(type => (
            <DateTypeItem
              key={type.key}
              icon={dateTypeIcons[type.key]}
              title={type.title}
              description={type.description}
              isSelected={type.key === dateType}
              onClick={() => {
                setDateType(type.key)
                setClearRelativeDueDate(false)
              }}
            />
          ))}
        </div>
        {RELATIVE_DATE.key === dateType && (
          <RelativeDateContent
            value={relativeDateParams}
            tasks={tasksAsOptions}
            onChange={updatedParam => {
              setRelativeDateParams(currentValue => ({ ...currentValue, ...updatedParam }))
            }}
          />
        )}
      </div>
      <div className={s.footer}>
        <Button
          isPrimary
          isOutline
          hasNewDesign
          style={buttonStyle}
          onClick={() => togglePopper?.()}
        >
          Cancel
        </Button>
        <Button
          isPrimary
          isDisabled={!isParamsValid()}
          hasNewDesign
          style={buttonStyle}
          onClick={confirm}
        >
          Apply
        </Button>
      </div>
    </>
  )
}

export default RelativeDueDatePicker
