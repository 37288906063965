import PropTypes from 'prop-types'

import TYPES from './types'

import Option from './Option'
import Empty from './Empty'

import s from './ComboBox.scss'

const OptionList = ({ options, selectionCb, emptyText }) =>
  options && options.length ? (
    <ul className={s.list}>
      {options.map((option, key) => (
        <Option option={option} selectionCb={selectionCb} key={key} />
      ))}
    </ul>
  ) : (
    <Empty text={emptyText} />
  )

OptionList.propTypes = {
  options: PropTypes.arrayOf(TYPES.option).isRequired,
  selectionCb: TYPES.selectionCb.isRequired,
  emptyText: TYPES.emptyText
}

export default OptionList
