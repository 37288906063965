import { Component } from 'react'
import { Panel, List, Tabs, Tab, Badge, DESIGN } from 'simple-core-ui'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import ACT from 'dashboard/actions'
import { MODULE, ORG_ROLE } from 'utils/constants'
import { connect } from 'react-redux'
import s from '../styles/HomeDashboard.scss'
import DashboardItem from '../components/DashboardItem'
import { DashboardSkeleton } from 'common'
import Expand from '../components/Expand'

const {
  color: { blue800 }
} = DESIGN

@connect(({ dashboard }) => {
  const { invoices, budgets, legalRequests, vendorSurveys, matters, timekeepers } = dashboard
  return {
    invoices,
    budgets,
    legalRequests,
    vendorSurveys,
    matters,
    timekeepers
  }
})
class InnerPanel extends Component {
  getTitle = (title, num) => {
    if (!num) {
      return title
    } else {
      return `${title} (${num})`
    }
  }

  render() {
    const {
      invoices,
      budgets,
      legalRequests,
      vendorSurveys,
      matters,
      timekeepers,
      expanded
    } = this.props
    const modules = window.credentials.modules
    document.cookie = `invoice_list=${invoices.map(inv => inv.id)}`
    const invoicesNum = invoices.length >= 100 ? '100+' : invoices.length
    return (
      <Tabs>
        <Tab header={this.getTitle('Invoices', invoicesNum)}>
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={invoices}
              fallback={'No Invoices'}
              render={({ id, number, total, createdDate, matterName, vendorName, flags }) => {
                return (
                  <DashboardItem
                    testid={!expanded && `invoice_${number}`}
                    title={number}
                    secondaryTitle={total}
                    onClick={this.goToInvoice}
                    subTitle={matterName}
                    secondarySubTitle={vendorName}
                    flags={flags}
                    date={createdDate}
                    dateLabel="Received"
                    url={`/invoices/${id}`}
                  />
                )
              }}
            />
          </div>
        </Tab>
        <Tab
          isVisible={modules.includes(MODULE.MATTER_REVIEWS)}
          header={this.getTitle('Matters', matters.length)}
        >
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={matters}
              fallback={'No Matters'}
              render={({ id, name, clientMatterId, createdDate, description }) => {
                return (
                  <DashboardItem
                    title={name}
                    subTitle={clientMatterId}
                    secondarySubTitle={description}
                    date={createdDate}
                    dateLabel="Created"
                    url={`/v2/matters/${id}`}
                  />
                )
              }}
            />
          </div>
        </Tab>
        <Tab
          isVisible={
            modules.includes(MODULE.LEGAL_REQUESTS) &&
            window.credentials.user.role === ORG_ROLE.ADMIN
          }
          header={this.getTitle('Legal Requests', legalRequests.length)}
        >
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={legalRequests}
              fallback={'No Legal Requests'}
              render={({ id, name, createdDate, requestedBy }) => (
                <DashboardItem
                  title={name}
                  subTitle={requestedBy}
                  date={createdDate}
                  dateLabel="Received"
                  url={`/v2/matters/requests/process/${id}`}
                />
              )}
            />
          </div>
        </Tab>
        <Tab
          isVisible={modules.includes(MODULE.BUDGET_REQUESTS)}
          header={this.getTitle('Budgets', budgets.length)}
        >
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={budgets}
              fallback={'No Budget Requests'}
              render={({ id, name, total, createdDate, matterName, vendorName }) => {
                return (
                  <DashboardItem
                    title={name}
                    secondaryTitle={total}
                    subTitle={matterName}
                    secondarySubTitle={vendorName}
                    date={createdDate}
                    dateLabel="Created"
                    url={`/budgets/${id}`}
                  />
                )
              }}
            />
          </div>
        </Tab>
        <Tab
          isVisible={modules.includes(MODULE.VENDOR_SURVEYS)}
          header={this.getTitle('Vendor Surveys', vendorSurveys.length)}
        >
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={vendorSurveys}
              fallback={'No Vendor Surveys'}
              render={({ name, dueDate, matterName, vendorName, link }) => {
                return (
                  <DashboardItem
                    title={name}
                    subTitle={matterName}
                    secondarySubTitle={vendorName}
                    dueDate={dueDate}
                    url={link}
                  />
                )
              }}
            />
          </div>
        </Tab>
        <Tab
          isVisible={
            window.credentials.user.role === ORG_ROLE.ADMIN && modules.includes(MODULE.TIMEKEEPERS)
          }
          header={this.getTitle('Timekeepers', timekeepers.length)}
        >
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={timekeepers}
              fallback={'No Timekeepers'}
              render={({ id, receivedDate, numTimekeepers, vendorName, vendorId, fileStatus }) => {
                return (
                  <DashboardItem
                    title={vendorName}
                    subTitle={`${numTimekeepers} Timekeepers`}
                    secondarySubTitle={fileStatus}
                    date={receivedDate}
                    dateLabel="Received"
                    url={`/timekeepers/pending?upload=${id}&vendor=${vendorId}`}
                  />
                )
              }}
            />
          </div>
        </Tab>
      </Tabs>
    )
  }
}

@connect(({ dashboard }) => {
  const { invoices, budgets, legalRequests, vendorSurveys, matters, timekeepers } = dashboard

  const numItemsNeedMyAction =
    invoices.length +
    budgets.length +
    legalRequests.length +
    vendorSurveys.length +
    matters.length +
    timekeepers.length

  return {
    numItemsNeedMyAction
  }
})
@withFetchFlow({
  flag: 'Items_Needing_My_Action',
  render: () => <DashboardSkeleton />,
  getFetchAction: () => ({
    type: ACT.ITEMS_NEEDING_MY_ACTION_FETCH_REQUESTED
  })
})
class ItemsNeedMyAction extends Component {
  render() {
    const { numItemsNeedMyAction } = this.props
    const limitedNumItems = numItemsNeedMyAction > 100 ? '100+' : numItemsNeedMyAction
    const title = numItemsNeedMyAction ? (
      <span className={s.dashPillContainer}>
        <Badge
          style={{ background: blue800 }}
          title={limitedNumItems}
          content={limitedNumItems}
          className={s.dashPill}
          asPill
          size="md"
        />
        <span className={s.dashPillHeader}>Items Need My Action</span>
      </span>
    ) : (
      'No Items Need My Action'
    )

    return (
      <Panel
        className="dashboard-panel"
        rightActions={[
          <Expand key={0} title={title}>
            <InnerPanel expanded />
          </Expand>
        ]}
        title={title}
      >
        <InnerPanel />
      </Panel>
    )
  }
}

export default ItemsNeedMyAction
