import { useState, useEffect, useMemo, useContext } from 'react'
import { AxiosError } from 'axios'
import pluralize from 'pluralize'
import { format, getYear, isBefore } from 'date-fns'
import {
  Button,
  DataTableWrapper,
  AvatarList,
  ButtonDropdown,
  Ellipsis,
  useLoading,
  ModalContainer,
  GoogleCalendarIcon,
  MicrosoftOfficeOutlookIcon,
  AppleCalendarIcon
} from 'simple-core-ui'
import s from './Events.scss'
import { Tabs } from './Tabs'
import {
  canEditEvent,
  createQueryParamsUrl,
  createFiltersObjectFromQueryString,
  updateUrlToUseUtcTime,
  isEventsCalendarSyncEnabled,
  isEventNotDeletable,
  getInitParams
} from './utils'
import { ActionsPopover } from './ActionsPopover'
import { Event, EventFilters, BulkEditValues, Cell } from './types'
import { useImmer, Updater } from 'use-immer'
import { toEvents, fromEvent, serializeBulkEditOptions } from './serializers'
import {
  openLink,
  sortAlphabeticallyByProperty,
  removeLabelsFromURL,
  updateUrlFragment,
  isBasicTaskManagementPlan
} from 'utils/helpers'
import { BsPersonPlus } from 'react-icons/bs'
import { MdOutlineComment, MdOutlineAttachFile } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import cn from 'classnames'
import { FaList } from 'react-icons/fa'
import { AddEvent } from './AddEvent'
import { makeGetRequest, makeDeleteRequest, makePostRequest, makePatchRequest } from 'utils/api'
import { ConfirmationDialog } from './ConfirmationDialog'
import { Filters } from './Filters'
import ReactTooltip from 'react-tooltip'
import { BulkEditModal } from './BulkEditModal'
import { BULK_EDIT_INITIAL_VALUES } from './constants'
import { useLocation, Link } from 'react-router-dom'
import qs from 'query-string'
import ThemeContext from 'context/ThemeContext'
import { CalendarSyncModal } from 'common/CalendarSyncModal'
import { FaLocationDot } from 'react-icons/fa6'

interface Props {
  scopeId?: string
  readOnly?: boolean
  scopeName?: string
  context: 'workbench' | 'matter'
  baseUrl: string
  restrictAllActions?: boolean
}

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const BULK_OPTIONS = [
  { label: 'Edit', value: 'edit' },
  { label: 'Delete', value: 'delete' }
]

const bulkEditInitialState = {
  isBulkEditDialog: false,
  options: BULK_EDIT_INITIAL_VALUES,
  isBulkEditConfirmationDialog: false
}

const updateUrlHash = (id: number | string) => {
  const parsedQuery = qs.parse(window.location.search)
  const parsedHash = qs.parse(window.location.hash, { decode: false })

  const mergedQuery = {
    ...parsedQuery,
    id
  }
  const queryString = qs.stringify(mergedQuery)
  const hashString = qs.stringify(parsedHash, { encode: false })

  window.history.replaceState(null, '', `?${queryString}${hashString ? `#${hashString}` : ''}`)
}

const loggedInUser = window.credentials.user

const Events = ({
  scopeId = '',
  readOnly = false,
  scopeName = '',
  context,
  baseUrl,
  restrictAllActions
}: Props) => {
  const initialEventFilters = {
    eventType: null,
    attendees: null,
    id: null,
    createdBy: null,
    comments: null,
    fileAttachments: null,
    date: null,
    createdDate: null,
    eventId: null,
    time: null,
    ...(context === 'workbench' ? { relatedMatter: null } : {})
  }

  const [localState, setLocalState] = useState(getInitParams(context, window.location.hash))
  const { params } = localState
  const [selectedTab, setSelectedTab] = useState(params.category)
  const [oldSelectedTab, setOldSelectedTab] = useState(params.category)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [events, setEvents]: [Event[], Updater<Event[]>] = useImmer<Event[]>([])
  const dispatch = useDispatch()
  const [view, setView] = useState('list')
  const [oldView, setOldView] = useState('list')
  const [isAddEventVisible, setIsAddEventVisible] = useState(false)
  const [editedEvent, setEditedEvent] = useState<Event | null>(null)
  const [oldEvent, setOldEvent] = useState<Event | null>(null)
  const [isLoading, withLoadingLocksGetEvents] = useLoading()
  const [, withLoadingLocks] = useLoading()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showBulkDeleteConfirmation, setShowBulkDeleteConfirmation] = useState(false)
  const [showBulkDeleteNotAllowed, setShowBulkDeleteNotAllowed] = useState(false)
  const [eventFilters, setEventFilters] = useState<EventFilters>(
    createFiltersObjectFromQueryString(window.location.href, context)
  )
  const [oldEventFilters, setOldEventFilters] = useState<EventFilters>(eventFilters)
  const [bulkEdit, setBulkEdit] = useState(bulkEditInitialState)
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const location = useLocation()
  const [_scopeId, setScopeId] = useState(scopeId)
  const { state } = useContext(ThemeContext)
  const [totalEntries, setTotalEntries] = useState(0)
  const [filteredTotal, setFilteredTotal] = useState(0)
  const [myEventsCount, setMyEventsCount] = useState(0)
  const [pastCount, setPastCount] = useState(0)
  const [invitationsCount, setInvitationsCount] = useState(0)
  const [upcomingEventsCount, setUpcomingEventsCount] = useState(0)
  const [isCalendarSyncModalVisible, setIsCalendarSyncModalVisible] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [detailsSubtab, setDetailsSubtab] = useState('')

  const isBasicPlan = isBasicTaskManagementPlan()

  const clearAllSelectedRows: () => void = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const editEvent = async (event: Event) => {
    try {
      if (event.id) {
        const tsk = await withLoadingLocks(makeGetRequest(`${baseUrl}/events/${event.id}/`))
        setIsAddEventVisible(true)
        setEditedEvent(toEvents([tsk])[0])
        setOldEvent(toEvents([tsk])[0])
        updateUrlHash(event.id)
      } else {
        setIsAddEventVisible(true)
        setEditedEvent(event as Event)
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'The event no longer exists',
            message: `We couldn't find the event you referenced. It may have been deleted.`,
            level: 'error'
          }
        })
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
    clearAllSelectedRows()
  }

  const openDetailsSubtab = (id: number, subtab: string) => {
    setDetailsSubtab(subtab)
    editEvent({ id } as Event)
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Name',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell, row: Event) => {
          return (
            <>
              <div style={{ float: 'left' }}>
                <a style={{ cursor: 'pointer' }} onClick={() => row && editEvent(row)}>
                  <Ellipsis className={s.nameCell}>
                    {typeof cell.content === 'string' && cell.content}
                  </Ellipsis>
                </a>
              </div>
            </>
          )
        }
      },
      {
        columnKey: 'eventId',
        content: 'Event ID',
        isSortable: true,
        isFilterable: true
      },
      ...(context === 'workbench'
        ? [
            {
              columnKey: 'relatedMatter',
              content: 'Related To',
              isSortable: true,
              isFilterable: true,
              filterableBy: 'label',
              render: (cell: Cell) => {
                if (typeof cell.content === 'object' && 'label' in cell.content) {
                  return (
                    <Link to={`/v2/matters/${cell.content.value}?fromEventsWorkbench=true`}>
                      <Ellipsis width={120} lines={1}>
                        {cell.content.label}
                      </Ellipsis>
                    </Link>
                  )
                }
                return '--'
              }
            }
          ]
        : []),
      {
        columnKey: 'attendees',
        content: 'Attendees',
        isSortable: false,
        isFilterable: true,
        filterableBy: 'label',
        render: (cell: Cell, row: Event) => {
          return Array.isArray(cell.content) && !cell.content.length ? (
            !readOnly && row && canEditEvent(row, context) ? (
              <span onClick={() => editEvent(row)} className={s.noAssignee}>
                <BsPersonPlus />
              </span>
            ) : (
              '--'
            )
          ) : (
            <AvatarList
              size={Array.isArray(cell.content) && cell.content.length > 1 ? 'sm' : 'md'}
              limit={2}
              avatarStyles={{ marginLeft: 0 }}
              entries={sortAlphabeticallyByProperty(
                Array.isArray(cell.content) ? cell.content : [],
                'label'
              )}
            />
          )
        }
      },
      {
        columnKey: 'startDate',
        content: 'Date',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell) => {
          if (cell.content === '----') {
            return '--'
          }
          return (
            <span style={isBefore(cell.content as Date, new Date()) ? { color: '#bb342f' } : {}}>
              {!(cell.content instanceof Date)
                ? '--'
                : format(
                    cell.content,
                    getYear(cell.content) === getYear(new Date()) ? 'MMM dd' : 'MMM dd yyyy'
                  )}
            </span>
          )
        }
      },
      {
        columnKey: 'endDate',
        content: 'Time',
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          return (
            <span>
              {cell.content === '----' ||
              !(row.startDate instanceof Date) ||
              !(row.endDate instanceof Date)
                ? '--'
                : `${format(row.startDate, 'h:mm a')} - ${format(row.endDate, 'h:mm a')}`}
            </span>
          )
        }
      },
      {
        columnKey: 'timezone',
        content: 'Time Zone',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'eventType',
        content: 'Event Type',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        render: (cell: Cell) => {
          return cell.content === '----' ? (
            '--'
          ) : (
            <Ellipsis width={150} lines={1}>
              {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
            </Ellipsis>
          )
        }
      },
      {
        columnKey: 'location',
        content: <FaLocationDot style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          const content = cell.content

          let location: JSX.Element | string = ''

          if (typeof content === 'object' && 'address' in content) {
            location = content.address

            if ('name' in content && content.name.trim() !== content.address.trim()) {
              location = (
                <>
                  <b>{content.name}</b> {content.address}
                </>
              )
            }
          }

          return (
            <>
              <FaLocationDot
                data-for={`location-${row.id}`}
                data-tip
                className={s.tableIcons}
                onClick={() => openDetailsSubtab(row.id, 'location')}
              />
              {content && content !== '----' ? (
                <>
                  <span style={{ color: '#3C98FD' }}>1</span>
                  <ReactTooltip
                    id={`location-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {location}
                  </ReactTooltip>
                </>
              ) : (
                0
              )}
            </>
          )
        }
      },
      {
        columnKey: 'commentsCount',
        content: <MdOutlineComment style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          const content = cell.content as string
          return (
            <>
              <MdOutlineComment
                className={s.tableIcons}
                data-for={`comments-${row.id}`}
                data-tip
                onClick={() => openDetailsSubtab(row.id, 'comments')}
              />
              {+content > 10 ? (
                <>
                  <span style={{ color: '#3C98FD' }}>10+</span>
                  <ReactTooltip
                    id={`comments-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : content === '----' ? (
                <span style={{ color: '#3C98FD' }}>0</span>
              ) : (
                <span style={{ color: '#3C98FD' }}>{content}</span>
              )}
            </>
          )
        }
      },
      {
        columnKey: 'fileAttachmentsCount',
        content: <MdOutlineAttachFile style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          const content = cell.content as string

          return (
            <>
              <MdOutlineAttachFile
                className={s.tableIcons}
                data-for={`files-${row.id}`}
                data-tip
                onClick={() => openDetailsSubtab(row.id, 'files')}
              />
              {+content > 10 ? (
                <>
                  <span style={{ color: '#3C98FD' }}>10+</span>
                  <ReactTooltip
                    id={`files-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : content === '----' ? (
                <span style={{ color: '#3C98FD' }}>0</span>
              ) : (
                <span style={{ color: '#3C98FD' }}>{content}</span>
              )}
            </>
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, events])

  if (eventFilters !== oldEventFilters) {
    setOldEventFilters(eventFilters)
  }

  const isFiltered = useMemo(() => {
    return Object.values(eventFilters).some(f => f !== null)
  }, [eventFilters])

  const filteredEvents = useMemo(() => {
    return events.filter(t => !t.hidden)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, eventFilters])

  useEffect(() => {
    setLocalState(state => ({
      ...state,
      params: {
        ...state.params,
        page: 1,
        category: selectedTab
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFilters, selectedTab])

  useEffect(() => {
    clearAllSelectedRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFilters, selectedTab, localState.params.search])

  const updateUrl = (queryString: string) => {
    const frg = updateUrlFragment(queryString)

    window.history.replaceState(null, '', frg)
  }

  const fetchEvents = async ({
    filters,
    tableParams,
    cb,
    hash,
    tab
  }: {
    filters?: EventFilters
    tableParams?: Params
    cb?: (url: string) => void
    hash?: string
    tab?: string
  } = {}) => {
    const eventFiltersMap = {
      eventType: 'event_type',
      name: 'name',
      startDate: 'start_date',
      eventId: 'event_id',
      relatedMatter: 'matter_id'
    }
    try {
      const base = `columnKey=${
        eventFiltersMap[(tableParams || params).ordering.columnKey as keyof typeof eventFiltersMap]
      }&isDesc=${Number((tableParams || params).ordering.isDesc)}&search=${
        (tableParams || params).search
      }&page_number=${(tableParams || params).page}&page_size=${(tableParams || params).pageSize}`

      const queryString = hash
        ? updateUrlToUseUtcTime(removeLabelsFromURL(hash.split('#')[1]))
        : `${base}&${createQueryParamsUrl(filters || eventFilters)}`

      const { rows, totalEntries } = await withLoadingLocksGetEvents(
        makeGetRequest(`${baseUrl}/events/?${queryString}`)
      )

      setEvents(
        toEvents(rows, (events: Event[]) => {
          if (context === 'workbench') {
            return events.map(t =>
              t?.relatedMatter?.status === 'closed' || !t?.relatedMatter?.canEdit
                ? { ...t, canEdit: false }
                : t
            )
          }
          return events
        })
      )
      setFilteredTotal(totalEntries)

      cb?.(
        hash
          ? hash.split('#')[1]
          : `${base}&category=${tab ?? selectedTab}&${createQueryParamsUrl(
              filters || eventFilters,
              true
            )}`
      )
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onChangeTabCb = (selectedTab: string) => {
    setOldSelectedTab(selectedTab)

    if (selectedTab === oldSelectedTab) return

    const loggedInUserObj = {
      operator: {
        label: 'Is',
        value: 'IS'
      },
      values: [
        {
          value: loggedInUser.id,
          label: loggedInUser.firstName + ' ' + loggedInUser.lastName
        }
      ]
    }

    let newFilters

    switch (selectedTab) {
      case 'all':
        newFilters = initialEventFilters
        break
      case 'mine':
        newFilters = {
          ...initialEventFilters,
          createdBy: loggedInUserObj
        }
        break
      case 'past':
        newFilters = {
          ...initialEventFilters,
          date: {
            operator: {
              label: 'Is before',
              value: 'IS_BEFORE'
            },
            values: [
              {
                value: format(new Date(), 'yyyy-MM-dd'),
                label: 'Start Date'
              }
            ]
          },
          time: {
            operator: {
              label: 'Is before',
              value: 'IS_BEFORE'
            },
            values: [
              {
                value: format(new Date(), 'HH:mm'),
                label: 'Start Time'
              }
            ]
          }
        }
        break
      case 'upcoming':
        newFilters = {
          ...initialEventFilters,
          date: {
            operator: {
              label: 'Is after',
              value: 'IS_AFTER'
            },
            values: [
              {
                value: format(new Date(), 'yyyy-MM-dd'),
                label: 'Start Date'
              }
            ]
          },
          time: {
            operator: {
              label: 'Is after',
              value: 'IS_AFTER'
            },
            values: [
              {
                value: format(new Date(), 'HH:mm'),
                label: 'Start Time'
              }
            ]
          }
        }
        break
      case 'invitations':
        newFilters = {
          ...initialEventFilters,
          attendees: loggedInUserObj
        }
        break
      default:
        break
    }

    if (!newFilters) return

    fetchEvents({ filters: newFilters, cb: updateUrl, tab: selectedTab })
    setEventFilters(newFilters)
  }

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
    onChangeTabCb(tab)
  }

  const clearFilters = () => {
    setEventFilters(initialEventFilters)
    fetchEvents({ filters: initialEventFilters, cb: updateUrl })
  }

  useEffect(() => {
    const hash = location.hash
    fetchEvents({ cb: updateUrl, hash })
    setLocalState(getInitParams(context, hash))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (oldView !== view) {
      fetchEvents({ cb: updateUrl })
    }
    setOldView(view)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  const fetchEventsCounts = async () => {
    try {
      const {
        totalEntries,
        myEventsCount,
        pastEventsCount,
        myInvitationsCount,
        upcomingEventsCount
      } = await withLoadingLocks(makeGetRequest(`${baseUrl}/events/counts`))
      setTotalEntries(totalEntries)
      setMyEventsCount(myEventsCount)
      setPastCount(pastEventsCount)
      setInvitationsCount(myInvitationsCount)
      setUpcomingEventsCount(upcomingEventsCount)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchEventsCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const parsedQuery = qs.parse(location.search)

    if (parsedQuery.id) {
      editEvent({ id: +parsedQuery.id } as Event)
    }
    if (parsedQuery.subtab) {
      changeTab(parsedQuery.subtab as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectRow = ({ id }: { id: number }) => {
    setAllRowsSelected(false)

    if (selectedRows.includes(id)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
    }
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(
      allRowsSelected ? [] : filteredEvents.filter(r => r.canEdit !== false).map(t => t.id)
    )
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })

    fetchEvents({ filters: eventFilters, tableParams: params, cb: updateUrl })
  }

  const downloadXlsx = async () => {
    const { columnKey, isDesc } = params.ordering
    const mappedTab: Record<string, string> = {
      upcoming: 'upcomingEvents',
      mine: 'myEvents',
      invitations: 'invitationsEvents',
      past: 'pastEvents'
    }

    clearAllSelectedRows()

    const url = `${baseUrl}/events/export/?columnKey=${columnKey}&isDesc=${+isDesc}${
      selectedTab !== 'all' ? `&${mappedTab[selectedTab]}` : ''
    }&${createQueryParamsUrl(eventFilters)}&search=${params.search}${
      context === 'workbench' ? '&template_id=is_not_set' : ''
    }`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(url)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const showDeleteModal = (event: Event) => {
    setShowDeleteConfirmation(true)
    setEditedEvent(event)
  }

  const onDeleteEvent = async () => {
    const eventIdToDelete = editedEvent?.id
    try {
      await makeDeleteRequest(`${baseUrl}/events/${eventIdToDelete}/`)

      clearAllSelectedRows()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Event successfully deleted',
          level: 'success'
        }
      })
      fetchEvents()
      fetchEventsCounts()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    setShowDeleteConfirmation(false)
  }

  const refreshTable = () => {
    fetchEvents()
    fetchEventsCounts()
  }

  const duplicateEvent = async (event: Event) => {
    try {
      await withLoadingLocks(
        makePostRequest(
          `/event-management/matters/${_scopeId ||
            String(event?.relatedMatter?.value ?? '')}/events/clone/`,
          {
            object_id: event.id
          }
        )
      )

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Event ${event.name} successfully copied.`,
          level: 'success'
        }
      })

      refreshTable()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const renderCustomAction = (row: Event) => {
    if (readOnly || !canEditEvent(row, context)) {
      return <span className={s.actionsPlaceholder}>...</span>
    }
    return (
      <ActionsPopover
        event={row}
        editEvent={() => editEvent(row)}
        deleteEvent={showDeleteModal}
        duplicateEvent={duplicateEvent}
      />
    )
  }

  const toggleAddEventSidebar = () => {
    setIsAddEventVisible(!isAddEventVisible)
    setEditedEvent(null)
    setDetailsSubtab('')
  }

  const saveEvent = async (
    event: Event,
    isEdit: boolean | undefined,
    callback?: (event?: Event) => void,
    shouldRefreshTable?: boolean
  ) => {
    try {
      if (event.id) {
        const index = events.findIndex(p => p.id === event.id)
        const scopeIdFallback = _scopeId || String(editedEvent?.relatedMatter?.value ?? '')

        const response = await withLoadingLocks(
          makePatchRequest(
            `/event-management/matters/${scopeIdFallback}/events/${event.id}/`,
            fromEvent(event, scopeIdFallback, isEdit)
          )
        )
        const formattedResponse = toEvents([response])[0]
        setEvents(draft => {
          draft[index] = formattedResponse
        })

        callback && callback(formattedResponse)
        setOldEvent(formattedResponse)
        setEditedEvent(formattedResponse)

        if (shouldRefreshTable) {
          refreshTable()
        }
      } else {
        const newEvent = await withLoadingLocks(
          makePostRequest(
            `/event-management/matters/${_scopeId}/events/`,
            fromEvent(event, _scopeId)
          )
        )
        // fetch newly created event
        editEvent(toEvents([newEvent])[0])
        refreshTable()
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Event ${event?.name ?? editedEvent?.name} successfully ${
            isEdit ? 'updated' : 'created'
          }.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const updateBulkEvents = async () => {
    try {
      await withLoadingLocks(
        makePatchRequest(
          `${baseUrl}/events/bulk-operations/`,
          serializeBulkEditOptions(bulkEdit.options, selectedRows)
        )
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('event', selectedRows.length, true)} successfully updated.`,
          level: 'success'
        }
      })
      await fetchEvents()
    } catch (err) {
      const error = err as AxiosError
      const customMessage = `Failed to bulk edit the ${pluralize(
        'event',
        selectedRows.length
      )}. Please try again later. If the issue persists, please contact support for further assistance.`
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    setBulkEdit(bulkEditInitialState)
    clearAllSelectedRows()
  }

  const deleteBulkEvents = async () => {
    setShowBulkDeleteConfirmation(false)
    try {
      await withLoadingLocks(
        makeDeleteRequest(`${baseUrl}/events/bulk-operations/`, {
          data: { event_ids: selectedRows }
        })
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('event', selectedRows.length, true)} successfully deleted.`,
          level: 'success'
        }
      })
      refreshTable()
    } catch (err) {
      const error = err as AxiosError
      const customMessage =
        'Failed to bulk delete the events. Please try again later. If the issue persists, please contact support for further assistance.'
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    clearAllSelectedRows()
  }

  const handleBulkDeleteAction = () => {
    const isBulkDeleteNotAllowed = filteredEvents.some(
      event => selectedRows.includes(event.id) && isEventNotDeletable(event)
    )
    isBulkDeleteNotAllowed ? setShowBulkDeleteNotAllowed(true) : setShowBulkDeleteConfirmation(true)
  }

  const triggerBulkAction = (option: { value: string; label: string }) => {
    switch (option.value) {
      case 'edit':
        setBulkEdit(state => ({ ...state, isBulkEditDialog: true }))
        break
      case 'delete':
        handleBulkDeleteAction()
        break
      default:
        break
    }
  }

  const bulkActionsButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRows.length})`}
      options={BULK_OPTIONS}
      style={{ padding: '10px', borderRadius: '4px' }}
      listStyles={{ width: '100%', top: '42px' }}
      listItemStyles={{ fontSize: '14px' }}
      onSelect={triggerBulkAction}
    />
  )

  const cantBulkDeleteConfirmationContent = () => {
    const cantDeleteEventsList = filteredEvents
      .filter(event => selectedRows.includes(event.id) && isEventNotDeletable(event))
      .map(event => event.eventId)
    const cantDeleteEvents = cantDeleteEventsList.join(', ')
    const events = pluralize('event', cantDeleteEventsList.length)
    const are = pluralize('is', cantDeleteEventsList.length)
    const these = pluralize('this', cantDeleteEventsList.length)
    return (
      <>
        <div className={s.cantDeleteReason}>
          {`The following ${events} ${are} private ${events} and cannot be deleted: ${cantDeleteEvents}.`}
        </div>
        <div>{`Remove ${these} ${events} from your bulk selection to proceed.`}</div>
      </>
    )
  }

  const getPaginationTotalEntries = () => {
    switch (selectedTab) {
      case 'upcoming':
        return upcomingEventsCount
      case 'mine':
        return myEventsCount
      case 'invitations':
        return invitationsCount
      case 'past':
        return pastCount
      default:
        return filteredTotal
    }
  }

  return (
    <section>
      {isAddEventVisible && (
        <AddEvent
          toggleAddEventSidebar={toggleAddEventSidebar}
          events={events.map(t => ({ id: t.id, name: t.name }))}
          scopeName={scopeName}
          event={editedEvent}
          saveEvent={saveEvent}
          setEvents={setEvents}
          scopeId={scopeId}
          oldEvent={oldEvent}
          readOnly={readOnly}
          context={context}
          setScopeId={setScopeId}
          openedTab={detailsSubtab}
          refreshTable={refreshTable}
        />
      )}
      <div className={cn('box', { [s.noActions]: isAddEventVisible || restrictAllActions })}>
        <div className="box-content" style={{ minHeight: '70vh' }}>
          <div className={s.header}>
            <h2 className={s.title} data-testid="title">
              Events
            </h2>
            <div className={s.tabs}>
              <span
                className={cn({ [s.selected]: view === 'list' })}
                onClick={() => setView('list')}
              >
                <FaList /> List
              </span>
            </div>
            <span className={s.rightActions}>
              {!readOnly && isEventsCalendarSyncEnabled() && (
                <>
                  <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                    <Button
                      style={{
                        padding: '8px 15px',
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        bottom: 2
                      }}
                      key="sync"
                      onClick={() => setIsCalendarSyncModalVisible(true)}
                      isPrimary
                      isOutline
                      hasNewDesign
                      isDisabled={isBasicPlan}
                    >
                      Calendar Sync
                      <MicrosoftOfficeOutlookIcon className={s.calendarIcon} />
                      <GoogleCalendarIcon className={s.calendarIcon} />
                      <AppleCalendarIcon className={cn(s.calendarIcon, s.appleCalendarIcon)} />
                    </Button>
                  </div>
                  <ReactTooltip
                    id="buttonTooltip"
                    type="light"
                    effect="solid"
                    place="top"
                    border
                    disable={!isBasicPlan}
                    className={s.tooltipPopup}
                  >
                    Contact customer success to upgrade.
                  </ReactTooltip>
                </>
              )}
              <Button
                style={{ padding: '10px 15px' }}
                key="download"
                onClick={downloadXlsx}
                isPrimary
                isOutline
                hasNewDesign
              >
                Download
              </Button>
              {!readOnly && (
                <ButtonDropdown
                  displayText="Add"
                  onSelect={option => {
                    if (option.value === 'simpleEvent') {
                      toggleAddEventSidebar()
                    }
                    clearAllSelectedRows()
                  }}
                  options={[
                    { label: 'Create an event', value: 'simpleEvent' },
                    {
                      label: 'Add court rules',
                      value: 'courtRoules'
                    }
                  ]}
                  isPrimary
                  alignRight
                  listStyles={{ marginTop: 12, fontSize: 14 }}
                  style={{
                    padding: '10px 15px',
                    borderRadius: '4px',
                    marginLeft: 10,
                    position: 'relative',
                    bottom: 2
                  }}
                  listItemStyles={{ fontSize: 14, margin: '10px 0' }}
                  hasNewDesign
                />
              )}
            </span>
          </div>
          <>
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={changeTab}
              myEventsCount={myEventsCount}
              pastCount={pastCount}
              invitationsCount={invitationsCount}
              totalEventsCount={totalEntries}
              upcomingEventsCount={upcomingEventsCount}
            />
            <DataTableWrapper
              isLoading={isLoading}
              remotePagination
              alwaysShowLoadingSkeleton
              params={params}
              categories={[]}
              rows={filteredEvents}
              totalEntries={totalEntries}
              filteredTotal={getPaginationTotalEntries()}
              columns={columns}
              updateTable={updateTable}
              panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
              className={s.itemsTable}
              customAction={renderCustomAction}
              hasActions={!readOnly}
              alwaysShowActions
              categoryKey="event"
              hasTooltip
              selectAllRows={!readOnly ? selectAllRows : undefined}
              selectRow={!readOnly ? selectRow : undefined}
              selectedRows={new Set(selectedRows)}
              allRowsSelected={allRowsSelected}
              checkboxSize="md"
              tooltipClassName={s.tooltip}
              filters={
                <Filters
                  eventFilters={eventFilters}
                  setEventFilters={filters => {
                    setEventFilters(filters)
                    fetchEvents({ filters, cb: updateUrl })
                  }}
                  clearFilters={clearFilters}
                  baseUrl={baseUrl}
                  scopeId={scopeId}
                  context={context}
                />
              }
              bulkActions={bulkActionsButton}
              customStatusText={
                !isFiltered && filteredEvents.length === 0
                  ? 'There are currently none, click Add to get started.'
                  : undefined
              }
            />
          </>
        </div>
      </div>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete this event?"
          confirmText="Delete"
          content="This will also delete all of it's files and comments."
          onConfirm={onDeleteEvent}
          onCancel={() => {
            setShowDeleteConfirmation(false)
          }}
        />
      )}
      {showBulkDeleteConfirmation && (
        <ConfirmationDialog
          title={`Delete ${pluralize('event', selectedRows.length, true)}?`}
          confirmText="Delete"
          content="This will also delete all associated files, and comments? This action cannot be undone."
          onConfirm={deleteBulkEvents}
          onCancel={() => setShowBulkDeleteConfirmation(false)}
        />
      )}
      {showBulkDeleteNotAllowed && (
        <ConfirmationDialog
          title={`Can’t delete ${selectedRows.length} selected ${pluralize(
            'event',
            selectedRows.length
          )}`}
          content={cantBulkDeleteConfirmationContent()}
          hideButtons
          onCancel={() => setShowBulkDeleteNotAllowed(false)}
        />
      )}
      {bulkEdit.isBulkEditDialog && (
        <BulkEditModal
          eventsNumber={selectedRows.length}
          matterId={+scopeId}
          onUpdate={(options: BulkEditValues) =>
            setBulkEdit({ isBulkEditDialog: false, options, isBulkEditConfirmationDialog: true })
          }
          onCancel={() => setBulkEdit(bulkEditInitialState)}
          context={context}
        />
      )}
      {bulkEdit.isBulkEditConfirmationDialog && (
        <ConfirmationDialog
          title={`Update ${pluralize('event', selectedRows.length, true)}`}
          confirmText="Update"
          content={`Are you sure you want to update the ${pluralize(
            'event',
            selectedRows.length,
            true
          )} you selected?`}
          onConfirm={updateBulkEvents}
          onCancel={() => setBulkEdit(bulkEditInitialState)}
        />
      )}
      {isCalendarSyncModalVisible && (
        <CalendarSyncModal
          toggleModal={() => setIsCalendarSyncModalVisible(!isCalendarSyncModalVisible)}
        />
      )}
      {isDownloadModalOpen && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your export is ready."
          confirmText="OK"
          confirmCb={() => setIsDownloadModalOpen(false)}
          cancelCb={() => setIsDownloadModalOpen(false)}
          size="sm"
          hideCancelBtn
        />
      )}
    </section>
  )
}

export default Events
