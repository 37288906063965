import { Component } from 'react'
import moment from 'moment'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import swal from 'sweetalert'
import * as CONST from 'matters/templates/constants'
import * as ACT from 'matters/templates/actions'
import { ListTableContainer } from 'containers'
import s from '../MatterTemplatesListContainer.scss'
import { TEMPLATE_STATUSES } from 'matters/templates/constants'

const columns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  { columnKey: 'description', content: 'Description', isSortable: true, isFilterable: true },
  { columnKey: 'createdBy', content: 'Created By', isSortable: true, isFilterable: true },
  { columnKey: 'lastModified', content: 'Last Modified', isSortable: true, isFilterable: false },
  { columnKey: 'status', content: 'Status', isSortable: true, isFilterable: true }
]

class TableContainer extends Component {
  deleteTemplate = async templateData => {
    const template = this.props.matterTemplates.find(template => template.id === templateData.id)
    let title = ''
    let text = ''
    let successStatus = ''
    let buttons = []
    let willDelete
    let onSuccessActionType
    let status

    if (template.default) {
      title = 'Default Template'
      text = 'You need to set another template as default if you want to archive this template.'
      await swal({
        title: title,
        text: text,
        button: 'Ok',
        icon: 'warning'
      })
    } else {
      buttons = ['No', 'Yes']
      if (template.status.toLowerCase() === TEMPLATE_STATUSES.DRAFT) {
        title = 'Delete Template?'
        text = 'You will not be able to recover this template.'
        successStatus = 'deleted'
        onSuccessActionType = ACT.MATTER_TEMPLATES_DELETE_TEMPLATE
        status = 'deleted'
      } else if (template.status.toLowerCase() === TEMPLATE_STATUSES.ARCHIVED) {
        title = 'Restore Template?'
        text = 'This template will become Published.'
        successStatus = 'restored'
        onSuccessActionType = ACT.MATTER_TEMPLATES_RESTORE_TEMPLATE
        status = 'published'
      } else {
        title = 'Archive Template?'
        text = 'Users will no longer be able to create matters with this template.'
        successStatus = 'archived'
        onSuccessActionType = ACT.MATTER_TEMPLATES_ARCHIVE_TEMPLATE
        status = 'archived'
      }
      willDelete = await swal({
        title: title,
        text: text,
        buttons: buttons,
        icon: 'warning'
      })
      if (willDelete) {
        this.props.dispatch({
          type: ACT.MATTER_TEMPLATE_UPDATE_STATUS_REQUESTED,
          payload: {
            id: template.id,
            template: {
              status
            },
            onSuccessActionType
          }
        })
        swal(
          `${successStatus.toUpperCase()}!`,
          `The template has been ${successStatus}.`,
          'success'
        )
      }
    }
  }

  viewTemplateDetail = template => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_RESET_STATE
    })
    this.props.router.navigate('/v2/matters/templates/details/' + template.id)
  }

  getNormalizedCellContent = (columnKey, row) => {
    const { cells } = row
    const cellContent = get(
      cells.find(cell => cell.columnKey === columnKey),
      'content',
      ''
    )

    if (!cellContent) {
      return cellContent
    }

    switch (columnKey) {
      case 'name':
        return cellContent.toLowerCase()
      case 'description':
        return cellContent.toLowerCase()
      case 'createdBy':
        return cellContent.toLowerCase()
      case 'lastModified':
        return moment(cellContent, 'MM/DD/YYYY').unix()
      case 'status':
        return cellContent.toLowerCase()
      default:
        console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
        return ''
    }
  }

  render() {
    const { matterTemplates, disabled, maxTemplatesCount, activeTemplatesNo } = this.props
    const { DRAFT, ARCHIVED, PUBLISHED } = TEMPLATE_STATUSES

    const templatesList = matterTemplates.map((template, index) => {
      return {
        ...template,
        actions: {
          canDelete: true,
          useTrashcan: template.status.toLowerCase() === DRAFT,
          useRefresh: template.status.toLowerCase() === ARCHIVED && !disabled,
          useArchive: template.status.toLowerCase() === PUBLISHED,
          isSortable: true
        }
      }
    })

    const filterListItems = [
      { label: 'All', value: 'All' },
      { label: 'Active', value: [DRAFT, PUBLISHED] },
      { label: capitalize(ARCHIVED), value: [ARCHIVED] }
    ]

    const primaryAction = !disabled
      ? {
          href: '/v2/matters/templates/details',
          id: 'slm_add_new_template_button',
          children: 'New Template'
        }
      : null

    return (
      <div>
        <ListTableContainer
          objects={templatesList}
          columns={columns}
          title="Matter Templates"
          filters={
            !disabled && (
              <div className={s.buttonWrapper}>
                {maxTemplatesCount < CONST.MAX_TEMPLATES_COUNT && (
                  <p className={s.activeTemplatesLabel}>
                    {activeTemplatesNo}/{maxTemplatesCount} active templates
                  </p>
                )}
              </div>
            )
          }
          primaryAction={primaryAction}
          filterListItems={filterListItems}
          clickRowAction={this.viewTemplateDetail}
          getNormalizedCellContent={this.getNormalizedCellContent}
          deleteRow={this.deleteTemplate}
        />
      </div>
    )
  }
}

export default TableContainer
