import { useState, useEffect } from 'react'
import {
  DataTableWrapper,
  Modal,
  Panel,
  Button,
  withConfirmation,
  useLoading
} from 'simple-core-ui'
import { useDispatch } from 'react-redux'
import { makeGetRequest, makePostRequest, makeFormDataPost } from 'utils/api'
import MoreActions from './MoreActions'
import CreateReferralModal from './CreateReferralModal'
import CreateBudgetRequestModal from './CreateBudgetRequestModal'
import EditLeadForm from './EditLeadForm'
import EditPOForm from './EditPOForm'
import NotifyForm from './NotifyForm'
import RequestAccrualsModal from './RequestAccrualsModal'
import { serializeCreateBudgetRequest } from 'budgets/serializers'
import BUDGET_ACTIONS from 'budgets/actions'
import { serializeResults } from './serializers'
import { getNormalizedCellContent, getColumns } from './tableDefinitions'
import update from 'immutability-helper'
import moment from 'moment'
import { hasModule } from 'utils/helpers'

const REMOVE_CONF = {
  title: 'You are removing a vendor from this matter.',
  text: 'Do you wish to continue?'
}

function ReferralListContainer({ matterId, matterName, readOnly, hasPurchaseOrders }) {
  const [params, setParams] = useState({
    pageSize: 10,
    page: 1,
    search: '',
    ordering: { columnKey: 'vendor__vendor_name', isDesc: true },
    category: 'all'
  })
  const [results, setResults] = useState({ rows: [] })
  const [isReferralOpen, setIsReferralOpen] = useState('')
  const [isNotifyOpen, setIsNotifyOpen] = useState(false)
  const [isLeadOpen, setIsLeadOpen] = useState(false)
  const [isPOOpen, setIsPOOpen] = useState(false)
  const [isBudgetRequestOpen, setIsBudgetRequestOpen] = useState(false)
  const [selectedReferral, setSelectedReferral] = useState(null)
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [isLoadingTable, withLoadingLocksTable] = useLoading()
  const [isLoadingRemove, withLoadingLocksRemove] = useLoading()
  const [isLoadingAssign, withLoadingLocksAssign] = useLoading()
  const [currentLead, setCurrentLead] = useState(null)
  const [currentTimekeeper, setCurrentTimekeeper] = useState(null)
  const [currentPO, setCurrentPO] = useState(null)
  const [notifyMessage, setNotifyMessage] = useState('')
  const [notifySubject, setNotifySubject] = useState(
    `Matter: ${matterName} from ${window.credentials.user.clientName}`
  )
  const [notifyUser, setNotifyUser] = useState('')
  const [accrualPeriod, setAccrualPeriod] = useState(null)
  const [vendorsWithBudgets, setVendorsWithBudgets] = useState(null)
  const [isAccrualsOpen, setIsAccrualsOpen] = useState(false)
  const dispatch = useDispatch()

  const updateTable = async tableOpts => {
    setParams(tableOpts)
  }

  async function fetchReferralList() {
    try {
      const { results, total } = await withLoadingLocksTable(
        makeGetRequest(`/manage/matters/referral_list/${matterId}/`, {
          params: {
            page: params.page,
            pageSize: params.pageSize
          }
        })
      )
      const rows = serializeResults(results)
      setResults({ rows, total })
    } catch (error) {
      if (error.response.status === 403) {
        return
      }
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    async function fetchAccrualPeriod() {
      try {
        const { period_end } = await makeGetRequest('/manage/matters/accrual_period/')
        setAccrualPeriod(period_end)
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({ type: 'API_ERROR', error })
      }
    }

    async function fetchVendorsWithBudgets() {
      try {
        const { vendors_with_budgets } = await makeGetRequest(
          `/manage/matters/vendors_with_budgets/${matterId}/`
        )
        setVendorsWithBudgets(vendors_with_budgets)
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({ type: 'API_ERROR', error })
      }
    }
    fetchReferralList()
    fetchAccrualPeriod()
    fetchVendorsWithBudgets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editLead = rowId => {
    setIsLeadOpen(true)
    const row = results.rows.find(row => row.id === rowId)
    setSelectedVendor({ id: row.vendor_id, name: row.vendor__vendor_name })
    setSelectedReferral(rowId)

    const initialTimekeeper = row.referral_vendor_contact__timekeeper_id
      ? {
          value: row.referral_vendor_contact__timekeeper_id,
          label: row.referral_vendor_contact__timekeeper_full_name
        }
      : null

    setCurrentTimekeeper(initialTimekeeper)

    const initialLead = row.referral_vendor_contact
      ? {
          value: row.referral_vendor_contact,
          label: row.referral_vendor_contact_name
        }
      : null
    setCurrentLead(initialLead)
  }

  const editPO = rowId => {
    setIsPOOpen(true)
    const row = results.rows.find(row => row.id === rowId)
    setSelectedVendor({ id: row.vendor_id, name: row.vendor__vendor_name })
    setSelectedReferral(rowId)

    const initialPO = row.po__po
      ? {
          value: row.po__id,
          label: row.po__po
        }
      : null
    setCurrentPO(initialPO)
  }

  const requestBudget = rowId => {
    const row = results.rows.find(row => row.id === rowId)
    setIsBudgetRequestOpen(true)
    setSelectedReferral(rowId)
    setSelectedVendor({ id: row.vendor_id, name: row.vendor__vendor_name })
  }

  const notify = async rowId => {
    setSelectedReferral(rowId)
    try {
      const row = results.rows.find(row => row.id === rowId)
      const response = await makeGetRequest(`/manage/matters/refer_vendor/email/${matterId}`)
      const vendor = {
        id: row.vendor_id,
        name: row.vendor__vendor_name,
        email: row.referral_vendor_contact__primary_email__email ?? row.vendor__vendor_email
      }
      setNotifyMessage(response)
      setIsNotifyOpen(true)
      setSelectedVendor(vendor)
      vendor.email && setNotifyUser([{ label: vendor.email, value: vendor.email }])
    } catch (error) {
      if (error.response.status === 403) {
        return
      }
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const submitNotify = async () => {
    try {
      const users = notifyUser.map(n => n.value).join(';')
      const body = {
        message_to: users,
        message_subject: notifySubject,
        message_body: notifyMessage,
        vendor: selectedVendor?.id
      }

      setIsNotifyOpen(false)
      const response = await withLoadingLocksAssign(
        makeFormDataPost(`/manage/matters/refer_vendor_notify/${matterId}/`, body)
      )

      const index = results.rows.findIndex(row => row.id === selectedReferral)
      const newState = update(results, {
        rows: {
          [index]: {
            $set: { ...results.rows[index], notified_date: moment().format('MM/DD/YYYY') }
          }
        }
      })

      setResults(newState)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          level: 'success',
          message: `You notified ${users}`
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const removeReferral = withConfirmation(async rowId => {
    try {
      const row = results.rows.find(row => row.id === rowId)
      const url = `/manage/matters/refer_vendor_delete/${matterId}/${row.vendor_id}/`
      const response = await withLoadingLocksRemove(makePostRequest(url))

      const newRows = results.rows.filter(row => row.id !== rowId)
      const newResults = {
        ...results,
        rows: [...newRows]
      }

      setResults(newResults)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }, REMOVE_CONF)

  const submitBudgetRequest = ({ vendorId, matterId, ...budgetRequestForm }) => {
    dispatch({
      type: BUDGET_ACTIONS.SUBMIT_BUDGET_REQUESTED,
      payload: {
        vendorId,
        matterId,
        ...budgetRequestForm
      }
    })
    cancelCb()
  }

  const cancelCb = () => {
    setIsBudgetRequestOpen(false)
    dispatch({ type: BUDGET_ACTIONS.CLEAR_BUDGET_FORM })
  }

  const submitLead = async () => {
    setIsLeadOpen(false)
    try {
      const createReferralUrl = '/manage/matters/referrals/'

      const createReferralBody = {
        value: currentLead ? currentLead.value : '',
        pk: selectedReferral,
        field: 'matter_vendor_contact'
      }

      await withLoadingLocksAssign(makeFormDataPost(createReferralUrl, createReferralBody))

      const rowIndex = results.rows.findIndex(row => row.id === selectedReferral)
      const row = results.rows[rowIndex]

      if (currentLead) {
        await makePostRequest(`/manage/contacts/${currentLead?.value}/associate_timekeeper/`, {
          tk_id: currentTimekeeper?.value ? currentTimekeeper.value : null
        })
      }

      if (!currentLead && row.referral_vendor_contact) {
        await makePostRequest(
          `/manage/contacts/${row.referral_vendor_contact}/associate_timekeeper/`,
          {
            tk_id: null
          }
        )
      }

      await fetchReferralList()

      updateTable(params)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const submitPO = async () => {
    setIsPOOpen(false)
    try {
      const createReferralUrl = '/manage/matters/referrals/'

      const createReferralBody = {
        value: currentPO ? currentPO.value : '',
        pk: selectedReferral,
        field: 'purchase_order'
      }

      await withLoadingLocksAssign(makeFormDataPost(createReferralUrl, createReferralBody))
      await fetchReferralList()

      updateTable(params)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const addReferral = async ({
    vendorId,
    matterId,
    vendorContactId,
    budgetRequested,
    timekeeperId,
    ...budgetRequestForm
  }) => {
    setIsReferralOpen(false)
    try {
      const createReferralUrl = '/manage/matters/create_referral/'

      const createReferralBody = {
        matter: +matterId,
        vendor: vendorId,
        vendor_contact: vendorContactId
      }

      const vendor = await withLoadingLocksAssign(
        makePostRequest(createReferralUrl, createReferralBody)
      )

      const row = serializeResults([vendor])

      setResults({ rows: [...results.rows, row[0]], total: results.total })

      updateTable(params)

      if (budgetRequested) {
        dispatch({
          type: BUDGET_ACTIONS.SUBMIT_BUDGET_REQUEST,
          payload: {
            body: serializeCreateBudgetRequest({ vendorId, matterId, ...budgetRequestForm }),
            requested: true
          }
        })
      }

      if (timekeeperId) {
        await makePostRequest(`/manage/contacts/${vendorContactId}/associate_timekeeper/`, {
          tk_id: timekeeperId
        })
      }

      await fetchReferralList()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const actions = [
    { key: 'edit_lead', label: 'Edit Lead', callback: editLead },
    ...(hasPurchaseOrders
      ? [{ key: 'edit_purchase_order', label: 'Edit Purchase Order', callback: editPO }]
      : []),
    ...(hasModule('has_budget_requests')
      ? [{ key: 'request_budget', label: 'Request Budget', callback: requestBudget }]
      : []),
    { key: 'notify', label: 'Notify', callback: notify },
    { key: 'remove', label: 'Remove', callback: removeReferral }
  ]

  const submitAccruals = () => {}

  const actionButtons = [
    <Button key="0" isPrimary onClick={() => setIsReferralOpen(true)}>
      Assign Vendor
    </Button>
  ]

  if (results.total > 0) {
    actionButtons.push([
      <Button key="1" isSecondary onClick={() => setIsAccrualsOpen(true)}>
        Request Accruals
      </Button>
    ])
  }

  return (
    <Panel title="Vendors" rightActions={readOnly ? [] : actionButtons}>
      <DataTableWrapper
        initialValues={params}
        categories={[]}
        totalEntries={results.total}
        params={params}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        columns={getColumns(hasPurchaseOrders)}
        updateTable={updateTable}
        rows={results.rows}
        hasActions
        alwaysShowActions
        customAction={!readOnly ? row => <MoreActions rowId={row.id} actions={actions} /> : null}
        isLoading={isLoadingTable}
        getNormalizedCellContent={getNormalizedCellContent}
      />
      <CreateReferralModal
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        matterId={matterId}
        isVisible={isReferralOpen}
        submitCb={addReferral}
        cancelCb={() => setIsReferralOpen(false)}
      />
      <RequestAccrualsModal
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        matterId={matterId}
        isVisible={isAccrualsOpen}
        submitCb={submitAccruals}
        periodEnd={accrualPeriod}
        cancelCb={() => setIsAccrualsOpen(false)}
      />
      <CreateBudgetRequestModal
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        matterId={matterId}
        matterName={matterName}
        vendorId={selectedVendor?.id}
        vendorName={selectedVendor?.name}
        isVisible={isBudgetRequestOpen}
        submitCb={submitBudgetRequest}
        vendorsWithBudgets={vendorsWithBudgets}
        cancelCb={cancelCb}
      />
      <Modal
        title="Notify Vendor"
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        content={
          <NotifyForm
            user={notifyUser}
            subject={notifySubject}
            message={notifyMessage}
            setUser={setNotifyUser}
            setSubject={event => setNotifySubject(event.target.value)}
            setMessage={setNotifyMessage}
            vendorId={selectedVendor?.id}
          />
        }
        isDisabled={!notifyUser}
        isVisible={isNotifyOpen}
        confirmCb={submitNotify}
        cancelCb={() => {
          setIsNotifyOpen(false)
          setNotifyUser('')
        }}
      />
      <Modal
        title="Edit Lead"
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        content={
          <EditLeadForm
            selectedVendor={selectedVendor?.id}
            currentLead={currentLead}
            setCurrentLead={setCurrentLead}
            currentTimekeeper={currentTimekeeper}
            setCurrentTimekeeper={setCurrentTimekeeper}
          />
        }
        isVisible={isLeadOpen}
        confirmCb={submitLead}
        cancelCb={() => setIsLeadOpen(false)}
      />
      <Modal
        title="Edit Purchase Order"
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        content={
          <EditPOForm
            selectedVendor={selectedVendor?.id}
            currentPO={currentPO}
            setCurrentPO={setCurrentPO}
          />
        }
        isVisible={isPOOpen}
        confirmCb={submitPO}
        cancelCb={() => setIsPOOpen(false)}
      />
    </Panel>
  )
}

export default ReferralListContainer
