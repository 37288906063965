import keyMirror from 'keymirror'

export default keyMirror({
  FETCH_ALL_RULES_REQUESTED: null,
  FETCH_ALL_RULES_SUCCESS: null,
  UPDATE_RULE_OPERAND: null,
  RULE_FIELDS_FETCH_REQUESTED: null,
  RULE_FIELDS_FETCH_SUCCESS: null,
  SAVE_INVOICE_AI_REQUESTED: null,
  SAVE_INVOICE_AI_SUCCESS: null,
  CLEAR_OPERAND_OPERATOR: null,
  CLEAR_OPERAND_RHS: null,
  RULE_SAVE_REQUESTED: null,
  RULE_SAVE_SUCCESS: null,
  ADD_AND_OPERAND: null,
  REMOVE_RULE_OPERAND: null,
  RULE_ACTIONS_FETCH_REQUESTED: null,
  RULE_ACTIONS_FETCH_SUCCESS: null,
  CREATE_RULE_ACTION: null,
  UPDATE_RULE_ACTION: null,
  UPDATE_RULE_ACTION_PARAMETERS: null,
  UPDATE_RULE_METADATA: null,
  ADD_NEW_RULE: null,
  CLONE_RULE_REQUESTED: null,
  CLONE_RULE_SUCCESS: null,
  UNDO_RULE_CHANGE: null,
  REDO_RULE_CHANGE: null,
  SIMPLIFY_RULE: null,
  REMOVE_RULE_ACTION: null,
  MATTER_TEMPLATE_FILTER_TERM: null,
  MATTER_TEMPLATE_PREVIEW: null,
  RULE_DELETE_REQUESTED: null,
  RULE_DELETE_SUCCESS: null,
  UPDATE_RULES_FILTER_TYPE: null,
  UPDATE_RULES_FILTER_SEARCH: null,
  SUBMIT_RULE_DSL_REQUESTED: null,
  SUBMIT_RULE_DSL_SUCCESS: null,
  FETCH_ALL_DSL_RULES_REQUESTED: null,
  FETCH_ALL_DSL_RULES_SUCCESS: null,
  UPDATE_BOOLEAN_OPERATOR: null,
  FETCH_AI_SUCCESS: null,
  UPDATE_CURRENT_RULE_INDEX: null,
  REMOVE_RULE_CONDITION: null,
  PUSH_NOTIFICATION: null,
  CLEAR_RULE_CONDITION: null,
  REVERT_RULE_CHANGES: null,
  RULE_IS_SAVING: null,
  RULE_IS_NOT_SAVING: null,
  UPDATE_RULE_ENGINE: null,
  DSL_IS_LOADING: null,
  DSL_IS_NOT_LOADING: null,
  DSL_IS_EDIT: null,
  DSL_IS_NOT_EDIT: null,
  RULES_LIST_IS_LOADING: null,
  RULES_LIST_IS_NOT_LOADING: null
})
