import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { Row } from '../types'

type FolderAction = (rowId: Row['id'], name: string, depth?: number) => void

interface Props {
  rowId: Row['id']
  folderName: string
  rowDepth: number
  editFolder: FolderAction
  deleteFolder: FolderAction
  moveFolder: FolderAction
}
const ActionsPopover = ({
  rowId,
  folderName,
  rowDepth,
  editFolder,
  deleteFolder,
  moveFolder
}: Props) => {
  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
        delayHide={1}
        isCapture
        event="click"
        globalEventOff="click"
        className={s.tooltip}
      >
        <ul className={s.actionsList}>
          <li onClick={() => editFolder(rowId, folderName, rowDepth)}>Rename</li>
          <li onClick={() => moveFolder(rowId, folderName)}>Move</li>
          <li onClick={() => deleteFolder(rowId, folderName)}>Delete</li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
