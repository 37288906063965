import { components } from 'react-select'
import classNames from 'classnames'

import { StackList } from 'components'
import { normalizeTeam } from '../../reviews/reviewer_config/utils/utils'

import { PaginatedSelect } from 'simple-core-ui'

import s from './TeamSelect.scss'
import { toUsers } from '../serializers'

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <StackList stacks={[normalizeTeam(props.data)]} />
    </components.SingleValue>
  )
}

const MultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props}>
      <StackList stacks={[normalizeTeam(props.data)]} />
    </components.MultiValueLabel>
  )
}

const Option = props => {
  return (
    <components.Option {...props}>
      <StackList stacks={[normalizeTeam(props.data)]} />
    </components.Option>
  )
}

const TeamSelect = ({
  id,
  onChange,
  value,
  isDisabled,
  styles,
  searchParams,
  url = '/teams/search/',
  cappedInputHeight,
  multi,
  ariaLabel,
  placeholder,
  limit,
  serializer,
  isClearable,
  customQueryParams,
  maxMenuHeight,
  onMenuOpen,
  filterOption,
  menuIsOpen,
  closeMenuOnSelect,
  resultsProperty = 'results'
}) => {
  return (
    <PaginatedSelect
      id={id}
      url={url}
      value={value}
      className={classNames({
        [s.multi]: multi,
        [s.single]: !multi,
        [s.capped]: cappedInputHeight
      })}
      onChange={(value, action) => onChange(value || [], action)}
      isMulti={multi}
      serializer={serializer || toUsers}
      isClearable={isClearable}
      searchTermParam={searchParams}
      placeholder={placeholder}
      hasMoreProperty="more"
      resultsProperty={resultsProperty}
      limit={limit || 100}
      comps={{ MultiValueLabel, Option, SingleValue }}
      aria-label={ariaLabel}
      isDisabled={isDisabled}
      mandatoryQueryParams={customQueryParams}
      styles={styles}
      closeMenuOnSelect={closeMenuOnSelect}
      maxMenuHeight={maxMenuHeight}
      onMenuOpen={onMenuOpen}
      filterOption={filterOption}
      menuIsOpen={menuIsOpen}
    />
  )
}

export default TeamSelect
