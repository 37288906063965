import { IoIosArrowForward } from 'react-icons/io'

import TYPES from './types'

import s from './ComboBox.scss'

const Option = ({ option, selectionCb }) => (
  <li
    className={s.option}
    onClick={e => {
      e.stopPropagation()
      selectionCb(option)
    }}
  >
    <span>{option.label}</span>
    {option.isGroup && <IoIosArrowForward className={s.right} />}
  </li>
)

Option.propTypes = {
  option: TYPES.option.isRequired,
  selectionCb: TYPES.selectionCb.isRequired
}

export default Option
