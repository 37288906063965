import get from 'lodash/get'
import { TYPE, OPERATOR, DATE_OPTIONS } from 'data/operators'
import moment from 'moment'
import { INVOICE_AI_LABELS } from 'rules/constants'
import qs from 'query-string'
import {
  MultiSelectFilterSerializer,
  SingleSelectFilterSerializer
} from 'common/Filters/serializers'

export const toInvoiceAI = aiData =>
  Object.entries(aiData).map(([key, value]) => {
    return { label: INVOICE_AI_LABELS[key], key, value }
  })

export const toRhsView = {
  [TYPE.BOOLEAN]: rhs => {
    const constant = get(rhs, 'constant', {})
    const { label, value } = constant
    return {
      selected: {
        label,
        value,
        isGroup: false
      },
      label,
      type: TYPE.BOOLEAN
    }
  },
  [TYPE.LIST]: rhs => {
    const constant = get(rhs, 'constant', {})
    const { label, value } = constant

    // multi reference for IN operator
    if (get(constant, 'length')) {
      let joinLabel = constant.map(c => c.label).join(', ')
      return {
        selected: {
          label: joinLabel,
          value: constant,
          type: 'list'
        },
        label: joinLabel
      }
    }
    return {
      selected: {
        label,
        value,
        isGroup: false
      },
      label,
      type: 'string'
    }
  },
  [TYPE.STRING]: rhs => {
    const constant = get(rhs, 'constant', {})
    const { label, value } = constant
    // multi reference for IN operator
    if (get(rhs, 'type') === 'reference' && constant.length) {
      return {
        selected: {
          label,
          value: constant.map(c => ({ label: c.label, value: c.value })),
          isGroup: false
        },
        label,
        type: 'string'
      }
    }
    return {
      selected: {
        label,
        value,
        isGroup: false
      },
      label,
      type: 'string'
    }
  },
  [TYPE.DATE]: (rhs, operator) => {
    const constant = get(rhs, 'constant', {})
    const { label, value } = constant

    const DATEPICKER_ONLY_OPERATORS = [
      OPERATOR.BETWEEN,
      OPERATOR.NOT_BETWEEN,
      OPERATOR.ON,
      OPERATOR.NOT_ON
    ]

    const options = DATEPICKER_ONLY_OPERATORS.includes(operator)
      ? DATE_OPTIONS.map(d => ({ ...d, isGroup: false }))
      : []

    if (get(rhs, 'constant.start')) {
      return {
        options,
        selected: {
          label,
          value: { start: get(rhs, 'constant.value.start'), end: get(rhs, 'constant.value.end') }
        },
        label: `${moment(get(rhs, 'constant.value.start')).format('MM/DD/YYYY')} - ${moment(
          get(rhs, 'constant.value.end')
        ).format('MM/DD/YYYY')}`
      }
    }

    return {
      options,
      selected: {
        label,
        value
      },
      label
    }
  },
  [TYPE.CURRENCY]: rhs => {
    const constant = get(rhs, 'constant', {})
    const { label, value } = constant

    return {
      selected: {
        amount: value,
        code: { value: get(rhs, 'sub_type'), label: get(rhs, 'sub_type') }
      },
      label,
      type: 'currency'
    }
  },
  [TYPE.NUMBER]: rhs => {
    const constant = get(rhs, 'constant', {})
    const { label, value } = constant

    return {
      selected: {
        label,
        value
      },
      label,
      type: 'number'
    }
  },
  [TYPE.REFERENCE]: rhs => {
    const constant = get(rhs, 'constant', {})
    const { label, value } = constant

    // multi reference for IN operator
    if (get(constant, 'length')) {
      let joinLabel = constant.map(c => c.label).join(', ')
      return {
        selected: {
          label: joinLabel,
          value: constant,
          type: 'reference'
        },
        label: joinLabel
      }
    }

    return {
      selected: {
        label,
        value,
        type: 'reference'
      },
      label
    }
  }
}

export const toRuleNotation = {
  [TYPE.BOOLEAN]: selected => {
    return {
      constant: { value: selected.value, label: selected.label },
      type: TYPE.BOOLEAN
    }
  },
  [TYPE.STRING]: selected => {
    // for CONTAINS operator

    if (selected.length) {
      return {
        constant: selected.map(v => ({ value: v.value, label: v.label })),
        type: 'reference',
        sub_type: selected.sub_type,
        member: selected.member
      }
    }

    return {
      constant: { label: selected.label, value: selected.value },
      type: 'string'
    }
  },
  [TYPE.LIST]: selected => {
    // for CONTAINS operator
    if (Array.isArray(get(selected, 'value')) && get(selected, 'value.length')) {
      return {
        constant: selected.value.map(v => ({
          label: v.label,
          value: v.value
        })),
        type: 'list',
        sub_type: get(selected, 'value[0].sub_type'),
        member: get(selected, 'value[0].member')
      }
    }

    return {
      constant: { label: selected.label, value: selected.value },
      type: 'list'
    }
  },
  [TYPE.DATE]: selected => {
    // for date ranges
    if (selected.start) {
      return {
        constant: {
          label: selected.start.toString() + ' - ' + selected.end.toString(),
          value: {
            start: selected.start,
            end: selected.end
          }
        },
        type: 'date'
      }
    }

    return {
      constant: { label: selected.label, value: selected.value },
      type: 'date'
    }
  },
  [TYPE.CURRENCY]: selected => {
    return {
      constant: { label: `${selected.code.value} ${selected.amount}`, value: selected.amount },
      sub_type: selected.code.value,
      type: 'currency'
    }
  },
  [TYPE.NUMBER]: selected => {
    return {
      constant: { value: selected.value, label: selected.label },
      type: 'number'
    }
  },
  [TYPE.REFERENCE]: selected => {
    // multi reference for IN operator
    if (Array.isArray(get(selected, 'value')) && get(selected, 'value.length')) {
      return {
        constant: selected.value.map(v => ({
          label: v.label,
          value: v.value
        })),
        type: 'reference',
        sub_type: get(selected, 'value[0].sub_type'),
        member: get(selected, 'value[0].member')
      }
    }

    return {
      constant: { label: selected.label, value: selected.value },
      type: selected.type,
      sub_type: selected.sub_type,
      member: selected.member
    }
  }
}

export const toOperatorsByType = ops => {
  let operatorsByType = {}

  for (let op of ops) {
    for (let type of op.types) {
      operatorsByType[type] = operatorsByType[type]
        ? [...operatorsByType[type], { label: op.label, value: op.symbol }]
        : [{ label: op.label, value: op.symbol }]
    }
  }
  return operatorsByType
}

export const fromFragment = hash => {
  const {
    rule,
    rule_category,
    modified_by,
    last_modified,
    conditions,
    actions,
    page = 1,
    search = '',
    category = 'active',
    columnKey = 'name',
    isDesc = true
  } = qs.parse(hash)

  const ruleSelector = rule && MultiSelectFilterSerializer.fromFragment('rule', { rule })

  const ruleCategorySelector =
    rule_category && SingleSelectFilterSerializer.fromFragment('rule_category', { rule_category })

  const modifiedBySelector =
    modified_by && MultiSelectFilterSerializer.fromFragment('modified_by', { modified_by })

  const lastModifiedSelector = last_modified && {
    label: last_modified.replace('-', '/'),
    value: last_modified
  }

  const rulesConditionsSelector =
    conditions && MultiSelectFilterSerializer.fromFragment('conditions', { conditions })

  const rulesActionsSelector =
    actions && MultiSelectFilterSerializer.fromFragment('actions', { actions })

  return {
    name: ruleSelector,
    ruleCategory: ruleCategorySelector,
    modifiedBy: modifiedBySelector,
    lastModified: lastModifiedSelector,
    conditions: rulesConditionsSelector,
    actions: rulesActionsSelector,
    ...(page ? { page: Number(page) } : {}),
    ...(search ? { search } : {}),
    ...(category ? { category } : {}),
    ...(columnKey
      ? {
          ordering: {
            columnKey,
            isDesc: !!isDesc
          }
        }
      : {})
  }
}
