import { Title, VerticalTabs, VerticalTab } from 'simple-core-ui'
import { RejectionReasonPreference } from './RejectionReasonPreference'
import { RejectionReasonList } from './list'
import { AdjustmentReasonList } from './AdjustmentsContainer'
import { RuleCategoriesList } from './ruleCategoriesList'
import s from './InvoiceRejectionSettings.scss'

const renderTabs = (): Array<JSX.Element> => {
  const tabsList = []
  tabsList.push(
    <VerticalTab key="adjustment" label="Adjustment Reasons">
      <AdjustmentReasonList />
    </VerticalTab>
  )
  tabsList.push(
    <VerticalTab key="rejection" label="Rejection Reasons">
      <RejectionReasonList />
    </VerticalTab>
  )
  tabsList.push(
    <VerticalTab key="preferences" label="Preferences">
      <RejectionReasonPreference />
    </VerticalTab>
  )
  if (window.credentials.invoiceRulesManagementEnabled)
    tabsList.push(
      <VerticalTab key="ruleCategories" label="Invoice Validation Rule Categories">
        <RuleCategoriesList />
      </VerticalTab>
    )
  return tabsList
}

const InvoiceRejectionSettings = () => {
  return (
    <section className={s.container}>
      <Title text="Invoice Settings" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs sidebarClassName={s.nav} activeTabKey="adjustment">
          {renderTabs()}
        </VerticalTabs>
      </section>
    </section>
  )
}

export default InvoiceRejectionSettings
