import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { call, put, takeLatest } from 'redux-saga/effects'
import { makePostRequest, makePutRequest, getRequest } from 'utils/api'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'

import * as ACT from '../actions'
import {
  toAllocationsJson,
  toMatterTemplateJson,
  toMatterTemplateWithEvaluatorsJson,
  toDynamicDropdowns
} from '../serializers'

import { NotificationList } from 'components'

function* fetchMatterTemplatesAttributes(action) {
  try {
    const url = '/templates/attributes'
    const groups = yield call(getRequest, url)

    yield put({ type: ACT.MATTER_TEMPLATES_ATTRIBUTES_FETCH_SUCCESS, payload: { groups } })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Matter Templates attributes',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchMatterTemplatesDynamicDropdowns(action) {
  try {
    const url = '/templates/related/relationships_template_info'
    const { relationships } = yield call(getRequest, url)

    yield put({
      type: ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_SUCCESS,
      payload: { dynamicDropdowns: toDynamicDropdowns(relationships) }
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Matter Templates attributes',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* createMatterTemplate(action) {
  const {
    template,
    redirect,
    navigate,
    location,
    allocations,
    sharedEvaluators,
    imanageTemplateId,
    imanageReadOnly,
    folderStructure
  } = action.payload

  try {
    const url = '/templates/'
    const imanageUrl = '/doc_management/template_mapping/'
    const templateJsonTmp = toMatterTemplateJson(template)
    const templateJson = toMatterTemplateWithEvaluatorsJson(templateJsonTmp, sharedEvaluators)
    const response = yield makePostRequest(url, templateJson)

    yield put({
      type: redirect
        ? ACT.MATTER_TEMPLATE_CREATE_SUCCESS_AND_REDIRECT
        : ACT.MATTER_TEMPLATE_CREATE_SUCCESS,
      payload: {
        templateJson,
        id: response.id
      },
      loadingLock: 'off'
    })
    // set imanage template
    if (imanageTemplateId) {
      yield makePostRequest(imanageUrl, {
        template_id: response.id,
        imanage_template_id: imanageTemplateId,
        read_only: imanageReadOnly
      })
    }
    // set template allocations
    yield put({
      type: ACT.MATTER_TEMPLATE_SET_ALLOCATIONS_REQUESTED,
      payload: {
        templateId: response.id,
        allocations
      }
    })
    // set folder structure
    if (hasModule(MODULE.DMS_DEFAULT_FOLDERS) && hasModule(MODULE.NATIVE_DMS)) {
      yield put({
        type: ACT.SET_DEFAULT_FOLDERS_REQUESTED,
        payload: {
          templateId: response.id,
          folderStructure
        }
      })
    }
    // set isSaving flag
    yield put({
      type: ACT.MATTER_TEMPLATE_SET_IS_SAVING,
      payload: {
        isSaving: false
      }
    })
    if (!redirect) {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'The template was created successfully! You can continue editing',
          level: 'success'
        }
      })
      if (!location.pathname.includes(response.id)) {
        navigate(`/v2/matters/templates/details/${response.id}`, { replace: true })
      }
    }
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue creating the Matter Template',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error',
        loadingLock: 'off'
      }
    })
    // set isSaving flag
    yield put({
      type: ACT.MATTER_TEMPLATE_SET_IS_SAVING,
      payload: {
        isSaving: false
      },
      loadingLock: 'off'
    })
  }
}

function* updateMatterTemplate(action) {
  const {
    template,
    id,
    redirect,
    navigate,
    location,
    allocations,
    sharedEvaluators,
    imanageTemplateId,
    imanageReadOnly,
    folderStructure
  } = action.payload

  try {
    const url = `/templates/${id}/`
    const imanageUrl = '/doc_management/template_mapping/'
    const templateJsonTmp = toMatterTemplateJson(template)
    const templateJson = toMatterTemplateWithEvaluatorsJson(templateJsonTmp, sharedEvaluators)

    const response = yield makePutRequest(url, templateJson)
    yield put({
      type: redirect
        ? ACT.MATTER_TEMPLATE_CREATE_SUCCESS_AND_REDIRECT
        : ACT.MATTER_TEMPLATE_CREATE_SUCCESS,
      payload: {
        templateJson,
        id: response.id
      },
      loadingLock: 'off'
    })

    if (imanageTemplateId) {
      yield makePostRequest(imanageUrl, {
        template_id: response.id,
        imanage_template_id: imanageTemplateId,
        read_only: imanageReadOnly
      })
    }
    // set isSaving flag
    yield put({
      type: ACT.MATTER_TEMPLATE_SET_IS_SAVING,
      payload: {
        isSaving: false
      }
    })
    // set template allocations
    yield put({
      type: ACT.MATTER_TEMPLATE_SET_ALLOCATIONS_REQUESTED,
      payload: {
        templateId: response.id,
        allocations
      }
    })
    // set folder structure
    if (hasModule(MODULE.DMS_DEFAULT_FOLDERS) && hasModule(MODULE.NATIVE_DMS)) {
      yield put({
        type: ACT.SET_DEFAULT_FOLDERS_REQUESTED,
        payload: {
          templateId: response.id,
          folderStructure
        }
      })
    }

    if (!redirect) {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'All changes were saved successfully.',
          level: 'success'
        }
      })
      if (!location.pathname.includes(response.id)) {
        navigate(`/v2/matters/templates/details/${response.id}`)
      }
    }
  } catch (error) {
    if (error.response.data.breaking_changes) {
      yield put({
        type: ACT.MATTER_TEMPLATE_SET_BREAKING_WARNING,
        payload: {
          breakingWarning: error.response.data.breaking_changes.map(e => e.message)
        },
        loadingLock: 'off'
      })
      // set isSaving flag
      yield put({
        type: ACT.MATTER_TEMPLATE_SET_IS_SAVING,
        payload: {
          isSaving: false
        }
      })
    } else {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was an issue updating the Matter Template',
          message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
          level: 'error'
        },
        loadingLock: 'off'
      })
      // set isSaving flag
      yield put({
        type: ACT.MATTER_TEMPLATE_SET_IS_SAVING,
        payload: {
          isSaving: false
        }
      })
    }
  }
}

function* cloneMatterTemplate(action) {
  const { id, navigate, location } = action.payload
  try {
    const url = `/templates/clone/${id}`
    const response = yield call(getRequest, url)

    if (!location.pathname.includes(response.id)) {
      navigate(`/v2/matters/templates/details/${response.id}`, { replace: true })
    }
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'The template was cloned successfully! You can continue editing',
        level: 'success'
      }
    })
    yield put({
      type: ACT.MATTER_TEMPLATE_RESTORE_TO_DRAFT
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue cloning Matter Template',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* setTemplateAllocations(action) {
  const { templateId, allocations } = action.payload
  let isValid = false

  if (allocations.length === 1) {
    allocations.forEach(allocation => {
      allocation.columns.forEach(column => {
        if (column.value) {
          isValid = true
        }
      })
    })
  } else {
    isValid = true
  }

  if (!isValid) return

  try {
    const url = '/templates/allocate'
    yield makePostRequest(url, { templateId, allocations: toAllocationsJson(allocations) })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue saving the allocations',
        message: <NotificationList lines={formatResponse(formatErrorMessage(e))} />,
        level: 'error'
      }
    })
  }
}

function* setTemplateFolders(action) {
  const { templateId, folderStructure } = action.payload
  try {
    const url = `/doc_management/native_docs/directories/matter_templates/${templateId}/?tree=1`
    const response = yield makePostRequest(url, folderStructure)

    yield put({
      type: ACT.SET_DEFAULT_FOLDERS_SUCCESS,
      payload: { response }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue saving the folders.',
        message: <NotificationList lines={formatResponse(formatErrorMessage(e))} />,
        level: 'error'
      }
    })
    yield put({
      type: ACT.DEFAULT_FOLDERS_FETCH_REQUESTED,
      payload: { templateId }
    })
  }
}

const matterTemplatesDetailSagas = [
  takeLatest(ACT.MATTER_TEMPLATES_ATTRIBUTES_FETCH_REQUESTED, fetchMatterTemplatesAttributes),
  takeLatest(
    ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_REQUESTED,
    fetchMatterTemplatesDynamicDropdowns
  ),
  takeLatest(ACT.MATTER_TEMPLATE_CREATE_REQUESTED, createMatterTemplate),
  takeLatest(ACT.MATTER_TEMPLATE_UPDATE_REQUESTED, updateMatterTemplate),
  takeLatest(ACT.MATTER_TEMPLATE_CLONE_REQUESTED, cloneMatterTemplate),
  takeLatest(ACT.MATTER_TEMPLATE_SET_ALLOCATIONS_REQUESTED, setTemplateAllocations),
  takeLatest(ACT.SET_DEFAULT_FOLDERS_REQUESTED, setTemplateFolders)
]

export default matterTemplatesDetailSagas
