import { useState } from 'react'
import {
  Panel,
  CollapsibleContainer,
  KeyValuePairs,
  XeditableContainer,
  Title,
  PaginatedSelect
} from 'simple-core-ui'

import {
  CostCodeSelect,
  PracticeAreaSelect,
  LegalEntitySelect,
  PurchaseOrderSelect
} from 'common/Selects'
import flatMap from 'lodash/flatMap'
import ConditionalAttribute from './ConditionalAttribute'

import { Currency, ReactDatePicker } from 'containers'

import { toReactSelect } from 'common/Selects/serializers'
import { IntegratedConfidentialMatterInfo } from 'matters/matter'
import { getInitialGeneralAttrValue, getInitialGeneralAttrDropdownValue } from '../serializers'

const getLabel = (required, label) => {
  return required ? (
    <>
      {label}
      <span style={{ color: 'red' }}>*</span>
    </>
  ) : (
    label
  )
}

const AttributesPanel = ({
  panel,
  title,
  attributes = [],
  matterId,
  updateAttribute,
  readOnly,
  className,
  isConfidential,
  reRenderKey
}) => {
  const [resetKey, setResetKey] = useState(0)

  const buildList = (attrList, sectionIndex) => {
    return attrList.map((attrGroup, attrGroupIndex) => {
      const buildSingleAttribute = (attr, relationId, group, attrIndex) => {
        const label = attr.label

        if (relationId) {
          return {
            key: label,
            value: (
              <ConditionalAttribute
                panel={panel}
                selectKey={resetKey}
                attr={attr}
                attrIndex={attrIndex}
                matterId={matterId}
                attrGroup={group}
                relationId={relationId}
                readOnly={readOnly}
                setValueCb={value => {
                  setResetKey(resetKey + 1)
                  updateAttribute({
                    attr,
                    value,
                    sectionIndex,
                    panel,
                    attrGroupIndex,
                    relationId,
                    group,
                    attrIndex
                  })
                }}
              />
            )
          }
        }

        // general attributes
        if (attr.name === 'matter_group') {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                renderForm={(_, updateFormValue, formValue) => {
                  return (
                    <PracticeAreaSelect
                      value={formValue ?? getInitialGeneralAttrDropdownValue(attr.value)}
                      onChange={updateFormValue}
                      isClearable={false}
                      paginated
                    />
                  )
                }}
                readOnly={attr.readOnly || readOnly}
                type="list"
                initialValue={getInitialGeneralAttrValue(attr.value)}
                isPortal
              />
            )
          }
        }

        if (attr.name === 'po') {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                renderForm={(_, updateFormValue, formValue) => {
                  return (
                    <PurchaseOrderSelect
                      value={formValue ?? getInitialGeneralAttrDropdownValue(attr.value)}
                      onChange={updateFormValue}
                      isClearable={false}
                      paginated
                      withNoneOption
                    />
                  )
                }}
                readOnly={attr.readOnly || readOnly}
                type="list"
                initialValue={getInitialGeneralAttrValue(attr.value)}
                isPortal
              />
            )
          }
        }

        if (attr.name === 'legal_entity') {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                key={reRenderKey}
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                renderForm={(_, updateFormValue, formValue) => {
                  return (
                    <LegalEntitySelect
                      value={formValue ?? getInitialGeneralAttrDropdownValue(attr.value)}
                      onChange={updateFormValue}
                      isClearable={false}
                      paginated
                    />
                  )
                }}
                readOnly={attr.readOnly || readOnly}
                type="list"
                initialValue={getInitialGeneralAttrValue(attr.value)}
                isPortal
              />
            )
          }
        }

        if (attr.name === 'cost_code') {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                renderForm={(_, updateFormValue, formValue) => {
                  return (
                    <CostCodeSelect
                      value={formValue ?? getInitialGeneralAttrDropdownValue(attr.value)}
                      onChange={updateFormValue}
                      isClearable={false}
                      paginated
                    />
                  )
                }}
                readOnly={attr.readOnly || readOnly}
                type="list"
                initialValue={getInitialGeneralAttrValue(attr.value)}
                isPortal
              />
            )
          }
        }

        if (
          attr.name === 'hours' ||
          attr.name === 'dates_worked' ||
          attr.name === 'total' ||
          attr.name === 'client_matter_id'
        ) {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                readOnly={attr.readOnly || readOnly}
                type="text"
                initialValue={attr.value}
                isPortal
              />
            )
          }
        }

        if (attr.type === 'property') {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                readOnly={attr.readOnly || readOnly}
                type="text"
                initialValue={attr.value?.label}
                isPortal
              />
            )
          }
        }

        if (['select', 'list'].includes(attr.type)) {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                key={reRenderKey}
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                renderForm={(_, updateFormValue, formValue) => {
                  return (
                    <PaginatedSelect
                      url={`/manage/attributes/${attr.id}/select2_json?matter_id=${matterId}`}
                      value={formValue ?? attr.value}
                      onChange={updateFormValue}
                      isClearable={false}
                      serializer={toReactSelect}
                    />
                  )
                }}
                readOnly={attr.readOnly || readOnly}
                type="list"
                initialValue={attr.value}
                isPortal
              />
            )
          }
        }
        if (['boolean'].includes(attr.type)) {
          const options = [
            ...(attr.name === 'accruals_enabled'
              ? [{ value: 'null', label: 'Inherit from Practice Area' }]
              : []),
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' }
          ]

          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                options={options}
                readOnly={attr.readOnly || readOnly}
                type="list"
                initialValue={options.find(
                  o =>
                    o.value ===
                    (typeof attr?.value === 'object' && attr.value?.value
                      ? String(attr.value.value)
                      : String(attr?.value))
                )}
                isPortal
              />
            )
          }
        }

        // custom attributes
        if (['text', 'textarea'].includes(attr.type)) {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                readOnly={attr.readOnly || readOnly}
                testid={label}
                type={attr.type}
                initialValue={typeof attr.value === 'string' ? attr.value : attr.value?.label}
                isPortal
              />
            )
          }
        }
        if (attr.type === 'date') {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                renderForm={(_, updateFormValue, formValue) => (
                  <ReactDatePicker value={formValue ?? attr.value} onChange={updateFormValue} />
                )}
                readOnly={attr.readOnly || readOnly}
                type="text"
                initialValue={attr.value?.value ? attr.value.value : attr.value}
                isPortal
              />
            )
          }
        }
        if (attr.type === 'currency') {
          return {
            key: getLabel(attr.required, label),
            value: (
              <XeditableContainer
                setValueCb={value =>
                  updateAttribute({ attr, value, sectionIndex, panel, attrGroupIndex })
                }
                renderForm={(_, updateFormValue, formValue) => (
                  <Currency
                    amount={formValue?.amount ?? attr.value?.amount}
                    selectedOption={formValue.code ?? attr.value?.code}
                    onChange={({ amount, code }) => {
                      updateFormValue({ amount, code: code ? code.value || code : null })
                    }}
                    isPortal={false}
                  />
                )}
                readOnly={attr.readOnly || readOnly}
                type="currency"
                initialValue={attr.value?.amount ? attr.value : ''}
                isPortal
              />
            )
          }
        }
      }

      const buildConditionalAttribute = group => {
        return group.attributes.map((a, i) => {
          const relationId = group.relation_id
          let pair = buildSingleAttribute(a, relationId, group, i)
          if (relationId && i === 0) {
            pair.style = { marginTop: 12 }
          }
          return pair
        })
      }

      if (attrGroup.relation_id) {
        return buildConditionalAttribute(attrGroup)
      } else {
        return buildSingleAttribute(attrGroup.attributes[0])
      }
    })
  }

  return (
    <Panel className={className} isBodyOnly>
      <div>
        <Title rank={3} text={title} style={{ marginBottom: 25, marginTop: 10, fontWeight: 600 }} />
        {isConfidential && <IntegratedConfidentialMatterInfo />}
        {attributes.map((section, index) => {
          return (
            <CollapsibleContainer
              key={index}
              startCollapsed={!section.expanded}
              title={section.name}
              style={{ marginBottom: 15 }}
            >
              <KeyValuePairs
                pairs={flatMap(buildList(section.attributes, index))}
                style={{ padding: 0 }}
                isLoading={false}
              />
            </CollapsibleContainer>
          )
        })}
      </div>
    </Panel>
  )
}

export default AttributesPanel
