import { FaExclamationCircle, FaCheckCircle } from 'react-icons/fa'
import cn from 'classnames'
import s from './FileStatusIcon.scss'
import { FILE_STATUS_SCANNING, FILE_STATUS_CLEAN, FILE_STATUS_INFECTED } from '../constants'
import ReactTooltip from 'react-tooltip'

const FileStatusIcon = ({ file, className }) => {
  let status = ''
  let tip = ''

  if (file.fileStatus === FILE_STATUS_SCANNING) {
    status = <FaCheckCircle className={cn(s.status, className)} />
    tip = 'File is still being scanned for malware.'
  } else if (file.fileStatus === FILE_STATUS_CLEAN) {
    status = <FaCheckCircle className={cn(s.status, s.green, className)} />
    tip = 'No malware detected.'
  } else {
    if (file.fileStatus === FILE_STATUS_INFECTED) {
      status = <FaExclamationCircle className={cn(s.status, s.red, className)} />
      tip = 'Malware was detected.'
    }
  }

  return (
    <>
      <span data-for={String(file.id)} className={s.checkboxWrapper} data-tip>
        {status}
      </span>
      <ReactTooltip id={String(file.id)} type="light" effect="solid" place="top" border clickable>
        {tip}
      </ReactTooltip>
    </>
  )
}

export default FileStatusIcon
