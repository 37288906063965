import moment from 'moment'
import get from 'lodash/get'

const BUDGET_TYPES = {
  PHASE: 1,
  MONTHLY: 2,
  LIFE_OF_MATTER: 3,
  QUARTERLY: 4
}

const _getErrObj = errors => {
  return { isError: !!Object.keys(errors).length, errors }
}

const _applyErrors = (formState, requiredFields, errors, scope = null) => {
  const isInvalidRange = moment(formState.budgetEndDate, 'MM/YYYY').isBefore(
    moment(formState.budgetStartDate, 'MM/YYYY')
  )
  if (isInvalidRange) {
    errors.budgetStartDate = true
    errors.budgetEndDate = true
  }
  if (requiredFields.includes('matterId') && scope === 'matter') {
    requiredFields.pop()
  }
  requiredFields.forEach(key => {
    if (!formState[key]) {
      errors[key] = true
    }
  })
}

export const validateBudgetRequest = formState => {
  let errors = {}
  let requiredFields = ['collaborators', 'budgetName', 'budgetType']

  const { PHASE, MONTHLY, LIFE_OF_MATTER, QUARTERLY } = BUDGET_TYPES

  const budgetType = get(formState, 'budgetType.value')

  if (budgetType === LIFE_OF_MATTER || budgetType === MONTHLY) {
    requiredFields = requiredFields.concat(['budgetStartDate', 'budgetEndDate'])
  } else if (budgetType === QUARTERLY) {
    requiredFields = requiredFields.concat(['budgetYear'])
  } else if (budgetType === PHASE) {
    requiredFields = requiredFields.concat(['phaseSet'])
  }

  _applyErrors(formState, requiredFields, errors)

  return _getErrObj(errors)
}

const negativeAndPositiveIntegers = n => /^[-]?[0-9]+$/.test(n + '')

export const validateLegacyBudget = (formState, scope) => {
  let errors = {}
  let requiredFields = ['budgetType', 'amount']
  let isIntegers = /^\d+$/
  const { PHASE, MONTHLY, LIFE_OF_MATTER, QUARTERLY } = BUDGET_TYPES

  const budgetType = get(formState, 'budgetType.value')

  if (budgetType === MONTHLY) {
    requiredFields = requiredFields.concat(['budgetStartDate', 'budgetEndDate'])
  } else if (budgetType === LIFE_OF_MATTER) {
    requiredFields = requiredFields.concat(['budgetStartDate', 'budgetEndDate', 'matterId'])
  } else if (budgetType === QUARTERLY) {
    requiredFields = requiredFields.concat(['budgetYear'])
    if (!isIntegers.test(formState.budgetYear)) {
      errors.budgetYear = true
    }
  } else if (budgetType === PHASE) {
    requiredFields = requiredFields.concat(['phaseSet'])
  }

  if (!negativeAndPositiveIntegers(formState.amount)) {
    errors.amount = true
  }

  _applyErrors(formState, requiredFields, errors, scope)

  return _getErrObj(errors)
}

export const validateReferral = formState => {
  let requiredFields = ['vendorId']
  let errors = {}

  if (formState.budgetRequested) {
    errors = validateBudgetRequest(formState).errors
  }

  _applyErrors(formState, requiredFields, errors)

  return _getErrObj(errors)
}
