import { Component } from 'react'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import queryString from 'query-string'

import { Loading } from 'components'
import { updateLinkParam } from 'utils/helpers'

import RulesList from '../components/RulesList'
import { EngineContext } from 'rules/context'
import ACT from 'rules/actions'
import RC_ACT from 'reviews/reviewer_config/actions'
import {
  FILTER_TYPE,
  ENABLED_ENGINES,
  ENGINE_REVIEWER_SCOPE_MAP,
  QUERY_PARAMS
} from 'rules/constants'
import {
  getRuleIndexMap,
  getRulePriorityMap,
  getRuleNameMap,
  canEditEngine,
  ruleMatchesSearch
} from 'rules/utils'

const getFilterType = state => state.filterType
const getFilterSearch = state => state.filterSearch
const getRules = state => ({
  rulesList: state[state.engine].rulesList,
  savedRules: state[state.engine].savedRules
})

const getRuleById = (rules, id) => rules.find(rule => rule.id === id)

const getTypeFilteredRules = createSelector(
  [getFilterType, getRules],
  (filterType, { rulesList, savedRules }) =>
    ({
      [FILTER_TYPE.ALL]: { rulesList, savedRules },
      [FILTER_TYPE.INACTIVE]: {
        rulesList: rulesList.filter(({ id }) => {
          const rule = getRuleById(savedRules, id)
          return rule.is_draft || rule.clearable
        }),
        savedRules
      },
      [FILTER_TYPE.ACTIVE]: {
        rulesList: rulesList.filter(({ id }) => {
          const rule = getRuleById(savedRules, id)
          return !rule.is_draft || rule.clearable
        }),
        savedRules
      }
    }[filterType])
)

const getFilteredRules = createSelector(
  [getFilterSearch, getTypeFilteredRules],
  (search, { rulesList, savedRules }) => {
    return search
      ? rulesList.filter(({ id }) => ruleMatchesSearch(getRuleById(savedRules, id), search))
      : rulesList
  }
)

@withFetchFlow({
  render: () => null,
  flag: 'Rule',
  getFetchAction: () => ({
    type: ACT.FETCH_ALL_RULES_REQUESTED,
    loadingStateDisabled: true
  })
})
@withFetchFlow({
  render: () => null,
  flag: 'RuleFields',
  getFetchAction: () => ({
    type: ACT.RULE_FIELDS_FETCH_REQUESTED,
    loadingStateDisabled: true
  })
})
@withFetchFlow({
  render: () => null,
  flag: 'ruleActions',
  getFetchAction: () => ({
    type: ACT.RULE_ACTIONS_FETCH_REQUESTED,
    loadingStateDisabled: true
  })
})
@connect(({ rules }) => {
  const { engine, isLoading } = rules
  const { rulesList: unfilteredRules, savedRules } = rules[engine]
  const hasUnsavedChanges =
    !isEqual(unfilteredRules, savedRules) || unfilteredRules.some(({ clearable }) => clearable)
  const ruleIndexMap = getRuleIndexMap(savedRules)
  const rulePriorityMap = getRulePriorityMap(savedRules)
  const ruleNameMap = getRuleNameMap(savedRules)
  const newRulePending = unfilteredRules.find(({ id = null }) => id === null)

  const rulesList = getFilteredRules(rules)
  const reviewerScope = ENGINE_REVIEWER_SCOPE_MAP[engine]
  return {
    hasUnsavedChanges,
    rulesList,
    filterType: rules.filterType,
    filterSearch: rules.filterSearch,
    isSaving: rules.isSaving,
    isLoading,
    engine: rules.engine,
    reviewerScope,
    ruleIndexMap,
    rulePriorityMap,
    ruleNameMap,
    newRulePending
  }
})
class RulesListContainer extends Component {
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload)

    const parsedQueryString = queryString.parse(location.search)
    const queryEngine = get(parsedQueryString, 'engine', ENABLED_ENGINES[0])
    // redirect to the first enabled engine if the one in the url is not enabled
    // this will not allow to access the tab using url directly
    const engine = ENABLED_ENGINES.includes(queryEngine) ? queryEngine : ENABLED_ENGINES[0]

    const filterType = get(parsedQueryString, 'filter', FILTER_TYPE.ACTIVE)
    const filterSearch = get(parsedQueryString, 'search', '')

    this.updateEngine(engine)
    this.updateFilterType(filterType)
    this.updateFilterSearch(filterSearch)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload)
  }

  handleUnload = async event => {
    const parsedQueryString = queryString.parse(location.search)
    const engine = get(parsedQueryString, 'engine', ENABLED_ENGINES[0])

    const { hasUnsavedChanges } = this.props
    if (hasUnsavedChanges && engine !== 'invoice_ai') {
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    }
  }

  resetReviewers = () => {
    const { reviewerScope, dispatch } = this.props

    // TODO: Ideally we would cache the reviewer configs
    if (reviewerScope) {
      dispatch({
        type: RC_ACT.RESET_REVIEWER_CONFIG,
        payload: { reviewerScope }
      })
    }
  }

  addNewRule = unconditional => {
    this.resetReviewers()

    this.props.dispatch({
      type: ACT.ADD_NEW_RULE,
      payload: { unconditional }
    })
  }

  updateEngine = engine => {
    updateLinkParam({ key: QUERY_PARAMS.ENGINE, value: engine })

    this.props.dispatch({
      type: ACT.UPDATE_RULE_ENGINE,
      payload: engine
    })
  }

  updateFilterType = filterType => {
    updateLinkParam({ key: QUERY_PARAMS.FILTER, value: filterType })

    this.props.dispatch({
      type: ACT.UPDATE_RULES_FILTER_TYPE,
      payload: {
        filterType
      }
    })
  }

  updateFilterSearch = filterSearch => {
    updateLinkParam({ key: QUERY_PARAMS.SEARCH, value: filterSearch })

    this.props.dispatch({
      type: ACT.UPDATE_RULES_FILTER_SEARCH,
      payload: {
        filterSearch
      }
    })
  }

  render() {
    const {
      rulesList,
      engine,
      filterSearch,
      filterType,
      isSaving,
      isLoading,
      ruleIndexMap,
      rulePriorityMap,
      ruleNameMap,
      newRulePending
    } = this.props

    return isLoading ? (
      <Loading />
    ) : (
      <EngineContext.Provider value={{ canEdit: canEditEngine(engine) }}>
        <RulesList
          rulesList={rulesList}
          engine={engine}
          updateFilterSearch={this.updateFilterSearch}
          updateFilterType={this.updateFilterType}
          updateEngine={this.updateEngine}
          filterSearch={filterSearch}
          filterType={filterType}
          addNewRule={this.addNewRule}
          isSaving={isSaving}
          ruleIndexMap={ruleIndexMap}
          rulePriorityMap={rulePriorityMap}
          ruleNameMap={ruleNameMap}
          newRulePending={newRulePending}
        />
      </EngineContext.Provider>
    )
  }
}

export default RulesListContainer
