import { createReducer } from 'redux-create-reducer'
import ACT from './actions'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { defaultBudgetTypes, defaultPhaseSets } from './constants'
import update from 'immutability-helper'
import findIndex from 'lodash/findIndex'
import { fromFragment } from './serializers'
import { currency2Num, currencyAwareFormat } from 'utils/formatting'

//on matter detail it comes as scope id
const scope = window.serverContext.get('scope')
const scopeId = window.serverContext.get('scope_id')

//on vendor detail it comes in as object id
const object_id = window.serverContext.get('object_id')
const object_model = window.serverContext.get('object_model')

export const initialState = {
  canEdit: window.serverContext.get('can_change_budget_allocations'),
  budgetTypes: window.serverContext.get('budget_types') || defaultBudgetTypes,
  phaseSets: window.serverContext.get('phase_sets') || defaultPhaseSets,
  matterName: window.serverContext.get('matter_short_name'),
  budgetDetails: {
    budget_details: {},
    remaining_budget: '',
    budget_currency: ''
  },
  budget_comments: window.serverContext.get('budget_comments'),
  compareDetails: window.serverContext.get('compare_details'),
  matterId: window.serverContext.get('matter_id'),
  proposalId: window.serverContext.get('proposal_id'),
  status: window.serverContext.get('budget_status'),
  modifiedDate: window.serverContext.get('modified_date'),
  scopeId: scopeId,
  scope: scope,
  filters: {
    pageSize: 50,
    ordering: { columnKey: 'budgetStartDate', isDesc: true },
    page: 1,
    search: '',
    category: 'approved',
    reset: 0,
    ...fromFragment(window.location.hash)
  },
  shouldCheckOnRenewal: window.serverContext.get('should_check_on_renewal'),
  vendorsWithBudgets: window.serverContext.get('vendors_with_budgets'),
  list: [],
  activeBudgets: { rows: [], filteredEntries: 0, totalEntries: 0 },
  lockState: {
    budgetsAreLockable: false,
    budgetLockingFeatureEnabled: false
  },
  createForm: {
    budgetType: null,
    refreshPeriod: null,
    phaseSet: null,
    budgetName: null,
    budgetYear: moment().format('YYYY'),
    budgetStartDate: null,
    budgetEndDate: null,
    budgetDueDate: null,
    collaborators: null,
    amount: 0,
    errors: {}
  },
  activeListParams: {
    pageSize: 10,
    ordering: { columnKey: 'startDate', isDesc: true },
    search: '',
    page: 1,
    ...(scope === 'matter' ? { matter: [{ value: scopeId }] } : {}),
    ...(object_model === 'vendor' ? { vendors: [{ value: object_id }] } : {}),
    ...(scope === 'matter_group' ? { matter_group: [{ value: scopeId }] } : {})
  },
  requestListParams: {
    pageSize: 50,
    ordering: { columnKey: 'startDate', isDesc: true },
    search: '',
    page: 1,
    category: 'all',
    total: 0,
    filteredTotal: 0
  },
  totalBudgetRequests: 0,
  filteredTotalBudgetRequests: 0,
  isLoading: false
}

export const curr2Num4Input = curr => {
  const num = currency2Num(curr)
  if (num === 0) {
    return ''
  } else {
    return num
  }
}

const toReforecast = details => {
  return {
    ...details,
    amount: curr2Num4Input(details.amount),
    rows: details.rows.map(row => {
      return {
        ...row,
        note: row.note,
        allocated_fees: curr2Num4Input(row.allocated_fees),
        allocated_expenses: curr2Num4Input(row.allocated_expenses)
      }
    })
  }
}

const clearReforecast = details => {
  return {
    ...details,
    amount: curr2Num4Input(details.amount),
    rows: details.rows.map(row => {
      return {
        ...row,
        allocated_fees: '',
        allocated_expenses: ''
      }
    })
  }
}

const calcRows = (rows, curr) => {
  return rows.map(row => {
    const {
      allocated_fees,
      allocated_expenses,
      approved_fees,
      approved_expenses,
      approved_total,
      pending_total
    } = row
    const newAllocatedRowTotal = allocated_fees + allocated_expenses

    const approvedPerc = !!newAllocatedRowTotal
      ? (currency2Num(approved_total) / newAllocatedRowTotal) * 100
      : 0
    const pendingPerc = !!newAllocatedRowTotal
      ? (currency2Num(pending_total) / newAllocatedRowTotal) * 100
      : 0

    return {
      ...row,
      allocated_total: currencyAwareFormat(newAllocatedRowTotal, curr),
      approved_percentage: approvedPerc,
      pending_percentage: pendingPerc
    }
  })
}

const calcTotal = (rows, key, curr) => {
  const total = rows.reduce((a, c) => {
    return a + currency2Num(c[key], true)
  }, 0)

  return currencyAwareFormat(total, curr)
}

const calcTotalPerc = (details, key) => {
  if (details.allocated_total === 0) return 0
  return (currency2Num(details[`${key}_total`]) / currency2Num(details.allocated_total)) * 100
}

const calcUnallocated = (amount, details, curr) => {
  return currencyAwareFormat(currency2Num(amount) - currency2Num(details.allocated_total), curr)
}

const calcRemaining = (amount, details, curr) => {
  return currencyAwareFormat(
    currency2Num(amount) -
      currency2Num(details.approved_total) -
      currency2Num(details.pending_total) -
      currency2Num(details.accrual_total),
    curr
  )
}

const recalcReforecast = (details, curr) => {
  const updatedDetails = update(details, {
    rows: { $set: calcRows(details.rows, curr) }
  })

  const newRows = updatedDetails.rows

  const updatedTotals = update(updatedDetails, {
    allocated_total: { $set: calcTotal(newRows, 'allocated_total', curr) },
    allocated_fees: { $set: calcTotal(newRows, 'allocated_fees', curr) },
    allocated_expenses: { $set: calcTotal(newRows, 'allocated_expenses', curr) },

    approved_total: { $set: calcTotal(newRows, 'approved_total', curr) },
    approved_fees: { $set: calcTotal(newRows, 'approved_fees', curr) },
    approved_expenses: { $set: calcTotal(newRows, 'approved_expenses', curr) },

    pending_total: { $set: calcTotal(newRows, 'pending_total', curr) },
    pending_fees: { $set: calcTotal(newRows, 'pending_fees', curr) },
    pending_expenses: { $set: calcTotal(newRows, 'pending_expenses', curr) }
  })

  return update(updatedTotals, {
    approved_percentage: { $set: calcTotalPerc(updatedTotals, 'approved') },
    pending_percentage: { $set: calcTotalPerc(updatedTotals, 'pending') },
    unallocated: { $set: calcUnallocated(updatedDetails.amount, updatedTotals, curr) }
  })
}

const toNewDetails = details => {
  const curr = details.budget_currency
  return {
    ...details,
    amount: currencyAwareFormat(details.amount, curr),
    rows: details.rows.map(row => {
      return {
        ...row,
        allocated_fees: currencyAwareFormat(row.allocated_fees, curr),
        allocated_expenses: currencyAwareFormat(row.allocated_expenses, curr)
      }
    })
  }
}

const budgetsReducer = createReducer(initialState, {
  [ACT.INIT_VENDOR_INFO](state, action) {
    const { vendorId, vendorName } = action.payload

    return {
      ...state,
      vendorId,
      vendorName
    }
  },

  [ACT.BUDGET_REQUESTS_LIST_FETCH_SUCCESS](state, action) {
    const { budgetRequests, totalEntries, filteredTotal, params } = action.payload
    return {
      ...state,
      list: budgetRequests,
      requestListParams: {
        ...params
      },
      totalBudgetRequests: totalEntries,
      filteredTotalBudgetRequests: filteredTotal
    }
  },

  [ACT.ACTIVE_BUDGETS_LIST_FETCH_SUCCESS](state, action) {
    const { activeBudgets } = action.payload

    return {
      ...state,
      activeBudgets
    }
  },

  [ACT.DELETE_BUDGET_SUCCESS](state, action) {
    const { budget_id } = action.payload
    const rowIndex = state.activeBudgets.rows.findIndex(x => x.id === budget_id)

    return update(state, {
      activeBudgets: {
        totalEntries: { $set: state.activeBudgets.totalEntries - 1 },
        filteredEntries: { $set: state.activeBudgets.filteredEntries - 1 },
        rows: { $splice: [[rowIndex, 1]] }
      }
    })
  },

  [ACT.BULK_UPDATE_BUDGET_SUCCESS](state, action) {
    const { budget_ids, action: bulkAction, action_val } = action.payload
    const rowIndexes = budget_ids
      .map(budget_id => state.activeBudgets.rows.findIndex(x => x.id === budget_id))
      .sort((a, b) => b - a)

    if (action.payload.status === 'delete') {
      return update(state, {
        activeBudgets: {
          totalEntries: { $set: state.activeBudgets.totalEntries - rowIndexes.length },
          filteredEntries: { $set: state.activeBudgets.filteredEntries - rowIndexes.length },
          rows: { $splice: rowIndexes.map(rowIndex => [rowIndex, 1]) }
        }
      })
    } else {
      return update(state, {
        activeBudgets: {
          rows: {
            ...rowIndexes.reduce(
              (acc, curr) => ({
                ...acc,
                [curr]: {
                  ...(bulkAction === 'vendor' ? { vendorName: { $set: action_val.label } } : {}),
                  ...(bulkAction === 'matter' ? { matterName: { $set: action_val.label } } : {}),
                  ...(bulkAction === 'matter_group'
                    ? { mattergroupName: { $set: action_val.label } }
                    : {}),
                  ...(bulkAction === 'cost_code'
                    ? { costcodeName: { $set: action_val.label } }
                    : {})
                }
              }),
              {}
            )
          }
        }
      })
    }
  },

  [ACT.LOCK_STATE_FETCH_SUCCESS](state, action) {
    const { payload } = action

    return update(state, {
      lockState: {
        budgetsAreLockable: { $set: payload.budgets_are_lockable },
        budgetLockingFeatureEnabled: { $set: payload.budget_locking_feature_enabled }
      }
    })
  },

  [ACT.UPDATE_ACTIVE_BUDGETS_LIST](state, action) {
    const { pk, ...params } = action.payload
    const rowIndex = state.activeBudgets.rows.findIndex(x => x.id === pk)

    return update(state, {
      activeBudgets: {
        rows: {
          [rowIndex]: {
            ...(params.vendor ? { vendorName: { $set: params.vendor.label } } : {}),
            ...(params.matter ? { matterName: { $set: params.matter.label } } : {}),
            ...(params.mattergroup ? { mattergroupName: { $set: params.mattergroup.label } } : {}),
            ...(params.costcode ? { costcodeName: { $set: params.costcode.label } } : {})
          }
        }
      }
    })
  },

  [ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED](state, action) {
    const { params } = action.payload
    return {
      ...state,
      filters: params,
      activeListParams: params
    }
  },

  [ACT.SUBMIT_REFERRAL_SUCCESS](state, action) {
    return {
      ...state,
      createForm: {
        ...cloneDeep(initialState.createForm)
      }
    }
  },

  [ACT.CLEAR_BUDGET_FORM](state, action) {
    return {
      ...state,
      createForm: {
        ...cloneDeep(initialState.createForm)
      }
    }
  },

  [ACT.SUBMIT_BUDGET_REQUEST_SUCCESS](state, action) {
    return {
      ...state,
      createForm: {
        ...cloneDeep(initialState.createForm)
      }
    }
  },

  [ACT.START_REFORECAST](state, action) {
    return {
      ...state,
      isReforecasting: true,
      rfBudgetDetails: toReforecast(cloneDeep(state.budgetDetails))
    }
  },

  [ACT.CANCEL_REFORECAST](state, action) {
    return {
      ...state,
      isReforecasting: false
    }
  },

  [ACT.CLEAR_RF_BUDGET](state, action) {
    return {
      ...state,
      rfBudgetDetails: clearReforecast(cloneDeep(state.budgetDetails))
    }
  },

  [ACT.REVERT_RF_BUDGET](state, action) {
    return {
      ...state,
      rfBudgetDetails: toReforecast(cloneDeep(state.budgetDetails))
    }
  },

  [ACT.SAVE_REFORECAST_SUCCESS](state, action) {
    return {
      ...state,
      loadingLock: 'off',
      isReforecasting: false,
      budgetDetails: toNewDetails(cloneDeep(state.rfBudgetDetails)),
      lastRfDetails: cloneDeep(state.budgetDetails)
    }
  },

  [ACT.RECENT_BUDGET_FETCH_SUCCESS](state, action) {
    const { budget } = action.payload

    if (!budget.budget_rows) {
      return state
    }

    const rows = budget.budget_rows

    return {
      ...state,
      lastRfDetails: {
        amount: budget.budget_amount / 100,
        allocated_total: rows.reduce((a, c) => {
          return a + c.allocated_total / 100
        }, 0),
        budget_currency: budget.currency,
        rows: rows.map(row => {
          return {
            allocated_fees: row.fees / 100,
            allocated_expenses: row.expenses / 100,
            allocated_total: row.allocated_total / 100
          }
        })
      }
    }
  },

  [ACT.UPDATE_RF_BUDGET_AMOUNT](state, action) {
    return update(state, {
      rfBudgetDetails: {
        amount: { $set: action.payload.value },
        remaining_budget: {
          $set: calcRemaining(
            action.payload.value,
            state.rfBudgetDetails,
            state.rfBudgetDetails.budget_currency
          )
        },
        unallocated: {
          $set: calcUnallocated(
            action.payload.value,
            state.rfBudgetDetails,
            state.rfBudgetDetails.budget_currency
          )
        }
      }
    })
  },

  [ACT.UPDATE_REFORECAST_ROW_VALUE](state, action) {
    const { rowIndex, columnKey, value } = action.payload

    const newDetails = update(state.rfBudgetDetails, {
      rows: {
        [rowIndex]: {
          [columnKey]: {
            $set: value
          }
        }
      }
    })

    return update(state, {
      rfBudgetDetails: {
        $set:
          columnKey !== 'note'
            ? recalcReforecast(newDetails, state.budgetDetails.budget_currency)
            : newDetails
      }
    })
  },

  [ACT.UPDATE_ROW_NOTES](state, action) {
    const { rowId, value } = action.payload

    const rowIndex = findIndex(state.budgetDetails.rows, row => row.id === rowId)

    return update(state, {
      budgetDetails: {
        rows: {
          [rowIndex]: {
            note: {
              $set: value
            }
          }
        }
      }
    })
  },

  [ACT.UPDATE_BUDGET_REQUEST_FORM](state, action) {
    return {
      ...state,
      createForm: {
        ...state.createForm,
        ...action.payload
      }
    }
  },

  [ACT.BUDGET_DETAILS_FETCH_SUCCESS](state, action) {
    const { budget } = action.payload
    const {
      budget_details,
      budget_currency,
      remaining_budget,
      budget_status,
      proposal_id,
      modified_date
    } = budget

    return update(state, {
      budgetDetails: { $set: { ...budget_details, budget_currency, remaining_budget } },
      status: { $set: budget_status },
      proposalId: { $set: proposal_id },
      modifiedDate: { $set: modified_date }
    })
  },

  [ACT.BUDGET_COMPARE_FETCH_SUCCESS](state, action) {
    const { budget } = action.payload
    const { compare_details } = budget

    return update(state, {
      compareDetails: { $set: compare_details }
    })
  }
})

export default budgetsReducer
