import * as React from 'react'

import { Confirmable } from 'components'

class ConfirmableContainer extends React.Component {
  static defaultProps = {
    promptText: 'Are you sure?',
    confirmText: 'Yes',
    cancelText: 'No',
    confirmCallback: () => {},
    cancelCallback: () => {}
  }

  state = {
    isPrompted: false
  }

  openPrompt = () => {
    this.setState({
      isPrompted: true
    })
  }

  closePrompt = () => {
    this.setState({
      isPrompted: false
    })
  }

  confirmHandler = event => {
    this.closePrompt()
    this.props.confirmCallback(event)
  }

  cancelHandler = event => {
    this.closePrompt()
    this.props.cancelCallback(event)
  }

  render() {
    return (
      <Confirmable
        {...this.props}
        {...this.state}
        openPrompt={this.openPrompt}
        confirmCallback={this.confirmHandler}
        cancelCallback={this.cancelHandler}
      />
    )
  }
}

export default ConfirmableContainer
