import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'
import { MouseEvent } from 'react'
import { Template } from '../types'

interface Props {
  template: Template
  editTemplate: (row: Template) => void
  deleteTemplate: (row: Template) => void
  duplicateTemplate: (row: Template) => void
}

const ActionsPopover = ({ template, deleteTemplate, editTemplate, duplicateTemplate }: Props) => {
  const handleClick = (event: MouseEvent, action: string) => {
    event.preventDefault()
    event.stopPropagation()
    ReactTooltip.hide()
    switch (action) {
      case 'DELETE':
        deleteTemplate(template)
        return
      case 'EDIT':
        editTemplate(template)
        return
      case 'DUPLICATE':
        duplicateTemplate(template)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${template.id}`}
        aria-describedby={`item-actions-${template.id}`}
        aria-label={`item-${template.id}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${template.id}`}
      >
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      </span>
      <ReactTooltip
        id={`item-actions-${template.id}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EDIT')
            }}
          >
            Edit
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DELETE')
            }}
          >
            Delete
          </li>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'DUPLICATE')
            }}
          >
            Duplicate
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
