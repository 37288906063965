import get from 'lodash/get'

import { MODULE, ORG_ROLE } from 'utils/constants'
import { hasModule } from 'utils/helpers'
import { SCOPE, REVIEWER_SCOPE } from 'reviews/constants'
import { getHasSimpleReview } from './utils'

export const BOOLEAN_OPERATOR = {
  AND: 'AND',
  OR: 'OR'
}

export const BOOLEAN_OPERATORS = Object.values(BOOLEAN_OPERATOR)

export const ENGINE = {
  SIMPLE_REVIEW: 'simple_review',
  INVOICE_VALIDATION: 'invoice_validation',
  INVOICE_REVIEW: 'invoice_review',
  INVOICE_AI: 'invoice_ai',
  MATTER_REVIEW: 'matter_review'
}

const { user } = window.credentials

export const ENABLED_ENGINES = Object.values(ENGINE).filter(engine => {
  if (user) {
    const ACCEPTED_ROLES = [ORG_ROLE.FINANCE, ORG_ROLE.ADMIN]
    const isAcceptedUser = ACCEPTED_ROLES.includes(get(user, 'role'))

    return get(
      {
        [ENGINE.SIMPLE_REVIEW]: getHasSimpleReview(),
        [ENGINE.INVOICE_AI]:
          get(user, 'isCSM', false) &&
          hasModule(MODULE.INVOICE_AI) &&
          !hasModule(MODULE.CUSTOMER_FACING_SIMPLE_REVIEW),
        [ENGINE.INVOICE_VALIDATION]: false,
        [ENGINE.INVOICE_REVIEW]: isAcceptedUser && hasModule(MODULE.INVOICE_REVIEWS),
        [ENGINE.MATTER_REVIEW]: isAcceptedUser && hasModule(MODULE.MATTER_REVIEWS)
      },
      engine,
      true
    )
  }
  return false
})

export const IVR_PAGE_TITLE = 'Invoice Validation Rules'

export const ENGINE_LABEL = {
  [ENGINE.SIMPLE_REVIEW]: IVR_PAGE_TITLE,
  [ENGINE.INVOICE_VALIDATION]: 'Old IIW Rules Page',
  [ENGINE.INVOICE_REVIEW]: 'Invoice Reviews',
  [ENGINE.INVOICE_AI]: 'AI Rules',
  [ENGINE.MATTER_REVIEW]: 'Matter Reviews'
}

export const FILTER_TYPE = {
  ALL: 'All',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DRAFT: 'Draft'
}

export const EMPTY_TEXT = {
  [FILTER_TYPE.ALL]: 'There are currently no rules in the system.',
  [FILTER_TYPE.INACTIVE]: 'There are no inactive rules in the system.',
  [FILTER_TYPE.ACTIVE]: 'There are no active rules in the system.'
}

export const PARAM_SUBTYPE = {
  REVIEWER_CONFIG: 'ReviewerConfig'
}

export const PARAM_ARGNAME = {
  PARAM_NAME: 'custom_reviewers'
}

export const PARAM_ARG = {
  LADDER_PRIORITY: 'ladder_priority'
}

export const ACTION_TYPE = {
  CUSTOM_REVIEWERS: 'add_custom_reviewers'
}

export const ENGINE_SCOPE_MAP = {
  [ENGINE.INVOICE_REVIEW]: SCOPE.APPROVAL_WORKFLOW,
  [ENGINE.MATTER_REVIEW]: SCOPE.MATTER_REVIEW_RULES
}

export const ENGINE_REVIEWER_SCOPE_MAP = {
  [ENGINE.INVOICE_REVIEW]: REVIEWER_SCOPE.INVOICE,
  [ENGINE.MATTER_REVIEW]: REVIEWER_SCOPE.MATTER
}

export const LHS_TYPES = {
  FUNC_CALL: 'func',
  FIELD_VAR: 'field',
  LIST_ATTR_FIELD: 'list_custom_attr'
}

export const QUERY_PARAMS = {
  RULE_ID: 'rule_id',
  ENGINE: 'engine',
  FILTER: 'filter',
  SEARCH: 'search'
}

export const FUNC_ARG_TYPES = {
  FIELD: 'field',
  CONST: 'const'
}

export const INVOICE_AI_LABELS = {
  admin: 'Administrative Task',
  block: 'Block Billing',
  conf: 'Conference',
  copy: 'Copy Changes',
  excs: 'Excessive Hours',
  nonatty: 'Non-Attorney Task',
  overhead: 'Overhead Task',
  post: 'Postage Fee',
  dlvry: 'Delivery Fee',
  rsrch: 'Research Task',
  trvl: 'Travel',
  vague: 'Vague Description'
}

export const INVOICE_AI = [
  {
    guideline_long_desc:
      'Administrative or Clerical tasks including filing, organizing, mailing, etc. are not chargeable tasks.',
    guideline_icon: 'icon-paper-clip',
    id: 2,
    guideline_code: 1,
    guideline_abbr: 'Administrative Task'
  },
  {
    guideline_long_desc: "Work billed on a date outside the invoice's start and end billing dates.",
    guideline_icon: 'icon-warning-sign',
    id: 28,
    guideline_code: 33,
    guideline_abbr: 'Block Billing'
  },
  {
    guideline_long_desc:
      'Block Billing is when a timekeeper combines several unrelated tasks into a single line item. The ABA recommends against this practice as it leaves clients open to excessive billing.',
    guideline_icon: 'icon-tags',
    id: 11,
    guideline_code: 10,
    guideline_abbr: 'Conference'
  },
  {
    guideline_long_desc:
      'Chipping describes splitting very short tasks into separate line items resulting in an inflated bill. For example: Preparing email (.2 hours), followed by Sending email (.1 hours).',
    guideline_icon: 'icon-list-ol',
    id: 20,
    guideline_code: 19,
    guideline_abbr: 'Copy'
  },
  {
    guideline_long_desc:
      'Time spent copying, scanning, faxing, printing, etc. are considered administrative tasks and are not chargeable tasks. Copying can be billed at up to $0.10 per page.',
    guideline_icon: 'icon-copy',
    id: 15,
    guideline_code: 14,
    guideline_abbr: 'Excessive Hours'
  },
  {
    guideline_long_desc: 'Incorrect discount applied.',
    guideline_icon: 'icon-cut',
    id: 26,
    guideline_code: 31,
    guideline_abbr: 'Non Attourney/Non Admin Task'
  },
  {
    guideline_long_desc:
      'Duplicate is usually a billing error that caused the same line item to appear twice on the same invoice.',
    guideline_icon: 'icon-copy',
    id: 8,
    guideline_code: 7,
    guideline_abbr: 'Overhead Task'
  },
  {
    guideline_long_desc:
      'Time appears excessive for the given description. Time spent completing templates and form letters should only be billed at actual time, not the time to originally build the template.',
    guideline_icon: 'icon-time',
    id: 5,
    guideline_code: 4,
    guideline_abbr: 'Research Task'
  },
  {
    guideline_long_desc:
      'Unless requested by client, expedited delivery is not necessary and the charges are not billable.',
    guideline_icon: 'icon-truck',
    id: 13,
    guideline_code: 12,
    guideline_abbr: 'Postage/Delivery Fee'
  },
  {
    guideline_long_desc:
      'A task entered repeatedly for the same amount has the potential for excessive hours. For example, Update Cap Table repeated several times, always at .5 hours is indicative of possibly excessive time.',
    guideline_icon: 'icon-cogs',
    id: 22,
    guideline_code: 21,
    guideline_abbr: 'Travel'
  },
  {
    guideline_long_desc: 'Internal conferences are not chargeable tasks.',
    guideline_icon: 'icon-comments',
    id: 17,
    guideline_code: 16,
    guideline_abbr: 'Vague Description'
  }
]

export const ER6_FIELDS = [
  'billing_start_date',
  'billing_end_date',
  'received_date',
  'line_item_date'
]
