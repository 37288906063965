import { useState, useEffect, ChangeEventHandler } from 'react'
import { Button } from 'simple-core-ui'
import { DayPicker } from 'react-day-picker8'
import { format, isValid, parse, add } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants'
import cn from 'classnames'
import s from './DueDatePicker.scss'
import '!style-loader!css-loader!react-day-picker8/dist/style.css' // eslint-disable-line

const { DEFAULT_DATE_FNS } = DATE_FORMATS

// eslint-disable-next-line prettier/prettier
export type DateFormat = `${number | ''}${number}/${number}${number}/${number}${number}${number}${number}`

interface Props {
  value: DateFormat | Date | undefined
  className?: string
  placeholder?: string
  disabledDays?: {
    before: Date
    after: Date
  }
  togglePopper?: () => void
  readOnly?: boolean
  canSave?: (date: Date | undefined) => boolean
  onClearCb?: () => void
}

interface WithButtons extends Props {
  onConfirm: (value: Date | undefined) => void
  onChange?: never
}
interface WithoutButtons extends Props {
  onConfirm?: never
  onChange: (value: Date | undefined) => void
}

const toDate = (date: string) => {
  if (!date) {
    return undefined
  }
  return parse(date, DEFAULT_DATE_FNS, new Date())
}

const getRelativeToTodayDate = (param: { [key in 'days' | 'weeks']?: number }) => {
  return format(add(new Date(), param), 'E')
}

const isValidFormat = (dateString: string) => {
  const regEx = /^\d{2}\/\d{2}\/\d{4}$/
  if (!regEx.test(dateString)) {
    return false
  }
  const year = parseInt(dateString.substring(6))
  return year > 1499
}

export const DueDatePicker = ({
  className,
  disabledDays,
  onChange,
  value,
  onConfirm,
  togglePopper,
  readOnly,
  canSave,
  onClearCb
}: WithButtons | WithoutButtons) => {
  const [selected, setSelected] = useState<Date | undefined>(undefined)
  const [inputValue, setInputValue] = useState('')
  const [refreshKey, setRefreshKey] = useState(0)
  const [showInvalidDateError, setShowInvalidDateError] = useState(false)

  useEffect(() => {
    if (!value) {
      setSelected(undefined)
      setInputValue('')
      return
    }
    const date = value instanceof Date ? value : toDate(value)
    if (date !== selected) {
      setSelected(date)
      date && setInputValue(format(date, DEFAULT_DATE_FNS))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleDaySelect = (date: Date | undefined) => {
    setSelected(date)
    setShowInvalidDateError(false)
    if (date) {
      setInputValue(format(date, DEFAULT_DATE_FNS))
      onChange?.(date)
    } else {
      setInputValue('')
    }
  }

  const handleShortcutClick = (date: Date) => {
    if (readOnly) {
      return
    }
    handleDaySelect(date)
    setRefreshKey(refreshKey + 1)
    setShowInvalidDateError(false)
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    setInputValue(e.currentTarget.value)
    const date = toDate(e.currentTarget.value)

    if (isValid(date) && isValidFormat(e.currentTarget.value)) {
      setShowInvalidDateError(false)
      setRefreshKey(refreshKey + 1)
      setSelected(date)
      onChange?.(date)
    } else {
      setShowInvalidDateError(e.currentTarget.value !== '')
      setSelected(undefined)
    }
  }

  const onClear = () => {
    setShowInvalidDateError(false)
    setSelected(undefined)
    setInputValue('')
    onChange?.(undefined)
    onClearCb?.()
  }

  return (
    <div className={cn(s.dayPickerWrapper, className)}>
      <div className={s.sidebar}>
        <input
          disabled={readOnly}
          type="text"
          placeholder="MM/DD/YYYY"
          value={inputValue}
          onChange={handleInputChange}
          style={showInvalidDateError ? { border: '1px solid red' } : {}}
        />
        {showInvalidDateError && <p className={s.error}>Enter MM/DD/YYYY format</p>}
        <div className={s.row} onClick={() => handleShortcutClick(new Date())}>
          <div className={s.bold}>Today</div>
          <div className={s.day}>{format(new Date(), 'E')}</div>
        </div>
        <div className={s.row} onClick={() => handleShortcutClick(add(new Date(), { days: 1 }))}>
          <div className={s.bold}>Tomorrow</div>
          <div className={s.day}>{getRelativeToTodayDate({ days: 1 })}</div>
        </div>
        <div className={s.row} onClick={() => handleShortcutClick(add(new Date(), { weeks: 1 }))}>
          <div className={s.bold}>In 1 week</div>
          <div className={s.day}>{getRelativeToTodayDate({ weeks: 1 })}</div>
        </div>
        <div className={s.row} onClick={() => handleShortcutClick(add(new Date(), { weeks: 2 }))}>
          <div className={s.bold}>In 2 weeks</div>
          <div className={s.day}>{getRelativeToTodayDate({ weeks: 2 })}</div>
        </div>
        <div className={s.row} onClick={() => handleShortcutClick(add(new Date(), { weeks: 4 }))}>
          <div className={s.bold}>In 4 weeks</div>
          <div className={s.day}>{getRelativeToTodayDate({ weeks: 4 })}</div>
        </div>
      </div>
      <div className={s.main}>
        <DayPicker
          key={refreshKey}
          mode="single"
          defaultMonth={selected}
          selected={selected}
          onSelect={handleDaySelect}
          className={className}
          disabled={disabledDays || readOnly}
          modifiersStyles={{
            selected: { background: '#E2F0FF', border: '2px solid #3C98FD', color: '#000000' }
          }}
          fromYear={1500}
          toYear={9999}
        />
        {onConfirm && (
          <div className={s.footer}>
            <Button hasNewDesign onClick={onClear} isPrimary isOutline isDisabled={readOnly}>
              Clear
            </Button>
            <Button
              hasNewDesign
              onClick={() => {
                onConfirm?.(selected)
                togglePopper?.()
              }}
              isPrimary
              isDisabled={showInvalidDateError || readOnly || (canSave && !canSave(selected))}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
