import { call, put, takeLatest } from 'redux-saga/effects'
import swal from 'sweetalert'

import { makeFormDataPost, makePostRequest, makeGetRequest, makeGetRepeatedParams } from 'utils/api'

import ACT from './actions'
import APP_ACT from '../app/actions'
import { uriEncodeObject } from 'utils/helpers'
import { formatResponse } from 'utils/formatting'
import { fes } from 'scui/utils/formatting'
import { NotificationList } from 'components'
import {
  serializeBudgetRequestList,
  serializeBudgetAmounts,
  serializeActiveBudgetList,
  serializeParams,
  serializeUpdateBudget,
  toFragment,
  serializeBudgetRequestParams,
  serializeCreateBudgetRequest
} from './serializers'

export function* submitBudgetRequest(action) {
  try {
    const { body, requested } = action.payload
    const createBudgetUrl = '/budgets/create/'
    const createBudgetResponse = yield call(makeFormDataPost, createBudgetUrl, body)
    if (createBudgetResponse.budget_url) {
      yield put({
        type: ACT.SUBMIT_BUDGET_REQUEST_SUCCESS,
        loadingLock: 'off',
        payload: {}
      })

      if (!requested) {
        window.location = createBudgetResponse.budget_url
      } else {
        yield put({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Budget Request Created',
            message: (
              <>
                {`You've successfully sent your budget request.`}{' '}
                <a href={createBudgetResponse.budget_url}>Click here to see your request.</a>
              </>
            ),
            level: 'success'
          }
        })
      }
    }
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

export function* submitReferral(action) {
  const {
    vendorId,
    vendorContactId,
    budgetType,
    refreshPeriod,
    budgetRequested,
    budgetName,
    budgetStartDate,
    budgetEndDate,
    phaseSet,
    matterId,
    budgetDueDate,
    collaborators,
    budgetYear
  } = action.payload

  // first create referral
  const createReferralUrl = '/manage/matters/v2/refer_vendor/'

  const createReferralBody = {
    matter: matterId,
    vendor: vendorId,
    vendor_contact: vendorContactId
  }

  let createReferralResponse
  try {
    createReferralResponse = yield call(makeFormDataPost, createReferralUrl, createReferralBody)
  } catch (e) {
    return yield put({
      type: ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'There was an issue referring vendor to matter',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }

  if (createReferralResponse && budgetRequested) {
    const body = serializeCreateBudgetRequest(action.payload)

    yield put({
      type: ACT.SUBMIT_BUDGET_REQUEST,
      payload: {
        body,
        requested: true
      }
    })
  } else {
    if (window._reactRerenderVendorPane) {
      window._reactRerenderVendorPane(createReferralResponse)
    }
  }

  yield put({
    type: ACT.SUBMIT_REFERRAL_SUCCESS,
    loadingLock: 'off',
    payload: {}
  })
}

export function* makeProposalAction(action) {
  const proposalUrl = window.serverContext.get('budget_proposal_endpoint') || 'proposal'

  const data = {
    proposal_id: action.payload.proposalId,
    action: action.payload.action,
    reject_note: action.payload.rejectNote
  }

  try {
    const response = yield call(makeFormDataPost, proposalUrl, data)

    window.location.reload()
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'There was an issue taking action on this budget proposal',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

export function* submitBudget(action) {
  const {
    vendorId,
    budgetType,
    refreshPeriod,
    budgetName,
    budgetStartDate,
    budgetEndDate,
    matterId,
    budgetDueDate,
    phaseSet,
    collaborators,
    budgetYear,
    requested,
    amount
  } = action.payload

  const body = {
    budget_type: budgetType ? budgetType.value : null,
    budget_name: budgetName,
    budget_vendor_id: vendorId,
    budget_matter_id: matterId,
    refresh: refreshPeriod ? refreshPeriod.value : null,
    requested: requested,
    budget_year: budgetYear,
    budget_phase_set: phaseSet ? phaseSet.value : null,
    counselgo_collaborators: collaborators ? collaborators.map(c => c.value) : null,
    budget_start_date: budgetStartDate,
    budget_end_date: budgetEndDate,
    budget_due_date: budgetDueDate,
    budget_amount: amount
  }
  yield put({
    type: ACT.SUBMIT_BUDGET_REQUEST,
    payload: { body, requested }
  })
}

function* fetchBudgetRequests(action) {
  const { params } = action
  const serialized = serializeBudgetRequestParams(params)
  const url = `/budgets/request_list/`

  try {
    const response = yield call(makeGetRequest, url, { params: serialized })
    const budgetRequests = serializeBudgetRequestList(response)
    yield put({
      type: ACT.BUDGET_REQUESTS_LIST_FETCH_SUCCESS,
      payload: {
        budgetRequests: budgetRequests,
        totalEntries: response.totalEntries,
        filteredTotal: response.filteredEntries,
        params
      }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue when creating budget request',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

export function* fetchActiveBudgets(action) {
  const url = '/budgets/active_list/'
  const params = serializeParams(action.payload.params)
  if (!action.payload.disableFragments) {
    toFragment(action.payload.params)
  }

  try {
    const response = yield call(makeGetRepeatedParams, url, params)
    const activeBudgets = serializeActiveBudgetList(response)
    yield put({
      type: ACT.ACTIVE_BUDGETS_LIST_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { activeBudgets }
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* notifyError(message) {
  yield put({
    type: APP_ACT.PUSH_NOTIFICATION,
    payload: {
      title: 'Error',
      level: 'error',
      message
    },
    loadingLock: 'off'
  })
}

function* downloadActiveBudgets(action) {
  const url = '/budgets/active/excel/download/'
  const baseParams = serializeParams(action.payload.params)

  // As a hack to get around difficulties starting a download from axios,
  // I'm going to find out ahead of time if I need to set window.location.href.
  let response
  try {
    response = yield call(makeGetRequest, url, { params: { ...baseParams, count_only: true } })
  } catch (e) {
    yield notifyError('There was an issue computing the budget list on the server.')
    return
  }

  // Per FK-562, this download is very slow because of expensive budget annotations.
  // About .1 seconds per budget at the moment. So we only serve in-band if we think
  // we can finish on time.
  if (response.count < 100) {
    window.location.href = url + '?' + uriEncodeObject(baseParams)
  } else {
    try {
      response = yield call(makeGetRequest, url, {
        params: { ...baseParams, generate_email: true }
      })
    } catch (e) {
      yield notifyError('There was an issue computing the budget list on the server.')
      return
    }
    const email = response.email
    swal('Generating Report', `A report will be sent to ${email} in a few minutes.`, 'success')
  }

  yield put({
    type: ACT.DOWNLOAD_ACTIVE_BUDGETS_SUCCESS,
    loadingLock: 'off'
  })
}

const serializeBudgetAmountNotes = rows => {
  const result = {}
  for (let i = 0; i < rows.length; i++) {
    let notesKey = `notes-${rows[i].id}`
    let feesKey = `fees-${rows[i].id}`
    let expensesKey = `fees-${rows[i].id}`
    result[notesKey] = rows[i].note
  }
  return result
}

function* deleteBudget(action) {
  const url = '/budgets/delete/'

  const data = {
    budget_id: action.payload
  }

  try {
    const response = yield call(makeFormDataPost, url, data)
    yield put({
      type: ACT.DELETE_BUDGET_SUCCESS,
      loadingLock: 'off',
      payload: data,
      ...(action.counts && action.counts.total === action.counts.current
        ? { success: `${action.counts.total} budgets deleted` }
        : {})
    })
  } catch (error) {
    put({ type: 'API_ERROR', error })
  }
}

function* updateBudgetAmounts(action) {
  const url = '/budgets/update-amounts/'
  const { details } = action.payload

  const transformedRows = serializeBudgetAmountNotes(details.rows)

  const body = {
    budget_id: details.id,
    ...transformedRows
  }

  const response = yield call(makeFormDataPost, url, body)

  yield put({
    type: ACT.UPDATE_BUDGET_AMOUNTS_SUCCESS
  })
}

function* bulkUpdateBudgets(action) {
  try {
    const url = '/budgets/bulk_edit/'

    const { num_failed, num_updated, budgets_updated } = yield call(makePostRequest, url, {
      action: action.payload.action,
      budget_ids: action.payload.budget_ids,
      execute: true,
      action_val: action.payload.action_val?.value || action.payload.action_val
    })
    const amountToChange = action.payload.budget_ids.length
    const deleteStatus =
      action.payload.action_val === 'archive' ||
      action.payload.action_val === 'draft' ||
      action.payload.action_val === 'approve' ||
      action.payload.action_val === 'revert'

    if (num_failed) {
      const confirmBody = {
        title: `${num_updated} out of ${amountToChange} records processed successfully.`,
        text: `${num_failed} out of ${amountToChange} records did not get processed. \n \n For those records that did not process, please try again later. If you need help, contact SimpleLegal support at help@simplelegal.com.`,
        buttons: [false, 'Okay']
      }
      yield call(swal, confirmBody)
      yield put({
        type: ACT.BULK_UPDATE_BUDGET_SUCCESS,
        payload: {
          ...action.payload,
          budget_ids: budgets_updated,
          status: deleteStatus ? 'delete' : 'mutate'
        }
      })
    } else {
      const success = action.payload.customSuccess || `${fes(num_updated, 'records')} modified`
      yield put({
        type: ACT.BULK_UPDATE_BUDGET_SUCCESS,
        success,
        payload: {
          ...action.payload,
          budget_ids: budgets_updated,
          status: deleteStatus ? 'delete' : 'mutate'
        }
      })
    }
  } catch (error) {
    yield put({ type: 'API_ERROR', error })
  }
}

function* updateBudgets(action) {
  try {
    const url = '/budgets/update/'

    const body = serializeUpdateBudget(action.payload)

    yield put({
      type: ACT.UPDATE_ACTIVE_BUDGETS_LIST,
      payload: action.payload
    })

    if (action.payload.status === 'Revert') {
      return yield put({
        type: ACT.BULK_UPDATE_BUDGET_REQUESTED,
        payload: {
          action: 'status',
          action_val: 'revert',
          budget_ids: [action.payload.pk],
          execute: true,
          customSuccess: 'Budget modified'
        }
      })
    }

    yield call(makeFormDataPost, url, body)
    const deleteStatuses = ['Archived', 'Revert', 'Approved', 'Draft']

    if (deleteStatuses.find(x => x === action.payload.status)) {
      yield put({
        type: ACT.DELETE_BUDGET_SUCCESS,
        success: 'Budget modified',
        payload: {
          budget_id: action.payload.pk
        }
      })
    } else {
      yield put({
        success: 'Budget modified',
        type: ACT.UPDATE_BUDGET_AMOUNTS_SUCCESS
      })
    }
  } catch (error) {
    yield call({
      type: 'PUSH_NOTIFICATION',
      payload: {
        message: 'Edit could not be saved, please try again later.',
        level: 'error'
      }
    })
  }
}

function* saveReforecast(action) {
  const url = '/budgets/update-amounts/'
  const { details } = action.payload

  const transformedRows = serializeBudgetAmounts(details.rows)

  const body = {
    budget_id: details.id,
    budget_amount: details.amount,
    ...transformedRows
  }

  try {
    yield call(makeFormDataPost, url, body)
    yield put({
      type: ACT.SAVE_REFORECAST_SUCCESS,
      loadingLock: 'off'
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* fetchRecentBudget(action) {
  const url = `/budgets/history/${action.payload.budgetId}`
  try {
    const response = yield call(makeGetRequest, url)
    yield put({
      type: ACT.RECENT_BUDGET_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { budget: response }
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* canLockBudgets() {
  try {
    const payload = yield call(makeGetRequest, '/budgets/can_lock')
    yield put({
      type: ACT.LOCK_STATE_FETCH_SUCCESS,
      payload
    })
  } catch (error) {
    yield put({ type: 'API_ERROR', error })
  }
}

function* fetchBudgetDetails() {
  let url = `/budgets/${window.serverContext.get('object_id')}/details`
  if (window.serverContext.get('recent_budget_id')) {
    url = `/budgets/${window.serverContext.get('recent_budget_id')}/details/recent`
  }
  try {
    const response = yield call(makeGetRequest, url)
    yield put({
      type: ACT.BUDGET_DETAILS_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { budget: response }
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* fetchCompareDetails() {
  try {
    let response
    if (window.serverContext.get('proposed_budget_id')) {
      let url = `/budgets/${window.serverContext.get('proposed_budget_id')}/details/proposed`
      response = yield call(makeGetRequest, url)

      yield put({
        type: ACT.BUDGET_COMPARE_FETCH_SUCCESS,
        loadingLock: 'off',
        payload: { budget: response }
      })
    } else {
      yield put({
        type: ACT.BUDGET_COMPARE_FETCH_SUCCESS,
        loadingLock: 'off',
        payload: { budget: {} }
      })
    }
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

const budgetsSagas = [
  takeLatest(ACT.SUBMIT_BUDGET_REQUEST, submitBudgetRequest),
  takeLatest(ACT.DELETE_BUDGET_REQUESTED, deleteBudget),
  takeLatest(ACT.SUBMIT_REFERRAL_REQUESTED, submitReferral),
  takeLatest(ACT.MAKE_PROPOSAL_ACTION_REQUESTED, makeProposalAction),
  takeLatest(ACT.SUBMIT_BUDGET_REQUESTED, submitBudget),
  takeLatest(ACT.BUDGET_REQUESTS_LIST_FETCH_REQUESTED, fetchBudgetRequests),
  takeLatest(ACT.LOCK_STATE_FETCH_REQUESTED, canLockBudgets),
  takeLatest(ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED, fetchActiveBudgets),
  takeLatest(ACT.UPDATE_BUDGET_REQUESTED, updateBudgets),
  takeLatest(ACT.BULK_UPDATE_BUDGET_REQUESTED, bulkUpdateBudgets),
  takeLatest(ACT.DOWNLOAD_ACTIVE_BUDGETS, downloadActiveBudgets),
  takeLatest(ACT.UPDATE_BUDGET_AMOUNTS_REQUESTED, updateBudgetAmounts),
  takeLatest(ACT.SAVE_REFORECAST_REQUESTED, saveReforecast),
  takeLatest(ACT.RECENT_BUDGET_FETCH_REQUESTED, fetchRecentBudget),
  takeLatest(ACT.BUDGET_DETAILS_FETCH_REQUESTED, fetchBudgetDetails),
  takeLatest(ACT.BUDGET_COMPARE_FETCH_REQUESTED, fetchCompareDetails)
]

export default budgetsSagas
