import { useState } from 'react'
import get from 'lodash/get'
import { Anchor, KeyValuePairs, XeditableContainer, If, Panel, SwitchToggle } from 'simple-core-ui'

import { INVOICE_EMAIL_PREFERENCE } from 'utils/constants'

import { makeGetRequest } from 'utils/api'
import { select2ToReactSelect, timezoneUtils, hasModule } from 'utils/helpers'

import s from './ContactUserSettings.scss'
import Select from 'react-select'

const ReadOnly = ({ settings, settingKey }) => (
  <span className={s.readOnly}>{get(settings, settingKey, '----')}</span>
)

const fetchOptions = async (url, search) => {
  const response = await makeGetRequest(url, { params: { search_term: search, page_size: '25' } })
  return select2ToReactSelect(response).options
}

const timezonesList = timezoneUtils.getTimezonesOptions().map(t => ({
  label: t.label + ' (' + t.offSet + ')',
  value: t.value
}))

const ContactUserSettings = ({
  settings,
  updateSetting,
  connectWithGoogle,
  disconnectWithGoogle,
  isRequestingUser,
  canEdit,
  canEditLoginPreference,
  hasDashboardModule,
  hasTableauModule,
  isLoading
}) => {
  const [customTimezone, setCustomTimezone] = useState(!!settings.timezone)
  const [oldCustomTimezone, setOldCustomTimezone] = useState(customTimezone)

  if (!!settings.timezone !== oldCustomTimezone) {
    setCustomTimezone(!!settings.timezone)
    setOldCustomTimezone(!!settings.timezone)
  }

  const ssoLink =
    isRequestingUser && !settings.clientUsingSSO
      ? [
          {
            key: 'Single Sign On',
            value: (
              <div
                className={s.googleAuthButton}
                onClick={settings.connectedToGoogle ? disconnectWithGoogle : connectWithGoogle}
              >
                <span className={s.googleGIcon} />
                <If condition={!settings.connectedToGoogle} fallback="Disconnect from Google">
                  Sign In with Google
                </If>
              </div>
            )
          }
        ]
      : []

  const defaultTemplate =
    isRequestingUser || canEdit
      ? [
          {
            key: 'Default Template',
            value: (
              <XeditableContainer
                type="list"
                title="Update Default Template"
                initialValue={settings.defaultTemplate}
                setValueCb={option =>
                  updateSetting({ defaultTemplate: get(option, 'value', option) })
                }
                position="right"
                options={search => fetchOptions('/templates/matter_templates_json/', search)}
              />
            )
          }
        ]
      : []

  const emailPreference = isRequestingUser
    ? [
        {
          key: 'Email Preference',
          value: (
            <XeditableContainer
              type="list"
              title="Update Email Preference"
              initialValue={settings.emailPreference}
              setValueCb={({ value }) => updateSetting({ emailPreference: value })}
              position="right"
              options={[
                {
                  label: 'One Per Invoice',
                  value: INVOICE_EMAIL_PREFERENCE.PER_INVOICE
                },
                {
                  label: 'One Per Vendor',
                  value: INVOICE_EMAIL_PREFERENCE.PER_VENDOR
                },
                {
                  label: 'Combined',
                  value: INVOICE_EMAIL_PREFERENCE.COMBINED
                }
              ]}
              validation={value => (!value ? 'An email preference is required.' : null)}
            />
          )
        }
      ]
    : []

  const tabAccess = hasTableauModule
    ? [
        {
          key: 'Tableau Access',
          value: (
            <XeditableContainer
              type="list"
              title="Update Tableau Access"
              initialValue={
                settings.tabUserActive
                  ? {
                      label: 'Yes',
                      value: true
                    }
                  : {
                      label: 'No',
                      value: false
                    }
              }
              setValueCb={({ value }) => {
                if (value) {
                  updateSetting({ tabAccessCreate: settings.userId })
                } else {
                  updateSetting({ tabAccessDelete: settings.tabUserId })
                }
              }}
              position="right"
              options={[
                {
                  label: 'Yes',
                  value: true
                },
                {
                  label: 'No',
                  value: false
                }
              ]}
              isClearable={false}
            />
          )
        }
      ]
    : []

  const loginPreferenceElement =
    settings.canEditLoginPreference && canEdit ? (
      <XeditableContainer
        type="list"
        title="Update Login Preference"
        initialValue={settings.loginPreference}
        position="right"
        setValueCb={value => updateSetting({ loginPreference: value.value })}
        options={[
          {
            label: 'Password',
            value: 'password'
          },
          {
            label: 'SSO',
            value: 'SSO'
          }
        ]}
        isClearable={false}
      />
    ) : (
      <ReadOnly settings={settings} settingKey="loginPreference.label" />
    )

  const approvalAuthorityString = get(settings, 'approvalAuthorityString')

  return (
    <section className={s.container}>
      <Panel title="Settings">
        <KeyValuePairs
          pairs={[
            ...ssoLink,
            {
              key: 'Legal Entity',
              value: (
                <If
                  condition={canEdit}
                  fallback={<ReadOnly settings={settings} settingKey="legalEntity.label" />}
                >
                  <XeditableContainer
                    type="list"
                    title="Update Legal Entity"
                    position="right"
                    initialValue={settings.legalEntity}
                    setValueCb={option =>
                      updateSetting({
                        legalEntity: get(option, 'value', -1)
                      })
                    }
                    options={search =>
                      fetchOptions('/manage/matters/legal_entities_list_json', search)
                    }
                  />
                </If>
              )
            },
            {
              key: 'User Role',
              value: (
                <span className={s.readOnly}>
                  <Anchor url="/company/#users">{get(settings, 'userRole.label', 'N/A')}</Anchor>
                </span>
              )
            },
            {
              key: 'Last Logged In',
              value: <ReadOnly settings={settings} settingKey="lastLogin" />
            },
            {
              key: 'Forced Logout On',
              value: <ReadOnly settings={settings} settingKey="forcedLogout" />
            },
            {
              key: 'Login Preference',
              value: loginPreferenceElement
            },
            {
              key: 'Approval Authority',
              value: (
                <span className={s.readOnly}>
                  <a href="/rules/escalations/">
                    {approvalAuthorityString === '' ? 'N/A' : approvalAuthorityString}
                  </a>
                </span>
              )
            },
            {
              key: 'Invoice Escalation',
              value: (
                <span className={s.readOnly}>
                  <a href="/rules/escalations/">
                    {get(settings, 'invoiceEscalation.label', 'N/A')}
                  </a>
                </span>
              )
            },
            ...emailPreference,
            ...defaultTemplate,
            ...tabAccess,
            ...(hasModule('has_event_management')
              ? [
                  {
                    key: 'Event Time zone',
                    value: (
                      <>
                        <p className={s.timezoneSubtitle}>
                          Set Time zone automatically using browser settings
                        </p>
                        <div style={{ display: 'flex' }}>
                          <SwitchToggle
                            onToggle={checked => {
                              if (checked && settings.timezone) {
                                updateSetting({ timezone: null })
                              }
                              setCustomTimezone(!checked)
                            }}
                            className={s.timezoneToggle}
                            checked={!customTimezone}
                          />
                          <Select
                            isDisabled={!customTimezone}
                            value={
                              settings.timezone
                                ? timezonesList.find(t => t.value === settings.timezone)
                                : null
                            }
                            onChange={value => updateSetting({ timezone: value.value })}
                            placeholder="Select Time zone"
                            options={timezonesList}
                            className={s.timezoneSelect}
                          />
                        </div>
                      </>
                    )
                  }
                ]
              : [])
          ]}
          style={{ padding: '0 1.5em' }}
          stackedKeys
          isLoading={isLoading}
        />
      </Panel>
    </section>
  )
}

export default ContactUserSettings
