import { Fragment, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import s from './Dropzone.scss'
import * as ACT from '../actions'
import { Input, HoverAction, Alert } from 'simple-core-ui'
import { CategorySelect, LabelsSelect } from 'common/Selects'
import { v4 as uuidv4 } from 'uuid'
import { IoIosClose } from 'react-icons/io'
import cn from 'classnames'
import { FaExclamationTriangle } from 'react-icons/fa'
import { MAX_FILES_ALLOWED, MAX_FILE_SIZE_ALLOWED, MAX_FILE_SIZE_ALLOWED_IN_GB } from '../constants'
import { bitesToMb } from '../helpers'
import { getFileNameExtension } from '../utils'
import allowedExtensions from './allowedExtensions'

const Dropzone = ({ files, dispatch, duplicatedFileNames, scanningInfectedFileNames }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [uploadedBatchLength, setUploadedBatchLength] = useState(0)
  const [rejectedFiles, setRejectedFiles] = useState([])

  const onDrop = acceptedFiles => {
    setIsDragging(false)
    setUploadedBatchLength(acceptedFiles.length)
    const limitedFiles = acceptedFiles.slice(0, MAX_FILES_ALLOWED - files.length)
    dispatch({
      type: ACT.ADD_FILES,
      payload: {
        files: limitedFiles.map(file => {
          file.id = uuidv4()
          return file
        })
      }
    })
  }

  const onDropRejected = rejectedFiles => {
    setRejectedFiles(rejectedFiles)
  }

  const removeFile = index => {
    dispatch({
      type: ACT.REMOVE_FILE,
      payload: {
        index
      }
    })
  }

  const changeFileName = (index, value, extension) => {
    dispatch({
      type: ACT.CHANGE_FILE_NAME,
      payload: {
        index,
        name: value,
        extension
      }
    })
  }

  const addCategory = (index, category) => {
    dispatch({
      type: ACT.ADD_CATEGORY,
      payload: {
        selectedCategory: category,
        index
      }
    })
  }

  const addLabels = (index, labels) => {
    dispatch({
      type: ACT.ADD_LABELS,
      payload: {
        selectedLabels: labels,
        index
      }
    })
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    onDropRejected,
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    },
    validator: file => {
      const { extension } = getFileNameExtension(file.name)
      if (!allowedExtensions.includes(extension.split('.')[1].toLowerCase())) {
        return {
          code: 'not-allowed',
          message: `${extension} extension is not allowed`
        }
      }
      return null
    }
  })

  return (
    <Fragment>
      <div className={s.dropzoneWrapper}>
        {uploadedBatchLength > MAX_FILES_ALLOWED && (
          <Alert
            message={`Maximum ${MAX_FILES_ALLOWED} files allowed per upload.`}
            status="warning"
            className={s.maxFilesExceded}
          />
        )}
        <p className={s.title}>Drag and drop file(s) below or browse your computer folders.</p>
        <div
          data-testid="dropzone"
          {...getRootProps({ className: cn(s.dropzone, { [s.onDrag]: isDragging }) })}
        >
          <input {...getInputProps()} />
          <div className={s.dropzoneText}>
            <span>Click here</span> to select files or drag and drop files in here <br />
            <p style={{ textAlign: 'center' }}>
              Maximum {MAX_FILES_ALLOWED} files allowed per upload.
            </p>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <div className={s.filesWrapper}>
          <div className={s.flex}>
            <label className={s.label}>File Name</label>
            <label className={s.label}>Category (optional)</label>
            <label className={s.label}>Label(s) (optional)</label>
          </div>
          {files.map((file, i) => {
            const isDupe = duplicatedFileNames.includes(file.name)
            const isScanningOrInfected = scanningInfectedFileNames.includes(file.name)
            return (
              <div key={file.id} className={s.file}>
                <div className={s.flex}>
                  <Input
                    type="text"
                    text={file.name}
                    placeholder="File Name"
                    isDisabled
                    cssClass={cn(s.fileName, {
                      error:
                        isDupe ||
                        bitesToMb(file.size) > MAX_FILE_SIZE_ALLOWED ||
                        isScanningOrInfected
                    })}
                  />
                  <CategorySelect value={file.category} onChange={value => addCategory(i, value)} />
                  <LabelsSelect value={file.labels} onChange={values => addLabels(i, values)} />
                  <HoverAction onClick={() => removeFile(i)} icon={<IoIosClose />} />
                </div>
                {isDupe && (
                  <p className={s.error}>
                    <span title="This name already exist">
                      <FaExclamationTriangle className={s.warningIcon} />
                    </span>
                    Cannot have duplicate file name
                  </p>
                )}
                {isScanningOrInfected && (
                  <p className={s.error}>
                    <span title="This file is in scanning or infected status">
                      <FaExclamationTriangle className={s.warningIcon} />
                    </span>
                    This file is in scanning or infected status
                  </p>
                )}
                {bitesToMb(file.size) > MAX_FILE_SIZE_ALLOWED && (
                  <p className={s.error}>
                    <span title={`File size exceeds the ${MAX_FILE_SIZE_ALLOWED_IN_GB}GB limit`}>
                      <FaExclamationTriangle className={s.warningIcon} />
                    </span>
                    {`File size exceeds the ${MAX_FILE_SIZE_ALLOWED_IN_GB}GB limit`}
                  </p>
                )}
              </div>
            )
          })}
        </div>
      )}
      {!!rejectedFiles.length &&
        rejectedFiles.map(({ errors }) => {
          return errors.map(e => (
            <p key={e.code} className={s.error}>
              <span title={e.message}>
                <FaExclamationTriangle className={s.warningIcon} />
              </span>
              {e.message}
            </p>
          ))
        })}
    </Fragment>
  )
}

export default Dropzone
