import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Panel } from 'simple-core-ui'
import Toggle from 'react-toggle'
import ACT from 'doc_management/settings/actions'
import s from './PreviewContainer.scss'

const selectPreviewSettings = createSelector([state => state.previewSettings], previewSettings => {
  return previewSettings
})

const PreviewContainer = () => {
  const previewSettings = useSelector(state =>
    selectPreviewSettings(state.documentManagementSettings)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: ACT.PREVIEW_SETTINGS_FETCH_REQUESTED })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeSettingValue = (setting, value) => {
    dispatch({
      type: ACT.EDIT_PREVIEW_SETTING_REQUESTED,
      payload: {
        setting: { ...setting, value }
      }
    })
  }

  return (
    <section className={s.previewSettings}>
      {previewSettings.map(setting => (
        <Panel
          key={setting.name}
          title={setting.title}
          className={s.previewSettingContainer}
          styles={{ boxShadow: 'none', marginBottom: 48 }}
        >
          <section className={s.settingTitle}>
            <h2>{setting.label}</h2>
            <Toggle
              checked={setting.value}
              onChange={e => changeSettingValue(setting, e.target.checked)}
              key={`${setting.name}-toggler`}
              className={s.test}
            />
          </section>
          <section className={s.settingBody}>{setting.description}</section>
        </Panel>
      ))}
    </section>
  )
}

export default PreviewContainer
