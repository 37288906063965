import moment from 'moment'

export const fromINMA = items => {
  return {
    invoices: items.invoices_needing_my_approval.map(i => {
      return {
        id: i.id,
        number: i.number,
        total: i.total,
        createdDate: i.created_date,
        matterName: i.matter_name,
        vendorName: i.vendor_name,
        flags: i.flags
      }
    }),
    budgets: items.budgets_needing_user_action.map(b => {
      return {
        id: b.id,
        name: b.name,
        total: b.total,
        createdDate: b.created_date,
        matterName: b.matter_name,
        vendorName: b.vendor_name
      }
    }),
    legalRequests: items.pending_matter_requests.map(lr => {
      return {
        id: lr.id,
        name: lr.name,
        createdDate: lr.created_date,
        requestedBy: `${lr.requested_by_user_fn} ${lr.requested_by_user_ln}`
      }
    }),
    vendorSurveys: items.pending_evaluations.map(vs => {
      return {
        id: vs.id,
        name: `Vendor Survey [${vs.start_date} - ${vs.end_date}]`,
        dueDate: vs.expiration_date,
        matterName: vs.matter_name,
        matterId: vs.matter_id,
        vendorName: vs.vendor_name,
        createdDate: vs.created_date,
        link: vs.link
      }
    }),
    matters: items.matters_needing_user_action.map(m => {
      return {
        id: m.id,
        name: m.name,
        clientMatterId: m.client_matter_id,
        description: m.description,
        createdDate: m.created_date
      }
    }),
    timekeepers: items.timekeepers_needing_my_approval.map(tk => {
      return {
        id: tk.id,
        fileStatus: tk.timekeeper_file_status,
        vendorId: tk.vendor_id,
        vendorName: tk.vendor_name,
        numTimekeepers: tk.num_timekeepers,
        receivedDate: moment(tk.file_upload_date).format('MM/DD/YYYY')
      }
    })
  }
}

export const fromEvents = response => {
  return response.events.map(i => ({
    id: i.id,
    name: i.name,
    description: i.description,
    eventTime: i.eventTime
  }))
}

export const fromRecent = recent => {
  return {
    lastWeek: recent.last_week.logs.map(i => ({
      name: i.name,
      model: i.model,
      url: i.model === 'Matter' ? `/v2/matters/${i.id}` : i.url,
      modelCount: i.model_count
    })),
    lastMonth: recent.last_month.logs.map(i => ({
      name: i.name,
      model: i.model,
      url: i.model === 'Matter' ? `/v2/matters/${i.id}` : i.url,
      modelCount: i.model_count
    }))
  }
}

export const fromFollowing = following => {
  return {
    mattersFollowing: following.my_matters.map(m => ({
      id: m.id,
      name: m.name,
      clientMatterId: m.client_matter_id,
      createdDate: m.created_date
    })),
    vendorsFollowing: following.my_vendors.map(v => ({
      id: v.id,
      name: v.name,
      createdDate: v.created_date
    }))
  }
}
