import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

const ActionsPopover = ({ rowId, editValue, deleteValue, canDelete }) => {
  const handleClick = (event, action) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    ReactTooltip.hide()
    switch (action) {
      case 'EDIT':
        editValue(rowId)
        return
      case 'DELETE':
        deleteValue(rowId)
        return
      default:
        return
    }
  }

  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
      >
        <ul className={s.actionsList}>
          <li
            className={s.actionItem}
            onClick={e => {
              handleClick(e, 'EDIT')
            }}
          >
            Edit
          </li>
          {canDelete && (
            <li
              className={s.actionItem}
              onClick={e => {
                handleClick(e, 'DELETE')
              }}
            >
              Delete
            </li>
          )}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
