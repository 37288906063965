import { useState, useEffect } from 'react'
import { Panel, Button, DataTableWrapper, AvatarList, useLoading } from 'simple-core-ui'
import { StatusModal } from './StatusModal'
import { getNormalizedCellContent } from './utils'
import { hex2rgba, checkByNameIfInArray, openLink, isBasicTaskManagementPlan } from 'utils/helpers'
import s from './Statuses.scss'
import moment from 'moment'
import { useImmer } from 'use-immer'
import { PHASES } from './constants'
import { Status } from './types'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import { makeGetRequest, makePatchRequest } from 'utils/api'
import { toStatuses } from './serializers'
import { ActionsPopover } from './ActionsPopover'
import ReactTooltip from 'react-tooltip'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 25,
    ordering: { columnKey: 'phase', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const columns = [
  {
    columnKey: 'name',
    content: 'Name',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'color',
    content: 'Color',
    isSortable: false,
    isFilterable: true
  },
  {
    columnKey: 'phase',
    content: 'Phase',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'relatedTasks',
    content: 'Related Tasks',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'lastEdited',
    content: 'Last Edited',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'editedBy',
    content: 'Edited By',
    isSortable: true,
    isFilterable: true
  }
]

const Statuses = () => {
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [statuses, setStatuses] = useImmer<Status[]>([])
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [status, setStatus] = useState({} as Status)
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statuses = await withLoadingLocks(
          makeGetRequest('/task-management/task-status-types/?includeClientSettings')
        )
        setStatuses(toStatuses(statuses))
      } catch (error) {
        dispatch({ type: 'API_ERROR', error })
      }
    }

    fetchStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCell = ({
    rowId,
    columnKey,
    content
  }: {
    rowId: number
    columnKey: string
    content: any
  }) => {
    if (!content && content !== 0) {
      return '--'
    }
    if (columnKey === 'name') {
      const color = statuses.find(p => p.id === rowId)?.color
      return (
        <div
          className={s.level}
          style={{
            backgroundColor: color && hex2rgba(color, 0.15),
            border: `1px solid ${color}`
          }}
        >
          {content}
        </div>
      )
    }
    if (columnKey === 'color') {
      return <div className={s.color} style={{ backgroundColor: content }} />
    }
    if (columnKey === 'phase') {
      return PHASES[+content]
    }
    if (columnKey === 'lastEdited') {
      return content === '----' ? '--' : moment(content).format('M/D/YYYY')
    }
    if (columnKey === 'editedBy') {
      return content === '----' ? (
        '--'
      ) : (
        <AvatarList size="md" wrapperStyles={{ width: 36 }} entries={[{ label: content }]} />
      )
    }
    return content
  }

  const toggleStatusModal = () => {
    setShowStatusModal(!showStatusModal)
    setShowDuplicateError(false)
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const showEditModal = (status: Status) => {
    toggleStatusModal()
    setStatus(status)
  }

  const updateStatus = (value: string, property: string) => {
    setShowDuplicateError(checkByNameIfInArray(value, statuses, { id: status.id }))
    setStatus({
      ...status,
      [property]: value
    })
  }

  const saveStatus = async () => {
    try {
      const { id, name, color } = status
      const index = statuses.findIndex(p => p.id === id)
      const oldStatus = statuses[index]

      const data = {
        ...(oldStatus.name !== name ? { displayName: name } : {}),
        ...(oldStatus.color !== color ? { color } : {})
      }
      const updatedStatus = await withLoadingLocks(
        makePatchRequest(`/task-management/task-status-types/${id}/`, data)
      )
      setStatuses(draft => {
        draft[index] = toStatuses([updatedStatus])[0]
      })

      toggleStatusModal()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task status ${status.name} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
      toggleStatusModal()
    }
  }

  const downloadXlsx = () => {
    const { columnKey, isDesc } = params.ordering
    openLink(`/task-management/task-status-types/export/?columnKey=${columnKey}&isDesc=${+isDesc}`)
  }

  const renderCustomAction = (row: Status) => {
    if (isBasicTaskManagementPlan()) {
      return (
        <>
          <span className={s.actionsPlaceholder} data-for={`upgradeTier-${row.id}`} data-tip>
            ...
          </span>
          <ReactTooltip
            id={`upgradeTier-${row.id}`}
            type="light"
            effect="solid"
            place="top"
            border
            className={s.tooltipPopup}
          >
            Contact customer success to upgrade.
          </ReactTooltip>
        </>
      )
    }
    return <ActionsPopover status={row} editStatus={showEditModal} />
  }

  return (
    <Panel
      title="Manage Task Statuses"
      subtitle="Use statuses to indicate a task's stage toward completion."
      rightActions={[
        <Button hasNewDesign key="download" onClick={downloadXlsx} isPrimary>
          Download
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.statuses}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={statuses}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.itemsTable}
        customAction={renderCustomAction}
        hasActions
        hasTooltip
        alwaysShowActions
        categoryKey="status"
        getNormalizedCellContent={getNormalizedCellContent}
        renderCell={renderCell}
        hasActionsHeader={false}
        hasPagination={false}
        multiSort
        memoizedRowActions={false}
      />
      {showStatusModal && (
        <StatusModal
          saveStatus={saveStatus}
          status={status}
          updateStatus={updateStatus}
          toggleStatusModal={toggleStatusModal}
          showDuplicateError={showDuplicateError}
        />
      )}
    </Panel>
  )
}

export default Statuses
