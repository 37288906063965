import s from '../BulkEditModalContainer.scss'
import { Checkbox, Grid, PaginatedSelect } from 'simple-core-ui'
import { ControlledDatePicker, Currency } from 'containers'
import {
  toUsers,
  toReactSelect,
  serializeDate,
  serializeBulkFieldList,
  filterOptions
} from 'bulk/serializer'
import Select from 'react-select'
import moment from 'moment'
import { omit } from 'lodash'
import TextareaAutosize from 'react-textarea-autosize'
import { modalControlStyles } from 'bulk/styles/styleSettings'
import { MATTERS_ONLY, MATTER_TEMPLATES } from 'bulk/record_types'

const inputStyles = {
  width: '75%',
  borderRadius: '4px'
}

const GenericFormBuilder = ({
  recordType,
  dynamicFields,
  selectedFields,
  searchString,
  formValues,
  setFormValues,
  setSelectedFields,
  errors,
  setErrors
}) => {
  const { Row, Column } = Grid
  const serializedFields = serializeBulkFieldList(dynamicFields)

  const onChangeHandler = (field, dataType, option) => {
    setSelectedFields({ ...selectedFields, [field]: true })
    if (dataType === 'currency' && (!option.value.amount || !option.value.code)) {
      setFormValues({ ...formValues, [field]: { ...option, dataType } })
      setErrors({ ...errors, [field]: true })
    } else if (!['', null].includes(option.value)) {
      setFormValues({ ...formValues, [field]: { ...option, dataType } })
      setErrors(omit(errors, field))
    } else {
      setFormValues(omit(formValues, field))
      setErrors({ ...errors, [field]: true })
    }
  }

  const checkboxHandler = (field, isChecked) => {
    setSelectedFields({ ...selectedFields, [field]: !isChecked })
    if (isChecked) {
      setFormValues(omit(formValues, field))
      setErrors(omit(errors, field))
    } else {
      setErrors({ ...errors, [field]: true })
    }
  }

  const currencyClassName = {
    input: s.currencyField,
    inputWrapper: s.currencyWrapper,
    selectWrapper: s.currencyCodeWrapper
  }

  const renderOptions = type => {
    const fields =
      type === 'selected'
        ? serializedFields.filter(fieldRow => selectedFields[fieldRow.field])
        : serializedFields.filter(
            fieldRow =>
              fieldRow.updatable &&
              fieldRow.display_name.toLowerCase().includes(searchString.toLowerCase()) &&
              !selectedFields[fieldRow.field]
          )

    return fields.map((fieldRow, idx) => {
      const field = fieldRow.field
      const dataType = fieldRow.type
      const optionValue = formValues[field]
      return (
        <Row key={field} className={s.ModalRow}>
          <Column span={1}>
            <Checkbox
              isChecked={selectedFields[field]}
              triggerCheckbox={() => checkboxHandler(field, selectedFields[field])}
              size="sm"
              styles={{ color: '#6C6C6C', marginBottom: '-3px', border: '1px solid #939393' }}
            />
          </Column>
          <Column span={5}>
            <p>
              {fieldRow.display_name}
              {fieldRow.required && <span style={{ color: 'red' }}> *</span>}
            </p>
          </Column>
          <Column span={6}>
            {(fieldRow.type === 'text' || fieldRow.type === 'textarea') && (
              <div id="bulk-update-textarea" className={errors[field] ? 'textarea-error' : ''}>
                <TextareaAutosize
                  autoFocus={type === 'selected'}
                  onFocus={e => {
                    const textLength = e.currentTarget.value.length
                    e.currentTarget.setSelectionRange(textLength, textLength)
                    formValues[field] &&
                      onChangeHandler(field, dataType, { value: formValues[field].value })
                  }}
                  id={field}
                  maxRows={3}
                  value={optionValue?.value}
                  placeholder={`Enter ${fieldRow.display_name}`}
                  onChange={e => onChangeHandler(field, dataType, { value: e.target.value })}
                  className={s.textField}
                  style={{ width: '95%' }}
                />
              </div>
            )}
            {fieldRow.type === 'date' && (
              <ControlledDatePicker
                className={selectedFields[field] && errors[field] ? s.dateError : s.dateField}
                value={optionValue?.value ? moment(optionValue.value).format('MM/DD/YYYY') : ''}
                onChange={d => {
                  const date = d ? serializeDate(d) : null
                  onChangeHandler(field, dataType, { value: date })
                }}
                classNames={{
                  container: s.dateField,
                  overlay: 'DayPickerInput-Overlay',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper'
                }}
              />
            )}
            {fieldRow.type === 'currency' && (
              <Currency
                isPortal={false}
                amount={optionValue?.value.amount}
                selectedOption={optionValue?.value.code}
                onChange={({ amount, code }) =>
                  onChangeHandler(field, dataType, {
                    value: { amount, code: code?.value || code }
                  })
                }
                classNameMap={currencyClassName}
                hasError={errors[field]}
                selectStyles={modalControlStyles(errors[field])}
                inputStyles={inputStyles}
                bulkInput
              />
            )}
            {(fieldRow.type === 'list' || fieldRow.type === 'boolean') && (
              <div className={s.inputFieldContainer}>
                {fieldRow.options.url ? (
                  <PaginatedSelect
                    id={field}
                    url={
                      field === 'matter_lead'
                        ? `${fieldRow.options.url}&i=true`
                        : fieldRow.options.url
                    }
                    value={optionValue}
                    onChange={option => onChangeHandler(field, dataType, option)}
                    limit={Infinity}
                    serializer={field === 'matter_lead' ? toUsers : toReactSelect}
                    pageStart={fieldRow.options.start_page}
                    pageParam={fieldRow.options.page_number_key}
                    pageSizeParam={fieldRow.options.page_size_key}
                    searchTermParam={fieldRow.options.search_key}
                    resultsProperty={fieldRow.options.results_key}
                    placeholder={`Select ${fieldRow.display_name}`}
                    className={s.inputFieldContainer}
                    ariaLabel={`field${idx + 1}`}
                    styles={modalControlStyles(errors[field])}
                  />
                ) : (
                  <Select
                    id={field}
                    value={optionValue}
                    placeholder={`Select ${fieldRow.display_name}`}
                    options={
                      recordType.value === MATTERS_ONLY
                        ? filterOptions(fieldRow.options, field)
                        : fieldRow.options
                    }
                    onChange={option => onChangeHandler(field, dataType, option)}
                    className={s.inputFieldContainer}
                    aria-label={`field${idx + 1}`}
                    styles={modalControlStyles(errors[field])}
                  />
                )}
              </div>
            )}
            <div>
              {recordType.value !== MATTER_TEMPLATES &&
                field === 'allocated_legal_entity' &&
                serializedFields.some(x => x.field.startsWith('attribute') && x.updatable) &&
                optionValue?.value && (
                  <p className={s.info}>
                    {
                      'Changing the allocated legal entity value may reset values for those custom attributes that are dependent on the specific legal entity.'
                    }
                  </p>
                )}
              <p className={s.error}>{errors[field] && 'Please provide a value'}</p>
            </div>
          </Column>
        </Row>
      )
    })
  }

  return (
    <div className={s.fieldsContainer}>
      {renderOptions('selected')}
      <div className={s.separator}></div>
      {renderOptions()}
    </div>
  )
}

export default GenericFormBuilder
