import { Fragment } from 'react'
import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

const ActionsPopover = ({
  rowId,
  status,
  updateVendorCb,
  setEditedVendorId,
  setIsEditModalVisible,
  enableEditVendor
}) => {
  return enableEditVendor ? (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
      >
        <ul className={s.actionsList}>
          {status === 'active' && (
            <Fragment>
              <li
                className={s.actionItem}
                onClick={() => {
                  ReactTooltip.hide()
                  setEditedVendorId(rowId)
                  setIsEditModalVisible(true)
                }}
              >
                Edit Vendor Manager
              </li>
              <li
                className={s.actionItem}
                onClick={() => {
                  ReactTooltip.hide()
                  updateVendorCb(rowId, 'deactivated')
                }}
              >
                Inactivate
              </li>
            </Fragment>
          )}
          {status === 'deactivated' && (
            <li
              className={s.actionItem}
              onClick={() => {
                ReactTooltip.hide()
                updateVendorCb(rowId, 'active')
              }}
            >
              Reactivate
            </li>
          )}
        </ul>
      </ReactTooltip>
    </>
  ) : null
}

export default ActionsPopover
