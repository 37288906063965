import APP_ACT from 'app/actions'
import { DatePickerSelect, UsersCheckboxSelect } from 'common/Selects'
import { useEffect, useState } from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { ModalContainer, useLoading } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import { BULK_EDIT_INITIAL_VALUES, TASK_OPTIONS_ENDPOINTS } from '../constants'
import {
  onlyActiveOptions,
  toPrioritiesOptions,
  toStatusesOptions,
  toTaskTypesOptions
} from '../serializers'
import { BulkEditValues, Option } from '../types'
import s from './BulkEditModal.scss'

interface Props {
  tasksNumber: number
  matterId: number
  onUpdate: (values: BulkEditValues) => void
  onCancel: () => void
  context?: 'matter' | 'workbench'
}

const SELECT_PLACEHOLDER = 'Select...'

const BulkEditModal = ({ tasksNumber, matterId, onUpdate, onCancel, context }: Props) => {
  const dispatch = useDispatch()
  const [, withLoadingLocks, resetLoadingLocks] = useLoading()
  const [priorities, setPriorities] = useState<Option[]>([])
  const [statuses, setStatuses] = useState<Option[]>([])
  const [taskTypes, setTaskTypes] = useState<Option[]>([])
  const [valuesToUpdate, setValuesToUpdate] = useState(BULK_EDIT_INITIAL_VALUES)

  const validateForm = (): boolean =>
    (Object.keys(valuesToUpdate) as Array<keyof BulkEditValues>).some(key =>
      Array.isArray(valuesToUpdate[key])
        ? (valuesToUpdate[key] as Option[]).length
        : !!valuesToUpdate[key]
    )

  useEffect(() => {
    let isMounted = true
    const fetchOptions = async () => {
      try {
        const [statuses, priorities, taskTypes] = await withLoadingLocks(
          Promise.allSettled([
            makeGetRequest(TASK_OPTIONS_ENDPOINTS.STATUSES),
            makeGetRequest(TASK_OPTIONS_ENDPOINTS.PRIORITIES),
            makeGetRequest(TASK_OPTIONS_ENDPOINTS.TASK_TYPES)
          ])
        )

        if (isMounted) {
          statuses.status === 'fulfilled'
            ? setStatuses(onlyActiveOptions(toStatusesOptions(statuses.value)))
            : dispatch({ type: APP_ACT.API_ERROR, error: statuses.reason })

          priorities.status === 'fulfilled'
            ? setPriorities(onlyActiveOptions(toPrioritiesOptions(priorities.value)))
            : dispatch({ type: APP_ACT.API_ERROR, error: priorities.reason })

          taskTypes.status === 'fulfilled'
            ? setTaskTypes(onlyActiveOptions(toTaskTypesOptions(taskTypes.value.rows)))
            : dispatch({ type: APP_ACT.API_ERROR, error: taskTypes.reason })
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: APP_ACT.API_ERROR, error })
        }
      }
    }

    fetchOptions()

    return () => {
      isMounted = false
      resetLoadingLocks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO: Fetch only active task types, statuses, and priorities.
  const assigneesRequestParams = { active: true, matterId, canEdit: true }
  const followersRequestParams = { active: true, matterId, canRead: true }

  return (
    <ModalContainer
      title={`Edit ${tasksNumber} task(s)`}
      size="sm"
      hasNewButtons
      cancelText="Cancel"
      confirmText="Update"
      isDisabled={!validateForm()}
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
      content={
        <>
          <div
            className={s.infoLabel}
          >{`Update the ${tasksNumber} selected task(s) attributes to:`}</div>
          {context === 'matter' && (
            <>
              <div className={s.label}>Add Assignees:</div>
              <UsersCheckboxSelect
                value={valuesToUpdate.assigneesToAdd}
                requestParams={assigneesRequestParams}
                updateSelectedUsers={assigneesToAdd => {
                  setValuesToUpdate(currentValue => ({ ...currentValue, assigneesToAdd }))
                }}
              />
              <div className={s.label}>Remove Assignees:</div>
              <UsersCheckboxSelect
                value={valuesToUpdate.assigneesToRemove}
                requestParams={assigneesRequestParams}
                updateSelectedUsers={assigneesToRemove => {
                  setValuesToUpdate(currentValue => ({ ...currentValue, assigneesToRemove }))
                }}
              />
              <div className={s.label}>Add Followers:</div>
              <UsersCheckboxSelect
                value={valuesToUpdate.followersToAdd}
                requestParams={followersRequestParams}
                updateSelectedUsers={followersToAdd => {
                  setValuesToUpdate(currentValue => ({ ...currentValue, followersToAdd }))
                }}
              />
              <div className={s.label}>Remove Followers:</div>
              <UsersCheckboxSelect
                value={valuesToUpdate.followersToRemove}
                requestParams={followersRequestParams}
                updateSelectedUsers={followersToRemove => {
                  setValuesToUpdate(currentValue => ({ ...currentValue, followersToRemove }))
                }}
              />
            </>
          )}
          <div className={s.label}>Priority:</div>
          <Select
            value={valuesToUpdate.priority}
            options={priorities}
            placeholder={SELECT_PLACEHOLDER}
            className={s.select}
            isClearable
            onChange={priority => {
              setValuesToUpdate(currentValue => ({ ...currentValue, priority }))
            }}
          />
          <div className={s.label}>Status:</div>
          <Select
            value={valuesToUpdate.status}
            options={statuses}
            placeholder={SELECT_PLACEHOLDER}
            className={s.select}
            isClearable
            onChange={status => {
              setValuesToUpdate(currentValue => ({ ...currentValue, status }))
            }}
          />
          <div className={s.label}>Type:</div>
          <Select
            value={valuesToUpdate.taskType}
            options={taskTypes}
            placeholder={SELECT_PLACEHOLDER}
            className={s.select}
            isClearable
            onChange={taskType => {
              setValuesToUpdate(currentValue => ({ ...currentValue, taskType }))
            }}
          />
          <div className={s.label}>Due Date:</div>
          <DatePickerSelect
            value={valuesToUpdate.dueDate}
            openUpwards
            selectDate={(dueDate: Date | undefined) => {
              setValuesToUpdate(currentValue => ({ ...currentValue, dueDate }))
            }}
          />
          {context === 'matter' && (
            <div className={s.warningNote}>
              <IoWarningOutline className={s.warningTriangle} />
              <div className={s.warningLabel}>
                Bulk adding and removing of assignees or followers will not trigger email
                notifications
              </div>
            </div>
          )}
        </>
      }
      confirmCb={() => onUpdate(valuesToUpdate)}
      cancelCb={onCancel}
    />
  )
}

export default BulkEditModal
