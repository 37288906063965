import orderBy from 'lodash/orderBy'

import { ExpandableContainer } from 'containers'

import AssignedContact from './AssignedContact/AssignedContact'

import {
  filterAssignedContactsByValue,
  contactsWithSingularRoles,
  contactHasSingularRole
} from '../utils'

import s from './AssignedContacts.scss'

const FilteredContacts = ({
  contacts,
  filter,
  unassignContactCb,
  unassignRoleCb,
  setContactToUpdateCb,
  expandCb,
  isExpanded,
  isEditable,
  userCanEditContactCG,
  readOnly
}) => {
  const filteredSortedContacts = orderBy(
    filterAssignedContactsByValue(contacts, filter),
    contactHasSingularRole,
    ['desc']
  )

  const contactComponents = filteredSortedContacts.map((assignedContact, contactIndex) => (
    <AssignedContact
      contact={assignedContact}
      unassignContactCb={unassignContactCb}
      unassignRoleCb={unassignRoleCb}
      setContactToUpdateCb={setContactToUpdateCb}
      isEditable={isEditable}
      key={contactIndex}
      userCanEditContactCG={userCanEditContactCG}
      readOnly={readOnly}
    />
  ))

  const singularRoleCount = contactsWithSingularRoles(filteredSortedContacts).length

  return filteredSortedContacts.length ? (
    <ExpandableContainer isExpanded={isExpanded} expandCb={expandCb} minShowing={3} showCount>
      {contactComponents}
    </ExpandableContainer>
  ) : (
    <span className={s.noResults}>{'There are no contacts matching this filter...'}</span>
  )
}

export default FilteredContacts
