import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

const ActionsPopover = ({ rowId, actions }) => {
  return (
    <>
      <span
        data-for={`item-actions-${rowId}`}
        aria-describedby={`item-actions-${rowId}`}
        aria-label={`item-${rowId}`}
        className={s.actionsContainer}
        data-event="click"
        data-tip
        data-testid={`item-actions-${rowId}`}
      >
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${rowId}`}
        type="light"
        effect="solid"
        place="bottom"
        border
        clickable
      >
        <ul className={s.actionsList}>
          {actions.map(({ key, label, callback }) => (
            <li
              key={key + label}
              className={s.actionItem}
              onClick={e => {
                ReactTooltip.hide()
                callback(rowId, e, key)
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </ReactTooltip>
    </>
  )
}

export default ActionsPopover
