import { useState, useEffect } from 'react'
import s from './CourtRules.scss'
import { Panel, Tabs, Tab, LawRuleIcon, Button, DataTableWrapper, useLoading } from 'simple-core-ui'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Jurisdiction, Option } from './types'
import Select from 'react-select'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { toOptions } from './serializers'

const initialState = {
  params: {
    pageSize: 25,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const columns = [
  {
    columnKey: 'name',
    content: 'Jurisdiction Name',
    isSortable: true,
    isFilterable: true,
    style: { width: '800px' }
  }
]

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const CourtRules = () => {
  const [activeTab, setActiveTab] = useState<'jurisdictions' | 'settings'>('jurisdictions')
  const [hasCalendarRules, setHasCalendarRules] = useState(false)
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>([])
  const [jurisdictionsList, setJurisdictionsList] = useState<Option[]>([])
  const [serviceTypesList, setServiceTypesList] = useState<Option[]>([])
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<Option | null>(null)
  const [selectedServiceType, setSelectedServiceType] = useState<Option | null>(null)
  const [selectedJurisdictionId, setSelectedJurisdictionId] = useState<number | null>(null)
  const [selectedServiceTypeId, setSelectedServiceTypeId] = useState<number | null>(null)

  const fetchConfig = async () => {
    try {
      const { configured, jurisdiction_system_id, service_type_system_id } = await withLoadingLocks(
        makeGetRequest('/event-management/calendar_rules/settings')
      )
      setHasCalendarRules(
        Boolean(
          [{ name: 'CalendarRules', configured }].filter(integration => integration.configured)
            .length
        )
      )
      setSelectedJurisdictionId(jurisdiction_system_id)
      setSelectedServiceTypeId(service_type_system_id)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchJurisdictionsList = async (stateId?: number) => {
    try {
      const { jurisdictions } = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/jurisdictions${stateId ? `?state_id=${stateId}` : ''}`
        )
      )
      setJurisdictionsList(toOptions(jurisdictions))
      setJurisdictions(jurisdictions)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchServiceTypes = async (jurisdictionId?: number) => {
    try {
      const { service_types } = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/service-types${
            jurisdictionId ? `?jurisdiction_id=${jurisdictionId}` : ''
          }`
        )
      )
      setServiceTypesList(toOptions(service_types))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hasCalendarRules) fetchJurisdictionsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCalendarRules])

  useEffect(() => {
    if (activeTab === 'settings' && selectedJurisdictionId) {
      fetchServiceTypes(selectedJurisdictionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    if (selectedServiceTypeId && serviceTypesList.length) {
      setSelectedServiceType(
        serviceTypesList.find(({ value }) => value === selectedServiceTypeId) ?? null
      )
    }
    if (selectedJurisdictionId && jurisdictionsList.length) {
      setSelectedJurisdiction(
        jurisdictionsList.find(({ value }) => value === selectedJurisdictionId) ?? null
      )
    }
  }, [selectedServiceTypeId, selectedJurisdictionId, jurisdictionsList, serviceTypesList])

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const saveSettings = async () => {
    try {
      await makePostRequest('/event-management/calendar_rules/settings', {
        jurisdiction_system_id: selectedJurisdiction?.value,
        service_type_system_id: selectedServiceType?.value
      })
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Settings were successfully saved',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <>
      <Panel
        title="Court Rules"
        subtitle={
          <>
            <AiOutlineInfoCircle className={s.info} /> Court Rules{' '}
            {activeTab === 'jurisdictions' ? 'sets' : 'Jurisdictions and Service Type'} are
            populated through <b>CalendarRules</b> a third party application
          </>
        }
        styles={{ boxShadow: 'none' }}
      >
        {hasCalendarRules ? (
          <Tabs alignRight>
            {/* @ts-expect-error */}
            <Tab
              selected={activeTab === 'jurisdictions'}
              header="Jurisdictions"
              onClickCb={() => {
                setActiveTab('jurisdictions')
              }}
            >
              <DataTableWrapper
                params={params}
                categories={[]}
                rows={jurisdictions}
                columns={columns}
                updateTable={updateTable}
                panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                className={s.itemsTable}
                hasTooltip
                categoryKey="jurisdiction"
                hasActions={false}
              />
            </Tab>
            {/* @ts-expect-error */}
            <Tab
              selected={activeTab === 'settings'}
              header="Default Settings"
              onClickCb={() => {
                setActiveTab('settings')
              }}
            >
              <div className={s.settingsWrapper}>
                <div className={s.statusItem}>
                  <p style={{ fontSize: 14, marginBottom: 5 }}>Default Jurisdiction</p>
                  <Select
                    options={jurisdictionsList}
                    value={selectedJurisdiction}
                    onChange={value => {
                      setSelectedJurisdiction(value)
                      if (value) {
                        fetchServiceTypes(value?.value)
                      }
                      setSelectedServiceType(null)
                      setSelectedServiceTypeId(null)
                      setSelectedJurisdictionId(null)
                    }}
                    placeholder="Select a Jurisdiction"
                    isSearchable={true}
                    isClearable
                    className={s.select}
                  />
                </div>
                <div className={s.statusItem}>
                  <p style={{ fontSize: 14, marginBottom: 5 }}>Default Service Type</p>
                  <Select
                    options={serviceTypesList}
                    onChange={setSelectedServiceType}
                    value={selectedServiceType}
                    placeholder="Select a Service Type"
                    isSearchable={true}
                    className={s.select}
                    isClearable
                  />
                </div>
                <Button
                  hasNewDesign
                  isPrimary
                  onClick={saveSettings}
                  isDisabled={!selectedJurisdiction || !selectedServiceType}
                >
                  Save Changes
                </Button>
              </div>
            </Tab>
          </Tabs>
        ) : (
          <div>
            <hr style={{ borderColor: '#dedede', borderTop: 0, marginBottom: 30 }} />
            <p className={s.emptyState}>
              Click on the <span style={{ color: '#3c98fd' }}>Integrations tab</span> in Event
              Settings to connect <b>CalendarRules</b> with your SimpleLegal account to access Court
              Rules.
            </p>
            <div style={{ marginTop: 20, textAlign: 'center' }}>
              <LawRuleIcon />
            </div>
          </div>
        )}
      </Panel>
    </>
  )
}

export default CourtRules
