import { Title, VerticalTabs, VerticalTab } from 'simple-core-ui'
import { CategoriesContainer } from './CategoriesContainer'
import { LabelsContainer } from './LabelsContainer'
import { PreviewContainer } from './PreviewContainer'
import { NotificationsContainer } from './NotificationsContainer'
import { InfectedFilesContainer } from './InfectedFilesContainer'
import s from './DocumentManagementSettings.scss'

const DocumentManagementSettings = () => {
  return (
    <section className={s.container}>
      <Title text="Document Management Settings" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs activeTabKey="categories">
          <VerticalTab key="categories" label="Document Categories">
            <CategoriesContainer />
          </VerticalTab>
          <VerticalTab key="labels" label="Document Labels">
            <LabelsContainer />
          </VerticalTab>
          <VerticalTab key="preview" label="Document Preferences">
            <PreviewContainer />
          </VerticalTab>
          <VerticalTab key="notifications" label="Notifications">
            <NotificationsContainer />
          </VerticalTab>
          <VerticalTab key="infected" label="Quarantined Files">
            <InfectedFilesContainer />
          </VerticalTab>
        </VerticalTabs>
      </section>
    </section>
  )
}

export default DocumentManagementSettings
