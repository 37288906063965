import { useLocation } from 'react-router-dom'
import AssignedContactsContainer from '../AssignedContacts/AssignedContactsContainer'
import s from './IntegratedAssignedContacts.scss'
import AssignedContactsTableContainer from 'contacts/AssignedContactsTableContainer/AssignedContactsTableContainer'

const isRevisedVendorContactsView = window.serverContext.get(
  'allow_vendor_collaboration_assigned_roles'
)

const IntegratedAssignedContacts = ({ readOnly, className }) => {
  const location = useLocation()

  return (
    <section className={s.container}>
      {location.pathname.includes('matters') && (
        <AssignedContactsContainer
          closedKeyword="is_matter_closed"
          editableKeyword={false}
          readOnly={readOnly}
          className={className}
        />
      )}
      {location.pathname.includes('vendors') ? (
        isRevisedVendorContactsView ? (
          <AssignedContactsTableContainer />
        ) : (
          <AssignedContactsContainer />
        )
      ) : null}
    </section>
  )
}

export default IntegratedAssignedContacts
