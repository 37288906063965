import React from 'react'
import { Panel, Button } from 'simple-core-ui'

import { FilterableContainer } from 'containers'

import ContactQuickFormContainer from '../ContactQuickForm/ContactQuickFormContainer.js'
import AssignContactsForm from '../AssignContactsForm/AssignContactsForm'
import TagBar from '../TagBar/TagBar'
import FilteredContacts from './FilteredContacts'

import { filterContactsByRoleId } from '../utils'

import s from './AssignedContacts.scss'
import cn from 'classnames'

const AssignedContacts = ({
  assignedContacts,
  assignContactCb,
  unassignContactCb,
  unassignRoleCb,
  selectedContact,
  selectedRoles,
  contactsChangeCb,
  rolesChangeCb,
  toggleAssignFormCb,
  toggleContactQuickFormCb,
  isAssignFormOpen,
  isContactQuickFormOpen,
  setContactToUpdateCb,
  contactToUpdate,
  expandCb,
  isExpanded,
  selectedRoleId,
  tagFilterSet,
  roleFilterCb,
  singularRoleIntersect,
  isClosed,
  isEditable,
  warningMessageType,
  changeCGAdminsNoCb,
  userCanEditContactCG,
  readOnly,
  className
}) => (
  <section className={s.container} data-testid="contacts_container">
    <Panel
      title={'Contacts'}
      className={cn(s.panel, className)}
      isClosed={isClosed}
      rightActions={
        !isClosed && isEditable && !isAssignFormOpen && !isContactQuickFormOpen
          ? [
              <Button
                isPrimary
                key={'a'}
                testid="testid_assign_contact"
                className={s.button}
                isDisabled={isClosed || readOnly}
                onClick={toggleAssignFormCb}
              >
                {' Assign Contact'}
              </Button>
            ]
          : []
      }
    >
      <section className={s.content}>
        {isContactQuickFormOpen && (
          <ContactQuickFormContainer
            cancelCb={toggleContactQuickFormCb}
            submitCb={toggleContactQuickFormCb}
            isUpdating={!!contactToUpdate}
            contactToUpdate={contactToUpdate}
            changeCGAdminsNoCb={changeCGAdminsNoCb}
            userCanEditContactCG={userCanEditContactCG}
          />
        )}
        {isAssignFormOpen && (
          <AssignContactsForm
            contacts={assignedContacts}
            selectedContact={selectedContact}
            selectedRoles={selectedRoles}
            contactsChangeCb={contactsChangeCb}
            rolesChangeCb={rolesChangeCb}
            assignContactCb={assignContactCb}
            toggleAssignFormCb={toggleAssignFormCb}
            toggleContactQuickFormCb={toggleContactQuickFormCb}
            singularRoleIntersect={singularRoleIntersect}
            warningMessageType={warningMessageType}
          />
        )}
        {!isContactQuickFormOpen &&
          (assignedContacts.length ? (
            <section>
              <FilterableContainer
                testid="search_contacts"
                render={filter => (
                  <React.Fragment>
                    <TagBar
                      tags={tagFilterSet}
                      selectedTagId={selectedRoleId}
                      selectCb={roleFilterCb}
                    />
                    <hr className={s.separator} />
                    <FilteredContacts
                      filter={filter}
                      contacts={filterContactsByRoleId(assignedContacts, selectedRoleId)}
                      unassignContactCb={unassignContactCb}
                      unassignRoleCb={unassignRoleCb}
                      setContactToUpdateCb={setContactToUpdateCb}
                      expandCb={expandCb}
                      isExpanded={isExpanded}
                      isEditable={!isClosed && isEditable}
                      userCanEditContactCG={userCanEditContactCG}
                      readOnly={readOnly}
                    />
                  </React.Fragment>
                )}
              />
            </section>
          ) : (
            <span className={s.noResults}>{'There are currently no contacts assigned.'}</span>
          ))}
      </section>
    </Panel>
  </section>
)

export default AssignedContacts
