import * as React from 'react'
import ACT from '../actions'

import ReviewerTiers from '../components/ReviewerTiers'

import {
  addReviewerTier,
  removeReviewerTier,
  coalesceReviewerTier,
  editReviewerTier,
  generateNewReviewerTier,
  filterEmptyTiers,
  dedupeReviewerTiers
} from '../utils/utils'

class ReviewerTiersContainer extends React.Component {
  state = {
    newReviewerTier: generateNewReviewerTier(),
    reviewerTiers: this.props.reviewerTiers
  }

  updateReviewerTiers = lastUpdatedIndex => {
    const {
      dispatch,
      subscribers,
      isLeadReviewer,
      reviewerConfigId,
      scope,
      scopeId,
      reviewerScope
    } = this.props

    let { reviewerTiers } = this.state
    reviewerTiers = filterEmptyTiers(reviewerTiers)

    if (reviewerTiers.length && typeof lastUpdatedIndex === 'number') {
      reviewerTiers = dedupeReviewerTiers(lastUpdatedIndex, reviewerTiers)
    }

    this.setState({ reviewerTiers })

    dispatch({
      type: ACT.UPDATE_REVIEWER_CONFIG_REQUESTED,
      payload: {
        reviewerTiers,
        subscribers,
        isLeadReviewer,
        reviewerConfigId,
        scope,
        scopeId,
        reviewerScope
      }
    })
  }

  revertReviewerTiers = () => {
    const { reviewerTiers } = this.props

    this.setState({
      newReviewerTier: generateNewReviewerTier(),
      reviewerTiers
    })
  }

  editTier = (tierIdx, tier) => {
    if (tierIdx === -1) {
      this.setState(prevState => {
        const prevLevel = prevState.newReviewerTier
        return {
          newReviewerTier: coalesceReviewerTier(prevLevel, tier)
        }
      })
    } else {
      this.setState({
        reviewerTiers: editReviewerTier(tierIdx, tier, this.state.reviewerTiers)
      })
    }
  }

  addNewTier = () => {
    this.setState(
      ({ newReviewerTier, reviewerTiers }) => ({
        reviewerTiers: addReviewerTier(newReviewerTier, reviewerTiers),
        newReviewerTier: generateNewReviewerTier()
      }),
      () => {
        const appendedLevelIndex = this.state.reviewerTiers.length - 1
        this.updateReviewerTiers(appendedLevelIndex)
      }
    )
  }

  removeTier = tierIdx => {
    this.setState(
      ({ reviewerTiers }) => ({
        reviewerTiers: removeReviewerTier(tierIdx, reviewerTiers)
      }),
      this.updateReviewerTiers
    )
  }

  render() {
    return (
      <ReviewerTiers
        readOnly={this.props.readOnly}
        newReviewerTier={this.state.newReviewerTier}
        savedReviewerTiers={this.props.reviewerTiers}
        editableReviewerTiers={this.state.reviewerTiers}
        editTier={this.editTier}
        addNewTier={this.addNewTier}
        removeTier={this.removeTier}
        updateReviewerTiers={this.updateReviewerTiers}
        revertReviewerTiers={this.revertReviewerTiers}
      />
    )
  }
}

export default ReviewerTiersContainer
