import { useState, useRef } from 'react'
import s from './Comments.scss'
import { AvatarList, Button } from 'simple-core-ui'
import { formatName } from 'utils/helpers'
import { CharLimitInput } from 'components'

const loggedInUser = window.credentials.user

interface Props {
  addComment: (comment: string) => void
  readOnly?: boolean
}

const Comments = ({ addComment, readOnly }: Props) => {
  const [comment, setComment] = useState('')
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  return (
    <div className={s.comments}>
      <AvatarList
        limit={3}
        size="md"
        wrapperStyles={{ width: 36 }}
        avatarStyles={{ border: '2px solid #0957ae', lineHeight: '30px' }}
        entries={[
          {
            label: formatName({
              first_name: loggedInUser.firstName,
              last_name: loggedInUser.lastName
            })
          }
        ]}
      />
      <CharLimitInput
        type="textarea"
        autoGrowing
        refObject={textAreaRef}
        value={comment}
        placeholder="Add a comment"
        onChangeCb={e => setComment(e.target.value)}
        maxLength={1000}
        hideInfoText
        cssClass={s.input}
        isDisabled={readOnly}
      />
      <Button
        onClick={() => {
          addComment(comment)
          setComment('')
        }}
        isPrimary
        isDisabled={comment === ''}
        style={{ height: 36 }}
      >
        Comment
      </Button>
    </div>
  )
}

export default Comments
