import { useState, Fragment } from 'react'
import { CalendarView } from 'common/CalendarView'
import {
  Panel,
  Button,
  GoogleCalendarIcon,
  MicrosoftOfficeOutlookIcon,
  AppleCalendarIcon
} from 'simple-core-ui'
import { CalendarSyncModal } from 'common/CalendarSyncModal'
import { isTaskCalendarSyncEnabled } from 'common/Tasks/utils'
import cn from 'classnames'
import s from './CalendarWorkbench.scss'
import ReactTooltip from 'react-tooltip'
import { isBasicTaskManagementPlan } from 'utils/helpers'

const isBasicPlan = isBasicTaskManagementPlan()

const CalendarWorkbench = () => {
  const [isCalendarSyncModalVisible, setIsCalendarSyncModalVisible] = useState(false)
  return (
    <>
      <Panel
        title="Calendar"
        rightActions={
          isTaskCalendarSyncEnabled()
            ? [
                <Fragment key="sync">
                  <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                    <Button
                      style={{
                        padding: '8px 15px',
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        bottom: 2
                      }}
                      onClick={() => setIsCalendarSyncModalVisible(true)}
                      isPrimary
                      isOutline
                      hasNewDesign
                      isDisabled={isBasicPlan}
                    >
                      Calendar Sync
                      <MicrosoftOfficeOutlookIcon className={s.calendarIcon} />
                      <GoogleCalendarIcon className={s.calendarIcon} />
                      <AppleCalendarIcon className={cn(s.calendarIcon, s.appleCalendarIcon)} />
                    </Button>
                  </div>
                  <ReactTooltip
                    id="buttonTooltip"
                    type="light"
                    effect="solid"
                    place="top"
                    border
                    disable={!isBasicPlan}
                    className={s.tooltipPopup}
                  >
                    Contact customer success to upgrade.
                  </ReactTooltip>
                </Fragment>
              ]
            : []
        }
      >
        <CalendarView context="workbench" baseUrl={`/task-management`} />
      </Panel>
      {isCalendarSyncModalVisible && (
        <CalendarSyncModal
          toggleModal={() => setIsCalendarSyncModalVisible(!isCalendarSyncModalVisible)}
        />
      )}
    </>
  )
}

export default CalendarWorkbench
