import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import s from './VendorListHeader.scss'
import ACT from 'vendors/actions'
import { Modal, Button } from 'simple-core-ui'
import CreateVendorForm from '../../forms/CreateVendorForm'
import { serializeParams } from '../serializers'
import { makeBlobRequest } from 'utils/api'
import { validateNewVendor } from '../../forms/validators'
import queryString from 'query-string'

const VendorListHeader = ({ selectedTab, title }) => {
  const dispatch = useDispatch()
  const createForm = useSelector(state => state.vendors.createForm)
  const params = useSelector(state => state.vendors.vendorListParams)
  const permissions = useSelector(state => state.vendors.vendorPermissions)

  const [isModalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    const showVendorModal = queryString.parse(window.location.search).new
    showVendorModal ? setModalVisible(true) : null
  }, [])

  const addNewVendor = () => {
    const { isError, errors } = validateNewVendor(createForm)
    if (isError) {
      dispatch({
        type: ACT.UPDATE_ADD_VENDOR_FORM,
        payload: {
          errors
        }
      })
    } else {
      dispatch({
        type: ACT.ADD_NEW_VENDOR_REQUESTED,
        loadingLock: 'on',
        payload: {
          ...createForm,
          requested: false
        }
      })
      setModalVisible(false)
    }
  }

  const handleDownload = async () => {
    const baseParams = serializeParams(params)
    const downloadUrl = `/invoices/v2/vendors/${selectedTab.toLowerCase()}/?export=true`
    try {
      const data = await makeBlobRequest({
        url: downloadUrl,
        method: 'POST',
        data: baseParams
      })
      if (data.size) {
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = 'Vendor Information.xlsx'
        link.click()
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const navigateTo = url => {
    window.location.href = url
  }

  return (
    <div className={s.header}>
      <h2>{title}</h2>
      <div className={s.rightActions}>
        <Button isSecondary onClick={handleDownload}>
          Download XLSX
        </Button>
        {permissions.enableManageVendorGroups && (
          <Button
            isSecondary
            onClick={() => navigateTo('/invoices/vendors/groups/?fromNewVendorList=true')}
          >
            Manage Vendor Groups
          </Button>
        )}
        {permissions.enableEditVendor && (
          <Button isPrimary onClick={() => setModalVisible(true)}>
            Add Vendor
          </Button>
        )}
      </div>
      <Modal
        title="Add Vendor"
        confirmText="Submit"
        cancelText="Cancel"
        size="sm"
        content={<CreateVendorForm />}
        isVisible={isModalVisible}
        confirmCb={addNewVendor}
        cancelCb={() => setModalVisible(false)}
        testid="add-vendor-modal"
      />
    </div>
  )
}

export default VendorListHeader
