import { put, takeLatest, call } from 'redux-saga/effects'

import ACT from './actions'

import { makeGetRequest, makePostRequest, handleError } from 'utils/api'
import { fromReview, toApproveFormFields, toRejectFormFields } from './serializers'
import { SCOPED_URL } from './urls'
import { ADMIN_ACTIONS } from './utils/constants'

function* genericLadderSaga(url, requester, params = {}) {
  try {
    yield put({ type: ACT.IS_HYDRATING })
    const apiReview = yield call(requester, url, params)
    const review = fromReview(apiReview)

    // handling Invoice status value read in 'view_invoice.html'
    // which toggles displaying 'Resend To AP' option
    const dropdown = document.getElementById('SendInvoiceDropdown')
    const button = document.getElementById('SendInvoiceButton')
    if (dropdown && button) {
      if (review.reviewableStatus === 'Approved' && window.credentials.user.role === 'admin') {
        dropdown.style.display = 'block'
        button.style.display = 'none'
      } else {
        dropdown.style.display = 'none'
        button.style.display = 'block'
      }
    }
    yield put({ type: ACT.HYDRATE_SUCCESS, loadingLock: 'off', payload: review })

    return review
  } catch (e) {
    yield put({ type: ACT.IS_NOT_HYDRATING })
    handleError(e)
  }
}

function* hydrateReviewLadder(action) {
  const { scope, scopeId } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  yield genericLadderSaga(url, makeGetRequest)
}

function* addReviewers(action) {
  const { scope, scopeId, teamIds } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  const params = { team_ids: teamIds }
  yield genericLadderSaga(url, makePostRequest, params)
}

function* removeReviewer(action) {
  const { scope, scopeId, teamId } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  const params = { team_id: teamId }
  yield genericLadderSaga(url, makePostRequest, params)
}

function* sendBackTo(action) {
  const { scope, scopeId } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  const params = { user_id: action.payload.soloUserId }
  yield genericLadderSaga(url, makePostRequest, params)
}

function* approve(action) {
  const { scope, scopeId, reviewFormFields } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  const params = reviewFormFields ? toApproveFormFields(reviewFormFields) : {}
  const review = yield genericLadderSaga(url, makePostRequest, params)
  yield call(performEffects, action, review)
}

function* finalize(action) {
  const { scope, scopeId, reviewFormFields } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  const params = reviewFormFields ? toApproveFormFields(reviewFormFields) : {}

  const review = yield genericLadderSaga(url, makePostRequest, params)
  yield call(performEffects, action, review)
}

function* unapprove(action) {
  const { scope, scopeId } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })

  const review = yield genericLadderSaga(url, makePostRequest)
  yield call(performEffects, action, review)
}

function* reject(action) {
  const { scope, scopeId, reviewFormFields } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  const params = reviewFormFields ? toRejectFormFields(reviewFormFields, scope) : {}

  const review = yield genericLadderSaga(url, makePostRequest, params)
  yield call(performEffects, action, review)
}

function* unreject(action) {
  const { scope, scopeId } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })

  const review = yield genericLadderSaga(url, makePostRequest)
  yield call(performEffects, action, review)
}

function* performEffects(action, review) {
  const {
    type,
    payload: { scope, scopeId, reviewActionEffects }
  } = action

  const reviewAction = {
    [ACT.FINALIZE_REQUESTED]: ACT.FINALIZE_SUCCESS,
    [ACT.APPROVAL_REQUESTED]: ACT.APPROVAL_SUCCESS,
    [ACT.UNAPPROVAL_REQUESTED]: ACT.UNAPPROVAL_SUCCESS,
    [ACT.REJECTION_REQUESTED]: ACT.REJECTION_SUCCESS,
    [ACT.UNREJECTION_REQUESTED]: ACT.UNREJECTION_SUCCESS
  }[type]

  if (reviewAction && reviewActionEffects) {
    yield call(reviewActionEffects, reviewAction, scope, scopeId, review)
  }
}

function* triggerAdminAction(action) {
  const { scope, scopeId, adminAction } = action.payload
  const url = SCOPED_URL({ scope, scopeId, actionType: action.type })
  const params = { action: adminAction }

  const wasSuccessful = yield genericLadderSaga(url, makePostRequest, params)

  if (wasSuccessful && adminAction === ADMIN_ACTIONS.START_REVIEW) {
    window.location.reload()
  }
}

const reviewLadderSagas = [
  takeLatest(ACT.HYDRATE_REQUESTED, hydrateReviewLadder),
  takeLatest(ACT.ADD_REVIEWERS_REQUESTED, addReviewers),
  takeLatest(ACT.REMOVE_REVIEWER_REQUESTED, removeReviewer),
  takeLatest(ACT.SEND_BACK_TO_REQUESTED, sendBackTo),
  takeLatest(ACT.FINALIZE_REQUESTED, finalize),
  takeLatest(ACT.APPROVAL_REQUESTED, approve),
  takeLatest(ACT.UNAPPROVAL_REQUESTED, unapprove),
  takeLatest(ACT.REJECTION_REQUESTED, reject),
  takeLatest(ACT.UNREJECTION_REQUESTED, unreject),
  takeLatest(ACT.ADMIN_ACTION_REQUESTED, triggerAdminAction)
]

export default reviewLadderSagas
