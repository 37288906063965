import { useState, useEffect } from 'react'
import s from './Integrations.scss'
import { Panel, Tabs, Tab, CalendarRulesIcon, useLoading } from 'simple-core-ui'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Card } from './Card'
import { ConnectModal } from './ConnectModal'
import { makeGetRequest, makeDeleteRequest } from 'utils/api'
import { useDispatch } from 'react-redux'

interface Config {
  name: string
  configured: boolean
}

const Integrations = () => {
  const [activeTab, setActiveTab] = useState<'all' | 'mine'>('all')
  const [myIntegrations, setMyIntegrations] = useState<Config[]>([])
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()

  const fetchConfig = async () => {
    try {
      const { configured } = await withLoadingLocks(
        makeGetRequest('/event-management/calendar_rules/settings')
      )
      setMyIntegrations(
        [{ name: 'CalendarRules', configured }].filter(integration => integration.configured)
      )
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleConnectModal = () => {
    setShowConnectModal(!showConnectModal)
  }

  const onDisconnect = async () => {
    try {
      await withLoadingLocks(makeDeleteRequest('/event-management/calendar_rules/settings'))
      setMyIntegrations(myIntegrations.filter(integration => integration.name !== 'CalendarRules'))

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'CalendarRules was successfully disconnected from your account',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <>
      <Panel
        title="Integrations"
        subtitle={
          <>
            <AiOutlineInfoCircle className={s.info} /> You will be redirected to an external link to
            authenticate your third-party applications
          </>
        }
        styles={{ boxShadow: 'none' }}
      >
        <Tabs alignRight>
          {/* @ts-expect-error */}
          <Tab
            selected={activeTab === 'all'}
            header="All"
            count={1}
            onClickCb={() => {
              setActiveTab('all')
            }}
          >
            <Card
              title="CalendarRules"
              description="CalendarRules integration will add automated court rules-based calculation of deadlines."
              active={Boolean(
                myIntegrations.find(integration => integration?.name === 'CalendarRules')
                  ?.configured
              )}
              onConnect={toggleConnectModal}
              onDisconnect={onDisconnect}
              logo={<CalendarRulesIcon />}
            />
          </Tab>
          {/* @ts-expect-error */}
          <Tab
            selected={activeTab === 'mine'}
            header="My Integrations"
            count={myIntegrations.length}
            onClickCb={() => {
              setActiveTab('mine')
            }}
          >
            {myIntegrations.length ? (
              <Card
                title="CalendarRules"
                description="CalendarRules integration will add automated court rules-based calculation of deadlines."
                active={Boolean(
                  myIntegrations.find(integration => integration?.name === 'CalendarRules')
                    ?.configured
                )}
                onConnect={toggleConnectModal}
                onDisconnect={onDisconnect}
                logo={<CalendarRulesIcon />}
              />
            ) : (
              <p className={s.noResults}>
                There are currently none, click <b>All</b> to get started.
              </p>
            )}
          </Tab>
        </Tabs>
      </Panel>
      {showConnectModal && (
        <ConnectModal
          toggleConnectModal={toggleConnectModal}
          onConnect={() => {
            setMyIntegrations([...myIntegrations, { name: 'CalendarRules', configured: true }])
            dispatch({
              type: 'PUSH_NOTIFICATION',
              payload: {
                title: 'CalendarRules was successfully integrated to your account',
                level: 'success'
              }
            })
          }}
        />
      )}
    </>
  )
}

export default Integrations
