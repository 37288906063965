import AsyncSelect from 'react-select/async'
import s from './BaseAsyncSelect.scss'
import { components } from 'react-select'
import debounce from 'debounce-promise'

const BaseAsyncSelect = ({
  id,
  reset,
  value,
  loadOptions,
  placeholder,
  isClearable = true,
  defaultOptions = true,
  onChange,
  isPortal = true,
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  limit = Infinity,
  comps = {},
  styles = {},
  dbTime = 250,
  filterOption,
  ariaLabel,
  noOptionsMessage
}) => {
  const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < limit ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Max limit achieved</div>
        )}
      </components.Menu>
    )
  }

  const debouncedLoadOptions = debounce(loadOptions, dbTime, {
    leading: false
  })

  return (
    <AsyncSelect
      id={id}
      aria-label={ariaLabel}
      key={reset}
      value={value}
      loadOptions={debouncedLoadOptions}
      defaultOptions={defaultOptions}
      cacheOptions
      placeholder={placeholder}
      onChange={onChange}
      isMulti={isMulti}
      // we cannot do isClearable with multi because
      // arr is null if user click X on all the pills
      // arr is [] if user clicks on the isClearable X
      // leads to lots of issues
      // https://github.com/JedWatson/react-select/issues/3632
      isClearable={isClearable && !isMulti}
      isLoading={isLoading}
      className={s.select}
      styles={{
        ...styles,
        ...(isPortal ? { menuPortal: base => ({ ...base, zIndex: 9999 }) } : {})
      }}
      menuPortalTarget={isPortal ? document.body : null}
      isDisabled={isDisabled}
      components={{ Menu, ...comps }}
      filterOption={filterOption}
      noOptionsMessage={noOptionsMessage}
    />
  )
}

export default BaseAsyncSelect
