import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeBlobRequest, makeGetRequest, makePutRequest } from 'utils/api'
import { Panel, Button, DataTableWrapper } from 'simple-core-ui'
import { useImmer } from 'use-immer'
import ReactTooltip from 'react-tooltip'
import APP_ACT from 'app/actions'
import { ActionsPopover } from './ActionsPopover'
import { ReasonModal } from '../ReasonModal'
import { getNormalizedCellContent, checkDuplicateReason } from '../utils'
import { Params } from '@common_types/common'
import { AdjustmentReason } from 'invoice_settings/types'
import { openBlob } from 'utils/helpers'
import { columns } from '../constants'

const BASE_URL = '/invoices/adjustment/reason/'

interface State {
  reason: AdjustmentReason
  reasons: Array<AdjustmentReason>
  reasonsDescList: Array<string>
  params: Params
  totalEntries: number
}

const baseReason = {
  long_description: ''
}

const initialState: State = {
  reason: baseReason as AdjustmentReason,
  reasons: [],
  reasonsDescList: [],
  params: {
    pageSize: 10,
    ordering: { columnKey: 'code', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  },
  totalEntries: 0
}

const AdjustmentReasonList = () => {
  const [localState, setLocalState] = useImmer(initialState)
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const { reason, reasons, reasonsDescList, params, totalEntries } = localState
  const dispatch = useDispatch()

  const notifyChange = (title: string, message: string, level: string) => {
    dispatch({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: { title, message, level }
    })
  }

  useEffect(() => {
    const fetchReasons = async () => {
      try {
        const { results } = await makeGetRequest(`${BASE_URL}list/`)
        setLocalState(draft => {
          draft.reasons = results
          draft.totalEntries = results.length
        })
      } catch (error) {
        notifyChange('Error', 'There was an issue fetching invoice adjustment reasons', 'error')
      }
    }

    fetchReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (updatedParams: Params) => {
    setLocalState(draft => {
      draft.params = updatedParams
    })
    ReactTooltip.hide()
  }

  const closeModal = () => {
    setShowReasonModal(false)
    setShowDuplicateError(false)
    setLocalState(draft => {
      draft.reason = initialState.reason
      draft.reasonsDescList = initialState.reasonsDescList
    })
  }

  const editReason = async () => {
    const { id, long_description } = reason
    try {
      const response = await makePutRequest(`${BASE_URL}${id}/edit/`, {
        description: long_description
      })
      const arr = [...reasons]
      const idx = arr.findIndex(ele => ele.id === id)
      arr[idx] = response
      setLocalState(draft => {
        draft.reasons = arr
      })
      notifyChange('Description edited successfully', '', 'success')
    } catch (error) {
      // in try catch, show the exact error instead of a generic one
      notifyChange('Error', 'There was an issue editing the invoice adjustment reason', 'error')
    }
    closeModal()
  }

  const openModal = (rowId: number) => {
    const descArr = reasons
      .filter(el => el.long_description)
      .map(ele => ele.long_description.trim().toLowerCase())
    const selectedReason = reasons.find(ele => ele.id === rowId) || baseReason
    const idx = descArr.indexOf(selectedReason.long_description?.toLowerCase())
    if (idx !== -1) {
      descArr.splice(idx, 1)
    }
    setLocalState(draft => {
      draft.reason = selectedReason as AdjustmentReason
      draft.reasonsDescList = descArr
    })

    setShowReasonModal(true)
  }

  const updateReasonDescription = (description: string) => {
    setLocalState(draft => {
      draft.reason.long_description = description
    })
    const isDuplicate = checkDuplicateReason(reasonsDescList, description)
    setShowDuplicateError(isDuplicate)
  }

  const handleDownload = async () => {
    try {
      const data = await makeBlobRequest({
        url: `${BASE_URL}list/`,
        method: 'GET',
        params: { export: true }
      })
      if (data.size) {
        openBlob(data, 'Adjustment Reasons Download.xlsx')
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const renderCustomAction = (row: AdjustmentReason) => (
    <ActionsPopover rowId={row.id} editReason={openModal} />
  )

  return (
    <Panel
      title="Manage Invoice Adjustment Reasons"
      rightActions={[
        <Button onClick={handleDownload} isSecondary key="xls">
          Download XLSX
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        totalEntries={totalEntries}
        rows={reasons}
        columns={columns}
        updateTable={updateTable}
        hasActions
        alwaysShowActions
        customAction={renderCustomAction}
        customActionsMemoizationDeps={[reasons]}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        getNormalizedCellContent={getNormalizedCellContent}
      />
      {showReasonModal && (
        <ReasonModal
          mode="adjustment"
          inputValue={reason.long_description}
          title="Edit Description"
          subtitle="Description"
          updateInputValue={updateReasonDescription}
          showDuplicateError={showDuplicateError}
          onChangeCb={editReason}
          onCancelCb={closeModal}
          inputPlaceholder="description"
          duplicateErrMsg="The description already exists. Please specify a different description."
        />
      )}
    </Panel>
  )
}

export default AdjustmentReasonList
