import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/constants'
import { formatName, sortAlphabeticallyByProperty } from 'utils/helpers'
import {
  APIAttachment,
  APIPriority,
  APISimpleMatter,
  APIStatus,
  APITask,
  APITaskType,
  Attachment,
  BulkEditValues,
  CanActivate,
  CustomizedOption,
  DateFormat,
  Option,
  Task
} from './types'

const { DEFAULT_DATE } = DATE_FORMATS

export const toTasks = (tasks: APITask[], callback?: (tasks: Task[]) => Task[]): Task[] => {
  const tasksArray = tasks.map((task: APITask) => {
    const {
      id,
      name,
      task_id,
      assignees,
      followers,
      status,
      due_date,
      priority,
      task_type,
      parent,
      children,
      comments,
      attachments,
      comments_count,
      attachments_count,
      description,
      is_private,
      subtasks = [],
      created_by,
      created_date,
      has_hidden_subtasks,
      related_matter
    } = task

    return {
      id,
      name,
      taskId: task_id,
      assignees: assignees.map(assignee => ({
        value: assignee.id,
        label: formatName(assignee) as string
      })),
      followers: followers.map(follower => ({
        value: follower.id,
        label: formatName(follower) as string
      })),
      status: status
        ? {
            id: status.id,
            name: status.client_settings?.display_name ?? status.default_display_name,
            color: status.client_settings?.color ?? status.default_color,
            occurredDate: moment(status.occurred_date).format(DEFAULT_DATE) as DateFormat,
            phase: status.phase.description
          }
        : null,
      dueDate: due_date ? (moment(due_date).format(DEFAULT_DATE) as DateFormat) : undefined,
      priority: priority
        ? {
            id: priority.id,
            name: priority.client_settings?.display_name ?? priority.default_display_name,
            color: priority.client_settings?.color ?? priority.default_color
          }
        : null,
      taskType: task_type
        ? {
            id: task_type.id,
            name: task_type.name
          }
        : null,
      parent: parent ? { id: parent.id, name: parent.name, isPrivate: parent.is_private } : null,
      expanded: children > 0,
      hidden: false,
      children,
      comments: comments || [],
      commentsCount: comments_count || (comments?.length ?? 0),
      fileAttachments: attachments || [],
      fileAttachmentsCount: attachments_count || (attachments?.length ?? 0),
      description,
      createdBy: created_by
        ? {
            value: created_by.id,
            label: formatName(created_by) as string
          }
        : null,
      createdDate: moment(created_date).format(DEFAULT_DATE),
      isPrivate: is_private,
      subtasks: subtasks.map(subtask => ({
        id: String(subtask.id),
        taskId: subtask.task_id,
        name: subtask.name,
        assignees: subtask.assignees.map(assignee => ({
          value: assignee.id,
          label: formatName(assignee) as string
        })),
        completed: subtask.status?.default_display_name === 'Complete' ?? false,
        dueDate: subtask.due_date
          ? (moment(subtask.due_date).format(DEFAULT_DATE) as DateFormat)
          : undefined,
        draft: false,
        ...(subtask.related_matter
          ? {
              relatedMatter: {
                value: subtask.related_matter.id,
                label: subtask.related_matter.name,
                status: subtask.related_matter.status,
                canEdit: subtask.related_matter.can_edit
              }
            }
          : {})
      })),
      hasHiddenSubtasks: has_hidden_subtasks,
      ...(parent ? { borderColor: '#3c99fd' } : {}),
      ...(related_matter
        ? {
            relatedMatter: {
              value: related_matter.id,
              label: related_matter.name,
              status: related_matter.status,
              canEdit: related_matter.can_edit
            }
          }
        : {})
    }
  })

  return callback ? callback(tasksArray) : tasksArray
}

export const fromAttachments = (attachments: APIAttachment[]) => {
  return attachments.reduce((acc, attachment): Attachment => {
    const { file_name, file_type, file_url, file_size, will_overwrite_file = false } = attachment
    acc[file_url] = {
      name: file_name,
      type: file_type,
      size: file_size,
      will_overwrite_file: will_overwrite_file
    }
    return acc
  }, {} as Attachment)
}

export const fromTask = (task: Task, scopeId: string, isEdit?: boolean) => {
  const {
    name,
    assignees,
    followers,
    status,
    dueDate,
    priority,
    taskType,
    description,
    isPrivate,
    subtasks,
    fileAttachments
  } = task
  if (isEdit) {
    return {
      ...(name !== undefined ? { name } : {}),
      ...(assignees !== undefined
        ? { assignee_ids: assignees.length ? assignees.map(assignee => assignee.value) : null }
        : {}),
      ...(followers !== undefined
        ? { follower_ids: followers.length ? followers.map(follower => follower.value) : null }
        : {}),
      ...(status !== undefined ? { task_status_id: status === null ? null : status?.id } : {}),
      ...(dueDate !== undefined ? { due_date: dueDate ? moment(dueDate).endOf('day') : '' } : {}),
      ...(priority !== undefined
        ? { task_priority_id: priority === null ? null : priority?.id }
        : {}),
      ...(taskType !== undefined ? { task_type_id: taskType === null ? null : taskType?.id } : {}),
      ...(description !== undefined ? { description } : {}),
      ...(isPrivate !== undefined ? { is_private: isPrivate } : {}),
      ...(subtasks !== undefined
        ? {
            subtasks: subtasks
              .filter(s => !s.draft)
              .map(subtask => ({
                ...(subtask.isNew ? {} : { id: subtask.id }),
                name: subtask.name,
                assignee_ids: subtask.assignees.map(assignee => assignee.value),
                due_date: subtask.dueDate ? moment(subtask.dueDate).endOf('day') : null,
                completed: subtask.completed
              }))
          }
        : {})
    }
  }

  return {
    name,
    assignee_ids: assignees?.map(assignee => assignee.value) ?? [],
    follower_ids: followers?.map(follower => follower.value) ?? [],
    task_status_id: status?.id ?? null,
    due_date: dueDate ? moment(dueDate).endOf('day') : '',
    task_priority_id: priority?.id ?? null,
    task_type_id: taskType?.id ?? null,
    description,
    is_private: isPrivate,
    comments: [],
    ...(isEdit
      ? {}
      : {
          files: fileAttachments.length ? JSON.stringify(fromAttachments(fileAttachments)) : null
        }),
    task_parent_id: scopeId ?? null,
    subtasks: subtasks
      .filter(s => !s.draft)
      .map(subtask => ({
        ...(subtask.isNew ? {} : { id: subtask.id }),
        name: subtask.name,
        assignee_ids: subtask.assignees.map(assignee => assignee.value),
        due_date: subtask.dueDate ? moment(subtask.dueDate).endOf('day') : null,
        completed: subtask.completed
      }))
  }
}

/**
 *
 * @param statuses The list of status dtos.
 * @returns A list of status view models to be rendered in a select list.
 */
export const toStatusesOptions = (
  statuses: APIStatus[]
): (CustomizedOption & { phase?: string })[] => {
  return statuses.map(option => {
    const { id, default_display_name, client_settings, phase } = option

    return {
      value: id,
      label: client_settings?.display_name ?? default_display_name,
      color: client_settings?.color ?? option.default_color,
      isActive: client_settings?.is_active ?? true,
      ...(phase ? { phase: phase.description } : {})
    }
  })
}

/**
 *
 * @param options A list of select options that may have been deactivated by
 * the client.
 * @returns A filtered list, containing only options that have not been
 * deactivated.
 */
export const onlyActiveOptions = <T extends CanActivate>(options: T[]): T[] => {
  return options.filter(o => o.isActive)
}

export const toNamesOptions = (
  tasks: APITask[]
): { value: number; label: string; taskId: string }[] => {
  const options = tasks

  const map = options.map(option => {
    const { id, name, task_id } = option

    return {
      value: id,
      label: name,
      taskId: task_id
    }
  })

  return orderBy(map, ['label'], ['asc'])
}

const extractNumericParts = (taskId: string): number[] => {
  const numericParts: string[] = taskId
    .replace(/[^0-9-]/g, '')
    .split('-')
    .filter(Boolean)
  return numericParts.map(part => parseInt(part))
}

export const toTaskIdsOptions = (tasks: APITask[]): { value: string; label: string }[] => {
  const sortedOptions = tasks
    .map(option => {
      const { task_id } = option

      return {
        value: task_id,
        label: task_id
      }
    })
    .sort((a, b) => {
      const numericPartsA = extractNumericParts(a.label)
      const numericPartsB = extractNumericParts(b.label)

      for (let i = 0; i < numericPartsA.length && i < numericPartsB.length; i++) {
        const numberA = numericPartsA[i]
        const numberB = numericPartsB[i]

        if (numberA !== numberB) {
          return numberA - numberB
        }
      }

      return numericPartsA.length - numericPartsB.length
    })

  return sortedOptions
}

/**
 *
 * @param priorities A list of priority DTOs.
 * @returns A list of priority view models to be rendered in a select list.
 */
export const toPrioritiesOptions = (priorities: APIPriority[]): CustomizedOption[] => {
  return priorities.map(option => {
    const { id, default_display_name, client_settings } = option

    return {
      value: id,
      label: client_settings?.display_name ?? default_display_name,
      color: client_settings?.color ?? option.default_color,
      isActive: client_settings?.is_active ?? true
    }
  })
}

/**
 *
 * @param taskTypes A list of task type DTOs.
 * @returns A list of task type view models to be rendered in a select list.
 */
export const toTaskTypesOptions = (taskTypes: APITaskType[]): CustomizedOption[] => {
  return taskTypes.map((type: APITaskType) => {
    const { id, name, is_active } = type

    return {
      value: id,
      label: name,
      isActive: is_active
    }
  })
}

export const serializeBulkEditOptions = (options: BulkEditValues, taskIds: Array<number>) => {
  const mapUsers = (users: Array<Option>) => users.map(option => option.value)
  return {
    task_ids: taskIds,
    ...(!!options.assigneesToAdd.length && { assignee_ids: mapUsers(options.assigneesToAdd) }),
    ...(!!options.followersToAdd.length && { follower_ids: mapUsers(options.followersToAdd) }),
    ...(!!options.assigneesToRemove.length && {
      remove_assignee_ids: mapUsers(options.assigneesToRemove)
    }),
    ...(!!options.followersToRemove.length && {
      remove_follower_ids: mapUsers(options.followersToRemove)
    }),
    ...(options.priority !== null && { task_priority_id: options.priority.value }),
    ...(options.status !== null && { task_status_id: options.status.value }),
    ...(options.taskType !== null && { task_type_id: options.taskType.value }),
    ...(!!options.dueDate && { due_date: moment(options.dueDate).endOf('day') })
  }
}

export const toMattersOptions = (response: APISimpleMatter[], excludeClosed = false) => {
  const statusesToExclude = excludeClosed ? ['inactive', 'closed'] : ['inactive']
  const arr = response
    .filter(m => !statusesToExclude.includes(m.status))
    .map(m => ({
      value: m.id,
      label: m.name,
      clientMatterId: m.client_matter_id,
      status: m.status
    }))

  return sortAlphabeticallyByProperty(arr, 'label')
}
