import { useMemo } from 'react'
import s from './ActionsPopover.scss'
import { HoverAction } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

const ActionsPopover = ({
  rowId,
  category,
  deleteBudget,
  archiveBudget,
  revertBudget,
  matterBudget,
  isExternal,
  areLocked,
  approveBudget,
  vendorBudget,
  mattergroupBudget,
  costcodeBudget,
  matterGroupLabels,
  draftBudget,
  canEdit
}) => {
  const options = useMemo(() => {
    return {
      draft: [
        { key: 'approve', label: 'Approve' },
        { key: 'archive', label: 'Archive' },
        ...(!isExternal
          ? [
              { key: 'matter', label: 'Edit Matter' },
              { key: 'vendor', label: 'Edit Vendor' },
              { key: 'mattergroup', label: `Edit ${matterGroupLabels[0]}` },
              { key: 'costcode', label: 'Edit Cost Code' },
              { key: 'delete', label: 'Delete' }
            ]
          : [])
      ],
      approved: [
        { key: 'archive', label: 'Archive' },
        ...(!isExternal && !areLocked ? [{ key: 'draft', label: 'Draft' }] : [])
      ],
      archived: [{ key: 'revert', label: 'Revert Status' }]
    }
  }, [isExternal, areLocked, matterGroupLabels])

  const handleClick = (event, action) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    ReactTooltip.hide()
    const actionSwitch = {
      delete: deleteBudget,
      archive: archiveBudget,
      matter: matterBudget,
      approve: approveBudget,
      revert: revertBudget,
      vendor: vendorBudget,
      mattergroup: mattergroupBudget,
      costcode: costcodeBudget,
      draft: draftBudget
    }
    actionSwitch[action](rowId)
  }

  return (
    canEdit && (
      <>
        <span
          data-for={`item-actions-${rowId}`}
          aria-describedby={`item-actions-${rowId}`}
          aria-label={`item-${rowId}`}
          className={s.actionsContainer}
          data-event="click"
          data-tip
          data-testid={`item-actions-${rowId}`}
        >
          <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
        </span>
        <ReactTooltip
          id={`item-actions-${rowId}`}
          type="light"
          effect="solid"
          place="bottom"
          border
          clickable
        >
          <ul className={s.actionsList}>
            {options[category].map(({ key, label }) => (
              <li
                key={key + label}
                className={s.actionItem}
                onClick={e => {
                  handleClick(e, key)
                }}
              >
                {label}
              </li>
            ))}
          </ul>
        </ReactTooltip>
      </>
    )
  )
}

export default ActionsPopover
