import { Action, AvailableField, CustomAttrRhs, Operator } from './api'
import { Constant, LogicalOperator, RuleTypeEnum } from './common'

export enum RhsTypes {
  Numeric = 'numeric',
  Slider = 'slider',
  Text = 'text',
  AsyncSearch = 'async_search',
  MultiSelect = 'multi_select',
  Creatable = 'creatable',
  Currency = 'currency'
}

export interface Operand {
  id: string
  lhs: AvailableField | null
  op: Operator | null
  rhs:
    | {
        constant: Constant | Array<Constant>
        sub_type: string | null
        type: string
      }
    | CustomAttrRhs
    | null
}

export interface Condition {
  id: string
  op: LogicalOperator
  operands: Array<Operand | Condition>
}

export interface Rule {
  name: string
  text: string
  categoryId: number | null
  description: string
  isActive: boolean
  condition: Condition
  actions: Array<Action>
  preSeedCode?: string | null
  type?: RuleTypeEnum
}

export interface Version {
  id: string
  date: string
  time: string
  rawDate: string
  modifiedBy: string
  changesMade: string | JSX.Element
}

export interface VersionDifference {
  versions: Version[]
}

export interface FlagAction {
  flagCode: string
  comment: string
}
